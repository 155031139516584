import * as React from "react";

import { css, StyleSheet } from "aphrodite";

// @ts-ignore
import { convertFromHTML } from "draft-convert";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import * as _ from "lodash";
import "../styles/RichInputText.css";
import { toast } from "styleguide";

interface IProp {
    className?: string;
    onChange: (text: string) => void;
    initialValue?: string;
    limit?: number;
    allowBullets?: boolean;
}

interface IState {
    input: EditorState;
}

export class RichInputText extends React.Component<IProp, IState> {
    private debounceOnChange: (text: string) => void;

    constructor(props: IProp) {
        super(props);

        this.state = { input: EditorState.createEmpty() };

        this.debounceOnChange = _.debounce(this.props.onChange, 1500);
    }

    public onChange = (input: EditorState) => {
        const currentContentState = this.state.input.getCurrentContent();
        const newContentState = input.getCurrentContent();

        if (currentContentState !== newContentState) {
            this.debounceOnChange(stateToHTML(input.getCurrentContent()));
        }

        this.setState({ input });
    };

    public handleKeyCommand = (command: string, input: EditorState) => {
        const newState = RichUtils.handleKeyCommand(input, command);
        if (newState) {
            this.onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    public onMouseDownStyle = (action: string) => (e: any) => {
        e.preventDefault();

        const input = RichUtils.toggleInlineStyle(this.state.input, action);

        this.setState({ input });

        this.debounceOnChange(stateToHTML(input.getCurrentContent()));
    };

    public addBulletPoint = (e: any) => {
        e.preventDefault();

        const input = RichUtils.toggleBlockType(this.state.input, "unordered-list-item");

        this.setState({ input });

        this.debounceOnChange(stateToHTML(input.getCurrentContent()));
    };

    public onClickStyle = (e: any) => {
        e.preventDefault();
    };

    public componentDidUpdate = (prevProps: IProp) => {
        if (!prevProps.initialValue && this.props.initialValue) {
            const text = _.chain(this.props.initialValue)
                .replace(/\n<p><br><\/p>/g, "<p></p>")
                .replace(/\n\n/g, "<p></p>")
                .value();

            const blocksFromHTML = convertFromHTML(text);
            const input = EditorState.createWithContent(blocksFromHTML);

            this.setState({ input });
            this.debounceOnChange(text);
        }
    };

    public handlePastedText = (text: string) => {
        const { limit } = this.props;

        const currentContent = this.state.input.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;

        if (limit && currentContentLength + text.length > limit) {
            toast(`Copied text is longer than ${limit} characters`);
            return "handled";
        }

        const newContent = Modifier.replaceText(this.state.input.getCurrentContent(), this.state.input.getSelection(), text);

        this.onChange(EditorState.push(this.state.input, newContent, "insert-characters"));

        return "not-handled";
    };

    _handleBeforeInput = () => {
        const { limit } = this.props;

        if (!limit) return "not-handled";

        const currentContent = this.state.input.getCurrentContent();
        const currentContentLength = currentContent.getPlainText("").length;

        if (currentContentLength > limit - 1) {
            return "handled";
        }

        return "not-handled";
    };

    public render() {
        const { className, allowBullets } = this.props;
        let extraProps = {};

        if (className) {
            extraProps = { ...extraProps, blockStyleFn: () => className };
        }

        return (
            <>
                <div>
                    <button onMouseDown={this.onMouseDownStyle("UNDERLINE")} onClick={this.onClickStyle} className={css(styles.button)} name="UNDERLINE">
                        U
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("BOLD")} onClick={this.onClickStyle} className={css(styles.button)} name="BOLD">
                        <b>B</b>
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("ITALIC")} onClick={this.onClickStyle} className={css(styles.button)} name="ITALIC">
                        /
                    </button>
                    {allowBullets && (
                        <button onMouseDown={this.addBulletPoint} onClick={this.onClickStyle} className={css(styles.button)} name="BULLET">
                            <svg style={{ transform: "translateY(1px)" }} width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.33333 0.666677H12V2.00001H3.33333V0.666677ZM1 2.33334C0.447713 2.33334 0 1.88563 0 1.33334C0 0.781057 0.447713 0.333344 1 0.333344C1.55229 0.333344 2 0.781057 2 1.33334C2 1.88563 1.55229 2.33334 1 2.33334ZM1 7.00001C0.447713 7.00001 0 6.55228 0 6.00001C0 5.44774 0.447713 5.00001 1 5.00001C1.55229 5.00001 2 5.44774 2 6.00001C2 6.55228 1.55229 7.00001 1 7.00001ZM1 11.6C0.447713 11.6 0 11.1523 0 10.6C0 10.0477 0.447713 9.60001 1 9.60001C1.55229 9.60001 2 10.0477 2 10.6C2 11.1523 1.55229 11.6 1 11.6ZM3.33333 5.33334H12V6.66668H3.33333V5.33334ZM3.33333 10H12V11.3333H3.33333V10Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    )}
                </div>
                <Editor
                    editorState={this.state.input}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                    // @ts-ignore
                    handlePastedText={this.handlePastedText}
                    handleBeforeInput={this._handleBeforeInput}
                    {...extraProps}
                />
            </>
        );
    }
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: "#039be5",
        border: 0,
        borderRadius: 40,
        color: "white",
        cursor: "pointer",
        margin: "15px 5px 15px 0px",
        padding: "10px 0px",
        width: 37,
    },
});

export default RichInputText;
