import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import { IState } from "../reducers";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { FETCH_PODCAST_PLATFORMS_FAILURE, FETCH_PODCAST_PLATFORMS_REQUEST, FETCH_PODCAST_PLATFORMS_SUCCESS } from "src/constants/podcastplatforms";
import { IPodcastPlatform } from "src/types/podcast-platforms";

export const fetchPodcastPlatforms = (params?: IParams, reset?: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: FETCH_PODCAST_PLATFORMS_REQUEST });

    const url = buildUrl(`${config.api.url}${config.api.paths.podcastplatforms}`, params);

    return fetch(url)
        .then(responseCheck)
        .then((platforms) => dispatch(fetchPodcastPlatformsSuccess(platforms)))
        .catch((error) => dispatch(fetchPodcastPlatformsFailure(error)));
};

const fetchPodcastPlatformsSuccess = (platforms: IPodcastPlatform[]) => {
    return { type: FETCH_PODCAST_PLATFORMS_SUCCESS, platforms };
};

const fetchPodcastPlatformsFailure = (error: Error) => {
    toast("Error fetching podcast platforms");
    return { type: FETCH_PODCAST_PLATFORMS_FAILURE, error };
};
