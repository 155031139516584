import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { clearTemplateState, createTemplate, editTemplate, fetchTemplate } from "../actions/template";
import TemplateForm from "../components/TemplateForm";
import { IState } from "../reducers";
import { createDefaultTemplate } from "../services/template";
import * as errors from "../types/errors";
import ITemplate from "../types/template";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    error?: errors.HttpError;
    template: ITemplate;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    clearTemplateState: typeof clearTemplateState;
    createTemplate: typeof createTemplate;
    editTemplate: typeof editTemplate;
    fetchTemplate: typeof fetchTemplate;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { template } = this.props;

        const params = {
            initialValues: { ...template, error: this.props.error },
            onSubmit: this.submitForm,
            template,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Template">
                    <TemplateForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (template: ITemplate | any) => {
        const { template: oldTemplate, history } = this.props;

        if (oldTemplate && oldTemplate.templateId) {
            return this.props.editTemplate(template, history);
        }
        return this.props.createTemplate(template, history);
    };

    public componentWillUnmount() {
        this.props.clearTemplateState();
    }

    public componentDidMount() {
        const { match } = this.props;

        if (match.params.id) {
            this.props.fetchTemplate(+match.params.id);
        }
    }

    public render() {
        const title = `Template Editor | ${this.props.template && this.props.template.templateName ? this.props.template.templateName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { TemplateForm: templateForm },
        template: { template },
    } = state;

    const templateValues = templateForm ? templateForm.values : {};

    const preTemplate = correctValue("templateId", templateValues, template);
    const validatedTemplate = preTemplate ? preTemplate : createDefaultTemplate();

    return {
        error: state.template.error,
        isLoading: state.template.isLoading,
        template: validatedTemplate,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearTemplateState,
            createTemplate,
            editTemplate,
            fetchTemplate,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
