import * as errors from "../types/errors";

import {
  CLEAR_JOURNALIST_STATE,
  CREATE_JOURNALIST_FAILURE,
  CREATE_JOURNALIST_REQUEST,
  CREATE_JOURNALIST_SUCCESS,
  FETCH_JOURNALIST_FAILURE,
  FETCH_JOURNALIST_REQUEST,
  FETCH_JOURNALIST_SUCCESS,
  FETCH_JOURNALISTS_FAILURE,
  FETCH_JOURNALISTS_REQUEST,
  FETCH_JOURNALISTS_SUCCESS,
  UPDATE_JOURNALIST_FAILURE,
  UPDATE_JOURNALIST_REQUEST,
  UPDATE_JOURNALIST_SUCCESS
} from "../constants/journalist";
import IJournalist from "../types/journalist";

export interface IState {
  isLoading: boolean;
  error?: errors.HttpError;
  journalists: IJournalist[];
  journalist?: IJournalist;
  offset: number;
}

export interface IAction {
  type: string;
  error?: errors.HttpError;
  journalists?: IJournalist[];
  journalist?: IJournalist;
  offset?: number;
}

export const initialState: IState = {
  isLoading: false,
  journalists: [],
  offset: 0
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_JOURNALISTS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_JOURNALISTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        journalists: action.journalists || [],
        offset: action.offset || 0
      };
    case FETCH_JOURNALISTS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_JOURNALIST_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_JOURNALIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        journalist: action.journalist
      };
    case FETCH_JOURNALIST_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_JOURNALIST_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_JOURNALIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        journalist: action.journalist
      };
    case CREATE_JOURNALIST_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case UPDATE_JOURNALIST_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_JOURNALIST_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_JOURNALIST_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_JOURNALIST_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
