const languages = [
  {
    id: 1,
    name: "Afar"
  },
  {
    id: 2,
    name: "Abkhazian"
  },
  {
    id: 3,
    name: "Avestan"
  },
  {
    id: 4,
    name: "Afrikaans"
  },
  {
    id: 5,
    name: "Akan"
  },
  {
    id: 6,
    name: "Amharic"
  },
  {
    id: 7,
    name: "Aragonese"
  },
  {
    id: 8,
    name: "Arabic"
  },
  {
    id: 9,
    name: "Assamese"
  },
  {
    id: 10,
    name: "Avaric"
  },
  {
    id: 11,
    name: "Aymara"
  },
  {
    id: 12,
    name: "Azerbaijani"
  },
  {
    id: 13,
    name: "Bashkir"
  },
  {
    id: 14,
    name: "Belarusian"
  },
  {
    id: 15,
    name: "Bulgarian"
  },
  {
    id: 16,
    name: "Bihari languages"
  },
  {
    id: 17,
    name: "Bislama"
  },
  {
    id: 18,
    name: "Bambara"
  },
  {
    id: 19,
    name: "Bengali"
  },
  {
    id: 20,
    name: "Tibetan"
  },
  {
    id: 21,
    name: "Breton"
  },
  {
    id: 22,
    name: "Bosnian"
  },
  {
    id: 23,
    name: "Catalan; Valencian"
  },
  {
    id: 24,
    name: "Chechen"
  },
  {
    id: 25,
    name: "Chamorro"
  },
  {
    id: 26,
    name: "Corsican"
  },
  {
    id: 27,
    name: "Cree"
  },
  {
    id: 28,
    name: "Czech"
  },
  {
    id: 29,
    name:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
  },
  {
    id: 30,
    name: "Chuvash"
  },
  {
    id: 31,
    name: "Welsh"
  },
  {
    id: 32,
    name: "Danish"
  },
  {
    id: 33,
    name: "German"
  },
  {
    id: 34,
    name: "Divehi; Dhivehi; Maldivian"
  },
  {
    id: 35,
    name: "Dzongkha"
  },
  {
    id: 36,
    name: "Ewe"
  },
  {
    id: 37,
    name: "Greek, Modern (1453-),"
  },
  {
    id: 38,
    name: "English"
  },
  {
    id: 39,
    name: "Esperanto"
  },
  {
    id: 40,
    name: "Spanish; Castilian"
  },
  {
    id: 41,
    name: "Estonian"
  },
  {
    id: 42,
    name: "Basque"
  },
  {
    id: 43,
    name: "Persian"
  },
  {
    id: 44,
    name: "Fulah"
  },
  {
    id: 45,
    name: "Finnish"
  },
  {
    id: 46,
    name: "Fijian"
  },
  {
    id: 47,
    name: "Faroese"
  },
  {
    id: 48,
    name: "French"
  },
  {
    id: 49,
    name: "Western Frisian"
  },
  {
    id: 50,
    name: "Irish"
  },
  {
    id: 51,
    name: "Gaelic; Scottish Gaelic"
  },
  {
    id: 52,
    name: "Galician"
  },
  {
    id: 53,
    name: "Guarani"
  },
  {
    id: 54,
    name: "Gujarati"
  },
  {
    id: 55,
    name: "Manx"
  },
  {
    id: 56,
    name: "Hausa"
  },
  {
    id: 57,
    name: "Hebrew"
  },
  {
    id: 58,
    name: "Hindi"
  },
  {
    id: 59,
    name: "Hiri Motu"
  },
  {
    id: 60,
    name: "Croatian"
  },
  {
    id: 61,
    name: "Haitian; Haitian Creole"
  },
  {
    id: 62,
    name: "Hungarian"
  },
  {
    id: 63,
    name: "Armenian"
  },
  {
    id: 64,
    name: "Herero"
  },
  {
    id: 65,
    name: "Interlingua (International Auxiliary Language Association)"
  },
  {
    id: 66,
    name: "Indonesian"
  },
  {
    id: 67,
    name: "Interlingue; Occidental"
  },
  {
    id: 68,
    name: "Igbo"
  },
  {
    id: 69,
    name: "Sichuan Yi; Nuosu"
  },
  {
    id: 70,
    name: "Inupiaq"
  },
  {
    id: 71,
    name: "Ido"
  },
  {
    id: 72,
    name: "Icelandic"
  },
  {
    id: 73,
    name: "Italian"
  },
  {
    id: 74,
    name: "Inuktitut"
  },
  {
    id: 75,
    name: "Japanese"
  },
  {
    id: 76,
    name: "Javanese"
  },
  {
    id: 77,
    name: "Georgian"
  },
  {
    id: 78,
    name: "Kongo"
  },
  {
    id: 79,
    name: "Kikuyu; Gikuyu"
  },
  {
    id: 80,
    name: "Kuanyama; Kwanyama"
  },
  {
    id: 81,
    name: "Kazakh"
  },
  {
    id: 82,
    name: "Kalaallisut; Greenlandic"
  },
  {
    id: 83,
    name: "Central Khmer"
  },
  {
    id: 84,
    name: "Kannada"
  },
  {
    id: 85,
    name: "Korean"
  },
  {
    id: 86,
    name: "Kanuri"
  },
  {
    id: 87,
    name: "Kashmiri"
  },
  {
    id: 88,
    name: "Kurdish"
  },
  {
    id: 89,
    name: "Komi"
  },
  {
    id: 90,
    name: "Cornish"
  },
  {
    id: 91,
    name: "Kirghiz; Kyrgyz"
  },
  {
    id: 92,
    name: "Latin"
  },
  {
    id: 93,
    name: "Luxembourgish; Letzeburgesch"
  },
  {
    id: 94,
    name: "Ganda"
  },
  {
    id: 95,
    name: "Limburgan; Limburger; Limburgish"
  },
  {
    id: 96,
    name: "Lingala"
  },
  {
    id: 97,
    name: "Lao"
  },
  {
    id: 98,
    name: "Lithuanian"
  },
  {
    id: 99,
    name: "Luba-Katanga"
  },
  {
    id: 100,
    name: "Latvian"
  },
  {
    id: 101,
    name: "Malagasy"
  },
  {
    id: 102,
    name: "Marshallese"
  },
  {
    id: 103,
    name: "Maori"
  },
  {
    id: 104,
    name: "Macedonian"
  },
  {
    id: 105,
    name: "Malayalam"
  },
  {
    id: 106,
    name: "Mongolian"
  },
  {
    id: 107,
    name: "Marathi"
  },
  {
    id: 108,
    name: "Malay"
  },
  {
    id: 109,
    name: "Maltese"
  },
  {
    id: 110,
    name: "Burmese"
  },
  {
    id: 111,
    name: "Nauru"
  },
  {
    id: 112,
    name: "Bokmål, Norwegian; Norwegian Bokmål"
  },
  {
    id: 113,
    name: "Ndebele, North; North Ndebele"
  },
  {
    id: 114,
    name: "Nepali"
  },
  {
    id: 115,
    name: "Ndonga"
  },
  {
    id: 116,
    name: "Dutch; Flemish"
  },
  {
    id: 117,
    name: "Norwegian Nynorsk; Nynorsk, Norwegian"
  },
  {
    id: 118,
    name: "Norwegian"
  },
  {
    id: 119,
    name: "Ndebele, South; South Ndebele"
  },
  {
    id: 120,
    name: "Navajo; Navaho"
  },
  {
    id: 121,
    name: "Chichewa; Chewa; Nyanja"
  },
  {
    id: 122,
    name: "Occitan (post 1500; Provençal"
  },
  {
    id: 123,
    name: "Ojibwa"
  },
  {
    id: 124,
    name: "Oromo"
  },
  {
    id: 125,
    name: "Oriya"
  },
  {
    id: 126,
    name: "Ossetian; Ossetic"
  },
  {
    id: 127,
    name: "Panjabi; Punjabi"
  },
  {
    id: 128,
    name: "Pali"
  },
  {
    id: 129,
    name: "Polish"
  },
  {
    id: 130,
    name: "Pushto; Pashto"
  },
  {
    id: 131,
    name: "Portuguese"
  },
  {
    id: 132,
    name: "Quechua"
  },
  {
    id: 133,
    name: "Romansh"
  },
  {
    id: 134,
    name: "Rundi"
  },
  {
    id: 135,
    name: "Romanian; Moldavian; Moldovan"
  },
  {
    id: 136,
    name: "Russian"
  },
  {
    id: 137,
    name: "Kinyarwanda"
  },
  {
    id: 138,
    name: "Sanskrit"
  },
  {
    id: 139,
    name: "Sardinian"
  },
  {
    id: 140,
    name: "Sindhi"
  },
  {
    id: 141,
    name: "Northern Sami"
  },
  {
    id: 142,
    name: "Sango"
  },
  {
    id: 143,
    name: "Sinhala; Sinhalese"
  },
  {
    id: 144,
    name: "Slovak"
  },
  {
    id: 145,
    name: "Slovenian"
  },
  {
    id: 146,
    name: "Samoan"
  },
  {
    id: 147,
    name: "Shona"
  },
  {
    id: 148,
    name: "Somali"
  },
  {
    id: 149,
    name: "Albanian"
  },
  {
    id: 150,
    name: "Serbian"
  },
  {
    id: 151,
    name: "Swati"
  },
  {
    id: 152,
    name: "Sotho, Southern"
  },
  {
    id: 153,
    name: "Sundanese"
  },
  {
    id: 154,
    name: "Swedish"
  },
  {
    id: 155,
    name: "Swahili"
  },
  {
    id: 156,
    name: "Tamil"
  },
  {
    id: 157,
    name: "Telugu"
  },
  {
    id: 158,
    name: "Tajik"
  },
  {
    id: 159,
    name: "Thai"
  },
  {
    id: 160,
    name: "Tigrinya"
  },
  {
    id: 161,
    name: "Turkmen"
  },
  {
    id: 162,
    name: "Tagalog"
  },
  {
    id: 163,
    name: "Tswana"
  },
  {
    id: 164,
    name: "Tonga (Tonga Islands)"
  },
  {
    id: 165,
    name: "Turkish"
  },
  {
    id: 166,
    name: "Tsonga"
  },
  {
    id: 167,
    name: "Tatar"
  },
  {
    id: 168,
    name: "Twi"
  },
  {
    id: 169,
    name: "Tahitian"
  },
  {
    id: 170,
    name: "Uighur; Uyghur"
  },
  {
    id: 171,
    name: "Ukrainian"
  },
  {
    id: 172,
    name: "Urdu"
  },
  {
    id: 173,
    name: "Uzbek"
  },
  {
    id: 174,
    name: "Venda"
  },
  {
    id: 175,
    name: "Vietnamese"
  },
  {
    id: 176,
    name: "Volapük"
  },
  {
    id: 177,
    name: "Walloon"
  },
  {
    id: 178,
    name: "Wolof"
  },
  {
    id: 179,
    name: "Xhosa"
  },
  {
    id: 180,
    name: "Yiddish"
  },
  {
    id: 181,
    name: "Yoruba"
  },
  {
    id: 182,
    name: "Zhuang; Chuang"
  },
  {
    id: 183,
    name: "Chinese"
  },
  {
    id: 184,
    name: "Zulu"
  }
];

export default languages;
