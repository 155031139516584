export const FETCH_ARTICLES_REQUEST = "FETCH_ARTICLES_REQUEST";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_FAILURE = "FETCH_ARTICLES_FAILURE";

export const FETCH_ARTICLE_REQUEST = "FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const TOGGLE_PUBLISH_ARTICLE_REQUEST = "TOGGLE_PUBLISH_ARTICLE_REQUEST";
export const TOGGLE_PUBLISH_ARTICLE_SUCCESS = "TOGGLE_PUBLISH_ARTICLE_SUCCESS";
export const TOGGLE_PUBLISH_ARTICLE_FAILURE = "TOGGLE_PUBLISH_ARTICLE_FAILURE";

export const EDIT_ARTICLE_REQUEST = "EDIT_ARTICLE_REQUEST";
export const EDIT_ARTICLE_SUCCESS = "EDIT_ARTICLE_SUCCESS";
export const EDIT_ARTICLE_FAILURE = "EDIT_ARTICLE_FAILURE";

export const RECONCILE_ARTICLE = "RECONCILE_ARTICLE";
export const CLEAR_ARTICLE_STATE = "CLEAR_ARTICLE_STATE";

export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAILURE = "CREATE_ARTICLE_FAILURE";

export const MOVE_UP_ARTICLE_REQUEST = "MOVE_UP_ARTICLE_REQUEST";
export const MOVE_UP_ARTICLE_SUCCESS = "MOVE_UP_ARTICLE_SUCCESS";
export const MOVE_UP_ARTICLE_FAILURE = "MOVE_UP_ARTICLE_FAILURE";

export const MOVE_DOWN_ARTICLE_REQUEST = "MOVE_DOWN_ARTICLE_REQUEST";
export const MOVE_DOWN_ARTICLE_SUCCESS = "MOVE_DOWN_ARTICLE_SUCCESS";
export const MOVE_DOWN_ARTICLE_FAILURE = "MOVE_DOWN_ARTICLE_FAILURE";