import * as _ from "lodash";

import IPlaylist from "../types/playlist";
import ISection from "../types/section";
import SectionTypes from "../types/section-types";
import { IParams } from "../utils/build-url";

export const requestAllSections = (): IParams => {
    return {
        orderBy: "ASC",
        sortBy: "sectionName",
        filter: [{ name: "parentId", value: null }],
    };
};

export const requestPublishedParentSections = (): IParams => {
    return {
        filter: [
            { name: "sectionIsPublished", value: 1 },
            { name: "parentId", value: null },
        ],
        orderBy: "ASC",
        sortBy: "sectionName",
    };
};

export const createDefaultSection = (): ISection => {
    return {
        sectionId: 0,
        sectionIsPublished: 0,
        sectionName: "",
        sectionType: SectionTypes.Regular,
    };
};

export const getSectionsFromPlaylists = (playlists: IPlaylist[]) => {
    const sections = _.chain(playlists)
        .map((p) => p.sections || [])
        .flatten()
        .uniqBy((s) => s.sectionId)
        .orderBy(["sectionIsPublished", "sectionName"], ["desc", "asc"])
        .value();

    return sections.map((section) => {
        const sectionPlaylists = _.filter(playlists, (p) => !!_.find(p.sections, (s) => s.sectionId === section.sectionId));

        section.playlists = _.sortBy(sectionPlaylists, (p) => p.playlistName);

        return section;
    });
};
