import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { updateFile } from "../actions/file";
import { clearSectionState, createSection, editSection, fetchSection, fetchSections } from "../actions/section";
import SectionForm from "../components/SectionForm";
import { IState } from "../reducers";
import { createDefaultSection } from "../services/section";
import * as errors from "../types/errors";
import ISection from "../types/section";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import SectionTypes from "src/types/section-types";

interface IPropsFromState {
    error?: errors.HttpError;
    section: ISection;
    isLoading: boolean;
    sections: ISection[];
}

interface IPropsFromDispatch {
    clearSectionState: typeof clearSectionState;
    createSection: typeof createSection;
    editSection: typeof editSection;
    fetchSection: typeof fetchSection;
    updateFile: typeof updateFile;
    fetchSections: typeof fetchSections;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { section, sections } = this.props;

        const params = {
            initialValues: { ...section, error: this.props.error },
            onSubmit: this.submitForm,
            section,
            updateFile: this.props.updateFile,
            parentSections: sections.filter((section) => !section.parentId),
            isParentSelectable: !section.sectionId,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Category/Subcategory">
                    <SectionForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (section: ISection | any) => {
        const { section: oldSection, history } = this.props;

        if (oldSection && oldSection.sectionId) {
            return this.props.editSection(section, history);
        }
        return this.props.createSection(section, history);
    };

    public componentWillUnmount() {
        this.props.clearSectionState();
    }

    public componentDidMount() {
        const { match } = this.props;

        if (match.params.id) {
            this.props.fetchSection(+match.params.id);
        }

        this.props.fetchSections({
            filter: [{ name: "sectionType", value: SectionTypes.Regular }],
        });
    }

    public render() {
        const title = `Categories Editor | ${this.props.section && this.props.section.sectionName ? this.props.section.sectionName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { SectionForm: sectionForm },
        section: { section },
    } = state;

    const sectionValues = sectionForm ? sectionForm.values : {};

    const preSection = correctValue("sectionId", sectionValues, section);
    const validatedSection = preSection ? preSection : createDefaultSection();

    return {
        error: state.section.error,
        isLoading: state.section.isLoading,
        section: validatedSection,
        sections: state.section.sections,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSectionState,
            createSection,
            editSection,
            fetchSection,
            updateFile,
            fetchSections,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
