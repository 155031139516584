import * as errors from "../types/errors";

import {
  FETCH_METRIC_FAILURE,
  FETCH_METRIC_REQUEST,
  FETCH_METRIC_SUCCESS
} from "../constants/metric";

export interface IState {
  metric?: any;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  metric?: any;
  error?: errors.HttpError;
}

export const initialState: IState = {
  isLoading: false
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_METRIC_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_METRIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        metric: action.metric
      };
    case FETCH_METRIC_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
