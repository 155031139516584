import { History } from "history";
import { Dispatch } from "redux";
import * as Cookies from 'js-cookie';

import config from "../config";
import HttpMethods from "../types/http-methods";
import ILogin from "../types/login";
import IUser from "../types/user";
import fetch from "../utils/fetch";

import {
  CLEAR_AUTH,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT
} from "../constants/auth";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const login = (loginParams: ILogin, history: History) => (
  dispatch: Dispatch
) => {
  dispatch({ type: LOGIN_USER_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.login}`, {
    body: JSON.stringify(loginParams),
    method: HttpMethods.POST
  })
    .then(responseCheck)
    .then(user => dispatch(loginSuccess(user)))
    .then(() => history.push(config.paths.dashboard))
    .catch(error => dispatch(loginFailure(error)));
};

export const logout = () => {
  // @ts-ignore
  window.Raven.clearContext();
  return { type: LOGOUT };
};

/*
    SYNC ACTIONS
*/

const loginSuccess = (user: IUser) => {
  // @ts-ignore
  window.Raven.setUserContext({ login: user.userLogin });
  Cookies.set('NoaUserToken', user.token)

  return { type: LOGIN_USER_SUCCESS, user };
};

const loginFailure = (error: Error) => {
  return { type: LOGIN_USER_FAILURE, error };
};

export const clearAuth = () => {
  return { type: CLEAR_AUTH };
};
