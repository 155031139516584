import * as errors from "../types/errors";

import {
    CREATE_EDITOR_PICK_FAILURE,
    CREATE_EDITOR_PICK_REQUEST,
    CREATE_EDITOR_PICK_SUCCESS,
    FETCH_SPECIAL_SECTIONS_FAILURE,
    FETCH_SPECIAL_SECTIONS_REQUEST,
    FETCH_SPECIAL_SECTIONS_SUCCESS,
    REMOVE_EDITOR_PICK_FAILURE,
    REMOVE_EDITOR_PICK_REQUEST,
    REMOVE_EDITOR_PICK_SUCCESS,
    REORDER_EDITOR_PICK_FAILURE,
    REORDER_EDITOR_PICK_REQUEST,
    REORDER_EDITOR_PICK_SUCCESS,
} from "src/constants/specialsection";
import ISpecialSection from "src/types/special-section";

export interface IState {
    sections: ISpecialSection[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    sections?: ISpecialSection[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    isLoading: false,
    sections: [],
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case FETCH_SPECIAL_SECTIONS_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case FETCH_SPECIAL_SECTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sections: action.sections ? action.sections.sort((a, b) => a.sectionName.localeCompare(b.sectionName)) : [],
            };

        case FETCH_SPECIAL_SECTIONS_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case CREATE_EDITOR_PICK_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case CREATE_EDITOR_PICK_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case CREATE_EDITOR_PICK_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case REMOVE_EDITOR_PICK_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case REMOVE_EDITOR_PICK_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case REMOVE_EDITOR_PICK_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case REORDER_EDITOR_PICK_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case REORDER_EDITOR_PICK_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case REORDER_EDITOR_PICK_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        default:
            return state;
    }
};
