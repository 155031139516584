import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, InputSelect, ModalConfirm, Row } from "styleguide";

import { updateFile } from "../actions/file";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import ISection from "../types/section";
import { renderError, renderInput } from "../utils/input";
import { required } from "../utils/validations";
import InputFile from "./InputFile";
import InputSwitch from "./InputSwitch";

interface IProp {
    isParentSelectable: boolean;
    parentSections: ISection[];
    section?: ISection;
    error?: errors.HttpError;
    updateFile: typeof updateFile;
}

export class SectionForm extends React.Component<IProp & InjectedFormProps<{}, IProp>> {
    public render() {
        const { section, parentSections, handleSubmit, valid, change, isParentSelectable } = this.props;

        if (!section) {
            return null;
        }

        const sectionParentId = section.parentId;

        return (
            <ModalConfirm title="Confirmation" description="Are you sure that you want save this category/subcategory?">
                {(confirm: any) => (
                    <form onSubmit={confirm(handleSubmit)}>
                        <Row>
                            <Col s={12}>
                                {isParentSelectable && (
                                    <Row>
                                        <Col s={12}>
                                            <InputField>
                                                <InputSelect
                                                    rows={parentSections.map((sec) => ({ value: sec.sectionId, text: sec.sectionName }))}
                                                    name="parentId"
                                                    change={change}
                                                    required={false}
                                                    placeholder="[Required for subcategories only] Select Parent Category"
                                                    value={sectionParentId}
                                                />
                                            </InputField>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <label htmlFor="sectionName">Name *</label>
                                            <Field name="sectionName" type="text" component={renderInput} validate={required} />
                                        </InputField>
                                    </Col>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputFile
                                                model="sections"
                                                id="sectionImage"
                                                upFile={this.props.updateFile}
                                                type={FileType.image}
                                                change={this.props.change}
                                                value={section && section.sectionImage}
                                            />
                                            <label className="active">Image</label>
                                        </InputField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <div className="center">
                                            <h5 className={css(styles.subtitle)}>Published?</h5>
                                            <InputSwitch name="sectionIsPublished" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col s={12}>
                                <Field name="error" type="hidden" component={renderError} />
                            </Col>

                            <Col s={12}>
                                <div className="center">
                                    <Button text="save" icon="save" submit={true} disable={!valid} />
                                </div>
                            </Col>
                        </Row>
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    fileInput: {
        marginTop: -15,
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingTop: 20,
    },
});

export default reduxForm<{}, IProp>({
    enableReinitialize: true,
    form: "SectionForm",
})(SectionForm);
