import * as errors from "../types/errors";

import {
  CLEAR_NEWSPAPER_STATE,
  CREATE_NEWSPAPER_FAILURE,
  CREATE_NEWSPAPER_REQUEST,
  CREATE_NEWSPAPER_SUCCESS,
  FETCH_NEWSPAPER_FAILURE,
  FETCH_NEWSPAPER_REQUEST,
  FETCH_NEWSPAPER_SUCCESS,
  FETCH_NEWSPAPERS_FAILURE,
  FETCH_NEWSPAPERS_REQUEST,
  FETCH_NEWSPAPERS_SUCCESS,
  UPDATE_NEWSPAPER_FAILURE,
  UPDATE_NEWSPAPER_REQUEST,
  UPDATE_NEWSPAPER_SUCCESS
} from "../constants/newspaper";
import INewspaper from "../types/newspaper";

export interface IState {
  newspapers: INewspaper[];
  newspaper?: INewspaper;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  newspaper?: INewspaper;
  newspapers?: INewspaper[];
  error?: errors.HttpError;
}

export const initialState: IState = {
  isLoading: false,
  newspapers: []
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_NEWSPAPERS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_NEWSPAPERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newspapers: action.newspapers || []
      };
    case FETCH_NEWSPAPERS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_NEWSPAPER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_NEWSPAPER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newspaper: action.newspaper
      };
    case FETCH_NEWSPAPER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_NEWSPAPER_STATE:
      return { ...initialState };
    case CREATE_NEWSPAPER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_NEWSPAPER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newspaper: action.newspaper
      };
    case CREATE_NEWSPAPER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case UPDATE_NEWSPAPER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_NEWSPAPER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newspaper: action.newspaper
      };
    case UPDATE_NEWSPAPER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
