import * as _ from "lodash";

import ICategory from "../types/category";
import { IParams } from "../utils/build-url";

export const requestAllCategories = (): IParams => {
  return {
    orderBy: "ASC",
    sortBy: "categoryName"
  };
};

export const requestPublishedCategories = (): IParams => {
  return {
    filter: [{ name: "categoryIsPublished", value: 1 }],
    orderBy: "ASC",
    sortBy: "categoryName"
  };
};

export const createDefaultCategory = (): ICategory => {
  return {
    categoryDisplayOrder: 0,
    categoryId: 0,
    categoryIsPublished: 0,
    categoryName: ""
  };
};
