import * as React from "react";

import * as _ from "lodash";
import { ButtonFixed, Clickable, Col, Icon, Loading, Table, Td, Th } from "styleguide";
import { css, StyleSheet } from "aphrodite";
import { clearSearch, doSearch } from "../../../actions/search";
import SearchForm from "../../../components/SearchForm";
import config from "../../../config";
import { requestAdvertSearched } from "../../../services/advert";
import { createDefaultSearch } from "../../../services/search";
import IAdvert from "../types/advert";
import * as errors from "../../../types/errors";
import ISearch from "../../../types/search";
import debounce from "../../../utils/debounce";
import { Helmet } from "react-helmet";
import { History } from "history";
import { advertIsComplete, getListOfCountriesForTable, isLegacyAd, mapPriority } from "../utils";
import { formatDate } from "src/utils/date";
import IAdvertListens from "../types/advertlistens";
import AdblockWarning from "./AdblockWarning";
import { fetchAdvertListens, fetchAdverts } from "../state/actions";

interface IProps {
    adverts: IAdvert[];
    advertListens?: IAdvertListens[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
    history: History;
    fetchAdverts: typeof fetchAdverts;
    fetchAdvertListens: typeof fetchAdvertListens;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class AdvertList extends React.Component<IProps> {
    public renderLiveAdverts = (adverts: IAdvert[]) => {
        const { history } = this.props;

        const NOW = new Date();

        const legacyAds = _.filter(adverts, isLegacyAd);
        const liveAds = _.filter(
            adverts,
            (a) =>
                !isLegacyAd(a) &&
                advertIsComplete(a) &&
                a.advertExpiresDateTime != null &&
                new Date(a.advertExpiresDateTime) > NOW &&
                a.advertLiveFromDateTime != null &&
                new Date(a.advertLiveFromDateTime) <= NOW,
        );
        const scheduledAds = _.filter(adverts, (a) => !isLegacyAd(a) && advertIsComplete(a) && a.advertLiveFromDateTime != null && new Date(a.advertLiveFromDateTime) > NOW);
        const awaitingPublication = _.filter(adverts, (a) => !isLegacyAd(a) && !advertIsComplete(a) && (a.advertExpiresDateTime == null || new Date(a.advertExpiresDateTime) > NOW));
        const expiredAds = _.filter(adverts, (a) => !isLegacyAd(a) && a.advertExpiresDateTime != null && new Date(a.advertExpiresDateTime) < NOW);

        const advertsGrouped = [
            {
                title: "Active",
                adverts: liveAds,
            },
            {
                title: "Scheduled",
                adverts: scheduledAds,
            },
            {
                title: "Unpublished / Incomplete",
                adverts: awaitingPublication,
            },
            {
                title: "For Old App Versions - Do Not Edit",
                adverts: legacyAds,
            },
            {
                title: "Expired",
                adverts: expiredAds,
            },
        ];

        return (
            <Col s={12}>
                {advertsGrouped.map((advertsInGroup) => (
                    <div className={css(styles.tableSection)}>
                        <Table title={advertsInGroup.title}>
                            <thead>
                                <tr>
                                    <Th>Name</Th>
                                    <Th>Client</Th>
                                    <Th hide="mobile">Priority</Th>
                                    <Th hide="mobile">Total Listens</Th>
                                    <Th hide="mobile">Listen Limit</Th>
                                    <Th hide="mobile">Countries</Th>
                                    <Th hide="tablet">Start Date</Th>
                                    <Th hide="tablet">Expiration Date</Th>
                                    <Th align="center">Is Published?</Th>
                                    <Th align="right">Actions</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {advertsInGroup.adverts.map((advert) => (
                                    <tr key={advert.advertID}>
                                        <Td>{advert.advertFriendlyName}</Td>
                                        <Td>{advert.company?.companyName || "Not set"}</Td>
                                        <Td hide="mobile">{mapPriority(advert.priority)}</Td>
                                        <Td hide="mobile">{advert.totalListens || 0}</Td>
                                        <Td hide="mobile">{advert.listenLimit || "Unlimited"}</Td>
                                        <Td hide="mobile">{getListOfCountriesForTable(advert)}</Td>
                                        <Td hide="tablet">{formatDate(advert.advertLiveFromDateTime)}</Td>
                                        <Td hide="tablet">{formatDate(advert.advertExpiresDateTime)}</Td>
                                        <Td align="center">{advert.advertIsPublished === 1 ? "Yes" : "No"}</Td>
                                        <Td align="right">
                                            <Clickable onClick={() => history.push(`${config.paths.advert}/${advert.advertID}`)}>
                                                <Icon icon="edit" />
                                            </Clickable>
                                        </Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ))}
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.advert}+`)} />
            </Col>
        );
    };

    public renderSearch = () => {
        return <SearchForm onSubmit={this.submitForm} />;
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchAdverts(requestAdvertSearched(search), true);
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchAdverts(requestAdvertSearched(this.props.search), false);
                });
            }
        };
        this.props.fetchAdverts(requestAdvertSearched(createDefaultSearch()), true);
        this.props.fetchAdvertListens();
    }

    public componentWillUnmount() {
        this.props.clearSearch();
        window.onscroll = null;
    }

    public render() {
        const { isLoading, adverts } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Adverts</title>
                </Helmet>
                {isLoading && <Loading />}
                <AdblockWarning />
                {this.renderSearch()}
                {this.renderLiveAdverts(adverts)}
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    tableSection: {
        marginBottom: 50,
    },
});

export default AdvertList;
