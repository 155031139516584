import * as _ from "lodash";
import TemplateType from "src/types/template-types";
import { toast } from "styleguide/build";

import config from "../config";
import IArticle from "../types/article";
import ISearch from "../types/search";
import SearchOptions from "../types/search-options";
import { IFilter, IParams } from "../utils/build-url";
import { addDays, filterDate, yyyymmdd, newDateAtMidnight } from "../utils/date";

const LIMIT = 20;

export const requestAllArticlesWithSections = (sectionId: number): IParams => {
    return {
        include: ["sections"],
        orderBy: "ASC",
        sortBy: "articleName",
        limit: 987654321,
        innerFilter: { sections: [{ name: "sectionId", value: sectionId }] },
    };
};

export const requestArticlesByStoryId = (playlistId: number, limit: number): IParams => {
    return {
        include: ["playlists", "newspapers"],
        innerFilter: { playlists: [{ name: "playlistId", value: playlistId }] },
        limit,
        orderBy: "DESC",
        sortBy: "order",
    };
};

export const requestArticlesByPlaylistId = (playlistId: number, limit: number, offset: number): IParams => {
    return {
        include: ["playlists", "newspapers"],
        innerFilter: { playlists: [{ name: "playlistId", value: playlistId }] },
        limit,
        offset,
        orderBy: "DESC",
        sortBy: "inverted-order",
    };
};

export const requestArticlesDynamicSearched = (search: ISearch | any): IParams => {
    let filters: IFilter[] = [];
    let innerFilters: { [key: string]: IFilter[] } = {};
    const dateFilter = filterDate("articleAddedDateTime", search);

    if (search.option === SearchOptions.DATE_PUBLISHED || (search.option && search.text)) {
        switch (search.option) {
            case SearchOptions.TITLE:
                filters = [...filters, { name: "articleName~", value: search.text }];
                break;
            case SearchOptions.ID:
                filters = [...filters, { name: "articleID", value: search.text }];
                break;
            case SearchOptions.NEWSPAPER:
                filters = [...filters, { name: "newspaperId", value: search.text }];
                break;
            case SearchOptions.PLAYLIST:
                innerFilters = {
                    ...innerFilters,
                    playlists: [{ name: "playlistId", value: search.text }],
                };
                break;
            case SearchOptions.JOURNALIST:
                innerFilters = {
                    ...innerFilters,
                    journalists: [{ name: "journalistId", value: search.text }],
                };
                break;
            case SearchOptions.NARRATOR:
                filters = [...filters, { name: "articleAudioRecorderID", value: search.text }];
                break;

            case SearchOptions.VOICE_REQUIREMENTS:
                innerFilters = {
                    ...innerFilters,
                    // voicerequirements: [{ name: "id", value: search.text }],
                };
                break;

            case SearchOptions.CATEGORY:
                innerFilters = {
                    ...innerFilters,
                    sections: [{ name: "sectionId", value: search.text }],
                };
                break;

            case SearchOptions.SUBCATEGORY:
                innerFilters = {
                    ...innerFilters,
                    sections: [{ name: "sectionId", value: search.subcategory }],
                };
                break;

            case SearchOptions.WORD_COUNT: {
                const numberRegex = /^[0-9]+$/;
                const { wordCountFrom, wordCountTo, wordCount } = search;

                if (wordCount) {
                    if (!numberRegex.test(wordCount)) {
                        toast("Word count must be a number only.");
                    }
                } else if (wordCountFrom && wordCountTo) {
                    if (!numberRegex.test(wordCountFrom) || !numberRegex.test(wordCountTo)) {
                        toast("Word count from/to must be a number only.");
                    }
                } else {
                    break;
                }

                if (search.text === "1") {
                    filters = [...filters, { name: "articleWordCount>", value: Number(wordCount) }];
                } else if (search.text === "2") {
                    filters = [...filters, { name: "articleWordCount<", value: Number(wordCount) }];
                } else {
                    filters = [...filters, { name: "articleWordCount", value: `${Number(wordCountFrom)}~${Number(wordCountTo)}` }];
                }

                break;
            }

            case SearchOptions.ARTICLE_UNITS: {
                const numberRegex = /^[0-9]+$/;
                const { articleUnitsFrom, articleUnitsTo, articleUnits } = search;

                if (articleUnits) {
                    if (!numberRegex.test(articleUnits)) {
                        toast("Article units must be a number only.");
                    }
                } else if (articleUnitsFrom && articleUnitsTo) {
                    if (!numberRegex.test(articleUnitsFrom) || !numberRegex.test(articleUnitsTo)) {
                        toast("Article units from/to must be a number only.");
                    }
                } else {
                    break;
                }

                if (search.text === "1") {
                    filters = [...filters, { name: "articleValue>", value: Number(articleUnits) }];
                } else if (search.text === "2") {
                    filters = [...filters, { name: "articleValue<", value: Number(articleUnits) }];
                } else {
                    filters = [...filters, { name: "articleValue", value: `${Number(articleUnitsFrom)}~${Number(articleUnitsTo)}` }];
                }

                break;
            }

            case SearchOptions.DATE_PUBLISHED:
                const [startDate, endDate] = search.datePublished || [];

                if ((!startDate && !endDate) || (!startDate && endDate)) {
                    toast("Select a valid date/range.");
                }

                if (startDate && endDate) {
                    filters = [...filters, { name: "lastPublishedDate", value: `${yyyymmdd(startDate)}~${yyyymmdd(endDate)}` }];
                } else if (startDate && !endDate) {
                    filters = [...filters, { name: "lastPublishedDate", value: yyyymmdd(startDate) }];
                }
                break;

            case SearchOptions.PUBLISHED_UNPUBLISHED:
                filters = [...filters, { name: "articleIsPublished", value: search.text }];
                break;
        }
    }

    if (dateFilter) {
        filters = [...filters, dateFilter];
    }

    return {
        filter: filters,
        include: ["journalists", "users:articleReader", "newspapers", "playlists", "tags", "voicerequirements:voiceRequirements", "sections"],
        innerFilter: innerFilters,
        limit: LIMIT,
        orderBy: "DESC",
        sortBy: "articleID",
    };
};

export const requestTodaysArticles = (): IParams => {
    let filters: IFilter[] = [];
    const tomorrow = addDays(new Date(), 1);
    const today = new Date();

    const dateFilter = filterDate("articleAddedDateTime", {
        fromDate: yyyymmdd(today),
        text: "",
        toDate: yyyymmdd(tomorrow),
    });

    if (dateFilter) {
        filters = [dateFilter];
    }

    return {
        filter: filters,
        include: ["journalists", "users:articleReader", "newspapers", "tags"],
        orderBy: "DESC",
        sortBy: "articleID",
    };
};

export const requestPendingArticles = (): IParams => {
    return {
        filter: [
            {
                name: "narrator-dashboard",
                value: null,
            },
        ],
        include: ["newspapers"],
        limit: LIMIT,
        orderBy: "DESC",
        sortBy: "articleID",
    };
};

export const requestLastArticles = (limit: number): IParams => {
    return {
        include: ["journalists", "users:articleReader", "newspapers", "tags"],
        limit,
        orderBy: "DESC",
        sortBy: "articleID",
    };
};

export const requestArticle = (): IParams => {
    return {
        include: [
            "tags",
            "journalists",
            "users:articleReader",
            "newspapers",
            "playlists",
            "templates:intro",
            "templates:outro",
            "users:articleAddedBy",
            "sections",
            "voicerequirements:voiceRequirements",
            "podcastplatforms",
        ],
    };
};

export const createDefaultArticle = (): IArticle => {
    return {
        articleComprehension: 3,
        articleID: 0,
        articleIsFeatured: 0,
        articleIsGlobal: 0,
        articleIsPublished: 0,
        articleName: "",
        articleOriginLink: "",
        articleOriginalPublicationDateTime: newDateAtMidnight().toString(),
        articlePolarisation: 0,
        articleQuality: 3,
        articleSection: "",
        articleText: "",
        articleTimeliness: 3,
        articleViewDateTime: newDateAtMidnight().toString(),
        journalistName: "",
    };
};

export const createDefaultArticleNestedProps = (article?: IArticle) => {
    if (!article || !article.playlists || !article.journalists || !article.tags || !article.sections || !article.voiceRequirements) {
        return null;
    }

    const journalistsId = article.journalistsId ? article.journalistsId : article.journalists.map((j) => `${j.journalistId}`);

    const tagsId = article.tagsId ? article.tagsId : article.tags.filter((t) => t.articletags.isDisplayTag !== 1).map((t) => ({ tagName: t.tagName, tagId: t.tagID }));

    const displayTagsId = article.displayTagsId ? article.displayTagsId : article.tags.filter((t) => t.articletags.isDisplayTag === 1).map((t) => ({ tagName: t.tagName, tagId: t.tagID }));

    const playlistsId = article.playlistsId ? article.playlistsId : article.playlists.map((p) => `${p.playlistId}`);

    const sectionsId = article.sectionsId ? article.sectionsId : article.sections.filter((s) => !s.parentId).map((s) => s.sectionId.toString());

    const sectionsIdx = article.sectionsIdx ? article.sectionsIdx : article.sections.filter((s) => s.parentId).map((s) => s.sectionId.toString());

    const voiceRequirementIds = article.voiceRequirementIds ? article.voiceRequirementIds : article.voiceRequirements.map((vr) => vr.id.toString());

    const podcastPlatformIds = article.podcastPlatformIds ? article.podcastPlatformIds : article.podcastplatforms!.map((pp) => pp.id.toString());

    return { journalistsId, tagsId, playlistsId, sectionsId, sectionsIdx, voiceRequirementIds, podcastPlatformIds, displayTagsId };
};

export const goToArticleEdit = (articleId: number) => {
    return window.open(`${config.paths.article}/${articleId}`, "_blank");
};

export const goToArticleView = (articleId: number) => {
    return window.open(`${config.paths.article}/${articleId}/view`, "_blank");
};

export const goToAudio = (articleAudioFileName?: string) => {
    if (!articleAudioFileName) {
        return;
    }

    return window.open(`${config.api.getAudio}/articles/${articleAudioFileName}`, "_blank");
};

export const hasAudioFile = (article: IArticle): boolean => {
    if (!article.articleAudioFileName) {
        return false;
    }

    return true;
};

export const missingFieldsForSaveWarning = (article: IArticle): string[] => {
    const missingFields = [];

    if (!article.articleName) {
        missingFields.push("Title");
    }

    if (!article.articleAudioRecorderID) {
        missingFields.push("Narrator");
    }

    if (!article.newspaperId) {
        missingFields.push("Newspaper");
    }

    if (!article.articleImageFileName) {
        missingFields.push("Image");
    }

    if (article.articleType == null) {
        missingFields.push("Article Type");
    }

    if (!article.articleValue) {
        missingFields.push("Article Value");
    }

    if (!article.articleOriginLink) {
        missingFields.push("Origin Link");
    }

    if (!article.articleSection) {
        missingFields.push("Publication Section");
    }

    if (!article.articleViewDateTime) {
        missingFields.push("View Date");
    }

    if (!article.articleOriginalPublicationDateTime) {
        missingFields.push("Original Publication Date");
    }

    if (!article.introId) {
        missingFields.push("Intro");
    }

    if (!article.outroId) {
        missingFields.push("Outro");
    }

    if (!Array.isArray(article.playlistsId) || (Array.isArray(article.playlistsId) && article.playlistsId.length === 0)) {
        missingFields.push("Playlist");
    }

    if (!Array.isArray(article.journalistsId) || (Array.isArray(article.journalistsId) && article.journalistsId.length === 0)) {
        missingFields.push("Journalist");
    }

    if (!Array.isArray(article.tagsId) || (Array.isArray(article.tagsId) && article.tagsId.length === 0)) {
        missingFields.push("Tag");
    }

    if (!Array.isArray(article.displayTagsId) || (Array.isArray(article.displayTagsId) && article.displayTagsId.length === 0)) {
        missingFields.push("Display Tags");
    }

    if (!Array.isArray(article.sectionsId) || (Array.isArray(article.sectionsId) && article.sectionsId.length === 0)) {
        missingFields.push("Categories");
    }
    if (!Array.isArray(article.sectionsIdx) || (Array.isArray(article.sectionsIdx) && article.sectionsIdx.length === 0)) {
        missingFields.push("Sub-categories");
    }

    return missingFields;
};

export const missingFieldsToPublish = (article: IArticle): string[] => {
    const missingFields = [];

    // @ts-ignore
    const articleAudioType = article ? +article.articleAudioType?.valueOf() : 3;

    const internalAudioRequired = articleAudioType === 0 || articleAudioType === 2;
    const externalAudioRequired = articleAudioType === 1 || articleAudioType === 2;

    if (!article.articleName) {
        missingFields.push("Title");
    }

    if (!article.newspaperId) {
        missingFields.push("Newspaper");
    }

    if (!(Array.isArray(article.journalists) && article.journalists.length) && !(Array.isArray(article.journalistsId) && article.journalistsId.length)) {
        missingFields.push("Journalist");
    }

    if (!article.articleAudioRecorderID) {
        missingFields.push("Narrator");
    }

    if (!article.articleOriginLink) {
        missingFields.push("Origin Link");
    }

    if (!article.introId) {
        missingFields.push("Intro");
    }

    if (!article.outroId) {
        missingFields.push("Outro");
    }

    if (article.articleType == null) {
        missingFields.push("Article Type");
    }

    if (!article.articleValue) {
        missingFields.push("Article Value");
    }

    if (!(externalAudioRequired === true && internalAudioRequired === false) && !article.articleImageFileName) {
        missingFields.push("Image");
    }

    if ((internalAudioRequired && !article.articleAudioFileName) || (externalAudioRequired && !article.articleExternalAudioFileName)) {
        missingFields.push("Audio");
    }

    if (!(externalAudioRequired === true && internalAudioRequired === false) && !article.articlePreviewText) {
        missingFields.push("Preview Text");
    }

    if (!(externalAudioRequired === true && internalAudioRequired === false) && Number(article.articleType) === 0 && (!Array.isArray(article.tagsId) || !article.tagsId.length)) {
        missingFields.push("Hidden Tags");
    }

    if (!(externalAudioRequired === true && internalAudioRequired === false) && Number(article.articleType) === 0 && (!Array.isArray(article.displayTagsId) || !article.displayTagsId.length)) {
        missingFields.push("Display Tags");
    }

    return missingFields;
};

export const getWarnWords = (article: IArticle) => {
    const articleText = article.articleText;

    if (!articleText) {
        return null;
    }

    const articleTextLowered = articleText.toLowerCase();
    const warnsFound: string[] = [];

    for (const word of config.warnKeywords) {
        const regex = new RegExp("\\b" + word.toLowerCase() + "\\b");
        const match = articleTextLowered.match(regex);

        if (match != null) {
            warnsFound.push(word);
        }
    }

    return warnsFound;
};

export const getAudioFileWarnings = (article?: IArticle, internalAudioLength?: number, externalAudioLength?: number) => {
    // @ts-ignore
    const articleAudioType = article ? +article.articleAudioType?.valueOf() : 3;

    const internalAudioRequired = articleAudioType === 0 || articleAudioType === 2;
    const externalAudioRequired = articleAudioType === 1 || articleAudioType === 2;

    const internalAudioMissing = internalAudioRequired && !article?.articleAudioFileName;
    const externalAudioMissing = externalAudioRequired && !article?.articleExternalAudioFileName;

    const checkAudioLength = internalAudioRequired && externalAudioRequired && !internalAudioMissing && !externalAudioMissing;
    // const audioLengthMismatch = checkAudioLength && internalAudioLength && externalAudioLength && externalAudioLength < internalAudioLength;

    const showWarning = internalAudioMissing || externalAudioMissing; // || !!audioLengthMismatch;

    return {
        internalAudioMissing,
        externalAudioMissing,
        checkAudioLength,
        showWarning,
        //audioLengthMismatch,
    };
};

export const getArticleAudioTypeText = (article: IArticle) => {
    if (article.articleAudioType === 0) {
        return "On-Platform";
    }

    if (article.articleAudioType === 1) {
        return "Off-Platform";
    }

    return "Both";
};

export const getCustomTemplate = (text: string, template: TemplateType) => {
    const border = "<h5><b>____________________________________</b></h5>";
    const outroTitle = "<b>OUTRO</b>";
    const introTitle = "<b>INTRO</b>";

    if (template === TemplateType.INTRO) {
        return `
        ${border}

        ${introTitle}

        ${text}
        ${border}`;
    }

    return `
    ${border}

    ${outroTitle}

    ${text}

    `;
};
