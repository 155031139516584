import * as _ from "lodash";

import ISearch from "../types/search";
import ITag from "../types/tag";
import { IFilter, IParams } from "../utils/build-url";
import { filterDate } from "../utils/date";

const LIMIT = 20;

export const requestTagsSearched = (search: ISearch): IParams => {
  let filters: IFilter[] = [];
  const dateFilter = filterDate("createdAt", search);

  if (search.text) {
    filters = [...filters, { name: "tagName~", value: search.text }];
  }

  if (dateFilter) {
    filters = [...filters, dateFilter];
  }

  return {
    filter: filters,
    limit: LIMIT,
    orderBy: "DESC",
    sortBy: "tagID"
  };
};

export const createDefaultTag = (): ITag => {
  return {
    tagID: 0,
    tagIsPublished: 0,
    tagName: ""
  };
};
