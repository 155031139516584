import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
  Button,
  Col,
  InputField,
  InputSelect,
  ModalConfirm,
  Row,
} from "styleguide";

import { updateFile } from "../actions/file";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import INewspaper from "../types/newspaper";
import countries from "../utils/countries";
import { renderError, renderInput } from "../utils/input";
import languages from "../utils/languages";
import { required } from "../utils/validations";
import InputFile from "./InputFile";
import InputSwitch from "./InputSwitch";

interface IProp {
  newspaper?: INewspaper;
  companies: ICompany[];
  error?: errors.HttpError;
  updateFile: typeof updateFile;
}

export class NewspaperForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { change, companies, handleSubmit, newspaper, valid } = this.props;

    if (!newspaper) {
      return null;
    }

    const countriesTransformed = countries.map((c) => ({
      text: c.name,
      value: c.id,
    }));

    const languagesTransformed = languages.map((l) => ({
      text: l.name,
      value: l.id,
    }));

    const companiesTransformed = [
      { value: "", text: "NO COMPANY" },
      ...companies.map((c) => ({
        text: c.companyName,
        value: c.companyId,
      })),
    ];

    const geographyID = newspaper ? newspaper.geographyID : "";
    const languageID = newspaper ? newspaper.languageID : "";
    const companyId = newspaper ? newspaper.companyId : "";

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this newspaper?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="newspaperName">Name *</label>
                      <Field
                        name="newspaperName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="textarea1">About</label>
                      <Field
                        name="newspaperInfo"
                        component="textarea"
                        className={`${css(
                          styles.textarea
                        )} materialize-textarea`}
                      />
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12} m={6}>
                    <InputField>
                      <InputSelect
                        rows={countriesTransformed}
                        name="geographyID"
                        change={change}
                        value={geographyID}
                        placeholder="Select One Country"
                      />
                      <label>Country</label>
                    </InputField>
                  </Col>
                  <Col s={12} m={6}>
                    <InputField>
                      <InputSelect
                        rows={languagesTransformed}
                        name="languageID"
                        change={change}
                        value={languageID}
                        placeholder="Select One Language"
                      />
                      <label>Language</label>
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={6}>
                    <InputField>
                      <label htmlFor="newspaperDisplayOrder">
                        Display order *
                      </label>
                      <Field
                        name="newspaperDisplayOrder"
                        type="number"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={6}>
                    <InputField>
                      <label htmlFor="newspaperDailyFreeListens">
                        Daily free listens *
                      </label>
                      <Field
                        name="newspaperDailyFreeListens"
                        type="number"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={6}>
                    <InputField>
                      <InputFile
                        model="newspapers"
                        id="newspaperImageSmall"
                        upFile={this.props.updateFile}
                        type={FileType.image}
                        change={this.props.change}
                        value={newspaper && newspaper.newspaperImageSmall}
                      />
                      <label className="active">Small image</label>
                    </InputField>
                  </Col>
                  <Col s={6}>
                    <InputField>
                      <InputFile
                        model="newspapers"
                        id="newspaperImageLarge"
                        upFile={this.props.updateFile}
                        type={FileType.image}
                        change={this.props.change}
                        value={newspaper && newspaper.newspaperImageLarge}
                      />
                      <label className="active">Large image</label>
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12} m={6} mOffset={3}>
                    <InputField>
                      <InputSelect
                        rows={companiesTransformed}
                        name="companyId"
                        change={change}
                        value={companyId}
                      />
                      <label>Company</label>
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Published?</h5>
                      <InputSwitch name="newspaperIsPublished" />
                    </div>
                  </Col>
                  <Col s={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Free Listens?</h5>
                      <InputSwitch name="newspaperPermitsFreeListens" />
                    </div>
                  </Col>
                  <Col s={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>
                        Featured Articles?
                      </h5>
                      <InputSwitch name="newspaperUseFeaturedArticle" />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

const styles = StyleSheet.create({
  published: {
    paddingTop: 15,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 300,
    paddingTop: 20,
  },
  textarea: {
    minHeight: 60,
    overflowY: "auto",
    resize: "vertical",
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "NewspaperForm",
})(NewspaperForm);
