export const addOverwriteTag = (
    searchText: string,
    tags: { label: string; value: number }[],
    selectedTags: { tagName: string; tagId: number }[],
): {
    label: string;
    value: number;
}[] => {
    const tagMatch = tags.find((t) => t.label.toLowerCase().trim() === searchText.toLowerCase().trim());

    // if no match or matched tag is already selected, then just skip
    if (!tagMatch || selectedTags.some((t) => t.tagId === tagMatch.value)) return tags;

    return [{ label: `Overwrite "${tagMatch.label}"`, value: `overwrite:${searchText}:${tagMatch.value}` as any as number }, ...tags];
};

export const getTagFromOverwriteTag = (tagName: string, tagId: string) => {
    if (tagId.indexOf("overwrite:") === 0) {
        const idSplit = tagId.split(":");

        tagId = idSplit[2];
        tagName = idSplit[1];
    }

    return { tagName, tagId: Number(tagId) };
};

export const sortTags = (searchText: string, tags: { label: string; value: number }[]) => {
    return tags.sort((a, b) => {
        if (a.label.toLowerCase().indexOf(searchText.toLowerCase()) > b.label.toLowerCase().indexOf(searchText.toLowerCase())) {
            return 1;
        } else if (a.label.toLowerCase().indexOf(searchText.toLowerCase()) < b.label.toLowerCase().indexOf(searchText.toLowerCase())) {
            return -1;
        } else {
            if (a.label > b.label) return 1;
            else return -1;
        }
    });
};

export const disableSelectedTags = (
    tags: { label: string; value: number }[],
    selectedTags: { tagName: string; tagId: number }[],
): {
    label: string;
    value: number;
}[] => {
    return tags.map((tag) => (selectedTags.some((st) => st.tagId === tag.value) ? ({ ...tag, disabled: true } as any) : tag));
};
