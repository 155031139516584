export const FETCH_ARTICLE_TEMPLATES_REQUEST = "FETCH_ARTICLE_TEMPLATES_REQUEST";
export const FETCH_ARTICLE_TEMPLATES_SUCCESS = "FETCH_ARTICLE_TEMPLATES_SUCCESS";
export const FETCH_ARTICLE_TEMPLATES_FAILURE = "FETCH_ARTICLE_TEMPLATES_FAILURE";
export const CREATE_ARTICLE_TEMPLATE_REQUEST = "CREATE_ARTICLE_TEMPLATE_REQUEST";
export const CREATE_ARTICLE_TEMPLATE_SUCCESS = "CREATE_ARTICLE_TEMPLATE_SUCCESS";
export const CREATE_ARTICLE_TEMPLATE_FAILURE = "CREATE_ARTICLE_TEMPLATE_FAILURE";
export const DELETE_ARTICLE_TEMPLATE_REQUEST = "DELETE_ARTICLE_TEMPLATE_REQUEST";
export const DELETE_ARTICLE_TEMPLATE_SUCCESS = "DELETE_ARTICLE_TEMPLATE_SUCCESS";
export const DELETE_ARTICLE_TEMPLATE_FAILURE = "DELETE_ARTICLE_TEMPLATE_FAILURE";

