import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Col, Loading } from "styleguide";

import { fetchAudiences } from "../actions/audience";
import AudienceList from "../components/AudienceList";
import { IState } from "../reducers";
import IAudience from "../types/audience";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    loading: boolean;
    audiences: IAudience[];
}

interface IPropsFromDispatch {
    fetchAudiences: typeof fetchAudiences;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public componentDidMount() {
        this.props.fetchAudiences({ playlists: true });
    }

    public render() {
        const { audiences, loading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Audiences</title>
                </Helmet>
                {loading && <Loading />}
                <Col s={12}>
                    <AudienceList audiences={audiences} />
                </Col>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        audiences: state.audience.audiences,
        loading: state.audience.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchAudiences,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
