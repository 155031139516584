import IPronunciation from "../types/pronunciation";
import ISearch from "../types/search";
import { IFilter, IParams } from "../utils/build-url";
import { filterDate } from "../utils/date";

const LIMIT = 20;

export const requestAllPronunciations = (): IParams => {
  return {
    orderBy: "ASC",
    sortBy: "pronunciationName"
  };
};

export const createDefaultPronunciation = (): IPronunciation => {
  return {
    pronunciationId: 0,
    pronunciationName: ""
  };
};

export const requestPronunciationsSearched = (search: ISearch): IParams => {
  let filters: IFilter[] = [];
  const dateFilter = filterDate("createdAt", search);

  if (search.text) {
    filters = [...filters, { name: "pronunciationName~", value: search.text }];
  }

  if (dateFilter) {
    filters = [...filters, dateFilter];
  }

  return {
    filter: filters,
    limit: LIMIT,
    orderBy: "DESC",
    sortBy: "pronunciationId"
  };
};