import { Dispatch } from "redux";
import config from "src/config";
import { FETCH_VOICE_REQUIREMENTS_FAILURE, FETCH_VOICE_REQUIREMENTS_REQUEST, FETCH_VOICE_REQUIREMENTS_SUCCESS } from "src/constants/voicerequirements";
import { IVoiceRequirement } from "src/types/voice-requirements";
import responseCheck from "src/utils/response-check";
import { toast } from "styleguide/build";
import fetch from "../utils/fetch";

export const fetchVoiceRequirements =
    () =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_VOICE_REQUIREMENTS_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.voicerequirements}`;

        return fetch(endpoint)
            .then(responseCheck)
            .then((voiceRequirements) => dispatch(fetchVoiceRequirementsSuccess(voiceRequirements)))
            .catch((error) => dispatch(fetchVoiceRequirementsFailure(error)));
    };

const fetchVoiceRequirementsSuccess = (voiceRequirements: IVoiceRequirement[]) => {
    return { type: FETCH_VOICE_REQUIREMENTS_SUCCESS, voiceRequirements };
};

const fetchVoiceRequirementsFailure = (error: Error) => {
    toast("Error fetching voice requirements");
    return { type: FETCH_VOICE_REQUIREMENTS_FAILURE, error };
};
