import IPronunciation from "../types/pronunciation";
import IUser from "../types/user";

export const canVerifyPronuncation = (p: IPronunciation, user?: IUser): boolean => {

    if (!user) {
        return false;
    }

    const lastEditor = p.editedBy ? p.editedBy : p.createdBy;

    if (lastEditor && lastEditor === user.userId) {
        return false;
    }

    return true;
}