import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Loading, Table, Td } from "styleguide";
import { clearArticleState, createArticle, editArticle, fetchArticle } from "../actions/article";
import { fetchCategories } from "../actions/category";
import { updateFile } from "../actions/file";
import { createJournalist } from "../actions/journalist";
import { clearNewspaperState, fetchNewspaper, fetchNewspapers } from "../actions/newspaper";
import { fetchPlaylists, fetchSuggestedPlaylists } from "../actions/playlist";
import { clearPronunciationState, createPronunciation, fetchPronunciations, editPronunciation, deletePronunciation, verifyPronunciation } from "../actions/pronunciation";
import { fetchVoiceRequirements } from "../actions/voicerequirements";
import { fetchSuggestedTags } from "../actions/tag";
import { fetchTemplates } from "../actions/template";
import { fetchUsers } from "../actions/user";
import ArticleFormEditor from "../components/ArticleFormEditor";
import ArticleFormNarrator from "../components/ArticleFormNarrator";
import ArticleScript from "../components/ArticleScript";
import PronunciationForm from "../components/PronunciationForm";
import config from "../config";
import { FETCH_SUGGESTED_TAGS_SUCCESS } from "../constants/tag";
import { createDefaultArticleNestedProps, createDefaultArticle } from "../services/article";
import { requestAllPlaylists, requestTopPlaylistsWithSections } from "../services/playlist";
import { requestNarrators } from "../services/user";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPlaylist from "../types/playlist";
import IPronunciation from "../types/pronunciation";
import ITemplate from "../types/template";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { ddmmyy } from "../utils/date";
import { correctValue } from "../utils/values";
import { countAllWordsInArticleScript } from "../utils/count-words";
import ISearch from "../types/search";
import { fetchArticleTemplates, deleteArticleTemplate } from "../actions/articletemplates";
import IArticleTemplate from "../types/article-template";
import ModalSaveArticleTemplate from "../components/ModalSaveArticleTemplate";
import { Helmet } from "react-helmet";
import { fetchSections } from "../actions/section";
import ISection from "../types/section";
import SectionTypes from "src/types/section-types";
import { IVoiceRequirement } from "src/types/voice-requirements";
import { fetchPodcastPlatforms } from "src/actions/podcastplatforms";
import { IPodcastPlatform } from "src/types/podcast-platforms";
const ALL_PRONUNCIATIONS_LIMIT = 100000000;

interface ILocalState {
    shouldFixForm: boolean;
    showTemplateSaveModal: boolean;
    templateArticle?: IArticle;
    selectedPlaylists: IPlaylist[];
}

interface IPropsFromState {
    article?: IArticle;
    articleTemplates?: IArticleTemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    playlists: IPlaylist[];
    sections: ISection[];
    journalists: IJournalist[];
    templates: ITemplate[];
    narrators: IUser[];
    pronunciations: IPronunciation[];
    hasChanged: boolean;
    search: ISearch;
    voiceRequirements: IVoiceRequirement[];
    podcastPlatforms: IPodcastPlatform[];
}

interface IPropsFromDispatch {
    createArticle: typeof createArticle;
    clearArticleState: typeof clearArticleState;
    fetchArticleTemplates: typeof fetchArticleTemplates;
    deleteArticleTemplate: typeof deleteArticleTemplate;
    clearNewspaperState: typeof clearNewspaperState;
    clearPronunciationState: typeof clearPronunciationState;
    editArticle: typeof editArticle;
    fetchArticle: typeof fetchArticle;
    updateFile: typeof updateFile;
    fetchNewspapers: typeof fetchNewspapers;
    fetchNewspaper: typeof fetchNewspaper;
    fetchPlaylists: typeof fetchPlaylists;
    fetchSections: typeof fetchSections;
    fetchUsers: typeof fetchUsers;
    fetchPronunciations: typeof fetchPronunciations;
    createPronunciation: typeof createPronunciation;
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;
    createJournalist: typeof createJournalist;
    fetchTemplates: typeof fetchTemplates;
    fetchSuggestedPlaylists: typeof fetchSuggestedPlaylists;
    fetchSuggestedTags: typeof fetchSuggestedTags;
    fetchVoiceRequirements: typeof fetchVoiceRequirements;
    fetchPodcastPlatforms: typeof fetchPodcastPlatforms;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>, ILocalState> {
    constructor(props: IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>) {
        super(props);

        this.state = {
            shouldFixForm: false,
            showTemplateSaveModal: false,
            templateArticle: undefined,
            selectedPlaylists: [],
        };
    }

    public getArticle = () => {
        const preArticle = this.props.article;
        const defaultArticle = createDefaultArticle();

        return preArticle ? preArticle : defaultArticle;
    };

    public getSuggestedTags = async (text: string): Promise<string[]> => {
        const action = await this.props.fetchSuggestedTags(text);

        // @ts-ignore
        const tags = action.type === FETCH_SUGGESTED_TAGS_SUCCESS ? action.tags.map((t) => t.tagName) : [];

        return Promise.resolve(tags);
    };

    public renderArticleInfo = () => {
        const { article } = this.props;

        if (!article || article.articleID === 0) {
            return null;
        }

        return (
            <Table title="Info">
                <tbody>
                    <tr>
                        <Td>Article Inserted At</Td>
                        <Td align="right">{article.articleAddedDateTime ? ddmmyy(new Date(article.articleAddedDateTime)) : "-"}</Td>
                    </tr>
                    <tr>
                        <Td>Article Updated At</Td>
                        <Td align="right">{article.updatedAt ? ddmmyy(new Date(article.updatedAt)) : "-"}</Td>
                    </tr>
                    <tr>
                        <Td>Article Inserted By</Td>
                        <Td align="right">{article.articleAddedBy && article.articleAddedBy.userLogin}</Td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    public deleteArticleTemplate = (template: IArticleTemplate) => {
        this.props.deleteArticleTemplate(template);
    };

    public fetchPlaylists = (sectionId: number) => {
        if (sectionId === -3) {
            this.props.fetchPlaylists(requestAllPlaylists(), undefined, this.state.selectedPlaylists);
            return;
        }

        this.props.fetchPlaylists(requestTopPlaylistsWithSections(), sectionId, this.state.selectedPlaylists);
    };

    public fetchSuggestedPlaylists = (tags: string[]) => {
        this.props.fetchSuggestedPlaylists(tags, this.state.selectedPlaylists);
    };

    public setSelectedPlaylists = (playlistsFiltered: IPlaylist[], newPlaylistIds: string[], previousPlaylistIds: string[]) => {
        // Remove de-selected playlists from selected list
        const deselectedPlaylistIds = _.filter(previousPlaylistIds, (p) => !_.includes(newPlaylistIds, p));
        const selectedPlaylists = _.filter(this.state.selectedPlaylists, (p) => !_.includes(deselectedPlaylistIds, `${p.playlistId}`));

        // Get the newly selected playlists, excluding those that were selected previously
        const newPlaylists = _.filter(playlistsFiltered, (p) => _.includes(newPlaylistIds, `${p.playlistId}`));
        const newSelectedPlaylists = _.filter(
            newPlaylists,
            (p) =>
                !_.includes(
                    selectedPlaylists.map((sp) => sp.playlistId),
                    p.playlistId,
                ),
        );

        // Save them
        selectedPlaylists.push(...newSelectedPlaylists);
        this.setState({ selectedPlaylists });
    };

    public renderArticleForm = () => {
        const { user, playlists, sections, voiceRequirements, podcastPlatforms } = this.props;

        const article = this.getArticle();

        if (user.userRoleIdList === UserRoles.NARRATOR) {
            const initialValue = {
                ...article,
                error: this.props.error,
            };

            return (
                <Card>
                    <ArticleFormNarrator {...this.props} initialValues={initialValue} onSubmit={(a: any) => this.submitForm(a, { redirectPath: config.paths.dashboard })} />
                </Card>
            );
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            const fakeSuggestedSectionId = -1;

            const sectionsWithAdditional = [
                { sectionId: -2, sectionName: "RECENTLY CREATED/EDITED" },
                {
                    sectionId: fakeSuggestedSectionId,
                    sectionName: "SUGGESTED (Ordered by most relevant)",
                },
                { sectionId: -3, sectionName: "ALL" },
                ...sections.filter((s) => !s.parentId),
            ];

            const articlePlaylists = article.playlists;
            const playlistsFiltered = articlePlaylists
                ? _.filter(
                      playlists,
                      (p) =>
                          !_.includes(
                              articlePlaylists.map((ap) => ap.playlistId),
                              p.playlistId,
                          ),
                  )
                : playlists;
            const playlistsOrdered = playlistsFiltered.concat(articlePlaylists ? articlePlaylists : []);

            const articleWithNestedProps = {
                ...article,
                ...createDefaultArticleNestedProps(article),
                filterPlaylistsBySectionId: undefined,
            };

            const initialValue = {
                ...articleWithNestedProps,
                error: this.props.error,
            };

            const articleFormProps = {
                ...this.props,
                article: articleWithNestedProps,
                createJournalist: this.submitCreateJournalist,
                initialValues: initialValue,
                onSubmit: this.submitForm,
                createArticleTemplate: this.createArticleTemplate,
                deleteArticleTemplate: this.deleteArticleTemplate,
                playlists: playlistsOrdered,
                sections: sectionsWithAdditional,
                sectionsRaw: sections,
                voiceRequirements,
                podcastPlatforms,
                fetchPlaylists: this.fetchPlaylists,
                suggestPlaylists: this.fetchSuggestedPlaylists,
                suggestTags: this.getSuggestedTags,
                setSelectedPlaylists: this.setSelectedPlaylists,
            };

            return (
                <>
                    {/* @ts-ignore */}
                    <ArticleFormEditor {...articleFormProps} />
                </>
            );
        }

        return null;
    };

    public renderPronunciationForm = () => {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return null;
        }

        const props = { onSubmit: this.submitPronunciationForm, small: true };

        return (
            <Card title="Pronunciation" className={css(styles.noPaddingBottom)}>
                <PronunciationForm {...props} />
            </Card>
        );
    };

    public renderTextInputCard = () => {
        const props = { onSubmit: this.submitPronunciationForm, small: true };

        return (
            <Card title="Pronunciation" className={css(styles.noPaddingBottom)}>
                <PronunciationForm {...props} />
            </Card>
        );
    };

    public renderArticleScript = () => {
        const {
            article,
            journalists,
            narrators,
            newspapers,
            pronunciations,
            templates,
            user: { userRoleIdList: userRole },
            history,
        } = this.props;

        if (userRole !== UserRoles.NARRATOR && userRole !== UserRoles.EDITOR && userRole !== UserRoles.SUPER_USER) {
            return null;
        }

        const dynamic = userRole !== UserRoles.NARRATOR;

        return (
            <div className="scroll-vertical-full">
                <Card className={css(userRole !== UserRoles.NARRATOR && styles.bg)}>
                    <ArticleScript
                        print={false}
                        article={article}
                        newspapers={newspapers}
                        journalists={journalists}
                        narrators={narrators}
                        pronunciations={pronunciations}
                        templates={templates}
                        dynamic={dynamic}
                        user={this.props.user}
                        editPronunciation={this.props.editPronunciation}
                        verifyPronunciation={this.props.verifyPronunciation}
                        deletePronunciation={this.props.deletePronunciation}
                        fetchPronunciations={this.props.fetchPronunciations}
                        search={this.props.search}
                        history={history}
                    />
                </Card>
            </div>
        );
    };

    public changePageIfNeeded = (articleId: number, changePage: boolean = false, redirectPath?: string) => {
        const { history } = this.props;

        if (redirectPath) {
            return window.location.replace(redirectPath);
        }

        if (!changePage) {
            return window.location.replace(`${config.paths.article}/${articleId}`);
        }
        return history.push(config.paths.article);
    };

    public createArticleTemplate = (article: IArticle) => {
        this.setState({
            templateArticle: article,
            showTemplateSaveModal: true,
        });

        // this.props.createArticleTemplate(articleTemplate);
    };

    public submitForm = (article: (IArticle & { changePage: boolean }) | any, config?: { redirectPath?: string }) => {
        const { article: oldArticle } = this.props;
        const changePage = article.changePage;
        const action = oldArticle && oldArticle.articleID ? this.props.editArticle : this.props.createArticle;

        const sectionsIdCombined = [
            ...(article.sectionsId && Array.isArray(article.sectionsId) ? article.sectionsId : []),
            ...(article.sectionsIdx && Array.isArray(article.sectionsIdx) ? article.sectionsIdx : []),
        ];

        const articleWithWordCount: IArticle = {
            ...article,
            sectionsId: sectionsIdCombined,
            tagsId:
                Number(article.articleType) === 0
                    ? [
                          ...(article.tagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: false })),
                          ...(article.displayTagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: true })),
                      ]
                    : [],
            articleWordCount: countAllWordsInArticleScript(article),
        };

        return (
            action(articleWithWordCount)
                // @ts-ignore
                .then((articleId) => {
                    if (_.isNumber(articleId) && articleId !== 0) {
                        this.changePageIfNeeded(articleId, changePage, config?.redirectPath);
                    }
                })
        );
    };

    public submitPronunciationForm = (pronunciation: IPronunciation | any) => {
        // @ts-ignore
        return this.props.createPronunciation(pronunciation).then(() => {
            this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        });
    };

    public submitCreateJournalist = () => {
        const { article } = this.props;

        if (!article || !article.journalistName) {
            return;
        }

        const journalist: IJournalist = {
            journalistId: 0,
            journalistName: article.journalistName,
            newspapersId: [`${article.newspaperId}`],
        };

        this.props
            .createJournalist(journalist, false)
            // @ts-ignore
            .then(() => this.props.fetchNewspaper(article.newspaperId));
    };

    public componentDidMount() {
        const { match, user } = this.props;

        this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        this.props.fetchTemplates();

        if (match.params.id) {
            this.props.fetchArticle(+match.params.id);
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            this.props.fetchNewspapers();
            this.props.fetchArticleTemplates();
            this.props.fetchSections({
                filter: [
                    { name: "sectionIsPublished", value: 1 },
                    { name: "sectionType", value: SectionTypes.Regular },
                ],
            });
            this.fetchPlaylists(-2);
            this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
            this.props.fetchVoiceRequirements();
            this.props.fetchPodcastPlatforms();
        }

        this.maybeSetFixForm();
        window.onscroll = () => {
            this.maybeSetFixForm();
        };
    }

    public maybeSetFixForm() {
        if (window.scrollY >= 80 && window.innerWidth > 992) {
            if (!this.state.shouldFixForm) {
                this.setState({ shouldFixForm: true });
            }
        } else if (this.state.shouldFixForm) {
            this.setState({ shouldFixForm: false });
        }
    }

    public componentDidUpdate(prevProps: IPropsFromState) {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return;
        }

        const newArticle = this.props.article ? this.props.article : { newspaperId: 0 };
        const prevArticle = prevProps.article ? prevProps.article : { newspaperId: 0 };

        if (newArticle.newspaperId !== prevArticle.newspaperId && !!newArticle.newspaperId && newArticle.newspaperId !== 0) {
            this.props.fetchNewspaper(newArticle.newspaperId);
        }
    }

    public componentWillUnmount() {
        this.props.clearArticleState();
        this.props.clearNewspaperState();
        this.props.clearPronunciationState();
        window.onscroll = null;
    }

    public closeTemplateModal = () => {
        this.setState({ showTemplateSaveModal: false });
    };

    public render() {
        const { isLoading, user } = this.props;
        const { showTemplateSaveModal, templateArticle } = this.state;

        if (user.userRoleIdList === UserRoles.NARRATOR) {
            return (
                <React.Fragment>
                    {isLoading && <Loading />}
                    <div className="col s12 m12 l6 noprint">{this.renderArticleForm()}</div>
                    <div className="col s12 m12 l6">{this.renderArticleScript()}</div>
                </React.Fragment>
            );
        }

        const title = `Article Editor | ${this.props.article && this.props.article.articleID > 0 ? this.props.article?.articleName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {isLoading && <Loading />}
                <ModalSaveArticleTemplate show={showTemplateSaveModal} article={templateArticle} close={this.closeTemplateModal} />
                <div className="col s12 m12 l7 noprint">
                    {this.renderPronunciationForm()}
                    {this.renderArticleForm()}
                </div>
                <div className={`${this.state.shouldFixForm && css(styles.articleScript)} col s12 m12 l5`}>{this.renderArticleScript()}</div>
                <div className="col s12 m12 l7 noprint">{this.renderArticleInfo()}</div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { ArticleForm },
        article: { article },
        newspaper: { newspaper },
        articleTemplate: { articleTemplates },
    } = state;

    const articleValues = ArticleForm ? ArticleForm.values : {};

    const journalists = newspaper && newspaper.journalists ? newspaper.journalists : [];

    const preArticle = correctValue("articleID", articleValues, article);
    const validatedArticle = preArticle;
    const hasChanged = ArticleForm && !ArticleForm.submitSucceeded ? !!ArticleForm.anyTouched : false;

    return {
        article: validatedArticle,
        articleTemplates,
        error: state.article.error,
        hasChanged,
        isLoading:
            state.article.isLoading ||
            state.file.isLoading ||
            state.newspaper.isLoading ||
            state.user.isLoading ||
            state.category.isLoading ||
            state.journalist.isLoading ||
            state.pronunciation.isLoading ||
            state.suggestion.isLoading ||
            state.section.isLoading ||
            state.tag.isLoading ||
            state.voiceRequirements.isLoading ||
            state.podcastPlatforms.isLoading,
        journalists,
        narrators: state.user.narrators,
        newspapers: state.newspaper.newspapers,
        playlists: state.playlist.playlists,
        pronunciations: state.pronunciation.pronunciations,
        templates: state.template.templates,
        user: state.auth.user as IUser,
        search: state.search,
        sections: state.section.sections,
        voiceRequirements: state.voiceRequirements.voiceRequirements,
        podcastPlatforms: state.podcastPlatforms.platforms,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearArticleState,
            clearNewspaperState,
            clearPronunciationState,
            createArticle,
            createJournalist,
            createPronunciation,
            deletePronunciation,
            editPronunciation,
            verifyPronunciation,
            editArticle,
            fetchArticle,
            fetchArticleTemplates,
            deleteArticleTemplate,
            fetchCategories,
            fetchNewspaper,
            fetchNewspapers,
            fetchPlaylists,
            fetchSections,
            fetchPronunciations,
            fetchSuggestedPlaylists,
            fetchSuggestedTags,
            fetchTemplates,
            fetchUsers,
            updateFile,
            fetchVoiceRequirements,
            fetchPodcastPlatforms,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));

const styles = StyleSheet.create({
    bg: {
        backgroundColor: "#f5fcff",
        paddingBottom: "50px !important",
    },
    articleScript: {
        position: "fixed",
        right: 0,
        top: 0,
    },
    noPaddingBottom: {
        paddingBottom: 0,
    },
});
