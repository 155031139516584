import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, InputSelect, Modal, Row } from "styleguide";

import IPlaylist from "../types/playlist";
import SectionTypes from "../types/section-types";

interface IProps {
    playlists: IPlaylist[];
}

interface IState {
    open: boolean;
    filter: number;
}

const PLAYLIST_ID_FIELD = "playlistId";
const SECTION_ID_FIELD = "sectionId";

export class ModalAddPlaylist extends React.Component<IProps & InjectedFormProps<{}, IProps>, IState> {
    constructor(props: IProps & InjectedFormProps<{}, IProps>) {
        super(props);

        this.state = { filter: 0, open: false };
    }

    public close = () => {
        this.props.reset();
        this.setState({ open: false, filter: 0 });
    };

    public changeSection = (newValue: string, oldValue: number) => {
        const { playlists } = this.props;
        const filter = +newValue;

        this.setState({ filter });

        const playlist = _.chain(playlists)
            .filter((p) => !!_.find(p.sections, (s) => s.sectionId === filter))
            .first()
            .value();

        if (playlist) {
            this.props.change(PLAYLIST_ID_FIELD, playlist.playlistId);
        }
    };

    public onSubmit = (event: any) => {
        this.props.handleSubmit((values) => {
            const cleanFilterValue = 0;

            this.setState({ open: false, filter: cleanFilterValue });

            this.props.change(SECTION_ID_FIELD, cleanFilterValue);
            // @ts-ignore
            this.props.onSubmit(+values.playlistId);
        })(event);
    };

    public transformPlaylists = () => {
        const { playlists } = this.props;
        const { filter } = this.state;

        if (filter === 0) {
            return _.chain(playlists)
                .map((c) => ({ text: c.playlistName, value: c.playlistId }))
                .sortBy((p) => p.text)
                .value();
        }

        return _.chain(playlists)
            .filter((p) => !!_.find(p.sections, (s) => s.sectionId === filter))
            .map((p) => ({ text: p.playlistName, value: p.playlistId }))
            .sortBy((p) => p.text)
            .value();
    };

    public transformSections = () => {
        const { playlists } = this.props;

        const sections = _.chain(playlists)
            .map((p) => p.sections || [])
            .flatten()
            .uniqBy("sectionId")
            .filter((s) => s.sectionType === SectionTypes.Regular)
            .map((s) => ({ text: s.sectionName, value: s.sectionId }))
            .sortBy("text")
            .value();

        return _.concat([{ text: "All", value: 0 }], sections);
    };

    public componentDidUpdate = (prevProps: IProps & InjectedFormProps<{}, IProps>) => {
        const { playlists } = this.props;

        if (_.size(prevProps.playlists) !== _.size(playlists)) {
            const firstPlaylist = _.first(playlists);
            const playlistId = firstPlaylist ? firstPlaylist.playlistId : 0;

            this.props.change(PLAYLIST_ID_FIELD, playlistId);
        }
    };

    public render() {
        const { change } = this.props;
        const { open } = this.state;

        const playlistsFiltered = this.transformPlaylists();
        const sectionsTransformed = this.transformSections();

        return (
            <>
                {open && (
                    <Modal title="Add Series" className={css(styles.modal)} close={this.close}>
                        <Row>
                            <Col s={6}>
                                <InputField>
                                    <InputSelect rows={sectionsTransformed} name={SECTION_ID_FIELD} onChange={this.changeSection} change={change} />
                                    <label>Categories</label>
                                </InputField>
                            </Col>
                            <Col s={6}>
                                <InputField>
                                    <InputSelect rows={playlistsFiltered} name={PLAYLIST_ID_FIELD} change={change} />
                                    <label>Playlists</label>
                                </InputField>
                            </Col>
                        </Row>

                        <Row>
                            <Col s={12}>
                                <div className={css(styles.div)}>
                                    <Button text="Add Series" onClick={this.onSubmit} />
                                </div>
                            </Col>
                        </Row>
                    </Modal>
                )}

                <Button text="Add Series" onClick={() => this.setState({ open: true })} />
            </>
        );
    }
}

const styles = StyleSheet.create({
    div: {
        textAlign: "center",
    },
    empty: {
        paddingTop: 100,
    },
    emptyText: {
        fontSize: "18px",
    },
    modal: {
        overflow: "initial",
    },
});

export default reduxForm<{}, IProps>({
    enableReinitialize: true,
})(ModalAddPlaylist);
