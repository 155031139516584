import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";

import { Clickable, Icon, Table, Td, Th } from "styleguide";
import config from "../config";
import IAudience from "../types/audience";
import AudienceTypes from "../types/audience-types";
import IPlaylist from "../types/playlist";

interface IProps {
    audiences: IAudience[];
    playlists: IPlaylist[];
    name: string;
    removePlaylist: (playlistId: number) => void;
    moveUp: (playlistId: number) => void;
    moveDown: (playlistId: number) => void;
}

export class SpecialSection extends React.Component<IProps> {
    public openEditPlaylist = (id: number) => {
        window.open(`${config.paths.playlist}/${id}`, "_blank");
    };

    public render() {
        const { name, audiences, playlists } = this.props;

        const regions = _.filter(audiences, (a) => a.audienceType === AudienceTypes.Region);
        const playlistsOrdeded = _.sortBy(playlists, (p) => (p.sectionplaylists ? p.sectionplaylists.order : ""));

        return (
            <div>
                <Table title={name}>
                    <thead>
                        <tr>
                            <Th size="medium" align="center">
                                Actions
                            </Th>
                            <Th size="small" align="center">
                                Rank
                            </Th>
                            <Th>Story Name</Th>
                            {regions.map((r) => {
                                const total = _.chain(playlistsOrdeded)
                                    .map((p) => p.audiences || [])
                                    .flatten()
                                    .filter((a) => a.audienceId === r.audienceId)
                                    .size()
                                    .value();

                                return (
                                    <Th key={r.audienceId} size="small" align="center">
                                        <span className={css(r.playlists && total === 0 && styles.red)}>
                                            {r.audienceName} ({total})
                                        </span>
                                    </Th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {playlistsOrdeded.map((p: IPlaylist, i: number) => (
                            <tr key={p.playlistId}>
                                <Td align="center">
                                    {p.playlistIsPublished === 1 ? (
                                        <Icon icon="cloud_done" className={`${css(styles.paddingLeft, styles.paddingRight)} green-text`} />
                                    ) : (
                                        <Icon icon="cloud_queue" className={`${css(styles.paddingLeft, styles.paddingRight)} blue-text`} />
                                    )}
                                    <Clickable onClick={() => this.props.removePlaylist(p.playlistId)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                        <Icon icon="cancel" className={css(styles.red)} />
                                    </Clickable>
                                    {i !== 0 ? (
                                        <Clickable onClick={() => this.props.moveDown(p.playlistId)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                            <Icon icon="arrow_upward" />
                                        </Clickable>
                                    ) : (
                                        <Icon icon="arrow_upward" className={css(styles.grey, styles.paddingLeft, styles.paddingRight)} />
                                    )}

                                    {i !== playlistsOrdeded.length - 1 ? (
                                        <Clickable onClick={() => this.props.moveUp(p.playlistId)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                            <Icon icon="arrow_downward" />
                                        </Clickable>
                                    ) : (
                                        <Icon icon="arrow_downward" className={css(styles.grey, styles.paddingLeft, styles.paddingRight)} />
                                    )}

                                    <Clickable onClick={() => this.openEditPlaylist(p.playlistId)} className={css(styles.paddingLeft, styles.paddingRight)}>
                                        <Icon icon="edit" />
                                    </Clickable>
                                </Td>
                                <Td align="center">{p.sectionplaylists && p.sectionplaylists.order + 1}</Td>
                                <Td>{p.playlistName}</Td>
                                {regions.map((r) => (
                                    <Td key={r.audienceId} align="center">
                                        {!!_.find(p.audiences, (a) => a.audienceId === r.audienceId) && <Icon icon="check" />}{" "}
                                    </Td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    deleteIcon: {
        color: "red",
    },
    grey: {
        color: "grey",
    },
    paddingLeft: {
        paddingLeft: 5,
    },
    paddingRight: {
        paddingRight: 5,
    },
    red: {
        color: "red",
    },
});

export default SpecialSection;
