import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, Row, Tooltipped } from "styleguide";

import { renderInput } from "../utils/input";
import { required } from "../utils/validations";

interface IProps {
  small?: boolean;
}

export class PronunciationForm extends React.Component<
  InjectedFormProps<IProps> & IProps
> {
  public render() {
    const { small, handleSubmit, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col s={12}>
            {small && (
              <i
                className={`${css(
                  styles.icon
                )} material-icons postfix blue-text`}
                onClick={handleSubmit}
              >
                add
              </i>
            )}

            <Row>
              <div className={css(small && styles.form)}>
                <Col s={12} m={4}>
                  <InputField>
                    <label htmlFor="pronunciationName">
                      <Tooltipped tooltip="If you want a pronunciation to only show for certain instances of a word, add a tilde (~) in front of it.">
                        Text (?) *
                      </Tooltipped>
                    </label>

                    <Field
                      name="pronunciationName"
                      type="text"
                      component={renderInput}
                      validate={required}
                    />
                  </InputField>
                </Col>
                <Col s={12} m={4}>
                  <InputField>
                    <label htmlFor="pronunciationComment">Comment</label>
                    <Field
                      name="pronunciationComment"
                      type="text"
                      component={renderInput}
                    />
                  </InputField>
                </Col>
                <Col s={12} m={4}>
                  <InputField>
                    <label htmlFor="pronunciationLink">Link</label>
                    <Field
                      name="pronunciationLink"
                      type="text"
                      component={renderInput}
                    />
                  </InputField>
                </Col>
              </div>
            </Row>
          </Col>

          {!small && (
            <Col s={12}>
              <div className="center">
                <Button
                  text="save"
                  icon="save"
                  submit={true}
                  disable={!valid}
                />
              </div>
            </Col>
          )}
        </Row>
      </form>
    );
  }
}

const styles = StyleSheet.create({
  form: {
    marginBottom: 0,
  },
  icon: {
    cursor: "pointer",
    float: "right",
    marginTop: "-42px",
  },
});

export default reduxForm<{}, IProps>({
  enableReinitialize: true,
  form: "PronunciationForm",
})(PronunciationForm);
