export const FETCH_PLAYLISTS_FAILURE = "FETCH_PLAYLISTS_FAILURE";
export const FETCH_PLAYLISTS_REQUEST = "FETCH_PLAYLISTS_REQUEST";
export const FETCH_PLAYLISTS_SUCCESS = "FETCH_PLAYLISTS_SUCCESS";

export const FETCH_PLAYLIST_FAILURE = "FETCH_PLAYLIST_FAILURE";
export const FETCH_PLAYLIST_REQUEST = "FETCH_PLAYLIST_REQUEST";
export const FETCH_PLAYLIST_SUCCESS = "FETCH_PLAYLIST_SUCCESS";

export const CREATE_PLAYLIST_FAILURE = "CREATE_PLAYLIST_FAILURE";
export const CREATE_PLAYLIST_REQUEST = "CREATE_PLAYLIST_REQUEST";
export const CREATE_PLAYLIST_SUCCESS = "CREATE_PLAYLIST_SUCCESS";

export const UPDATE_PLAYLIST_FAILURE = "UPDATE_PLAYLIST_FAILURE";
export const UPDATE_PLAYLIST_REQUEST = "UPDATE_PLAYLIST_REQUEST";
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS";

export const CLEAR_PLAYLIST_STATE = "CLEAR_PLAYLIST_STATE";

export const MOVE_UP_PLAYLIST_REQUEST = "MOVE_UP_PLAYLIST_REQUEST";
export const MOVE_UP_PLAYLIST_SUCCESS = "MOVE_UP_PLAYLIST_SUCCESS";
export const MOVE_UP_PLAYLIST_FAILURE = "MOVE_UP_PLAYLIST_FAILURE";

export const MOVE_DOWN_PLAYLIST_REQUEST = "MOVE_DOWN_PLAYLIST_REQUEST";
export const MOVE_DOWN_PLAYLIST_SUCCESS = "MOVE_DOWN_PLAYLIST_SUCCESS";
export const MOVE_DOWN_PLAYLIST_FAILURE = "MOVE_DOWN_PLAYLIST_FAILURE";

export const FETCH_SUGGESTION_PLAYLIST_FAILURE = "FETCH_SUGGESTION_PLAYLIST_FAILURE";
export const FETCH_SUGGESTION_PLAYLIST_REQUEST = "FETCH_SUGGESTION_PLAYLIST_REQUEST";
export const FETCH_SUGGESTION_PLAYLIST_SUCCESS = "FETCH_SUGGESTION_PLAYLIST_SUCCESS";