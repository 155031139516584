import * as _ from 'lodash';
import { Dispatch } from "redux";
import { toast } from "styleguide";
import config from "../config";
import fetch from "../utils/fetch";

import {
  FETCH_AUDIENCES_FAILURE,
  FETCH_AUDIENCES_REQUEST,
  FETCH_AUDIENCES_SUCCESS
} from "../constants/audience";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const fetchAudiences = (params?: { playlists?: boolean }) => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: FETCH_AUDIENCES_REQUEST });
  const qs = params && params.playlists ? '?playlists=true' : '';

  return fetch(`${config.api.url}${config.api.paths.audience}${qs}`)
    .then(responseCheck)
    .then(response => dispatch(fetchAudiencesSuccess(response)))
    .catch(error => dispatch(fetchAudiencesFailure(error)));
};

/*
    SYNC ACTIONS
*/

const fetchAudiencesSuccess = (response: any) => {
  const audiences = _.map(response.data, a => ({
    audienceId: a.id,
    audienceName: a.name,
    audienceType: a.type,
    playlists: a.playlists && a.playlists.map((p: any) => ({ playlistId: p.id, playlistName: p.name }))
  }));

  return { type: FETCH_AUDIENCES_SUCCESS, audiences };
};

const fetchAudiencesFailure = (error: Error) => {
  toast("Error fetching audiences");
  return { type: FETCH_AUDIENCES_FAILURE, error };
};