import { debounce } from "ts-debounce";

const DEBOUNCE_TIME = 250;

export const myDebounce = (fn: () => void) => {
  debounce(() => fn(), DEBOUNCE_TIME, {
    isImmediate: true
  })();
};

export default myDebounce;
