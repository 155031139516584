import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Collapsible, Loading, Row } from "styleguide";

import { clearArticleState, fetchArticles, moveDown, moveUp } from "../actions/article";
import { clearPlaylistState, fetchPlaylists } from "../actions/playlist";
import ArticleListForPlaylist from "../components/ArticleListForPlaylist";
import PlaylistItem from "../components/PlaylistItem";
import config from "../config";
import { IState } from "../reducers";
import { requestArticlesByPlaylistId, requestArticlesByStoryId } from "../services/article";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import IPlaylist from "../types/playlist";
import PlaylistTypes from "../types/playlist-types";
import ISection from "../types/section";
import { Helmet } from "react-helmet";
import { requestAllPlaylistsWithSectionsAndArticles } from "../services/playlist";
import ReactSelect from "react-select";
import { ReactSelectStyles } from "src/utils/react-select";
import { fetchSections } from "src/actions/section";
import InputTextV2 from "src/components/InputTextV2";
import { StyleSheet, css } from "aphrodite";
import SectionTypes from "src/types/section-types";

type IPlaylistWithMetadata = IPlaylist & { numArticles: number; lastArticlePublished: string };
type ISectionWithMetadata = ISection & { playlists: IPlaylistWithMetadata[] };

interface IPropsFromState {
    articles: IArticle[];
    sections: ISectionWithMetadata[];
    sectionsRaw: ISection[];
    playlists: IPlaylist[];
    error?: errors.HttpError;
    isLoading: boolean;
    isArticleLoading: boolean;
}

interface IPropsFromDispatch {
    fetchPlaylists: typeof fetchPlaylists;
    fetchSections: typeof fetchSections;
    fetchArticles: typeof fetchArticles;
    clearArticleState: typeof clearArticleState;
    clearPlaylistState: typeof clearPlaylistState;
    moveUp: typeof moveUp;
    moveDown: typeof moveDown;
}

interface IInternalState {
    playlistIdOpened: number;
    articleLimit: number;
    searchText: string | null;
    selectedCategory?: number;
    availableSubCategories: { label: string; value: number }[];
    selectedSubCategory?: number;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>, IInternalState> {
    private PAGE_RANGE = 20;

    constructor(props: IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>) {
        super(props);

        this.state = { playlistIdOpened: 0, articleLimit: this.PAGE_RANGE, searchText: null, availableSubCategories: [] };
    }

    public fetchPlaylists = (sectionId: number) => {
        this.props.fetchPlaylists(requestAllPlaylistsWithSectionsAndArticles(sectionId));
    };

    public getSectionsToRender = () => {
        const { playlists, sections, isLoading } = this.props;

        if (this.state.selectedCategory === -1) {
            return [{ sectionId: 0, sectionIsPublished: -1, sectionName: "Without Section", playlists: playlists.filter((p) => !p.sections?.length) }];
        }

        if (isLoading || _.size(playlists) < 1 || _.size(sections) < 1) {
            return [];
        }

        return [...sections];
    };

    public fetchPlaylistOrStoryArticles = (playlist: IPlaylistWithMetadata, limit: number) => {
        if (playlist.playlistType === PlaylistTypes.Story) {
            this.props.fetchArticles(requestArticlesByStoryId(playlist.playlistId, limit), true);
            return;
        }

        const numArticlesMinusLimit = playlist.numArticles - limit;
        const offset = numArticlesMinusLimit >= 0 ? numArticlesMinusLimit : 0;
        this.props.fetchArticles(requestArticlesByPlaylistId(playlist.playlistId, limit, offset), true);
    };

    public clickOnPlaylist = (playlist: IPlaylistWithMetadata) => {
        this.setState((prev) => ({ ...prev, playlistIdOpened: playlist.playlistId, articleLimit: this.PAGE_RANGE }));
        this.props.clearArticleState();
        this.fetchPlaylistOrStoryArticles(playlist, this.PAGE_RANGE);
    };

    public moveUp = (articleId: number, playlist: IPlaylistWithMetadata) => {
        this.props
            .moveUp(articleId, playlist.playlistId)
            // @ts-ignore
            .then(() => {
                this.props.clearArticleState();
                this.fetchPlaylistOrStoryArticles(playlist, this.state.articleLimit);
            });
    };

    public moveDown = (articleId: number, playlist: IPlaylistWithMetadata) => {
        this.props
            .moveDown(articleId, playlist.playlistId)
            // @ts-ignore
            .then(() => {
                this.props.clearArticleState();
                this.fetchPlaylistOrStoryArticles(playlist, this.state.articleLimit);
            });
    };

    public seeMore = (playlist: IPlaylistWithMetadata) => {
        const newLimit = this.state.articleLimit + this.PAGE_RANGE;
        this.setState({ articleLimit: newLimit });

        this.fetchPlaylistOrStoryArticles(playlist, newLimit);
    };

    public onChangeCategory = (sectionId: number) => {
        const { sectionsRaw: sections } = this.props;

        this.setState((prev) => ({ ...prev, selectedCategory: sectionId, selectedSubCategory: undefined }));

        const subSections = _.chain(sections)
            .filter((s) => s.parentId === sectionId)
            .map((s) => ({ label: s.sectionName, value: s.sectionId }))
            .sortBy("label")
            .value();

        this.setState((prev) => ({ ...prev, availableSubCategories: [{ label: "All", value: -1 }, ...subSections] }));
    };

    public onChangeSubCategory = (sectionId: number) => {
        this.setState((prev) => ({ ...prev, selectedSubCategory: sectionId, searchText: null }));

        if (sectionId === -1) {
            if (this.state.selectedCategory) this.fetchPlaylists(this.state.selectedCategory);
        } else this.fetchPlaylists(sectionId);
    };

    public searchPlaylists = (searchText: string) => {
        if (searchText.trim().length === 0) {
            this.setState((prev) => ({ ...prev, searchText: null }));
        } else {
            this.setState((prev) => ({ ...prev, searchText: searchText }));
        }
    };

    public renderListPlaylists = () => {
        const { articles } = this.props;
        const { playlistIdOpened, searchText } = this.state;

        const sectionsToRender = this.getSectionsToRender();

        return sectionsToRender.map((section) => (
            <Collapsible
                title={`${section.sectionName} ${section.sectionIsPublished === -1 ? "" : section.sectionIsPublished === 1 ? "(PUBLISHED)" : "(UNPUBLISHED)"}`}
                key={`${section.sectionId}-${section.sectionName}`}
                accordion={true}
            >
                {section.playlists &&
                    (section.playlists.length === 0 ? (
                        <p className={css(styles.notFound)}>No playlists found!</p>
                    ) : (
                        (section.playlists as IPlaylistWithMetadata[]).map((playlist: IPlaylistWithMetadata) => {
                            if (searchText && !playlist.playlistName.toLowerCase().includes(searchText.trim().toLowerCase())) return null;
                            return (
                                <PlaylistItem key={`${section.sectionId}-${playlist.playlistId}`} playlist={playlist} onClick={() => this.clickOnPlaylist(playlist)}>
                                    <ArticleListForPlaylist
                                        playlistType={playlist.playlistType}
                                        total={playlist.numArticles}
                                        articles={playlistIdOpened === playlist.playlistId ? articles : []}
                                        moveUp={(articleId) => this.moveUp(articleId, playlist)}
                                        moveDown={(articleId) => this.moveDown(articleId, playlist)}
                                        seeMore={() => this.seeMore(playlist)}
                                    />
                                </PlaylistItem>
                            );
                        })
                    ))}
            </Collapsible>
        ));
    };

    public componentDidMount() {
        this.props.fetchPlaylists(requestAllPlaylistsWithSectionsAndArticles(-110));
        this.props.fetchSections({
            filter: [{ name: "sectionType", value: SectionTypes.Regular }],
        });
    }

    public componentWillUnmount() {
        this.props.clearArticleState();
        this.props.clearPlaylistState();
    }

    public render() {
        const { isLoading, history, sectionsRaw, isArticleLoading } = this.props;

        const sectionsTransformed = [
            ...sectionsRaw
                .filter((c) => !c.parentId)
                .map((c) => ({
                    label: c.sectionName,
                    value: c.sectionId,
                })),
            { label: "Without Section", value: -1 },
        ];

        const isSearchDisabled = isLoading || isArticleLoading || !this.state.selectedCategory || !this.state.selectedSubCategory;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Series &amp; Playlists</title>
                </Helmet>

                <div style={{ marginTop: "20px" }} />

                {isArticleLoading && <Loading />}

                <Row>
                    <Col s={12} m={6}>
                        <label>Category</label>
                        <ReactSelect
                            styles={ReactSelectStyles}
                            name="playlistSections"
                            placeholder="Select Category"
                            options={sectionsTransformed}
                            onChange={(section) => section && this.onChangeCategory(section.value)}
                        />
                    </Col>
                    <Col s={12} m={6}>
                        <label>Sub-Category</label>
                        <ReactSelect
                            styles={ReactSelectStyles}
                            name="sections"
                            placeholder="Select Sub-category"
                            options={this.state.availableSubCategories}
                            key={`add_article_react_select_${this.state.selectedCategory}`}
                            onChange={(section) => section && this.onChangeSubCategory(section.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col s={12} m={12}>
                        <label>Search playlists</label>
                        <InputTextV2
                            placeholder="Search playlists..."
                            name="search"
                            type="text"
                            value={this.state.searchText || ""}
                            change={(_, value) => this.searchPlaylists(value)}
                            className={css(styles.search, isSearchDisabled && styles.searchDisabled)}
                            disabled={isSearchDisabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>{isLoading ? <Loading /> : this.renderListPlaylists()}</Col>
                </Row>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.playlist}+`)} />
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    search: {
        boxSizing: "border-box",
        background: "#ffffff",
        height: "57px",
        padding: "0px 10px",
    },

    searchDisabled: {
        background: "#cbcbcb",
        "::placeholder": {
            color: "#9e9e9e",
        },
    },

    notFound: {
        color: "grey",
        fontSize: "20px",
        textAlign: "center",
        margin: "auto",
        padding: "20px 10px",
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        articles: state.article.articles,
        error: state.playlist.error,
        isLoading: state.playlist.isLoading || state.section.isLoading,
        isArticleLoading: state.article.isLoading,
        playlists: state.playlist.playlists,
        sections: state.playlist.sections as ISectionWithMetadata[],
        sectionsRaw: state.section.sections,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearArticleState,
            clearPlaylistState,
            fetchArticles,
            fetchPlaylists,
            moveDown,
            moveUp,
            fetchSections,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
