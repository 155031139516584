import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Loading } from "styleguide";

import { fetchArticles, togglePublishArticle } from "../actions/article";
import ArticleListForNewspapers from "../components/ArticleListForNewspapers";
import { IState } from "../reducers";
import { goToAudio } from "../services/article";
import { requestLastArticles } from "../services/article";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import INewspaperMetric from "../types/newspaper-metric";
import IUser from "../types/user";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    error?: errors.HttpError;
    isLoading: boolean;
    user?: IUser;
    metric?: INewspaperMetric[];
    articles: IArticle[];
}

interface IPropsFromDispatch {
    fetchArticles: typeof fetchArticles;
    togglePublishArticle: typeof togglePublishArticle;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public togglePublishArticle = (article: IArticle) => {
        this.props.togglePublishArticle(article);
    };

    public renderLastArticles = () => {
        return <ArticleListForNewspapers title="Last 10 Articles" articles={this.props.articles} goToAudio={goToAudio} togglePublishArticle={this.togglePublishArticle} />;
    };

    public componentDidMount() {
        this.props.fetchArticles(requestLastArticles(10), true);
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderLastArticles()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        articles: state.article.articles,
        error: state.metric.error,
        isLoading: state.metric.isLoading || state.article.isLoading,
        metric: state.metric.metric,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchArticles,
            togglePublishArticle,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
