import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
    CLEAR_SECTION_STATE,
    CREATE_SECTION_FAILURE,
    CREATE_SECTION_REQUEST,
    CREATE_SECTION_SUCCESS,
    FETCH_SECTION_FAILURE,
    FETCH_SECTION_REQUEST,
    FETCH_SECTION_SUCCESS,
    FETCH_SECTIONS_FAILURE,
    FETCH_SECTIONS_REQUEST,
    FETCH_SECTIONS_SUCCESS,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_REQUEST,
    UPDATE_SECTION_SUCCESS,
} from "../constants/section";
import { IState } from "../reducers";
import { requestAllSections } from "../services/section";
import IFile from "../types/file";
import FileType from "../types/file-type";
import HttpMethods from "../types/http-methods";
import ISection from "../types/section";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";

/*
    ASYNC ACTIONS
*/

export const fetchSections =
    (params?: IParams) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_SECTIONS_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.section}`;

        const url = !params ? buildUrl(endpoint, requestAllSections()) : buildUrl(endpoint, params);

        return fetch(url)
            .then(responseCheck)
            .then((sections) => dispatch(fetchSectionsSuccess(sections)))
            .catch((error) => dispatch(fetchSectionsFailure(error)));
    };

export const fetchSection =
    (id: number) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_SECTION_REQUEST });

        return fetch(`${config.api.url}${config.api.paths.section}/${id}`)
            .then(responseCheck)
            .then((section) => dispatch(fetchSectionSuccess(section)))
            .catch((error) => dispatch(fetchSectionFailure(error)));
    };

export const createSection =
    (section: ISection, history: History) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: CREATE_SECTION_REQUEST });

        return uploadFiles("section")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    if (files[0].type === FileType.image) {
                        section.sectionImage = files[0].key;
                    }
                });

                return fetch(`${config.api.url}${config.api.paths.section}`, {
                    body: JSON.stringify({ model: section }),
                    method: HttpMethods.POST,
                });
            })
            .then(responseCheck)
            .then((cat) => dispatch(createSectionSuccess(cat)))
            .then(() => dispatch(clearFiles()))
            .then(() => history.push(config.paths.section))
            .catch((error) => dispatch(createSectionFailure(error)));
    };

export const editSection =
    (section: ISection, history: History) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: UPDATE_SECTION_REQUEST });

        return uploadFiles("section")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    if (files[0].type === FileType.image) {
                        section.sectionImage = files[0].key;
                    }
                });

                return fetch(`${config.api.url}${config.api.paths.section}/${section.sectionId}`, {
                    body: JSON.stringify({ model: section }),
                    method: HttpMethods.PUT,
                });
            })
            .then(responseCheck)
            .then(() => dispatch(editSectionSuccess()))
            .then(() => dispatch(clearFiles()))
            .then(() => history.push(config.paths.section))
            .catch((error) => dispatch(editSectionFailure(error)));
    };

/*
    SYNC ACTIONS
*/

const fetchSectionsSuccess = (sections: ISection[]) => {
    return { type: FETCH_SECTIONS_SUCCESS, sections };
};

const fetchSectionsFailure = (error: Error) => {
    toast("Error fetching sections");
    return { type: FETCH_SECTIONS_FAILURE, error };
};

const fetchSectionSuccess = (section: ISection) => {
    return { type: FETCH_SECTION_SUCCESS, section };
};

const fetchSectionFailure = (error: Error) => {
    toast("Error fetching sections");
    return { type: FETCH_SECTION_FAILURE, error };
};

const createSectionSuccess = (section: ISection) => {
    toast("Section created");
    return { type: CREATE_SECTION_SUCCESS, section };
};

const createSectionFailure = (error: Error) => {
    toast("Error creating section");
    return { type: CREATE_SECTION_FAILURE, error };
};

const editSectionSuccess = () => {
    toast("Section updated");
    return { type: UPDATE_SECTION_SUCCESS };
};

const editSectionFailure = (error: Error) => {
    toast("Error editing section");
    return { type: UPDATE_SECTION_FAILURE, error };
};

export const clearSectionState = () => {
    return { type: CLEAR_SECTION_STATE };
};
