import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Card, Col, InputDate, InputField, InputSelect, ModalConfirm, Row } from "styleguide";

import { updateFile } from "../../../actions/file";
import IAdvert from "../types/advert";
import * as errors from "../../../types/errors";
import FileType from "../../../types/file-type";
import IUser from "../../../types/user";
import { renderError, renderInput } from "../../../utils/input";
import { required } from "../../../utils/validations";
import InputFile from "../../../components/InputFile";
import { InputSwitch } from "../../../components/InputSwitch";
import ICompany from "../../../types/company";
import ICountryGroup from "src/types/country-group";
import InputSelectSectioned from "src/components/InputSelectSectioned";
import UserRoles from "src/types/user-roles";

interface IProp {
    advert?: IAdvert;
    error?: errors.HttpError;
    narrators: IUser[];
    companies: ICompany[];
    countriesGrouped: ICountryGroup[];
    user?: IUser;
    updateFile: typeof updateFile;
}

export class AdvertForm extends React.Component<IProp & InjectedFormProps> {
    public renderAdvertFields = (advert: IAdvert) => {
        return (
            <Card title="Advert">
                <Row>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="advertAudioText">In-app title *</label>
                            <Field validate={required} required={true} name="advertAudioText" type="textarea" component="textarea" className={`${css(styles.textarea)} materialize-textarea`} />
                        </InputField>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} m={6}>
                        <InputField>
                            <div className={css(styles.fileInput)}>
                                <InputFile
                                    model="adverts"
                                    id="advertImageFileName"
                                    upFile={this.props.updateFile}
                                    type={FileType.image}
                                    change={this.props.change}
                                    value={advert && advert.advertImageFileName}
                                    imageDimensionsCheck
                                    imageSizeCheck
                                />
                            </div>
                            <label className="active">Image</label>
                        </InputField>
                    </Col>
                    <Col s={12} m={6}>
                        <InputField>
                            <div className={css(styles.fileInput)}>
                                <InputFile
                                    model="adverts"
                                    id="advertAudioFileName"
                                    upFile={this.props.updateFile}
                                    type={FileType.audio}
                                    change={this.props.change}
                                    value={advert && advert.advertAudioFileName}
                                    audioBitrateCheck
                                />
                            </div>
                            <label className="active">Audio</label>
                        </InputField>
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="advertUrl">Advert link</label>
                            <Field name="advertUrl" type="text" component={renderInput} />
                        </InputField>
                    </Col>
                </Row>
            </Card>
        );
    };

    public renderMetadata = (advert: IAdvert) => {
        const { companies, change, narrators, user } = this.props;

        const companiesTransformed = companies
            ? [
                  ...companies.map((c) => ({
                      text: c.companyName,
                      value: c.companyId,
                  })),
              ]
            : [];

        const narratorsTransformed = narrators.map((n) => ({
            text: `${n.userFirstName} ${n.userLastName}`,
            value: n.userId,
        }));

        const advertAudioRecorderID = advert ? advert.advertAudioRecorderID : [];

        const companyId = advert.companyId;

        const showNarrator = user && user.userRoleIdList !== UserRoles.ADVERTISING;

        return (
            <Card title="Metadata">
                <Row>
                    <Col s={12} m={6}>
                        <InputField>
                            <label htmlFor="advertFriendlyName">Reference name *</label>
                            <Field name="advertFriendlyName" type="text" component={renderInput} validate={required} />
                        </InputField>
                    </Col>
                    <Col s={12} m={6}>
                        <InputField>
                            <InputSelect placeholder="Select company" rows={companiesTransformed} name="companyId" change={change} value={companyId} required={true} />
                            <label>Client company *</label>
                        </InputField>
                    </Col>
                </Row>
                <Row>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="advertComments">Comments</label>
                            <Field name="advertComments" type="textarea" component="textarea" className={`${css(styles.textarea)} materialize-textarea`} />
                        </InputField>
                    </Col>
                </Row>
                {showNarrator && (
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <InputSelect placeholder="Select One Recorder" rows={narratorsTransformed} name="advertAudioRecorderID" change={change} value={advertAudioRecorderID} />
                                <label>Narrator</label>
                            </InputField>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    };

    public renderAdSettings = (advert: IAdvert) => {
        const { change, countriesGrouped, user } = this.props;

        const countriesTransformed = countriesGrouped
            ? [
                  ...countriesGrouped.map((g) => ({
                      title: g.title,
                      rows: g.countries.map((c) => ({
                          text: c.name,
                          value: c.id,
                      })),
                  })),
              ]
            : [];

        const priorityTransformed = [
            {
                text: "High (Ads on lower priorities will not play until this ad expires)",
                value: 3,
            },
            {
                text: "Medium (Standard)",
                value: 2,
            },
            {
                text: "Low (Will only play if no higher priority ads available)",
                value: 1,
            },
        ];

        const countryIds = advert.countries ? advert.countries.map((c) => c.id.toString()) : [];

        const showAdminFields = user && user.userRoleIdList !== UserRoles.ADVERTISING;

        return (
            <Card title="Settings">
                <Row>
                    <Col s={12} m={6}>
                        <InputField>
                            <label htmlFor="advertLiveFromDateTime">Start date *</label>
                            <InputDate change={change} name="advertLiveFromDateTime" />
                        </InputField>
                    </Col>
                    <Col s={12} m={6}>
                        <InputField>
                            <label htmlFor="advertExpiresDateTime">Expiration date *</label>
                            <InputDate change={change} name="advertExpiresDateTime" />
                        </InputField>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} xl={6}>
                        <InputField>
                            <label htmlFor="listenLimit">Listen limit (If set, ad will end when limit is reached)</label>
                            <Field name="listenLimit" type="number" component={renderInput} />
                        </InputField>
                    </Col>
                    {showAdminFields && (
                        <Col s={12} xl={6}>
                            <InputField>
                                <InputSelect rows={priorityTransformed} name="priority" change={change} value={advert.priority} placeholder="Select priority" />
                                <label>Priority</label>
                            </InputField>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col s={12}>
                        <InputField>
                            <InputSelectSectioned sections={countriesTransformed} name="countryIds" change={change} multiple={true} value={countryIds} placeholder="All countries" />
                            <label>Target countries</label>
                        </InputField>
                    </Col>
                </Row>
                {showAdminFields && (
                    <Row>
                        <Col s={12}>
                            <div className="center">
                                <h5 className={css(styles.subtitle)}>Published?</h5>
                                <InputSwitch name="advertIsPublished" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    };

    public render() {
        const { advert, handleSubmit, valid } = this.props;

        if (!advert) {
            return null;
        }

        return (
            <ModalConfirm title="Confirmation" description="Are you sure that you want save this advert?">
                {(confirm: any) => (
                    <form onSubmit={confirm(handleSubmit)}>
                        <Row>
                            <Col s={12}>
                                {this.renderAdvertFields(advert)}
                                {this.renderMetadata(advert)}
                                {this.renderAdSettings(advert)}

                                <Col s={12}>
                                    <Field name="error" type="hidden" component={renderError} />
                                </Col>

                                <Col s={12}>
                                    <div className="center">
                                        <Button text="save" icon="save" submit={true} disable={!valid} />
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    fileInput: {
        marginTop: -15,
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 300,
        paddingTop: 20,
    },
    textarea: {
        height: 60,
        overflowY: "auto",
        resize: "vertical",
    },
});

export default reduxForm({
    enableReinitialize: true,
    form: "AdvertForm",
    // @ts-ignore
})(AdvertForm);
