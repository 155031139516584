import * as React from "react";

import * as _ from "lodash";

interface IProps {
    type: "text" | "textarea";
    name: string;
    placeholder?: string;
    required?: boolean;
    change: (field: string, value: any) => void;
    value?: string;
    className?: string;
    disabled?: boolean;
}

interface IState {
    text: string;
}

export class InputTextV2 extends React.Component<IProps, IState> {
    private debounceOnChange: (field: string, value: any) => void;

    constructor(props: IProps) {
        super(props);

        this.state = { text: props.value || "" };
        this.debounceOnChange = _.debounce(this.props.change, 400);
    }

    public onChange = (event: React.ChangeEvent<any>) => {
        const value = event.target.value;

        this.setState({ text: value });
        this.debounceOnChange(this.props.name, value);
    };

    public componentDidUpdate = (prevProps: IProps) => {
        const { value } = this.props;
        if (value !== undefined && value !== prevProps.value) {
            this.debounceOnChange(this.props.name, value);
            this.setState({ text: value });
        }
    };

    public render() {
        const { name, required, placeholder, type, className } = this.props;
        const { text } = this.state;

        if (type === "textarea") {
            return <textarea placeholder={placeholder} value={text} name={name} required={required} onChange={this.onChange} className={className} disabled={this.props.disabled} />;
        }

        return (
            <input
                type="text"
                name={name}
                className={className}
                required={required}
                placeholder={placeholder}
                onChange={this.onChange}
                value={text}
                defaultValue={text}
                disabled={this.props.disabled}
            />
        );
    }
}

export default InputTextV2;
