import { Dispatch } from "redux";
import config from "src/config";
import {
    CREATE_EDITOR_PICK_FAILURE,
    CREATE_EDITOR_PICK_REQUEST,
    CREATE_EDITOR_PICK_SUCCESS,
    FETCH_SPECIAL_SECTIONS_FAILURE,
    FETCH_SPECIAL_SECTIONS_REQUEST,
    FETCH_SPECIAL_SECTIONS_SUCCESS,
    REMOVE_EDITOR_PICK_FAILURE,
    REMOVE_EDITOR_PICK_REQUEST,
    REMOVE_EDITOR_PICK_SUCCESS,
    REORDER_EDITOR_PICK_FAILURE,
    REORDER_EDITOR_PICK_REQUEST,
    REORDER_EDITOR_PICK_SUCCESS,
} from "src/constants/specialsection";
import HttpMethods from "src/types/http-methods";
import ISpecialSection from "src/types/special-section";
import responseCheck from "src/utils/response-check";
import { toast } from "styleguide/build";
import fetch from "../utils/fetch";

export const fetchSpecialSections =
    () =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_SPECIAL_SECTIONS_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.specialsections}`;

        return fetch(endpoint)
            .then(responseCheck)
            .then((sections) => dispatch(fetchSpecialSectionsSuccess(sections)))
            .catch((error) => dispatch(fetchSpecialSectionsFailure(error)));
    };

export const createEditorPick =
    (payload: { articleId?: number; playlistId?: number; sectionId: number }) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: CREATE_EDITOR_PICK_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.specialsections}/${payload.sectionId}/items`;

        return fetch(endpoint, { method: HttpMethods.POST, body: JSON.stringify(payload) })
            .then(responseCheck)
            .then(() => dispatch(createEditorPickSuccess()))
            .catch((error) => dispatch(createEditorPickFailure(error)));
    };

export const removeEditorPick =
    (sectionId: number, itemId: number) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: REMOVE_EDITOR_PICK_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.specialsections}/${sectionId}/items/${itemId}`;

        return fetch(endpoint, { method: HttpMethods.DELETE })
            .then(responseCheck)
            .then(() => dispatch(removeEditorPickSuccess()))
            .catch((error) => dispatch(removeEditorPickFailure(error)));
    };

export const reOrderEditorPick =
    (sectionId: number, itemId: number, order: "up" | "down") =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: REORDER_EDITOR_PICK_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.specialsections}/${sectionId}/items/${itemId}/reorder/${order}`;

        return fetch(endpoint, { method: HttpMethods.PUT })
            .then(responseCheck)
            .then(() => dispatch(reOrderEditorPickSuccess()))
            .catch((error) => dispatch(reOrderEditorPickFailure(error)));
    };

const fetchSpecialSectionsSuccess = (sections: ISpecialSection[]) => {
    return { type: FETCH_SPECIAL_SECTIONS_SUCCESS, sections };
};

const fetchSpecialSectionsFailure = (error: Error) => {
    toast("Error fetching sections");
    return { type: FETCH_SPECIAL_SECTIONS_FAILURE, error };
};

const createEditorPickSuccess = () => {
    return { type: CREATE_EDITOR_PICK_SUCCESS };
};

const createEditorPickFailure = (error: Error) => {
    toast("Unable to add this editor pick.");
    return { type: CREATE_EDITOR_PICK_FAILURE, error };
};

const removeEditorPickSuccess = () => {
    return { type: REMOVE_EDITOR_PICK_SUCCESS };
};

const removeEditorPickFailure = (error: Error) => {
    toast("Unable to remove this editor pick.");
    return { type: REMOVE_EDITOR_PICK_FAILURE, error };
};

const reOrderEditorPickSuccess = () => {
    return { type: REORDER_EDITOR_PICK_SUCCESS };
};

const reOrderEditorPickFailure = (error: Error) => {
    toast("Unable to reorder this editor pick.");
    return { type: REORDER_EDITOR_PICK_FAILURE, error };
};
