import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, Modal, Row } from "styleguide";

import InputText from "./InputText";
import { createArticleTemplate } from "../actions/articletemplates";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { IState } from "../reducers";
import IArticle from "../types/article";

interface ILocalProps {
  show: boolean;
  article?: IArticle;
  close: any;
}

interface ILocalState {}

interface IPropsFromDispatch {
  createArticleTemplate: typeof createArticleTemplate;
}

interface IPropsFromState {}

export class ModalSaveArticleTemplate extends React.Component<
  ILocalProps &
    IPropsFromState &
    IPropsFromDispatch &
    InjectedFormProps<{}, ILocalProps & IPropsFromState & IPropsFromDispatch>,
  ILocalState
> {
  public onSubmit = (event: any) => {
    this.props.handleSubmit((values) => {
      const { article } = this.props;

      if (!article) {
        return;
      }

      this.props.close();

      // @ts-ignore
      const { templateName } = values;

      this.props.createArticleTemplate({
        ...article,
        name: templateName,
      });
    })(event);
  };

  public close = () => {
    this.props.close();
    this.props.reset();
  };

  public render() {
    const { change, show, invalid, pristine, submitting } = this.props;

    return (
      <>
        {show && (
          <Modal
            title="Save Template"
            className={css(styles.modal)}
            close={this.close}
          >
            <Row>
              <Col s={12} m={6}>
                <InputField>
                  <InputText
                    type="text"
                    name="templateName"
                    required={true}
                    change={change}
                    initialValue={undefined}
                  />
                  <label>Template Name</label>
                </InputField>
              </Col>
            </Row>
            <Row>
              <Button
                text="Save template"
                icon="save"
                disable={invalid || pristine || submitting}
                onClick={this.onSubmit}
              />
            </Row>
          </Modal>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  div: {
    textAlign: "center",
  },
  empty: {
    paddingTop: 100,
  },
  emptyText: {
    fontSize: "18px",
  },
  modal: {
    overflow: "initial",
  },
});

function mapStateToProps(
  state: IState,
  ownProps: ILocalProps
): IPropsFromState {
  return {
    article: ownProps.article,
    show: ownProps.show,
    close: ownProps.close,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
  return bindActionCreators(
    {
      createArticleTemplate,
    },
    dispatch
  );
}

const myForm = reduxForm<
  {},
  ILocalProps & IPropsFromState & IPropsFromDispatch
>({ enableReinitialize: true, form: "TemplateForm" })(ModalSaveArticleTemplate);

export default connect(mapStateToProps, mapDispatchToProps)(myForm);
