import * as errors from "../types/errors";
import IPlaylist from "../types/playlist";

export interface IState {
    playlists: IPlaylist[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    playlists?: IPlaylist[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    isLoading: false,
    playlists: []
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {
    switch (action.type) {
        default:
            return state;
    }
};
