import { CLEAR_SEARCH, DO_SEARCH } from "../constants/search";
import ISearch from "../types/search";

export const doSearch = (search: ISearch) => {
  return {
    fromDate: search.fromDate,
    option: search.option,
    text: search.text,
    toDate: search.toDate,
    type: DO_SEARCH
  };
};

export const clearSearch = () => {
  return { type: CLEAR_SEARCH };
};
