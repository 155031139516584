import * as React from "react";

import { css, StyleSheet } from "aphrodite";

import { updateFile } from "../actions/file";
import config from "../config";
import FileType from "../types/file-type";
import fetch from "../utils/fetch";
import { jQuery } from "../utils/jquery";
import Toast from "./Toast";

interface IProp {
    model: string;
    id: string;
    upFile: typeof updateFile;
    type: FileType;
    change: (field: string, value: any) => void;
    value?: string;
    audioBitrateCheck?: boolean;
    imageSizeCheck?: boolean;
    imageDimensionsCheck?: boolean;
}

interface IState {
    uploadedFile: boolean;
    toastErrorMessage?: string;
}

const MAX_IMAGE_FILE_SIZE = 150 * 1000; // bytes
const MAX_AUDIO_FILE_BITRATE = 100; // kbps
const MIN_IMAGE_FILE_WIDTH = 300; // pixels
const MIN_IMAGE_FILE_HEIGHT = 300; // pixels

export class InputFile extends React.Component<IProp, IState> {
    constructor(props: IProp) {
        super(props);

        this.state = { uploadedFile: false };
    }

    public fetchFile = () => {
        const { model, value, type } = this.props;
        const viewUrl = type === FileType.audio ? config.api.getAudio : config.api.getImage;
        const validationEndpoint = type === FileType.audio ? "audios" : "images";
        const validationURL = `${config.api.uploadFile}/${validationEndpoint}/${model}/${value}/valid`;
        const URL = `${viewUrl}/${model}/${value}`;

        if (!value) {
            return Promise.reject("file not found");
        }

        return fetch(validationURL).then((resp) => {
            if (resp.status === 200) {
                return Promise.resolve(URL);
            }
            return Promise.reject("file not found");
        });
    };

    public clickIconInputFile = () => {
        this.fetchFile().then((url) => window.open(url, "_blank"));
    };

    public getAudioFileBitrateInKbps = async (file: File): Promise<number> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio((reader as any).result);
                media.onloadedmetadata = () => resolve(Math.floor(((file.size / media.duration) * 8) / 1000));
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });
    };

    public getImageFileDimensions = async (file: File): Promise<{ width: number; height: number }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => resolve({ width: img.width, height: img.height });

                img.src = (reader as any).result;
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });
    };

    public setInputFileToDefault = (id: string) => {
        const f = document.getElementById(`input-file-main-${id}`) as any;
        f.value = "";
        const ft = document.getElementById(`input-file-text-${id}`) as any;
        ft.value = "";
    };

    public onChange = async (e: any) => {
        const { change, id, upFile, type, audioBitrateCheck, imageDimensionsCheck, imageSizeCheck } = this.props;

        const files = e.target.files;
        if (!files) {
            return;
        }

        this.setState({ uploadedFile: false });

        const file = files.item(0);

        if (type === FileType.image) {
            if (imageSizeCheck && file.size > MAX_IMAGE_FILE_SIZE) {
                this.setState({ toastErrorMessage: "The image size exceeds the maximum permitted size of 150KB." });
                this.setInputFileToDefault(id);
                return;
            }

            const dimensions = await this.getImageFileDimensions(file);

            if (imageDimensionsCheck && (dimensions.width < MIN_IMAGE_FILE_WIDTH || dimensions.height < MIN_IMAGE_FILE_HEIGHT)) {
                this.setState({ toastErrorMessage: "The image height or width is less than the minimum permitted dimension of 300 pixels." });
                this.setInputFileToDefault(id);
                return;
            }

            upFile(id, file, type);
        } else if (type === FileType.audio) {
            const bitrate = await this.getAudioFileBitrateInKbps(file);
            if (audioBitrateCheck && bitrate > MAX_AUDIO_FILE_BITRATE) {
                this.setState({ toastErrorMessage: "The audio bit rate (Kbps) exceeds the maximum permitted bit rate of 100 Kbps." });
                this.setInputFileToDefault(id);
                return;
            }

            const objectUrl = URL.createObjectURL(file);

            jQuery("#audio-file").prop("src", objectUrl);

            jQuery("#audio-file").one("canplaythrough", (event: any) => {
                upFile(id, file, type, event.currentTarget.duration);
                URL.revokeObjectURL(objectUrl);
            });
        }

        change(id, file.name);
    };

    public checkIfFileUploaded = () => {
        this.fetchFile()
            .then(() => this.setState({ uploadedFile: true }))
            .catch(() => this.setState({ uploadedFile: false }));
    };

    public componentDidMount() {
        this.checkIfFileUploaded();
    }

    public componentDidUpdate(prevProps: IProp) {
        if (this.props.value !== prevProps.value) {
            this.checkIfFileUploaded();
        }
    }

    public render() {
        const { id, type, value } = this.props;
        const { uploadedFile } = this.state;

        return (
            <React.Fragment>
                <div className={`${css(styles.fileInput)} file-field input-field`}>
                    {type === FileType.audio && <audio id="audio-file" />}
                    <div>
                        <i onClick={this.clickIconInputFile} className={`${css(styles.iconInputFile)} ${css(uploadedFile && styles.link)} material-icons prefix left grey-text `}>
                            {type === FileType.audio ? "audiotrack" : "image"}
                        </i>
                        <input id={`input-file-main-${id}`} type="file" name="file" accept={type === FileType.audio ? "audio/*" : "image/*"} onChange={this.onChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input id={`input-file-text-${id}`} className="file-path validate" type="text" value={value} placeholder="Click here to upload a file" />
                    </div>
                </div>
                <Toast message={this.state.toastErrorMessage} onTimeout={() => this.setState({ toastErrorMessage: undefined })} />
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    fileInput: {
        height: 45,
        marginLeft: "2rem",
    },
    fileView: {
        paddingTop: 10,
    },
    iconInputFile: {
        marginLeft: "-2rem",
    },
    input: {
        marginBottom: 0,
    },
    link: {
        color: "#039be5",
        cursor: "pointer",
    },
});

export default InputFile;
