import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Col, Loading, Row } from "styleguide";
import Helmet from "react-helmet";

import { clearArticleState, fetchArticles, togglePublishArticle } from "../actions/article";
import { fetchMetric } from "../actions/metric";
import ArticleListForEditors from "../components/ArticleListForEditors";
import config from "../config";
import { IState } from "../reducers";
import { goToArticleEdit, goToArticleView, goToAudio, requestTodaysArticles } from "../services/article";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import INewspaperMetric from "../types/newspaper-metric";
import IUser from "../types/user";
import { countAllWordsInArticleScript } from "../utils/count-words";

interface IPropsFromState {
    error?: errors.HttpError;
    isLoading: boolean;
    user?: IUser;
    metric?: INewspaperMetric[];
    articles: IArticle[];
}

interface IPropsFromDispatch {
    fetchMetric: typeof fetchMetric;
    fetchArticles: typeof fetchArticles;
    togglePublishArticle: typeof togglePublishArticle;
    clearArticleState: typeof clearArticleState;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public togglePublishArticle = (article: IArticle) => {
        this.props.togglePublishArticle(article);
    };

    public renderLastArticles = () => {
        const { history } = this.props;
        const articles = this.props.articles.map((a) => {
            a.articleWordCount = countAllWordsInArticleScript(a);
            return a;
        });

        return (
            <>
                <ArticleListForEditors
                    title="Today's Articles"
                    articles={articles}
                    goToArticleEdit={goToArticleEdit}
                    goToArticleView={goToArticleView}
                    goToAudio={goToAudio}
                    togglePublishArticle={this.togglePublishArticle}
                />
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.article}+`)} />
                <Row>
                    <Col s={12} />
                </Row>
            </>
        );
    };

    public componentWillUnmount() {
        this.props.clearArticleState();
    }

    public componentDidMount() {
        this.props.fetchArticles(requestTodaysArticles(), true);
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderLastArticles()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        articles: state.article.articles,
        error: state.metric.error,
        isLoading: state.metric.isLoading || state.article.isLoading,
        metric: state.metric.metric,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearArticleState,
            fetchArticles,
            fetchMetric,
            togglePublishArticle,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
