enum UserRoles {
  REGULAR_USER = 1,
  SUPER_USER = 2,
  NEWSPAPER_ADMIN = 3,
  JOURNALIST = 4,
  NARRATOR = 5,
  ADVERTISING = 7,
  EDITOR = 9,
  CORPORATE_ADMIN = 10,
  INVALID = 99
}

export default UserRoles;
