import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { IState } from "../../../../reducers";
import { HttpError } from "../../../../types/errors";
import { fetchSettings } from "../../state/actions";
import ISetting from "../../types/ISetting";
import SettingsList from "../components/SettingsList";

interface IPropsFromState {
    settings: ISetting[];
    error?: HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchSettings: typeof fetchSettings;
}

class SettingsListContainer extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {

    public render() {

        return (
            // @ts-ignore
            <SettingsList {...this.props} />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {

    return {
        settings: state.settings.settings,
        error: state.settings.error,
        isLoading: state.settings.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchSettings,
        },
        dispatch
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SettingsListContainer)
);
