import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { NotFound } from "../components/NotFound";
import config from "../config";
import { IState } from "../reducers";
import IUser from "../types/user";

interface IPropsFromState {
    user?: IUser;
}

class MyComponent extends React.Component<IPropsFromState & RouteComponentProps<{}>> {
    public componentDidMount() {
        const { history, user } = this.props;

        if (!user) {
            history.push(config.paths.login);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <NotFound />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        user: state.auth.user,
    };
}

export default withRouter(connect(mapStateToProps)(MyComponent));
