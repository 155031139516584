import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import config from "./config";
import Routes from "./routes";
import configureStore from "./store";
import * as serviceWorker from "./serviceWorker";

import "styleguide/src/scripts.js";
import "styleguide/src/styles.css";
import "./index.css";

const { store, persistor } = configureStore();

// tslint:disable-next-line:no-string-literal
window["store"] = store;

// tslint:disable-next-line:no-string-literal
// window["setNarratorToken"] = setNarratorToken;

// @ts-ignore
window.Raven.config(config.ravenUrl, {
    debug: process.env.REACT_APP_ENV === "development",
    environment: process.env.REACT_APP_ENV,
}).install();

ReactDOM.render(
    // @ts-ignore
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Routes />
        </PersistGate>
    </Provider>,
    document.getElementById("root") as HTMLElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
