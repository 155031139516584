import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Loading } from "styleguide";

import {
    // clearArticleState,
    fetchArticles,
    togglePublishArticle,
} from "../actions/article";
import { fetchMetric } from "../actions/metric";
import ArticleListForNarrators from "../components/ArticleListForNarrators";
import { IState } from "../reducers";
import { goToArticleEdit, goToArticleView, goToAudio, requestPendingArticles } from "../services/article";
import IArticle from "../types/article";
import * as errors from "../types/errors";
import INarratorMetric from "../types/narrator-metric";
import IUser from "../types/user";
import { countAllWordsInArticleScript } from "../utils/count-words";
import debounce from "../utils/debounce";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    articles: IArticle[];
    error?: errors.HttpError;
    isLoading: boolean;
    user?: IUser;
    offset: number;
    metric: INarratorMetric[];
}

interface IPropsFromDispatch {
    fetchArticles: typeof fetchArticles;
    togglePublishArticle: typeof togglePublishArticle;
    fetchMetric: typeof fetchMetric;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public togglePublishArticle = (article: IArticle) => {
        this.props.togglePublishArticle(article);
    };

    public renderListArticles = () => {
        const articles = this.props.articles.map((a) => {
            a.articleWordCount = countAllWordsInArticleScript(a);
            return a;
        });

        return (
            <ArticleListForNarrators
                title="Pending Articles"
                articles={articles}
                goToArticleEdit={goToArticleEdit}
                goToArticleView={goToArticleView}
                goToAudio={goToAudio}
                togglePublishArticle={this.togglePublishArticle}
            />
        );
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchArticles(requestPendingArticles(), false);
                });
            }
        };

        this.props.fetchArticles(requestPendingArticles(), true);
        this.props.fetchMetric();
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderListArticles()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        articles: state.article.articles,
        error: state.article.error,
        isLoading: state.article.isLoading || state.metric.isLoading,
        metric: state.metric.metric,
        offset: state.article.offset,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchArticles,
            fetchMetric,
            togglePublishArticle,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
