import * as errors from "../types/errors";
import IUser from "../types/user";

import {
  CLEAR_AUTH,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT
} from "../constants/auth";

export interface IState {
  user?: IUser;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  user?: IUser;
  error?: errors.HttpError;
}

export const initialState: IState = {
  error: undefined,
  isLoading: false,
  user: undefined
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case LOGIN_USER_SUCCESS:
      return { ...state, isLoading: false, user: action.user };
    case LOGIN_USER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case LOGOUT:
      return { ...state, user: undefined };
    case CLEAR_AUTH:
      return { ...initialState };
    default:
      return state;
  }
};
