import { History } from "history";
import { Dispatch } from "redux";

import { toast } from "styleguide";

import IAdvertListens from "../types/advertlistens";
import { CLEAR_ADVERT_STATE, CREATE_ADVERT_FAILURE, CREATE_ADVERT_REQUEST, CREATE_ADVERT_SUCCESS, FETCH_ADVERTS_FAILURE, FETCH_ADVERTS_REQUEST, FETCH_ADVERTS_SUCCESS, FETCH_ADVERT_FAILURE, FETCH_ADVERT_LISTENS_FAILURE, FETCH_ADVERT_LISTENS_REQUEST, FETCH_ADVERT_LISTENS_SUCCESS, FETCH_ADVERT_REQUEST, FETCH_ADVERT_SUCCESS, UPDATE_ADVERT_FAILURE, UPDATE_ADVERT_REQUEST, UPDATE_ADVERT_SUCCESS } from "./constants";
import { buildUrl, IParams } from "../../../utils/build-url";
import responseCheck from "../../../utils/response-check";
import { uploadFiles, populateWithFiles, clearFiles } from "../../../actions/file";
import { requestAdvert } from "../../../services/advert";
import IAdvert from "../types/advert";
import IFile from "../../../types/file";
import HttpMethods from "../../../types/http-methods";
import config from "../../../config";
import { IState } from "../../../reducers";
import fetch from "../../../utils/fetch";

/*
    ASYNC ACTIONS
*/

export const fetchAdverts = (params?: IParams, reset?: boolean) => (
    dispatch: Dispatch,
    getState: () => IState
) => {
    dispatch({ type: FETCH_ADVERTS_REQUEST });

    const state = getState();

    const url = buildUrl(`${config.api.url}${config.api.paths.advert}`, {
        ...params,
        offset: reset ? 0 : state.advert.offset
    });

    return fetch(url)
        .then(responseCheck)
        .then(adverts =>
            fetchAdvertsSuccess(
                dispatch,
                adverts,
                state.advert.adverts,
                reset ? reset : false
            )
        )
        .catch(error => dispatch(fetchAdvertsFailure(error)));
};

export const fetchAdvert = (id: number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ADVERT_REQUEST });

    const url = buildUrl(
        `${config.api.url}${config.api.paths.advert}/${id}`,
        requestAdvert()
    );

    return fetch(url)
        .then(responseCheck)
        .then(result => dispatch(fetchAdvertSuccess(result)))
        .catch(error => dispatch(fetchAdvertFailure(error)));
};

export const fetchAdvertListens = () => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ADVERT_LISTENS_REQUEST });

    const url = buildUrl(
        `${config.api.url}${config.api.paths.advert}/listens`,
    );

    return fetch(url)
        .then(responseCheck)
        .then(result => dispatch(fetchAdvertListensSuccess(result)))
        .catch(error => dispatch(fetchAdvertListensFailure(error)));
};

export const createAdvert = (advert: IAdvert, history?: History) => (
    dispatch: Dispatch,
    getState: () => IState
): Promise<any> => {
    dispatch({ type: CREATE_ADVERT_REQUEST });

    return uploadFiles("advert")(dispatch, getState)
        .then(() => {
            populateWithFiles(getState, (files: IFile[]) => {
                files.forEach(f => {
                    // @ts-ignore
                    advert[f.id] = f.key;
                    if (f.id === "advertAudioFileName") {
                        advert.advertAudioLength = `${f.duration}` || "0";
                    }
                });
            });

            return fetch(`${config.api.url}${config.api.paths.advert}`, {
                body: JSON.stringify({ model: advert }),
                method: HttpMethods.POST
            });
        })
        .then(responseCheck)
        .then(jour => dispatch(createAdvertSuccess(jour)))
        .then(() => dispatch(clearFiles()))
        .then(() => history && history.push(config.paths.advert))
        .catch(error => dispatch(createAdvertFailure(error)));
};

export const editAdvert = (advert: IAdvert, history: History) => (
    dispatch: Dispatch,
    getState: () => IState
): Promise<any> => {
    dispatch({ type: UPDATE_ADVERT_REQUEST });

    return uploadFiles("advert")(dispatch, getState)
        .then(() => {
            populateWithFiles(getState, (files: IFile[]) => {
                files.forEach(f => {
                    // @ts-ignore
                    advert[f.id] = f.key;
                    if (f.id === "advertAudioFileName") {
                        advert.advertAudioLength = `${f.duration}` || "0";
                    }
                });
            });

            return fetch(
                `${config.api.url}${config.api.paths.advert}/${advert.advertID}`,
                {
                    body: JSON.stringify({ model: advert }),
                    method: HttpMethods.PUT
                }
            );
        })
        .then(responseCheck)
        .then(() => dispatch(editAdvertSuccess()))
        .then(() => dispatch(clearFiles()))
        .then(() => history.push(config.paths.advert))
        .catch(error => dispatch(editAdvertFailure(error)));
};

/*
    SYNC ACTIONS
*/

const fetchAdvertsSuccess = (
    dispatch: Dispatch,
    adverts: IAdvert[],
    oldAdverts: IAdvert[],
    reset: boolean
) => {
    const newAdverts = !reset ? [...oldAdverts, ...adverts] : adverts;

    return dispatch({
        adverts: newAdverts,
        offset: newAdverts.length,
        type: FETCH_ADVERTS_SUCCESS
    });
};

const fetchAdvertListensSuccess = (advertListens: IAdvertListens[]) => {

    return {
        advertListens,
        type: FETCH_ADVERT_LISTENS_SUCCESS
    };
};

export const clearAdvertState = () => {
    return { type: CLEAR_ADVERT_STATE };
};

const fetchAdvertsFailure = (error: Error) => {
    toast("Error fetching adverts");
    return { type: FETCH_ADVERTS_FAILURE, error };
};

const fetchAdvertListensFailure = (error: Error) => {
    toast("Error fetching advert listen figures");
    return { type: FETCH_ADVERT_LISTENS_FAILURE, error };
};

const createAdvertSuccess = (advert: IAdvert) => {
    toast("Created advert");
    return { type: CREATE_ADVERT_SUCCESS, advert };
};

const createAdvertFailure = (error: Error) => {
    toast("Error creating advert");
    return { type: CREATE_ADVERT_FAILURE, error };
};

const fetchAdvertSuccess = (advert: IAdvert) => {
    return { type: FETCH_ADVERT_SUCCESS, advert };
};

const fetchAdvertFailure = (error: Error) => {
    toast("Error fetching advert");
    return { type: FETCH_ADVERT_FAILURE, error };
};

const editAdvertSuccess = () => {
    toast("Updated advert");
    return { type: UPDATE_ADVERT_SUCCESS };
};

const editAdvertFailure = (error: Error) => {
    toast("Error updating advert");
    return { type: UPDATE_ADVERT_FAILURE, error };
};
