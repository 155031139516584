export const CREATE_JOURNALIST_FAILURE = "CREATE_JOURNALIST_FAILURE";
export const CREATE_JOURNALIST_REQUEST = "CREATE_JOURNALIST_REQUEST";
export const CREATE_JOURNALIST_SUCCESS = "CREATE_JOURNALIST_SUCCESS";

export const FETCH_JOURNALIST_FAILURE = "FETCH_JOURNALIST_FAILURE";
export const FETCH_JOURNALIST_REQUEST = "FETCH_JOURNALIST_REQUEST";
export const FETCH_JOURNALIST_SUCCESS = "FETCH_JOURNALIST_SUCCESS";

export const FETCH_JOURNALISTS_FAILURE = "FETCH_JOURNALISTS_FAILURE";
export const FETCH_JOURNALISTS_REQUEST = "FETCH_JOURNALISTS_REQUEST";
export const FETCH_JOURNALISTS_SUCCESS = "FETCH_JOURNALISTS_SUCCESS";

export const UPDATE_JOURNALIST_FAILURE = "UPDATE_JOURNALIST_FAILURE";
export const UPDATE_JOURNALIST_REQUEST = "UPDATE_JOURNALIST_REQUEST";
export const UPDATE_JOURNALIST_SUCCESS = "UPDATE_JOURNALIST_SUCCESS";

export const CLEAR_JOURNALIST_STATE = "CLEAR_JOURNALIST_STATE";
