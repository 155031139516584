export const downloadArticleAudioUrl = (filename?: string, audioUrl?: string) => {
  if (!audioUrl || !filename) {
    return;
  }

  return fetch(`${audioUrl}`)
    .then(response => response.blob())
    .then(blob => {
      const u = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = u;
      a.download = `${filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};
