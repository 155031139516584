import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Loading } from "styleguide";

import { fetchArticle } from "../actions/article";
import { fetchCategories } from "../actions/category";
import { fetchNewspaper, fetchNewspapers } from "../actions/newspaper";
import { fetchPlaylists } from "../actions/playlist";
import { fetchPronunciations, editPronunciation, deletePronunciation, verifyPronunciation } from "../actions/pronunciation";
import { fetchTemplates } from "../actions/template";
import { fetchUsers } from "../actions/user";
import ArticleScript from "../components/ArticleScript";
import { IState } from "../reducers";
import { createDefaultArticle } from "../services/article";
import { requestPublishedCategories } from "../services/category";
import { requestPublishedPlaylists } from "../services/playlist";
import { requestNarrators } from "../services/user";
import IArticle from "../types/article";
import ICategory from "../types/category";
import * as errors from "../types/errors";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPlaylist from "../types/playlist";
import IPronunciation from "../types/pronunciation";
import ITemplate from "../types/template";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { correctValue } from "../utils/values";
import ISearch from "../types/search";
import { Helmet } from "react-helmet";

const ALL_PRONUNCIATIONS_LIMIT = 100000000;

interface IPropsFromState {
    article?: IArticle;
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    categories: ICategory[];
    playlists: IPlaylist[];
    journalists: IJournalist[];
    templates: ITemplate[];
    narrators: IUser[];
    pronunciations: IPronunciation[];
    hasChanged: boolean;
    search: ISearch;
}

interface IPropsFromDispatch {
    fetchArticle: typeof fetchArticle;
    fetchNewspapers: typeof fetchNewspapers;
    fetchNewspaper: typeof fetchNewspaper;
    fetchCategories: typeof fetchCategories;
    fetchPlaylists: typeof fetchPlaylists;
    fetchUsers: typeof fetchUsers;
    fetchPronunciations: typeof fetchPronunciations;
    editPronunciation: typeof editPronunciation;
    verifyPronunciation: typeof verifyPronunciation;
    deletePronunciation: typeof deletePronunciation;
    fetchTemplates: typeof fetchTemplates;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderArticleScript = () => {
        const { article, journalists, narrators, newspapers, pronunciations, templates, history } = this.props;

        return (
            <Card>
                <ArticleScript
                    print={true}
                    article={article}
                    newspapers={newspapers}
                    journalists={journalists}
                    narrators={narrators}
                    pronunciations={pronunciations}
                    templates={templates}
                    dynamic={false}
                    user={this.props.user}
                    editPronunciation={this.props.editPronunciation}
                    verifyPronunciation={this.props.verifyPronunciation}
                    deletePronunciation={this.props.deletePronunciation}
                    fetchPronunciations={this.props.fetchPronunciations}
                    search={this.props.search}
                    history={history}
                />
            </Card>
        );
    };

    public submitPronunciationForm = (pronunciation: IPronunciation) => {
        // @ts-ignore
        return this.props.createPronunciation(pronunciation).then(() => {
            this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        });
    };

    public componentDidMount() {
        const { match, user } = this.props;

        this.props.fetchPronunciations({ limit: ALL_PRONUNCIATIONS_LIMIT });
        this.props.fetchTemplates();

        if (match.params.id) {
            this.props.fetchArticle(+match.params.id);
        }

        if (user.userRoleIdList === UserRoles.EDITOR || user.userRoleIdList === UserRoles.SUPER_USER) {
            this.props.fetchNewspapers();
            this.props.fetchCategories(requestPublishedCategories());
            this.props.fetchPlaylists(requestPublishedPlaylists());
            this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
        }
    }

    public componentDidUpdate(prevProps: IPropsFromState & IPropsFromDispatch) {
        const { user } = this.props;

        if (user.userRoleIdList !== UserRoles.EDITOR && user.userRoleIdList !== UserRoles.SUPER_USER) {
            return;
        }

        const newArticle = this.props.article ? this.props.article : { newspaperId: 0 };
        const prevArticle = prevProps.article ? prevProps.article : { newspaperId: 0 };

        if (newArticle.newspaperId !== prevArticle.newspaperId && !!newArticle.newspaperId && newArticle.newspaperId !== 0) {
            this.props.fetchNewspaper(newArticle.newspaperId);
        }
    }
    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Article View</title>
                </Helmet>
                {isLoading && <Loading />}
                <div className="col s12">{this.renderArticleScript()}</div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { ArticleForm },
        article: { article },
        newspaper: { newspaper },
    } = state;

    const articleValues = ArticleForm ? ArticleForm.values : {};

    const journalists = newspaper && newspaper.journalists ? newspaper.journalists : [];

    const preArticle = correctValue("articleID", articleValues, article);
    const validatedArticle = preArticle ? preArticle : createDefaultArticle();
    const hasChanged = ArticleForm && !ArticleForm.submitSucceeded ? !!ArticleForm.anyTouched : false;

    return {
        article: validatedArticle,
        categories: state.category.categories,
        error: state.article.error,
        hasChanged,
        isLoading:
            state.article.isLoading ||
            state.file.isLoading ||
            state.newspaper.isLoading ||
            state.user.isLoading ||
            state.category.isLoading ||
            state.journalist.isLoading ||
            state.pronunciation.isLoading,
        journalists,
        narrators: state.user.narrators,
        newspapers: state.newspaper.newspapers,
        playlists: state.playlist.playlists,
        pronunciations: state.pronunciation.pronunciations,
        templates: state.template.templates,
        user: state.auth.user as IUser,
        search: state.search,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchArticle,
            fetchCategories,
            fetchNewspaper,
            fetchNewspapers,
            fetchPlaylists,
            fetchPronunciations,
            editPronunciation,
            verifyPronunciation,
            deletePronunciation,
            fetchTemplates,
            fetchUsers,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
