import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Badge, Clickable, CollapsibleItem, Icon } from "styleguide";
import config from "../config";
import IPlaylist from "../types/playlist";
import { daysAgo } from "../utils/date";

type IPlaylistWithMetadata = IPlaylist & { numArticles: number, lastArticlePublished: string }

interface IProps {
  playlist?: IPlaylistWithMetadata;
  children: any;
  onClick: () => void;
}

export class PlaylistItem extends React.Component<IProps> {
  public goToPlaylist = () => {
    const { playlist } = this.props;

    if (!playlist) {
      return;
    }

    window.open(`${config.paths.playlist}/${playlist.playlistId}`, "_blank")
  }

  public render() {
    const { playlist } = this.props;

    if (!playlist) {
      return null;
    }

    const days = playlist.lastArticlePublished ? daysAgo(new Date(playlist.lastArticlePublished)) : '-'

    return (
      <CollapsibleItem
        onOpen={this.props.onClick}
        text={playlist.playlistName}
        key={playlist.playlistId}
        renderRight={
          <>
            <Badge icon="access_time" message={days} tooltip={`last article published ${days} days ago`} />
            <Badge icon="view_list" message={playlist.numArticles} tooltip="number of articles" />
            <div className={css(styles.editIcon)}>
              {playlist.playlistIsPublished === 1 ? <Icon icon="cloud_done" className="green-text" /> : <Icon icon="cloud_queue" className="blue-text" />}
              <Clickable onClick={this.goToPlaylist}>
                <Icon icon="edit" />
              </Clickable>
            </div>
          </>
        }
      >
        {this.props.children}
      </CollapsibleItem>
    )
  }
}

const styles = StyleSheet.create({
  editIcon: {
    paddingLeft: 30
  },
  metadata: {
    background: 'linear-gradient(45deg, #90caf9 0%, #90caf9 100%)',
    color: 'white'
  },
});

export default PlaylistItem;
