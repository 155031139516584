import * as _ from "lodash";

import IJournalist from "../types/journalist";
import ISearch from "../types/search";
import { IFilter, IParams } from "../utils/build-url";
import { filterDate } from "../utils/date";

const LIMIT = 20;

export const requestJournalistsSearched = (search: ISearch): IParams => {
  let filters: IFilter[] = [];
  const dateFilter = filterDate("createdAt", search);

  if (search.text) {
    filters = [...filters, { name: "journalistName~", value: search.text }];
  }

  if (dateFilter) {
    filters = [...filters, dateFilter];
  }

  return {
    filter: filters,
    include: ["newspapers"],
    limit: LIMIT,
    orderBy: "DESC",
    sortBy: "journalistId"
  };
};

export const requestAllJournalists = (): IParams => {
  return { sortBy: "journalistName", limit: 10000 };
};

export const createDefaultJournalist = (): IJournalist => {
  return {
    journalistId: 0,
    journalistName: ""
  };
};

export const requestJournalist = (): IParams => {
  return {
    include: ["newspapers"]
  };
};

export const createDefaultJournalistNestedProps = (
  journalist?: IJournalist
) => {
  if (!journalist || !journalist.newspapers) {
    return null;
  }

  const newspapersId = journalist.newspapersId
    ? journalist.newspapersId
    : journalist.newspapers.map(n => `${n.newspaperID}`);

  return { newspapersId };
};
