import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { clearCompanyState, createCompany, editCompany, fetchCompany } from "../actions/company";
import CompanyForm from "../components/CompanyForm";
import { IState } from "../reducers";
import { createDefaultCompany } from "../services/company";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import { updateFile } from "../actions/file";

interface IPropsFromState {
    error?: errors.HttpError;
    company: ICompany;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    clearCompanyState: typeof clearCompanyState;
    createCompany: typeof createCompany;
    editCompany: typeof editCompany;
    fetchCompany: typeof fetchCompany;
    updateFile: typeof updateFile;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { company, updateFile } = this.props;

        const params = {
            company,
            updateFile,
            initialValues: { ...company, error: this.props.error },
            onSubmit: this.submitForm,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Company">
                    <CompanyForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (company: ICompany | any) => {
        const { company: oldCompany, history } = this.props;

        if (oldCompany && oldCompany.companyId) {
            return this.props.editCompany(company, history);
        }
        return this.props.createCompany(company, history);
    };

    public componentWillUnmount() {
        this.props.clearCompanyState();
    }

    public componentDidMount() {
        const { match } = this.props;

        if (match.params.id) {
            this.props.fetchCompany(+match.params.id);
        }
    }

    public render() {
        const title = `Company Editor | ${this.props.company && this.props.company.companyName ? this.props.company.companyName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { CompanyForm: companyForm },
        company: { company },
    } = state;

    const companyValues = companyForm ? companyForm.values : {};

    const preCompany = correctValue("companyId", companyValues, company);
    const validatedCompany = preCompany ? preCompany : createDefaultCompany();

    return {
        company: validatedCompany,
        error: state.company.error,
        isLoading: state.company.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearCompanyState,
            createCompany,
            editCompany,
            fetchCompany,
            updateFile,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
