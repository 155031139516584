import * as React from "react";

import { css, StyleSheet } from "aphrodite";

// @ts-ignore
import { convertFromHTML } from "draft-convert";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import * as _ from "lodash";

import "../styles/RichInputTextV2.css";

interface IProp {
    className?: string;
    onChange: (text: string) => void;
    initialValue?: string;
}

interface IState {
    input: EditorState;
}

export class RichInputTextV2 extends React.Component<IProp, IState> {
    private debounceOnChange: (text: string) => void;

    constructor(props: IProp) {
        super(props);

        this.state = { input: EditorState.createEmpty() };

        this.debounceOnChange = _.debounce(this.props.onChange, 1500);
    }

    public onChange = (input: EditorState) => {
        const currentContentState = this.state.input.getCurrentContent();
        const newContentState = input.getCurrentContent();

        if (currentContentState !== newContentState) {
            this.debounceOnChange(stateToHTML(input.getCurrentContent()));
        }

        this.setState({ input });
    };

    public handleKeyCommand = (command: string, input: EditorState) => {
        const newState = RichUtils.handleKeyCommand(input, command);
        if (newState) {
            this.onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    public onMouseDownStyle = (action: string) => (e: any) => {
        e.preventDefault();

        const input = RichUtils.toggleInlineStyle(this.state.input, action);

        this.setState({ input });

        this.debounceOnChange(stateToHTML(input.getCurrentContent()));
    };

    public onClickStyle = (e: any) => {
        e.preventDefault();
    };

    public componentDidMount = () => {
        const text = _.chain(this.props.initialValue)
            .replace(/\n<p><br><\/p>/g, "<p></p>")
            .replace(/\n\n/g, "<p></p>")
            .value();

        const blocksFromHTML = convertFromHTML(text);
        const input = EditorState.createWithContent(blocksFromHTML);

        this.setState({ input });
        this.debounceOnChange(text);
    };

    public handlePastedText = (text: string) => {
        const newContent = Modifier.replaceText(this.state.input.getCurrentContent(), this.state.input.getSelection(), text);

        this.onChange(EditorState.push(this.state.input, newContent, "insert-characters"));

        return true;
    };

    public render() {
        const { className } = this.props;
        let extraProps = {};

        if (className) {
            extraProps = { ...extraProps, blockStyleFn: () => className };
        }

        return (
            <>
                <div>
                    <button onMouseDown={this.onMouseDownStyle("UNDERLINE")} onClick={this.onClickStyle} className={css(styles.button)} name="UNDERLINE">
                        U
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("BOLD")} onClick={this.onClickStyle} className={css(styles.button)} name="BOLD">
                        <b>B</b>
                    </button>
                    <button onMouseDown={this.onMouseDownStyle("ITALIC")} onClick={this.onClickStyle} className={css(styles.button)} name="ITALIC">
                        /
                    </button>
                </div>
                <Editor
                    editorState={this.state.input}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                    // @ts-ignore
                    handlePastedText={this.handlePastedText}
                    {...extraProps}
                />
            </>
        );
    }
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: "#039be5",
        border: 0,
        borderRadius: 40,
        color: "white",
        cursor: "pointer",
        margin: "15px 5px 15px 0px",
        padding: "10px 0px",
        width: 37,
    },
});

export default RichInputTextV2;
