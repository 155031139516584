import { AdBlockDetectedWrapper } from "adblock-detect-react";
import { css, StyleSheet } from "aphrodite";
import React from "react";
import { Card } from "styleguide/build";

class AdblockWarning extends React.Component {

    public render() {
        return (
            <AdBlockDetectedWrapper>
                <Card title="⚠ Adblock Plugin Detected" className={css(styles.background)}>
                    <p>Please disable adblock for this site to ensure advert management functions correctly.</p>
                </Card>
            </AdBlockDetectedWrapper>
        )
    }
}

const styles = StyleSheet.create({
    background: {
        background: 'red',
        color: 'white'
    },
});

export default AdblockWarning;