import { HttpError } from "../../../types/errors";
import ISetting from "../types/ISetting";
import { FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE, EDIT_SETTING_FAILURE, EDIT_SETTING_REQUEST, EDIT_SETTING_SUCCESS, FETCH_SETTING_FAILURE, FETCH_SETTING_REQUEST, FETCH_SETTING_SUCCESS } from "./constants";

export interface IState {
    isLoading: boolean;
    error?: HttpError;
    settings: ISetting[];
    setting?: ISetting;
}

export interface IAction {
    type: string;
    error?: HttpError;
    settings: ISetting[];
    setting?: ISetting;
}

export const initialState: IState = {
    settings: [],
    setting: undefined,
    isLoading: false,
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {
    switch (action.type) {
        case FETCH_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings || [],
                isLoading: false,
            };
        case FETCH_SETTINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case FETCH_SETTING_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
                setting: undefined,
            };
        case FETCH_SETTING_SUCCESS:
            return {
                ...state,
                setting: action.setting,
                isLoading: false,
            };
        case FETCH_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case EDIT_SETTING_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case EDIT_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case EDIT_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};
