import IAdvert from "../domaindriven/adverts/types/advert";
import ISearch from "../types/search";
import { IFilter, IParams } from "../utils/build-url";
import { addDays, filterDate } from "../utils/date";

const LIMIT = 20;

export const requestAdvertSearched = (search: ISearch): IParams => {
    let filters: IFilter[] = [];
    const dateFilter = filterDate("createdAt", search);

    if (search.text) {
        filters = [...filters, { name: "advertFriendlyName~", value: search.text }];
    }

    if (dateFilter) {
        filters = [...filters, dateFilter];
    }

    return {
        filter: filters,
        include: ["countries", "companies"],
        limit: LIMIT,
        orderBy: "DESC",
        sortBy: "advertExpiresDateTime"
    };
};

export const createDefaultAdvert = (): IAdvert => {
    return {
        priority: 2,
        advertLiveFromDateTime: new Date(),
        advertExpiresDateTime: addDays(new Date(), 30),
    };
};

export const requestAdvert = (): IParams => {
    return {
        include: ["countries", "companies"]
    };
};

export const createDefaultAdvertNestedProps = (advert?: IAdvert) => {
    if (!advert) {
        return null;
    }

    const countryIds = advert.countryIds ? advert.countryIds : advert.countries ? advert.countries.map((c) => `${c.id}`) : [];

    return {
        countryIds
    };
};
