import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_COMPANY_STATE,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS
} from "../constants/company";
import ICompany from "../types/company";
import HttpMethods from "../types/http-methods";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { IState } from "../reducers";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";
import IFile from "../types/file";
import FileType from "../types/file-type";

/*
    ASYNC ACTIONS
*/

export const fetchCompanies = (params: IParams) => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: FETCH_COMPANIES_REQUEST });

  const url = buildUrl(
    `${config.api.url}${config.api.paths.company}`,
    params
  );

  return fetch(url)
    .then(responseCheck)
    .then(companies => dispatch(fetchCompaniesSuccess(companies)))
    .catch(error => dispatch(fetchCompaniesFailure(error)));
};

export const fetchCompany = (id: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_COMPANY_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.company}/${id}`)
    .then(responseCheck)
    .then(company => dispatch(fetchCompanySuccess(company)))
    .catch(error => dispatch(fetchCompanyFailure(error)));
};

export const createCompany = (company: ICompany, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: CREATE_COMPANY_REQUEST });

  return uploadFiles("company")(dispatch, getState)
    .then(() => {

      populateWithFiles(getState, (files: IFile[]) => {
        if (files[0].type === FileType.image) {
          company.logoFileName = files[0].key;
        }
      });

      return fetch(`${config.api.url}${config.api.paths.company}`, {
        body: JSON.stringify({ model: company }),
        method: HttpMethods.POST
      });

    })
    .then(responseCheck)
    .then(news => dispatch(createCompanySuccess(news)))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.company))
    .catch(error => dispatch(createCompanyFailure(error)));
};

export const editCompany = (company: ICompany, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {

  dispatch({ type: UPDATE_COMPANY_REQUEST });

  return uploadFiles("company")(dispatch, getState)
    .then(() => {

      populateWithFiles(getState, (files: IFile[]) => {
        if (files[0].type === FileType.image) {
          company.logoFileName = files[0].key;
        }
      });

      return fetch(
        `${config.api.url}${config.api.paths.company}/${company.companyId}`,
        {
          body: JSON.stringify({ model: company }),
          method: HttpMethods.PUT
        }
      );
    })
    .then(responseCheck)
    .then(() => dispatch(updateCompanySuccess()))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.company))
    .catch(error => dispatch(updateCompanyFailure(error)));
};

/*
SYNC ACTIONS
*/

export const clearCompanyState = () => {
  return { type: CLEAR_COMPANY_STATE };
};

const fetchCompaniesSuccess = (companies: any) => {
  return { type: FETCH_COMPANIES_SUCCESS, companies };
};

const fetchCompaniesFailure = (error: Error) => {
  toast("Error fetching companies");
  return { type: FETCH_COMPANIES_FAILURE, error };
};

const fetchCompanySuccess = (company: any) => {
  return { type: FETCH_COMPANY_SUCCESS, company };
};

const fetchCompanyFailure = (error: Error) => {
  toast("Error fetching company");
  return { type: FETCH_COMPANY_FAILURE, error };
};

const createCompanySuccess = (company: ICompany) => {
  toast("Company created");
  return { type: CREATE_COMPANY_SUCCESS, company };
};

const createCompanyFailure = (error: Error) => {
  toast("Error creating company");
  return { type: CREATE_COMPANY_FAILURE, error };
};

const updateCompanySuccess = () => {
  toast("Company updated");
  return { type: UPDATE_COMPANY_SUCCESS };
};

const updateCompanyFailure = (error: Error) => {
  toast("Error updating company");
  return { type: UPDATE_COMPANY_FAILURE, error };
};
