import * as React from "react";

import * as _ from "lodash";

interface IProps {
    type: "text" | "textarea";
    name: string;
    placeholder?: string;
    required?: boolean;
    change: (field: string, value: any) => void;
    initialValue?: string;
    className?: string;
}

interface IState {
    text: string;
}

export class InputText extends React.Component<IProps, IState> {
    private debounceOnChange: (field: string, value: any) => void;

    constructor(props: IProps) {
        super(props);

        this.state = { text: props.initialValue ? props.initialValue : "" };
        this.debounceOnChange = _.debounce(this.props.change, 400);
    }

    public onChange = (event: React.ChangeEvent<any>) => {
        const value = event.target.value;

        this.setState({ text: value });
        this.debounceOnChange(this.props.name, value);
    };

    public componentDidUpdate = (prevProps: IProps) => {
        const { initialValue } = this.props;

        if (initialValue && (!prevProps.initialValue || initialValue !== prevProps.initialValue)) {
            this.debounceOnChange(this.props.name, initialValue);
            this.setState({ text: initialValue });
        }
    };

    public render() {
        const { name, required, placeholder, type, className } = this.props;
        const { text } = this.state;

        if (type === "textarea") {
            return <textarea placeholder={placeholder} value={text} name={name} required={required} onChange={this.onChange} className={className} />;
        }

        return <input type="text" name={name} required={required} placeholder={placeholder} onChange={this.onChange} value={text} defaultValue={text} />;
    }
}

export default InputText;
