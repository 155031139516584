import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_NEWSPAPER_STATE,
  CREATE_NEWSPAPER_FAILURE,
  CREATE_NEWSPAPER_REQUEST,
  CREATE_NEWSPAPER_SUCCESS,
  FETCH_NEWSPAPER_FAILURE,
  FETCH_NEWSPAPER_REQUEST,
  FETCH_NEWSPAPER_SUCCESS,
  FETCH_NEWSPAPERS_FAILURE,
  FETCH_NEWSPAPERS_REQUEST,
  FETCH_NEWSPAPERS_SUCCESS,
  UPDATE_NEWSPAPER_FAILURE,
  UPDATE_NEWSPAPER_REQUEST,
  UPDATE_NEWSPAPER_SUCCESS
} from "../constants/newspaper";
import { IState } from "../reducers";
import { requestAllNewspapers } from "../services/newspaper";
import IFile from "../types/file";
import HttpMethods from "../types/http-methods";
import INewspaper from "../types/newspaper";
import { buildUrl } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";

/*
    ASYNC ACTIONS
*/

export const fetchNewspapers = () => (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: FETCH_NEWSPAPERS_REQUEST });

  const url = buildUrl(
    `${config.api.url}${config.api.paths.newspaper}`,
    requestAllNewspapers()
  );

  return fetch(url)
    .then(responseCheck)
    .then(newspapers => dispatch(fetchNewspapersSuccess(newspapers)))
    .catch(error => dispatch(fetchNewspapersFailure(error)));
};

export const fetchNewspaper = (id: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_NEWSPAPER_REQUEST });

  const url = buildUrl(`${config.api.url}${config.api.paths.newspaper}/${id}`, {
    include: ["journalists"]
  });

  return fetch(url)
    .then(responseCheck)
    .then(newspaper => dispatch(fetchNewspaperSuccess(newspaper)))
    .catch(error => dispatch(fetchNewspaperFailure(error)));
};

export const createNewspaper = (newspaper: INewspaper, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: CREATE_NEWSPAPER_REQUEST });

  return uploadFiles("publisher")(dispatch, getState)
    .then(() => {
      populateWithFiles(getState, (files: IFile[]) => {
        files.forEach(f => (newspaper[f.id] = f.key));
      });

      return fetch(`${config.api.url}${config.api.paths.newspaper}`, {
        body: JSON.stringify({ model: newspaper }),
        method: HttpMethods.POST
      });
    })
    .then(responseCheck)
    .then(news => dispatch(createNewspaperSuccess(news)))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.newspaper))
    .catch(error => dispatch(createNewspaperFailure(error)));
};

export const editNewspaper = (newspaper: INewspaper, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: UPDATE_NEWSPAPER_REQUEST });

  return uploadFiles("publisher")(dispatch, getState)
    .then(() => {
      populateWithFiles(getState, (files: IFile[]) => {
        files.forEach(f => (newspaper[f.id] = f.key));
      });

      return fetch(
        `${config.api.url}${config.api.paths.newspaper}/${
          newspaper.newspaperID
        }`,
        {
          body: JSON.stringify({ model: newspaper }),
          method: HttpMethods.PUT
        }
      );
    })
    .then(responseCheck)
    .then(() => dispatch(updateNewspaperSuccess()))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.newspaper))
    .catch(error => dispatch(updateNewspaperFailure(error)));
};

/*
SYNC ACTIONS
*/

export const clearNewspaperState = () => {
  return { type: CLEAR_NEWSPAPER_STATE };
};

const fetchNewspapersSuccess = (newspapers: any) => {
  return { type: FETCH_NEWSPAPERS_SUCCESS, newspapers };
};

const fetchNewspapersFailure = (error: Error) => {
  toast("Error fetching newspapers");
  return { type: FETCH_NEWSPAPERS_FAILURE, error };
};

const fetchNewspaperSuccess = (newspaper: any) => {
  return { type: FETCH_NEWSPAPER_SUCCESS, newspaper };
};

const fetchNewspaperFailure = (error: Error) => {
  toast("Error fetching newspaper");
  return { type: FETCH_NEWSPAPER_FAILURE, error };
};

const createNewspaperSuccess = (newspaper: INewspaper) => {
  toast("Newspaper created");
  return { type: CREATE_NEWSPAPER_SUCCESS, newspaper };
};

const createNewspaperFailure = (error: Error) => {
  toast("Error creating newspaper");
  return { type: CREATE_NEWSPAPER_FAILURE, error };
};

const updateNewspaperSuccess = () => {
  toast("Newspaper updated");
  return { type: UPDATE_NEWSPAPER_SUCCESS };
};

const updateNewspaperFailure = (error: Error) => {
  toast("Error updating newspaper");
  return { type: UPDATE_NEWSPAPER_FAILURE, error };
};
