import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import IPronunciation from "../../types/pronunciation";
import IUser from "../../types/user";
import { Clickable, Icon, ModalConfirm, Modal } from "styleguide/build";
import {
  deletePronunciation,
  fetchPronunciations,
  editPronunciation,
} from "../../actions/pronunciation";
import ISearch from "../../types/search";
import UserRoles from "../../types/user-roles";
import PronunciationForm from "../PronunciationForm";

interface IProps {
  user?: IUser;
  pronunciation: IPronunciation;
  search: ISearch;
  history: any;
  deletePronunciation: typeof deletePronunciation;
  fetchPronunciations: typeof fetchPronunciations;
  editPronunciation: typeof editPronunciation;
}

interface IState {
  editModalOpen: boolean;
}

export class PronunciationEditButtons extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { editModalOpen: false };
  }

  public delete = (pronunciation: IPronunciation) => {
    this.props.deletePronunciation(pronunciation);
  };

  public showEditModal = () => {
    this.setState({ editModalOpen: true });
  };

  public close = () => {
    this.setState({ editModalOpen: false });
  };

  // Added '| any' to type to avoid compiler error.
  public submitForm = (pronunciation: IPronunciation | any) => {
    this.props.editPronunciation(pronunciation, this.props.user);
    this.close();
  };

  public onError = () => {
    // this.props.error
  };

  public render = () => {
    const { pronunciation, user } = this.props;

    if (
      !user ||
      (user.userRoleIdList !== UserRoles.EDITOR &&
        user.userRoleIdList !== UserRoles.SUPER_USER)
    ) {
      return null;
    }

    const params = {
      initialValues: { ...pronunciation, error: this.onError },
      onSubmit: this.submitForm,
      pronunciation,
    };

    return (
      <>
        {this.state.editModalOpen && (
          <Modal
            title="Edit Pronunciation"
            className={css(styles.modal)}
            close={this.close}
          >
            <PronunciationForm {...params} />
          </Modal>
        )}
        <Clickable
          className={css(styles.icon)}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={this.showEditModal}
        >
          <Icon icon="edit" />
        </Clickable>
        <ModalConfirm
          title="Confirmation"
          description="Are you sure that you want delete this pronunciation?"
        >
          {(confirm: any) => (
            <Clickable
              className={css(styles.icon)}
              onClick={confirm(() => this.delete(pronunciation))}
            >
              <Icon icon="remove_circle" className="red-text" />
            </Clickable>
          )}
        </ModalConfirm>
      </>
    );
  };
}

const styles = StyleSheet.create({
  icon: {
    "@media (max-width: 600px)": {
      paddingLeft: 5,
      paddingRight: 5,
    },
    paddingLeft: 10,
    paddingRight: 10,
  },
  modal: {
    overflow: "initial",
  },
});

export default PronunciationEditButtons;
