import * as errors from "../types/errors";

import {
  CLEAR_COMPANY_STATE,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS
} from "../constants/company";
import ICompany from "../types/company";

export interface IState {
  companies: ICompany[];
  company?: ICompany;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  company?: ICompany;
  companies?: ICompany[];
  error?: errors.HttpError;
}

export const initialState: IState = {
  companies: [],
  isLoading: false
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies || [],
        isLoading: false
      };
    case FETCH_COMPANIES_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_COMPANY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
        isLoading: false
      };
    case FETCH_COMPANY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_COMPANY_STATE:
      return { ...initialState };
    case CREATE_COMPANY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
        isLoading: false
      };
    case CREATE_COMPANY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case UPDATE_COMPANY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
        isLoading: false
      };
    case UPDATE_COMPANY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
