enum ArticleType {
    REGULAR = 0,
    FLASH = 1,
    NUTSHELL = 2,
    STORY_INTRO = 3,
    STORY_BRIDGE = 4,
    STORY_OUTRO = 5,
}

export default ArticleType;
