import { GroupBase, StylesConfig } from "react-select";

export const ReactSelectStylesMulti: StylesConfig<
    {
        label: string;
        value: number;
    },
    true,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return { ...base, borderRadius: "0px", borderTop: "none", borderLeft: "none", borderRight: "none", boxShadow: "none", borderColor: " #9e9e9e" };
    },
    placeholder: (base) => {
        return { ...base, color: "black" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};

export const ReactSelectStyles: StylesConfig<
    {
        label: string;
        value: number;
    },
    false,
    GroupBase<{
        label: string;
        value: number;
    }>
> = {
    control: (base) => {
        return { ...base, borderRadius: "0px", borderTop: "none", borderLeft: "none", borderRight: "none", boxShadow: "none", borderColor: " #9e9e9e" };
    },
    placeholder: (base) => {
        return { ...base, color: "black" };
    },
    menu: (base) => {
        return { ...base, zIndex: 100 };
    },
};
