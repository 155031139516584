import { CLEAR_SEARCH, DO_SEARCH } from "../constants/search";

export interface IState {
  text: string;
  toDate: string;
  fromDate: string;
}

export interface IAction {
  type: string;
  text?: string;
  toDate?: string;
  fromDate?: string;
}

export const initialState: IState = {
  fromDate: "",
  text: "",
  toDate: ""
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case DO_SEARCH:
      return {
        ...state,
        fromDate: action.fromDate || "",
        text: action.text || "",
        toDate: action.toDate || ""
      };
    case CLEAR_SEARCH:
      return { ...initialState };
    default:
      return state;
  }
};
