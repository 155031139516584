export const required = (value: any) => (value ? undefined : "Required");

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min: number) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const isNumber = (value: any) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const minValue = (min: number) => (value: any) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const passwordsMatch = (fieldName: string) => (
  value: any,
  allValues: any
) => {
  return value !== allValues[fieldName] ? `Passwords don't match` : undefined;
};
