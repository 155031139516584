import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
  Button,
  Col,
  InputField,
  InputSelect,
  ModalConfirm,
  Row,
} from "styleguide";

import { updateFile } from "../actions/file";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import { renderError, renderInput } from "../utils/input";
import { required } from "../utils/validations";
import InputFile from "./InputFile";

interface IProp {
  journalist?: IJournalist;
  error?: errors.HttpError;
  newspapers: INewspaper[];
  updateFile: typeof updateFile;
}

export class JournalistForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { change, handleSubmit, journalist, newspapers, valid } = this.props;

    if (!journalist) {
      return null;
    }

    const newspapersTransformed = newspapers.map((n) => ({
      text: n.newspaperName,
      value: n.newspaperID,
    }));
    const newspapersId = journalist ? journalist.newspapersId : [];

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this journalist?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12} m={6}>
                    <InputField>
                      <label htmlFor="journalistName">Name *</label>
                      <Field
                        name="journalistName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12} m={6}>
                    <InputField>
                      <InputFile
                        model="journalists"
                        id="journalistImage"
                        upFile={this.props.updateFile}
                        type={FileType.image}
                        change={this.props.change}
                        value={journalist && journalist.journalistImage}
                      />
                      <label className="active">Image</label>
                    </InputField>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="journalistDescription">Description</label>
                      <Field
                        name="journalistDescription"
                        type="textarea"
                        component="textarea"
                        className={`${css(
                          styles.textarea
                        )} materialize-textarea`}
                      />
                    </InputField>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <InputSelect
                        placeholder="Select Newspapers"
                        rows={newspapersTransformed}
                        name="newspapersId"
                        change={change}
                        value={newspapersId}
                        multiple={true}
                      />
                      <label>Newspapers</label>
                    </InputField>
                  </Col>
                </Row>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

const styles = StyleSheet.create({
  fileInput: {
    marginTop: -15,
  },
  textarea: {
    height: 60,
    overflowY: "auto",
    resize: "vertical",
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "JournalistForm",
})(JournalistForm);
