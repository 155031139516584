import * as errors from "../types/errors";

import {
  CLEAR_CATEGORY_STATE,
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS
} from "../constants/category";
import ICategory from "../types/category";

export interface IState {
  categories: ICategory[];
  category?: ICategory;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  categories?: ICategory[];
  category?: ICategory;
  error?: errors.HttpError;
}

export const initialState: IState = {
  categories: [],
  isLoading: false
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories || [],
        isLoading: false
      };
    case FETCH_CATEGORIES_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_CATEGORY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
        isLoading: false
      };
    case FETCH_CATEGORY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_CATEGORY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
        isLoading: false
      };
    case CREATE_CATEGORY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case UPDATE_CATEGORY_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_CATEGORY_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_CATEGORY_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
