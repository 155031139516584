import * as errors from "../types/errors";
import { isValidJSON } from "./json";

const responseCheck = (response: Response): Promise<any> => {
  if (response.status >= 200 && response.status < 300) {
    return response.text().then(body => {
      if (isValidJSON(body)) {
        return JSON.parse(body);
      }
      return Promise.resolve(body);
    });
  }

  if (response.status === 401) {
    const error = new errors.HttpError();
    error.status = response.status;
    throw error;
  }

  return response.json().then(json => {
    const error = new errors.HttpError(json.errorMessage);
    error.status = response.status;
    error.data = json.error;
    throw error;
  });
};

export default responseCheck;
