import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, InputSelect, ModalConfirm, Row, toast } from "styleguide";

import CreatableSelect from "react-select/async-creatable";
import { components } from "react-select";

import { updateFile } from "../actions/file";
import config from "../config";
import IAudience from "../types/audience";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import IPlaylist from "../types/playlist";
import PlaylistTypes from "../types/playlist-types";
import ISection from "../types/section";
import { renderError, renderInput } from "../utils/input";
import { maxLength, minLength, required } from "../utils/validations";
import InputFile from "./InputFile";
import InputSwitch from "./InputSwitch";
import PlaylistStyles from "../types/playlist-styles";
import INewspaper from "../types/newspaper";
import { ReactSelectStylesMulti } from "src/utils/react-select";
import { createTagManually, searchTags, updateTagName } from "src/actions/tag";
import { addOverwriteTag, disableSelectedTags, getTagFromOverwriteTag, sortTags } from "src/utils/tag";

const maxLengthNameSize = config.rules.playlist.maxNameLength;
const minLengthDescriptionSize = config.rules.playlist.minDescriptionLength;
const maxLengthDescriptionFirstParagraphSize = config.rules.playlist.maxDescriptionFirstParagraphLength;
const maxLengthCuratorQuote = config.rules.playlist.maxCuratorQuoteLength;
const maxLengthName = maxLength(maxLengthNameSize);
const minLengthDescription = minLength(minLengthDescriptionSize);
const maxLengthCuratorQuoteValidation = maxLength(maxLengthCuratorQuote);

const playlistTypes = [
    { value: PlaylistTypes.Regular, text: "Regular" },
    { value: PlaylistTypes.Story, text: "Story" },
];

const styleTypes = [
    { value: PlaylistStyles.Regular, text: "Regular" },
    { value: PlaylistStyles.LongFormArticle, text: "Long-Form Article" },
];

// update from the backend as well.
const DISPLAY_TAGS_MAX_LENGTH = 60;

const DISPLAY_TAGS_TOTAL_LENGTH = 120;

const MaxLengthInput = (props: any) => <components.Input {...props} maxLength={DISPLAY_TAGS_MAX_LENGTH} />;

interface IProp {
    playlist?: IPlaylist;
    audiences: IAudience[];
    companies: ICompany[];
    error?: errors.HttpError;
    sections: ISection[];
    newspaper?: INewspaper;
    updateFile: typeof updateFile;
}

interface IState {
    tagsSearchRequestController?: AbortController;
    tagsSearchRequestSignal?: AbortSignal;
    displayTagsInput: string;
    hiddenTagsInput: string;
}

export class PlaylistForm extends React.Component<InjectedFormProps<{}, IProp> & IProp, IState> {
    private searchTagsForReactSelectDebounce: (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => void;

    constructor(props: InjectedFormProps<{}, IProp> & IProp) {
        super(props);
        this.state = { displayTagsInput: "", hiddenTagsInput: "" };
        this.searchTagsForReactSelectDebounce = _.debounce(this.searchTagsForReactSelect, 400);
    }

    public searchTagsForReactSelect = (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => {
        // aborting already running request
        if (this.state.tagsSearchRequestController) {
            this.state.tagsSearchRequestController.abort();
        }

        // creating new controller
        const newController = new AbortController();
        const newSignal = newController.signal;

        this.setState((prev) => ({ ...prev, tagsSearchRequestController: newController, tagsSearchRequestSignal: newSignal }));

        const options: { signal: AbortSignal; maxLength?: number } = { signal: newSignal };

        if (displayTags) {
            options.maxLength = DISPLAY_TAGS_MAX_LENGTH;
        }

        if (!searchText.trim()) return callback([]);

        searchTags(searchText.trim(), options)
            .then((tags) =>
                callback(
                    disableSelectedTags(
                        addOverwriteTag(
                            searchText,
                            sortTags(
                                searchText,
                                tags.map((tag) => ({ label: tag.tagName, value: tag.tagID })),
                            ),
                            [...(this.props.playlist?.displayTagsId || []), ...(this.props.playlist?.tagsId || [])],
                        ),
                        [...(this.props.playlist?.displayTagsId || []), ...(this.props.playlist?.tagsId || [])],
                    ),
                ),
            )
            .catch(() => {
                toast(`Error searching tags by "${searchText}"`);
                callback([]);
            });
    };

    public countDescription = () => {
        const { playlist } = this.props;
        if (!playlist) {
            return null;
        }

        if (!playlist.playlistDescription) {
            return `0/${maxLengthDescriptionFirstParagraphSize}`;
        }

        const paragraphs = playlist.playlistDescription.split(/\n/);

        return `${paragraphs[0].length}/${maxLengthDescriptionFirstParagraphSize}`;
    };

    public getCuratorQuoteLength = () => {
        const { playlist } = this.props;
        if (!playlist) {
            return null;
        }

        if (!playlist.curatorQuote) {
            return `0/${maxLengthCuratorQuote}`;
        }

        const length = playlist.curatorQuote.length;

        return `${length}/${maxLengthCuratorQuote}`;
    };

    public countName = () => {
        const { playlist } = this.props;
        if (!playlist) {
            return null;
        }

        return `${playlist.playlistName.length}/${maxLengthNameSize}`;
    };

    public createTag = (tagName: string, isDisplayTag?: boolean) => {
        const tagsIdName = isDisplayTag ? "displayTagsId" : "tagsId";
        const tags = this.props.playlist?.[tagsIdName] || [];

        if (!tagName.trim()) return toast("Empty tags cannot be created.");

        createTagManually(tagName.trim())
            .then((tag) => {
                if (isDisplayTag) {
                    this.onChangeDisplayTags({ label: tag.tagName, value: tag.tagID });
                } else {
                    this.props.change(tagsIdName, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
                }
            })
            .catch(() => {
                toast(`Error creating tag: ${tagName}`);
            });
    };

    public isTagInputValid = (input: string) => {
        if (input === "") return true;
        return /^[0-9A-Za-z]+$/.test(input);
    };

    public onDisplayTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ displayTagsInput: input });
        }
    };

    public onHiddenTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ hiddenTagsInput: input });
        }
    };

    public onChangeDisplayTags = (newTags: { label: string; value: number } | { label: string; value: number }[]) => {
        const { playlist, change } = this.props;
        const displayTags = playlist?.displayTagsId || [];
        const displayTagsLength = displayTags.reduce((prev, curr) => prev + curr.tagName.length, 0) as number;

        if (Array.isArray(newTags)) {
            const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

            // when tag is removed
            if (!newTag) {
                change(
                    "displayTagsId",
                    newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
                );
                return;
            }

            // when tag is selected
            if (displayTagsLength + newTag.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
                // updating the tagname on the backend to correct the tag's cast style
                this.updateTagNameBeforeSelect(newTag.label, newTag.value, "displayTagsId");
                return;
            }

            // when tag is created
        } else if (displayTagsLength + newTags.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
            change("displayTagsId", [...displayTags, { tagName: newTags.label, tagId: newTags.value }]);
            return;
        }

        toast("Display tags limit exceeded.");
    };

    public onChangeHiddenTags = (newTags: { label: string; value: number }[]) => {
        const { playlist, change } = this.props;
        const displayTags = playlist?.tagsId || [];

        const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

        // when tag is removed
        if (!newTag) {
            change(
                "tagsId",
                newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
            );
            return;
        }

        // when tag is selected
        // updating the tagname on the backend to correct the tag's cast style
        this.updateTagNameBeforeSelect(newTag.label, newTag.value, "tagsId");
    };

    public updateTagNameBeforeSelect = (tagName: string, tagId: number, tagsId: "tagsId" | "displayTagsId") => {
        const { playlist, change } = this.props;
        const tags = playlist?.[tagsId] || [];

        // to overwrite the tag instead of auto-update
        const tag = getTagFromOverwriteTag(tagName, tagId.toString());

        return updateTagName(tag.tagId, tag.tagName)
            .then((tag) => {
                change(tagsId, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
            })
            .catch(() => {
                toast(`unable to select: ${tagName}`);
            });
    };

    public render() {
        const { audiences, change, companies, handleSubmit, playlist, sections, valid, newspaper } = this.props;
        const { displayTagsInput, hiddenTagsInput } = this.state;

        if (!playlist) {
            return null;
        }

        const companiesTransformed = [
            { value: "", text: "NO COMPANY" },
            ...companies.map((c) => ({
                text: c.companyName,
                value: c.companyId,
            })),
        ];

        const audiencesSorted = _.orderBy(audiences, ["audienceType"], ["desc"]);
        const audiencesTransformed = audiencesSorted.map((a) => ({
            text: a.audienceName,
            value: a.audienceId,
        }));

        const parentSectionsTransformed = sections
            .filter((s) => s.parentId == null)
            .map((s) => ({
                text: s.sectionName,
                value: s.sectionId,
            }));

        const subSectionsTransformed = sections
            .filter((s) => s.parentId != null && playlist.sectionsId && playlist.sectionsId.includes(s.parentId?.toString()))
            .map((s) => ({
                text: s.sectionName,
                value: s.sectionId,
            }));

        const curatorTransformed = newspaper?.journalists?.map((j) => ({
            text: j.journalistName,
            value: j.journalistId,
        }));

        const companyId = playlist ? playlist.companyId : "";
        const sectionsId = playlist ? playlist.sectionsId : [];
        const sectionsIdx = playlist ? playlist.sectionsIdx : [];
        // const sectionsIdx = playlist ? playlist.sectionsIdx : [];
        // const selectedSections = playlist.sections.filter((s) => s.parentId == null)
        const audiencesId = playlist ? playlist.audiencesId : [];

        const tags = playlist?.tagsId || [];
        const displayTags = playlist?.displayTagsId || [];

        const displayTagsTransformed = displayTags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        const tagsTransformed = tags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        return (
            <ModalConfirm title="Confirmation" description="Are you sure that you want save this playlist?">
                {(confirm: any) => (
                    <form onSubmit={confirm(handleSubmit)}>
                        <Row>
                            <Col s={12}>
                                <Row>
                                    <Col s={12}>
                                        <InputField>
                                            <label htmlFor="playlistName">Name *</label>
                                            <Field name="playlistName" type="text" component={renderInput} validate={[required, maxLengthName]} />
                                            <span className={`right ${css(styles.counter)}`}>{this.countName()}</span>
                                        </InputField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <InputField>
                                            <label htmlFor="playlistDescription">Description</label>
                                            <Field
                                                id="input-playlist-form-description"
                                                name="playlistDescription"
                                                type="textarea"
                                                component="textarea"
                                                rows="20"
                                                validate={[required, minLengthDescription]}
                                                className={`${css(styles.textarea)} materialize-textarea`}
                                            />
                                            <span className={`right ${css(styles.counter)}`}>
                                                First paragraph character count (Min {minLengthDescriptionSize}, Max {maxLengthDescriptionFirstParagraphSize}): {this.countDescription()}
                                            </span>
                                        </InputField>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect rows={curatorTransformed} name="curatorId" change={change} value={playlist && playlist.curatorId} />
                                            <label>Curator</label>
                                        </InputField>
                                    </Col>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <label htmlFor="curatorQuote">Curator Quote</label>
                                            <Field
                                                id="input-playlist-form-curator-quote"
                                                name="curatorQuote"
                                                type="textarea"
                                                component="textarea"
                                                rows="1"
                                                className={`materialize-textarea`}
                                                validate={[maxLengthCuratorQuoteValidation]}
                                            />
                                            <span className={`right ${css(styles.counter)}`}>Max characters: {this.getCuratorQuoteLength()}</span>
                                        </InputField>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect
                                                placeholder="Categories"
                                                rows={parentSectionsTransformed}
                                                name="sectionsId"
                                                onChange={() => null}
                                                change={() => null}
                                                value={sectionsId}
                                                multiple={true}
                                            />
                                            <label>Categories</label>
                                        </InputField>
                                    </Col>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect
                                                placeholder="Sub-Categories"
                                                rows={subSectionsTransformed}
                                                name="sectionsIdx"
                                                change={() => null}
                                                onChange={() => null}
                                                value={sectionsIdx}
                                                multiple={true}
                                            />
                                            <label>Sub-Categories</label>
                                        </InputField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <label className="active"> Display tags (Shown on series card)</label>
                                        <br />
                                        <label className="active">* Max limit for a tag is 60 and the total limit for all the tags collectively is 120. </label>
                                        <br />
                                        <label className="active">* Only letters and numbers allowed.</label>
                                        <CreatableSelect
                                            isMulti
                                            value={displayTagsTransformed}
                                            loadOptions={(inputValue, callback) => this.searchTagsForReactSelectDebounce(inputValue, callback, true)}
                                            styles={ReactSelectStylesMulti}
                                            name="displayTagsId"
                                            placeholder="Select display tags"
                                            isClearable={false}
                                            onCreateOption={(t) => this.createTag(t, true)}
                                            components={{ Input: MaxLengthInput }}
                                            inputValue={displayTagsInput}
                                            onInputChange={this.onDisplayTagsInputChange}
                                            onChange={(v) => this.onChangeDisplayTags(Array.from(v))}
                                            isOptionDisabled={(option: any) => option.disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12}>
                                        <label className="active">Hidden tags (Used in search & recommendations)</label>
                                        <br />
                                        <label className="active">* Max limit for a tag is 60.</label>
                                        <br />
                                        <label className="active">* Only letters and numbers allowed.</label>
                                        <CreatableSelect
                                            isMulti
                                            value={tagsTransformed}
                                            loadOptions={this.searchTagsForReactSelectDebounce}
                                            styles={ReactSelectStylesMulti}
                                            name="tagsId"
                                            placeholder="Select hidden tags"
                                            isClearable={false}
                                            onCreateOption={this.createTag}
                                            inputValue={hiddenTagsInput}
                                            onInputChange={this.onHiddenTagsInputChange}
                                            onChange={(v) => this.onChangeHiddenTags(Array.from(v))}
                                            isOptionDisabled={(option: any) => option.disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col s={12} m={12}>
                                        <InputField>
                                            <InputFile
                                                model="playlists"
                                                id="playlistImage"
                                                upFile={this.props.updateFile}
                                                type={FileType.image}
                                                change={this.props.change}
                                                value={playlist && playlist.playlistImage}
                                                imageDimensionsCheck
                                                imageSizeCheck
                                            />
                                            <label className="active">Image</label>
                                        </InputField>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect rows={companiesTransformed} name="companyId" change={change} value={companyId} />
                                            <label>Company</label>
                                        </InputField>
                                    </Col>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect rows={playlistTypes} name="playlistType" change={change} />
                                            <label>Playlist Type</label>
                                        </InputField>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect placeholder="Select Audiences" rows={audiencesTransformed} name="audiencesId" change={change} multiple={true} value={audiencesId} />
                                            <label>Audiences</label>
                                        </InputField>
                                    </Col>
                                    <Col s={12} m={6}>
                                        <InputField>
                                            <InputSelect rows={styleTypes} name="style" change={change} />
                                            <label>Style</label>
                                        </InputField>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col s={12}>
                                        <div className="center">
                                            <h5 className={css(styles.subtitle)}>Published?</h5>
                                            <InputSwitch name="playlistIsPublished" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col s={12}>
                                <Field name="error" type="hidden" component={renderError} />
                            </Col>

                            <Col s={12}>
                                <div className="center">
                                    <Button text="save" icon="save" submit={true} disable={!valid} />
                                </div>
                            </Col>
                        </Row>
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    counter: {
        fontSize: 12,
    },
    fileInput: {
        marginTop: -15,
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingTop: 20,
    },
    textarea: {
        minHeight: 300,
        overflowY: "auto",
        resize: "vertical",
    },
    curatorTextArea: {
        overflowY: "auto",
        resize: "vertical",
    },
});

export default reduxForm<{}, IProp>({
    enableReinitialize: true,
    form: "PlaylistForm",
})(PlaylistForm);
