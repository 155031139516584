import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_TEMPLATE_STATE,
  CREATE_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_SUCCESS
} from "../constants/template";
import { IState } from "../reducers";
import { requestAllTemplates } from "../services/template";
import HttpMethods from "../types/http-methods";
import ITemplate from "../types/template";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const fetchTemplates = (params?: IParams) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_TEMPLATES_REQUEST });
  const endpoint = `${config.api.url}${config.api.paths.template}`;

  const url = !params
    ? buildUrl(endpoint, requestAllTemplates())
    : buildUrl(endpoint, params);

  return fetch(url)
    .then(responseCheck)
    .then(templates => dispatch(fetchTemplatesSuccess(templates)))
    .catch(error => dispatch(fetchTemplatesFailure(error)));
};

export const fetchTemplate = (id: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_TEMPLATE_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.template}/${id}`)
    .then(responseCheck)
    .then(template => dispatch(fetchTemplateSuccess(template)))
    .catch(error => dispatch(fetchTemplateFailure(error)));
};

export const createTemplate = (template: ITemplate, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: CREATE_TEMPLATE_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.template}`, {
    body: JSON.stringify({ model: template }),
    method: HttpMethods.POST
  })
    .then(responseCheck)
    .then(cat => dispatch(createTemplateSuccess(cat)))
    .then(() => history.push(config.paths.template))
    .catch(error => dispatch(createTemplateFailure(error)));
};

export const editTemplate = (template: ITemplate, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: UPDATE_TEMPLATE_REQUEST });

  return fetch(
    `${config.api.url}${config.api.paths.template}/${template.templateId}`,
    {
      body: JSON.stringify({ model: template }),
      method: HttpMethods.PUT
    }
  )
    .then(responseCheck)
    .then(() => dispatch(editTemplateSuccess()))
    .then(() => history.push(config.paths.template))
    .catch(error => dispatch(editTemplateFailure(error)));
};

export const deleteTemplate = (template: ITemplate) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: DELETE_TEMPLATE_REQUEST });

  return fetch(
    `${config.api.url}${config.api.paths.template}/${
    template.templateId
    }`,
    {
      method: HttpMethods.DELETE
    }
  )
    .then(responseCheck)
    .then(() => dispatch(deleteTemplateSuccess()))
    .catch(error => dispatch(deleteTemplateFailure(error)));
};


/*
    SYNC ACTIONS
*/

const fetchTemplatesSuccess = (templates: ITemplate[]) => {
  return { type: FETCH_TEMPLATES_SUCCESS, templates };
};

const fetchTemplatesFailure = (error: Error) => {
  toast("Error fetching templates");
  return { type: FETCH_TEMPLATES_FAILURE, error };
};

const fetchTemplateSuccess = (template: ITemplate) => {
  return { type: FETCH_TEMPLATE_SUCCESS, template };
};

const fetchTemplateFailure = (error: Error) => {
  toast("Error fetching templates");
  return { type: FETCH_TEMPLATE_FAILURE, error };
};

const createTemplateSuccess = (template: ITemplate) => {
  toast("Template created");
  return { type: CREATE_TEMPLATE_SUCCESS, template };
};

const createTemplateFailure = (error: Error) => {
  toast("Error creating template");
  return { type: CREATE_TEMPLATE_FAILURE, error };
};

const editTemplateSuccess = () => {
  toast("Template updated");
  return { type: UPDATE_TEMPLATE_SUCCESS };
};

const editTemplateFailure = (error: Error) => {
  toast("Error editing template");
  return { type: UPDATE_TEMPLATE_FAILURE, error };
};

const deleteTemplateSuccess = () => {
  toast("Template updated");
  return { type: DELETE_TEMPLATE_SUCCESS };
};

const deleteTemplateFailure = (error: Error) => {
  toast("Error deleting template");
  return { type: DELETE_TEMPLATE_FAILURE, error };
};

export const clearTemplateState = () => {
  return { type: CLEAR_TEMPLATE_STATE };
};
