import React, { useEffect } from "react";

interface ToastProps {
    message?: string;
    onTimeout: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, onTimeout }) => {
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (message) {
            timeout = setTimeout(onTimeout, 2000);
        }

        return () => clearTimeout(timeout);
    }, [message]);

    return (
        <div
            style={{
                display: message ? "flex" : "none",
                position: "fixed",
                top: "50%",
                left: "50%",
                background: "rgba(0, 0, 0, 0.7)",
                transform: "translate(-50%, -50%)",
                padding: "10px",
                borderRadius: "2px",
                zIndex: 1000,
                color: "white",
                fontFamily: "inherit",
            }}
        >
            <p>{message}</p>
        </div>
    );
};

export default Toast;
