import * as React from "react";

import { Clickable, Col, Icon, Loading, Table, Td, Th } from "styleguide";

import ISetting from "../../types/ISetting";
import { fetchSettings } from "../../state/actions";
import { HttpError } from "../../../../types/errors";
import { Helmet } from "react-helmet";
import { History } from "history";
import config from "../../../../config";

interface IProps {
    settings: ISetting[];
    error?: HttpError;
    isLoading: boolean;
    history: History;
    fetchSettings: typeof fetchSettings;
}

export class SettingsList extends React.Component<IProps> {
    public componentDidMount() {
        this.props.fetchSettings();
    }

    public renderSettingsList = (settings: ISetting[]) => {
        const { history } = this.props;

        return (
            <Col s={12}>
                <Table title={"Settings"}>
                    <thead>
                        <tr>
                            <Th size="big">Name</Th>
                            <Th hide={"mobile"}>Description</Th>
                            <Th size="small">Value</Th>
                            <Th align="right">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {settings.map((setting) => (
                            <tr key={setting.id}>
                                <Td>{setting.name}</Td>
                                <Td hide={"mobile"}>{setting.description}</Td>
                                <Td>{setting.value}</Td>
                                <Td align="right">
                                    <Clickable onClick={() => history.push(`${config.paths.setting}/${setting.id}`)}>
                                        <Icon icon="edit" />
                                    </Clickable>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        );
    };

    public render() {
        const { isLoading, settings } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Adverts</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSettingsList(settings)}
            </React.Fragment>
        );
    }
}

export default SettingsList;
