import { Dispatch } from "redux";
import config from "../config";
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS } from "../constants/country";
import { IState } from "../reducers";
import ICountry from "../types/country";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { toast } from "styleguide/build";
import fetch from "../utils/fetch";

export const fetchCountries = (params?: IParams) => (
    dispatch: Dispatch,
    getState: () => IState
) => {
    dispatch({ type: FETCH_COUNTRIES_REQUEST });

    const url = buildUrl(`${config.api.url}${config.api.paths.country}`, {
        ...params,
    });

    return fetch(url)
        .then(responseCheck)
        .then((response) => dispatch(fetchCountriesSuccess(response)))
        .catch(error => dispatch(fetchCountriesFailure(error)));
};

const fetchCountriesSuccess = (countries: ICountry[]) => {
    return { type: FETCH_COUNTRIES_SUCCESS, countries };
};

const fetchCountriesFailure = (error: Error) => {
    toast(`Error fetching countries: ${error}`);
    return { type: FETCH_COUNTRIES_FAILURE, error };
};