import * as _ from "lodash";

import * as errors from "../types/errors";

import { CLEAR_FILES, UPDATE_FILE, UPLOAD_FILES_FAILURE, UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS } from "../constants/file";
import IFile from "../types/file";

export interface IState {
    tempFiles: any[];
    files: IFile[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    file?: any;
    files?: IFile[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    error: undefined,
    files: [],
    isLoading: false,
    tempFiles: [],
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case UPLOAD_FILES_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                files: action.files || [],
                isLoading: false,
            };
        case UPLOAD_FILES_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case UPDATE_FILE:
            const indexFile = _.findIndex(state.tempFiles, { id: action.file.id });
            if (indexFile === -1) {
                return { ...state, tempFiles: [...state.tempFiles, action.file] };
            }
            state.tempFiles.splice(indexFile, 1, action.file);

            return {
                ...state,
                tempFiles: [...state.tempFiles],
            };
        case CLEAR_FILES:
            return { ...initialState };
        default:
            return state;
    }
};
