import * as React from "react";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
    Button,
    Card,
    Col,
    InputField,
    ModalConfirm,
    Row,
} from "styleguide";

import ISetting from "../../types/ISetting";
import { renderInput } from "../../../../utils/input";

interface IProps {
    setting: ISetting;
}

export class SettingsForm extends React.Component<IProps & InjectedFormProps> {

    public renderSettingFields = (setting: ISetting) => {

        return (
            <Card title="Settings">
                <Row>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="name">
                                Name
                            </label>
                            <Field
                                name="name"
                                type="text"
                                component={renderInput}
                                disabled={true}
                            />
                        </InputField>
                    </Col>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="description">
                                Description
                            </label>
                            <Field
                                name="description"
                                type="text"
                                component={renderInput}
                            />
                        </InputField>
                    </Col>
                    <Col s={12}>
                        <InputField>
                            <label htmlFor="value">
                                Value
                            </label>
                            <Field
                                name="value"
                                type="text"
                                component={renderInput}
                            />
                        </InputField>
                    </Col>
                </Row>
            </Card>
        )
    }

    public render() {
        const { setting, valid, handleSubmit } = this.props;

        if (!setting) {
            return null;
        }

        return (
            <ModalConfirm
                title="Confirmation"
                description="Are you sure that you want save this advert?"
            >
                {(confirm: any) => (
                    <form onSubmit={confirm(handleSubmit)}>
                        <Row>
                            <Col s={12}>
                                {this.renderSettingFields(setting)}

                                <Col s={12}>
                                    <div className="center">
                                        <Button
                                            text="save"
                                            icon="save"
                                            submit={true}
                                            disable={!valid}
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

export default reduxForm<{}, IProps>({
    enableReinitialize: true,
    form: "SettingsForm",
    // @ts-ignore
})(SettingsForm);
