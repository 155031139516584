import * as _ from "lodash";
import { Dispatch } from "redux";

import config from "../config";
import { IState } from "../reducers";
import IFile from "../types/file";
import HttpMethods from "../types/http-methods";
import fetch from "../utils/fetch";

import { CLEAR_FILES, UPDATE_FILE, UPLOAD_FILES_FAILURE, UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS } from "../constants/file";
import FileType from "../types/file-type";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

type Model = "section" | "article" | "playlist" | "advert" | "category" | "journalist" | "publisher" | "company";

export const uploadFiles =
    (model: Model) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        const paths = config.api.paths;
        const dataImage = new FormData();
        const dataAudio = new FormData();
        const files = getState().file.tempFiles;
        const images = files.filter((f: File) => f.type === FileType.image);
        const audios = files.filter((f: File) => f.type === FileType.audio);

        if (files.length < 1) {
            return Promise.resolve([]);
        }

        dispatch({ type: UPLOAD_FILES_REQUEST });

        images.forEach((file) => dataImage.append(FileType.image, file.file));
        audios.forEach((file) => dataAudio.append(FileType.audio, file.file));

        return Promise.all([
            fetch(`${config.api.uploadFile}${paths.image}?model=${model}`, {
                body: dataImage,
                formData: true,
                method: HttpMethods.POST,
            }),
            fetch(`${config.api.uploadFile}${paths.audio}?model=${model}`, {
                body: dataAudio,
                formData: true,
                method: HttpMethods.POST,
            }),
        ])
            .then((resp) => {
                if (resp?.[0]?.status !== 201 || resp?.[1].status !== 201) {
                    throw new Error("failed to upload");
                }

                return resp;
            })
            .then((resp) => {
                return Promise.all([responseCheck(resp[0]), responseCheck(resp[1])]);
            })
            .then((checkedResp) => [...checkedResp[0], ...checkedResp[1]])
            .then((filesName) => dispatch(uploadFilesSuccess(filesName)))
            .catch((error) => {
                console.log("error1", error);
                dispatch(uploadFilesFailure(error));
                throw error;
            });
    };

/*
    SYNC ACTIONS
*/

export const updateFile = (id: string, file: IFile, type: FileType, duration?: number) => {
    return { type: UPDATE_FILE, file: { id, file, type, duration } };
};

export const populateWithFiles = (getState: () => IState, callback: (files: IFile[]) => void): void => {
    const files = getState().file.files;
    const tempFiles = getState().file.tempFiles;

    if (_.isEmpty(files)) {
        return;
    }

    const filesWithDuration = files.map((f: IFile, i: number) => {
        const tempFile =
            _.find(tempFiles, (tf) => {
                if (!tf || !tf.file) {
                    return false;
                }

                return tf.file.name === f.original;
            }) || {};

        return {
            ...f,
            duration: tempFile.duration,
            id: tempFile.id,
        };
    });

    return callback(filesWithDuration);
};

export const clearFiles = () => {
    return { type: CLEAR_FILES };
};

const uploadFilesSuccess = (files: IFile[]) => {
    return { type: UPLOAD_FILES_SUCCESS, files };
};

const uploadFilesFailure = (error: Error) => {
    return { type: UPLOAD_FILES_FAILURE, error };
};
