import { Dispatch } from "redux";
import { REHYDRATE } from "redux-persist";

import { logout } from "../actions/auth";

export const logoutOnUnauthenticated = (store: any) => (next: Dispatch) => (
  action: any
) => {
  if (action && action.error && action.error.status === 401) {
    const state = store.getState();

    if (state.auth.user) {
      return next(logout());
    }
  }

  return next(action);
};

export const initUserContext = (store: any) => (next: Dispatch) => (
  action: any
) => {
  if (action && action.type === REHYDRATE) {
    const user =
      action.payload && action.payload.auth && action.payload.auth.user;

    if (user) {
      // @ts-ignore
      window.Raven.setUserContext({ login: user.userLogin });
    }
  }

  return next(action);
};
