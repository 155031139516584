import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import HttpMethods from "../types/http-methods";
import ITag from "../types/tag";
import fetch from "../utils/fetch";

import {
    CLEAR_TAG_STATE,
    CREATE_TAG_FAILURE,
    CREATE_TAG_REQUEST,
    CREATE_TAG_SUCCESS,
    EDIT_TAG_FAILURE,
    EDIT_TAG_REQUEST,
    EDIT_TAG_SUCCESS,
    FETCH_SUGGESTED_TAGS_FAILURE,
    FETCH_SUGGESTED_TAGS_REQUEST,
    FETCH_SUGGESTED_TAGS_SUCCESS,
    FETCH_TAG_FAILURE,
    FETCH_TAG_REQUEST,
    FETCH_TAG_SUCCESS,
    FETCH_TAGS_FAILURE,
    FETCH_TAGS_REQUEST,
    FETCH_TAGS_SUCCESS,
} from "../constants/tag";
import { IState } from "../reducers";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const fetchSuggestedTags = (text: string) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SUGGESTED_TAGS_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.suggestedTags}`, {
        body: JSON.stringify({ text }),
        method: "POST",
    })
        .then((resp) => responseCheck(resp))
        .then((resp) => dispatch(fetchSuggestedTagsSuccess(resp.data)))
        .catch((error) => dispatch(fetchSuggestedTagsFailure(error)));
};

export const fetchTags = (params?: IParams, reset?: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: FETCH_TAGS_REQUEST });

    const state = getState();

    const url = buildUrl(`${config.api.url}${config.api.paths.tag}`, {
        ...params,
        offset: reset ? 0 : state.tag.offset,
    });

    return fetch(url)
        .then((resp) => responseCheck(resp))
        .then((articles) => fetchTagsSuccess(dispatch, articles, state.tag.tags, reset ? reset : false))
        .catch((error) => dispatch(fetchTagsFailure(error)));
};

export const fetchTag = (id: number) => (dispatch: Dispatch) => {
    dispatch({ type: FETCH_TAG_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.tag}/${id}`)
        .then(responseCheck)
        .then((tag) => dispatch(fetchTagSuccess(tag)))
        .catch((error) => dispatch(fetchTagFailure(error)));
};

export const createTag = (newTag: ITag, history: History) => (dispatch: Dispatch) => {
    dispatch({ type: CREATE_TAG_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.tag}`, {
        body: JSON.stringify({ model: newTag }),
        method: HttpMethods.POST,
    })
        .then(responseCheck)
        .then((tag) => dispatch(createTagSuccess(tag)))
        .then(() => history.push(config.paths.tag))
        .catch((error) => dispatch(createTagFailure(error)));
};

export const editTag =
    (tag: ITag, history: History) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: EDIT_TAG_REQUEST });

        return fetch(`${config.api.url}${config.api.paths.tag}/${tag.tagID}`, {
            body: JSON.stringify({ model: tag }),
            method: HttpMethods.PUT,
        })
            .then(responseCheck)
            .then(() => dispatch(editTagSuccess()))
            .then(() => history.push(config.paths.tag))
            .catch((error) => dispatch(editTagFailure(error)));
    };

export const searchTags = (searchText: string, options: { signal: AbortSignal; maxLength?: number }) => {
    let params = "";

    if (options.maxLength) {
        params += `max_length=${options.maxLength}&`;
    }

    return fetch(`${config.api.url}${config.api.paths.tag}/search/${searchText}?${params}`, { signal: options.signal })
        .then(responseCheck)
        .then((tags) => tags as ITag[]);
};

export const createTagManually = (tagName: string) =>
    fetch(`${config.api.url}${config.api.paths.tag}`, {
        body: JSON.stringify({ model: { tagName, tagIsPublished: 1 } }),
        method: HttpMethods.POST,
    })
        .then(responseCheck)
        .then((tag) => tag as ITag);

export const updateTagName = (tagId: number, tagName: string) =>
    fetch(`${config.api.url}${config.api.paths.tag}/${tagId}`, {
        body: JSON.stringify({ model: { tagName } }),
        method: HttpMethods.PUT,
    })
        .then(responseCheck)
        .then((tag) => tag as ITag);

/*
    SYNC ACTIONS
*/

export const clearTagState = (): any => {
    return { type: CLEAR_TAG_STATE };
};

const fetchTagsSuccess = (dispatch: Dispatch, tags: ITag[], oldTags: ITag[], reset: boolean) => {
    const newTags = !reset ? [...oldTags, ...tags] : tags;

    return dispatch({
        offset: newTags.length,
        tags: newTags,
        type: FETCH_TAGS_SUCCESS,
    });
};

export const fetchTagsFailure = (error: Error) => {
    toast("Error fetching tags");
    return { type: FETCH_TAGS_FAILURE, error };
};

export const fetchTagSuccess = (tag: ITag) => {
    return { type: FETCH_TAG_SUCCESS, tag };
};

export const fetchTagFailure = (error: Error) => {
    toast("Error fetching tag");
    return { type: FETCH_TAG_FAILURE, error };
};

export const createTagSuccess = (tag: ITag) => {
    toast("Tag created");
    return { type: CREATE_TAG_SUCCESS, tag };
};

export const createTagFailure = (error: Error) => {
    toast("Error creating tag");
    return { type: CREATE_TAG_FAILURE, error };
};

export const editTagSuccess = () => {
    toast("Tag updated");
    return { type: EDIT_TAG_SUCCESS };
};

export const editTagFailure = (error: Error) => {
    toast("Error editing tag");
    return { type: EDIT_TAG_FAILURE, error };
};

const fetchSuggestedTagsSuccess = (tags: Array<{ name: string }>) => {
    return { type: FETCH_SUGGESTED_TAGS_SUCCESS, tags: tags.map((t) => ({ tagName: t.name })) };
};

const fetchSuggestedTagsFailure = (error: Error) => {
    toast("Error fetching tags");
    return { type: FETCH_SUGGESTED_TAGS_FAILURE, error };
};
