import * as React from "react";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import ICompany from "src/types/company";
import {
  Button,
  Col,
  InputField,
  InputSelect,
  ModalConfirm,
  Row,
} from "styleguide";

import * as errors from "../types/errors";
import INewspaper from "../types/newspaper";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { renderError, renderInput } from "../utils/input";
import {
  email,
  minLength,
  passwordsMatch,
  required,
} from "../utils/validations";

const userRolesDefault = [
  { value: UserRoles.NARRATOR, text: UserRoles[UserRoles.NARRATOR] },
  {
    text: UserRoles[UserRoles.NEWSPAPER_ADMIN],
    value: UserRoles.NEWSPAPER_ADMIN,
  },
  { value: UserRoles.EDITOR, text: UserRoles[UserRoles.EDITOR] },
  { value: UserRoles.ADVERTISING, text: UserRoles[UserRoles.ADVERTISING] },
];
const userRolesNewspapers = [
  {
    text: UserRoles[UserRoles.NEWSPAPER_ADMIN],
    value: UserRoles.NEWSPAPER_ADMIN,
  },
];
const confirmPassword = passwordsMatch("userPassword");
const minLength6 = minLength(6);

interface IProp {
  user?: IUser;
  error?: errors.HttpError;
  newspapers: INewspaper[];
  companies: ICompany[];
  userAuth?: IUser;
}

export class UserForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { change, handleSubmit, user, userAuth, newspapers, companies, valid } =
      this.props;

    if (!user) {
      return null;
    }

    const passwordValidation = user && user.userId === 0 ? [required, minLength6] : [minLength6];
    const confirmPasswordValidation = user && user.userId === 0 ? [required, confirmPassword] : [minLength6];
    const userRoleIdList = user ? user.userRoleIdList : "";
    const newspapersId = user ? user.newspapersId : "";
    const companyId = user ? user.companyId : "";
    const hideNewspaper = +user.userRoleIdList !== UserRoles.NEWSPAPER_ADMIN || (userAuth && userAuth.userRoleIdList === UserRoles.NEWSPAPER_ADMIN);
    const hideCompanies = +user.userRoleIdList !== UserRoles.ADVERTISING || (userAuth && userAuth.userRoleIdList === UserRoles.ADVERTISING);
    const hidePassword = user && user.userId !== 0;

    const newspapersTransformed = newspapers.map((n) => ({
      text: n.newspaperName,
      value: n.newspaperID,
    }));

    const companiesTransformed = companies ? [
      ...companies.map((c) => ({
        text: c.companyName,
        value: c.companyId,
      })),
    ] : [];

    const userRoles = userAuth && userAuth.userRoleIdList === UserRoles.NEWSPAPER_ADMIN
      ? userRolesNewspapers
      : userRolesDefault;

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this user?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <InputSelect
                        rows={userRoles}
                        name="userRoleIdList"
                        change={change}
                        value={userRoleIdList}
                      />
                    </InputField>
                  </Col>
                </Row>
                <div className={hideNewspaper ? "hide" : ""}>
                  <Row>
                    <Col s={12}>
                      <InputField>
                        <InputSelect
                          placeholder="Select Newspapers"
                          rows={newspapersTransformed}
                          name="newspapersId"
                          multiple={true}
                          change={change}
                          value={newspapersId}
                        />
                        <label>Newspapers</label>
                      </InputField>
                    </Col>
                  </Row>
                </div>

                <div className={hideCompanies ? "hide" : ""}>
                  <Row>
                    <Col s={12}>
                      <InputField>
                        <InputSelect
                          placeholder="Select Company"
                          rows={companiesTransformed}
                          name="companyId"
                          multiple={false}
                          change={change}
                          value={companyId}
                        />
                        <label>Company</label>
                      </InputField>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col s={12} m={6}>
                    <InputField>
                      <label htmlFor="userFirstName">First Name *</label>
                      <Field
                        name="userFirstName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12} m={6}>
                    <InputField>
                      <label htmlFor="userLastName">Last Name *</label>
                      <Field
                        name="userLastName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="userLogin">Login *</label>
                      <Field
                        name="userLogin"
                        type="text"
                        component={renderInput}
                        validate={[required, email]}
                      />
                    </InputField>
                  </Col>
                </Row>
                <div className={hidePassword ? "hide" : ""}>
                  <Row>
                    <Col s={12}>
                      <InputField>
                        <label htmlFor="userPassword">Password *</label>
                        <Field
                          name="userPassword"
                          type="password"
                          component={renderInput}
                          validate={passwordValidation}
                        />
                      </InputField>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={12}>
                      <InputField>
                        <label htmlFor="confirmPassword">
                          Confirm password *
                        </label>
                        <Field
                          name="confirmPassword"
                          type="password"
                          component={renderInput}
                          validate={confirmPasswordValidation}
                        />
                      </InputField>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "UserForm",
})(UserForm);
