import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field } from "redux-form";

interface IProp {
    name: string;
    disabled?: boolean;
}

export class InputSwitch extends React.Component<IProp> {
    public render() {
        const { name } = this.props;

        return (
            <div className={`${css(styles.switch)} switch`}>
                <label>
                    No
                    <Field disabled={this.props.disabled} name={name} id={name} type="checkbox" component="input" format={(v: any) => v === 1} normalize={(v: any) => (v || v === 1 ? 1 : 0)} />
                    <span className="lever" />
                    Yes
                </label>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    switch: {
        paddingTop: 15,
    },
});

export default InputSwitch;
