import { combineReducers } from "redux";

import { FormReducer, reducer as formReducer } from "redux-form";

import { LOGOUT } from "../constants/auth";
import { IState as IAdvertState, reducer as advertReducer } from "../domaindriven/adverts/state/reducers";
import { IState as ISettingsSate, reducer as settingsReducer } from "../domaindriven/settings/state/reducers";

import { IState as IArticleState, reducer as articleReducer } from "./article";
import { IState as IArticleTemplateState, reducer as articleTemplateReducer } from "./articletemplates";
import { IState as IAudienceState, reducer as audienceReducer } from "./audience";
import { IState as IAuthState, reducer as authReducer } from "./auth";
import { IState as ICategoryState, reducer as categoryReducer } from "./category";
import { IState as ICompanyState, reducer as companyReducer } from "./company";
import { IState as IFileState, reducer as fileReducer } from "./file";
import { IState as IJournalistState, reducer as journalistReducer } from "./journalist";
import { IState as IMetricState, reducer as metricReducer } from "./metric";
import { IState as INewspaperState, reducer as newspaperReducer } from "./newspaper";
import { IState as IPlaylistState, reducer as playlistReducer } from "./playlist";
import { IState as IPronunciationState, reducer as pronunciationReducer } from "./pronunciation";
import { IState as ISearchState, reducer as searchReducer } from "./search";
import { IState as ISectionState, reducer as sectionReducer } from "./section";
import { IState as ISuggestionState, reducer as suggestionReducer } from "./suggestion";
import { IState as ITagState, reducer as tagReducer } from "./tag";
import { IState as ITemplateState, reducer as templateReducer } from "./template";
import { IState as IUserState, reducer as userReducer } from "./user";
import { IState as ICountryState, reducer as countryReducer } from "./country";
import { IState as ISpecialSectionState, reducer as specialSectionReducer } from "./specialsection";
import { IState as IVoiceRequirementsState, reducer as voiceRequirementsReducer } from "./voicerequirements";
import { IState as IPodcastPlatformsState, reducer as podcastPlatformsReducer } from "./podcastplatforms";

const allReducers = {
    advert: advertReducer,
    settings: settingsReducer,
    article: articleReducer,
    articleTemplate: articleTemplateReducer,
    audience: audienceReducer,
    auth: authReducer,
    category: categoryReducer,
    company: companyReducer,
    file: fileReducer,
    form: formReducer,
    journalist: journalistReducer,
    metric: metricReducer,
    newspaper: newspaperReducer,
    playlist: playlistReducer,
    pronunciation: pronunciationReducer,
    search: searchReducer,
    section: sectionReducer,
    suggestion: suggestionReducer,
    tag: tagReducer,
    template: templateReducer,
    user: userReducer,
    country: countryReducer,
    specialSection: specialSectionReducer,
    voiceRequirements: voiceRequirementsReducer,
    podcastPlatforms: podcastPlatformsReducer,
};

const combinedReducers = combineReducers(allReducers);

export interface IState {
    advert: IAdvertState;
    settings: ISettingsSate;
    article: IArticleState;
    articleTemplate: IArticleTemplateState;
    tag: ITagState;
    form: FormReducer;
    auth: IAuthState;
    file: IFileState;
    search: ISearchState;
    metric: IMetricState;
    user: IUserState;
    newspaper: INewspaperState;
    category: ICategoryState;
    pronunciation: IPronunciationState;
    journalist: IJournalistState;
    company: ICompanyState;
    playlist: IPlaylistState;
    section: ISectionState;
    template: ITemplateState;
    suggestion: ISuggestionState;
    audience: IAudienceState;
    country: ICountryState;
    specialSection: ISpecialSectionState;
    voiceRequirements: IVoiceRequirementsState;
    podcastPlatforms: IPodcastPlatformsState;
}

export const reducers = (state: IState, action: any) => {
    if (action.type === LOGOUT) {
        return combinedReducers(undefined, action);
    }
    // @ts-ignore
    return combinedReducers(state, action);
};
