import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { fetchNewspapers } from "../actions/newspaper";
import { clearUserState, createUser, editUser, fetchUser } from "../actions/user";
import UserForm from "../components/UserForm";
import { IState } from "../reducers";
import { createDefaultUser, createDefaultUserNestedProps } from "../services/user";
import * as errors from "../types/errors";
import INewspaper from "../types/newspaper";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import ICompany from "src/types/company";
import { fetchCompanies } from "src/actions/company";
import { requestOnlyAdvertiserCompanies } from "src/services/company";

interface IPropsFromState {
    error?: errors.HttpError;
    user: IUser;
    isLoading: boolean;
    newspapers: INewspaper[];
    companies: ICompany[];
    userAuth?: IUser;
}

interface IPropsFromDispatch {
    clearUserState: typeof clearUserState;
    createUser: typeof createUser;
    editUser: typeof editUser;
    fetchUser: typeof fetchUser;
    fetchNewspapers: typeof fetchNewspapers;
    fetchCompanies: typeof fetchCompanies;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { user, userAuth, newspapers, companies } = this.props;

        const params = {
            initialValues: {
                ...user,
                ...createDefaultUserNestedProps(user),
                error: this.props.error,
            },
            newspapers,
            companies,
            onSubmit: this.submitForm,
            user,
            userAuth,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="User">
                    <UserForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (user: IUser | any) => {
        const { user: oldUser, history } = this.props;

        if (oldUser && oldUser.userId) {
            return this.props.editUser(user, history);
        }
        return this.props.createUser(user, history);
    };

    public componentWillUnmount() {
        this.props.clearUserState();
    }

    public componentDidMount() {
        const { match, userAuth } = this.props;

        if (userAuth && userAuth.userRoleIdList !== UserRoles.NEWSPAPER_ADMIN) {
            this.props.fetchNewspapers();
        }

        if (userAuth && userAuth.userRoleIdList !== UserRoles.ADVERTISING) {
            this.props.fetchCompanies(requestOnlyAdvertiserCompanies());
        }

        if (match.params.id) {
            this.props.fetchUser(+match.params.id);
        }
    }

    public render() {
        const title = `User Editor | ${this.props.user && this.props.user.userEmail ? this.props.user.userEmail : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { UserForm: userForm },
        user: { user },
    } = state;

    const userValues = userForm ? userForm.values : {};
    const preUser = correctValue("userId", userValues, user);
    const validatedUser = preUser ? preUser : createDefaultUser();

    return {
        error: state.user.error,
        isLoading: state.user.isLoading,
        newspapers: state.newspaper.newspapers,
        user: validatedUser,
        userAuth: state.auth.user,
        companies: state.company.companies,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearUserState,
            createUser,
            editUser,
            fetchNewspapers,
            fetchUser,
            fetchCompanies,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
