import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Card, Col, InputField, Row } from "styleguide";

import * as errors from "../types/errors";
import { renderInput } from "../utils/input";
import { required } from "../utils/validations";

interface IProp {
  loginError?: errors.HttpError;
  onSubmit: (loginParams: any) => void;
}

class LoginForm extends React.Component<IProp & InjectedFormProps<{}, IProp>> {
  public render() {
    const { onSubmit, handleSubmit, loginError, valid } = this.props;

    return (
      <div className={`${css(styles.container)} row`}>
        <Col>
          <Card>
            <Row>
              <div className="col s12 center">
                <h5 className={css(styles.title)}>Noa CMS</h5>
                <h6 className={css(styles.subtitle)}>Please log in below</h6>
              </div>
              <Col s={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="email">Email</label>
                      <Field
                        name="email"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="password">Password</label>
                      <Field
                        name="password"
                        type="password"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Row>
                    <Col s={12}>
                      <div className={`${css(styles.submit)} center`}>
                        <Button
                          text="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log In&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                          submit={true}
                          disable={!valid}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className={`${css(styles.paddingTop)} col s12 center`}>
                      <a
                        className={css(styles.forgotPassword)}
                        href="https://www.newsoveraudio.com/reset-password-init"
                      >
                        I forgot my password
                      </a>
                    </div>
                    {loginError && (
                      <div
                        className={`${css(styles.paddingTop)} col s12 center`}
                      >
                        <span className={css(styles.error)}>
                          Invalid email or password
                        </span>
                      </div>
                    )}
                  </Row>
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 390,
    paddingTop: "10%",
    width: "100%",
  },
  error: {
    color: "#F44336",
    fontSize: 14,
  },
  forgotPassword: {
    fontSize: 14,
    paddingTop: 10,
  },
  paddingTop: {
    paddingTop: 20,
  },
  submit: {
    paddingTop: 20,
  },
  subtitle: {
    fontWeight: 300,
  },
  title: {
    fontWeight: 300,
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "LoginForm",
})(LoginForm);
