import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { fetchCompanies } from "../actions/company";
import { updateFile } from "../actions/file";
import { clearNewspaperState, createNewspaper, editNewspaper, fetchNewspaper } from "../actions/newspaper";
import NewspaperForm from "../components/NewspaperForm";
import { IState } from "../reducers";
import { createDefaultNewspaper } from "../services/newspaper";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import INewspaper from "../types/newspaper";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import { requestAllCompanies } from "../services/company";

interface IPropsFromState {
    error?: errors.HttpError;
    newspaper: INewspaper;
    isLoading: boolean;
    companies: ICompany[];
}

interface IPropsFromDispatch {
    clearNewspaperState: typeof clearNewspaperState;
    createNewspaper: typeof createNewspaper;
    editNewspaper: typeof editNewspaper;
    fetchNewspaper: typeof fetchNewspaper;
    updateFile: typeof updateFile;
    fetchCompanies: typeof fetchCompanies;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { newspaper, companies } = this.props;

        const params = {
            companies,
            initialValues: { ...newspaper, error: this.props.error },
            newspaper,
            onSubmit: this.submitForm,
            updateFile: this.props.updateFile,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Newspaper">
                    <NewspaperForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (newspaper: INewspaper | any) => {
        const { newspaper: oldNewspaper, history } = this.props;

        if (oldNewspaper && oldNewspaper.newspaperID) {
            return this.props.editNewspaper(newspaper, history);
        }
        return this.props.createNewspaper(newspaper, history);
    };

    public componentWillUnmount() {
        this.props.clearNewspaperState();
    }

    public componentDidMount() {
        const { match } = this.props;

        this.props.fetchCompanies(requestAllCompanies());

        if (match.params.id) {
            this.props.fetchNewspaper(+match.params.id);
        }
    }

    public render() {
        const title = `Newspaper Editor | ${this.props.newspaper && this.props.newspaper.newspaperName ? this.props.newspaper.newspaperName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { NewspaperForm: newspaperForm },
        newspaper: { newspaper },
    } = state;

    const newspaperValues = newspaperForm ? newspaperForm.values : {};

    const preNewspaper = correctValue("newspaperID", newspaperValues, newspaper);
    const validatedNewspaper = preNewspaper ? preNewspaper : createDefaultNewspaper();

    return {
        companies: state.company.companies,
        error: state.newspaper.error,
        isLoading: state.newspaper.isLoading,
        newspaper: validatedNewspaper,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearNewspaperState,
            createNewspaper,
            editNewspaper,
            fetchCompanies,
            fetchNewspaper,
            updateFile,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
