import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Clickable, Col, Icon, Loading, Table, Td, Th } from "styleguide";

import { clearSearch, doSearch } from "../actions/search";
import { fetchTags } from "../actions/tag";
import SearchForm from "../components/SearchForm";
import config from "../config";
import { IState } from "../reducers";
import { createDefaultSearch } from "../services/search";
import { requestTagsSearched } from "../services/tag";
import * as errors from "../types/errors";
import ISearch from "../types/search";
import ITag from "../types/tag";
import debounce from "../utils/debounce";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    tags: ITag[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
}

interface IPropsFromDispatch {
    fetchTags: typeof fetchTags;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public renderListTags = () => {
        const { history, tags } = this.props;

        return (
            <Col s={12}>
                <Table title="Tags">
                    <thead>
                        <tr>
                            <Th>Name</Th>
                            <Th align="right">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {tags.map((tag) => (
                            <tr key={tag.tagID}>
                                <Td>{tag.tagName}</Td>
                                <Td align="right">
                                    <Clickable onClick={() => history.push(`${config.paths.tag}/${tag.tagID}`)}>
                                        <Icon icon="edit" />
                                    </Clickable>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.tag}+`)} />
            </Col>
        );
    };

    public renderSearch = () => {
        return <SearchForm onSubmit={this.submitForm} />;
    };

    public submitForm = (search: ISearch | any) => {
        this.props.doSearch(search);

        this.props.fetchTags(requestTagsSearched(search), true);
    };

    public componentDidMount() {
        window.onscroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                debounce(() => {
                    this.props.fetchTags(requestTagsSearched(this.props.search), false);
                });
            }
        };
        this.props.fetchTags(requestTagsSearched(createDefaultSearch()), true);
    }

    public componentWillUnmount() {
        this.props.clearSearch();
        window.onscroll = null;
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Tags</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderSearch()}
                {this.renderListTags()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.tag.error,
        isLoading: state.tag.isLoading,
        offset: state.tag.offset,
        search: state.search,
        tags: state.tag.tags,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSearch,
            doSearch,
            fetchTags,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
