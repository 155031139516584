import * as _ from "lodash";
import { Dispatch } from "redux";

import { toast } from "styleguide";
import config from "../config";
import { IState } from "../reducers";
import fetch from "../utils/fetch";

import { buildUrl } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { FETCH_ARTICLE_TEMPLATES_REQUEST, FETCH_ARTICLE_TEMPLATES_SUCCESS, FETCH_ARTICLE_TEMPLATES_FAILURE, CREATE_ARTICLE_TEMPLATE_REQUEST, CREATE_ARTICLE_TEMPLATE_SUCCESS, CREATE_ARTICLE_TEMPLATE_FAILURE, DELETE_ARTICLE_TEMPLATE_FAILURE, DELETE_ARTICLE_TEMPLATE_REQUEST, DELETE_ARTICLE_TEMPLATE_SUCCESS } from "../constants/articletemplates";
import IArticleTemplate from "../types/article-template";
import HttpMethods from "../types/http-methods";

export const fetchArticleTemplates = () => (
    dispatch: Dispatch,
    getState: () => IState
) => {

    dispatch({ type: FETCH_ARTICLE_TEMPLATES_REQUEST });

    const url = buildUrl(`${config.api.url}${config.api.paths.articletemplate}`);

    return fetch(url)
        .then(resp => responseCheck(resp))
        .then(articleTemplates =>
            fetchArticleTemplatesSuccess({
                dispatch,
                articleTemplates,
            })
        )
        .catch(error => fetchArticleTemplatesFailure(dispatch, error));
};


export const createArticleTemplate = (articleTemplate: IArticleTemplate) => (
    dispatch: Dispatch,
    getState: () => IState
): Promise<any> => {

    dispatch({ type: CREATE_ARTICLE_TEMPLATE_REQUEST });

    const url = `${config.api.url}${config.api.paths.articletemplate}`;

    return fetch(url, {
        body: JSON.stringify({ model: articleTemplate }),
        method: HttpMethods.POST
    })
        .then(responseCheck)
        .then(() => dispatch(createArticleTemplateSuccess()))
        .then(() => fetch(url))
        .then(resp => responseCheck(resp))
        .then(articleTemplates =>
            fetchArticleTemplatesSuccess({
                dispatch,
                articleTemplates,
            })
        )
        .catch(error => fetchArticleTemplatesFailure(dispatch, error))
        .catch(error => dispatch(createArticleTemplateFailure(error)));
};

export const deleteArticleTemplate = (articleTemplate: IArticleTemplate) => (
    dispatch: Dispatch,
    getState: () => IState
): Promise<any> => {

    dispatch({ type: DELETE_ARTICLE_TEMPLATE_REQUEST });

    const url = `${config.api.url}${config.api.paths.articletemplate}`;
    const deleteUrl = `${url}/${articleTemplate.templateId}`;

    return fetch(deleteUrl, { method: HttpMethods.DELETE })
        .then(responseCheck)
        .then(() => dispatch(deleteArticleTemplateSuccess()))
        .then(() => fetch(url))
        .then(resp => responseCheck(resp))
        .then(articleTemplates =>
            fetchArticleTemplatesSuccess({
                dispatch,
                articleTemplates,
            })
        )
        .catch(error => fetchArticleTemplatesFailure(dispatch, error))
        .catch(error => dispatch(deleteArticleTemplateFailure(error)));
};

const fetchArticleTemplatesSuccess = (params: {
    dispatch: Dispatch,
    articleTemplates: IArticleTemplate[],
}) => {

    return params.dispatch({
        articleTemplates: params.articleTemplates,
        type: FETCH_ARTICLE_TEMPLATES_SUCCESS
    });
};

const fetchArticleTemplatesFailure = (dispatch: Dispatch, error: Error) => {
    toast("Error fetching article templates");
    return dispatch({ type: FETCH_ARTICLE_TEMPLATES_FAILURE, error });
};

const createArticleTemplateSuccess = () => {
    toast("Success creating article template");
    return { type: CREATE_ARTICLE_TEMPLATE_SUCCESS };
};

const createArticleTemplateFailure = (error: Error) => {
    toast("Error creating article template");
    return { type: CREATE_ARTICLE_TEMPLATE_FAILURE, error };
};

const deleteArticleTemplateFailure = (error: Error) => {
    toast("Error deleting article template");
    return { type: DELETE_ARTICLE_TEMPLATE_FAILURE, error };
};

const deleteArticleTemplateSuccess = () => {
    toast("Success deleting article template");
    return { type: DELETE_ARTICLE_TEMPLATE_SUCCESS };
};
