import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
    CLEAR_PLAYLIST_STATE,
    CREATE_PLAYLIST_FAILURE,
    CREATE_PLAYLIST_REQUEST,
    CREATE_PLAYLIST_SUCCESS,
    FETCH_PLAYLIST_FAILURE,
    FETCH_PLAYLIST_REQUEST,
    FETCH_PLAYLIST_SUCCESS,
    FETCH_PLAYLISTS_FAILURE,
    FETCH_PLAYLISTS_REQUEST,
    FETCH_PLAYLISTS_SUCCESS,
    MOVE_DOWN_PLAYLIST_FAILURE,
    MOVE_DOWN_PLAYLIST_REQUEST,
    MOVE_DOWN_PLAYLIST_SUCCESS,
    MOVE_UP_PLAYLIST_FAILURE,
    MOVE_UP_PLAYLIST_REQUEST,
    MOVE_UP_PLAYLIST_SUCCESS,
    UPDATE_PLAYLIST_FAILURE,
    UPDATE_PLAYLIST_REQUEST,
    UPDATE_PLAYLIST_SUCCESS,
    FETCH_SUGGESTION_PLAYLIST_SUCCESS,
    FETCH_SUGGESTION_PLAYLIST_FAILURE,
    FETCH_SUGGESTION_PLAYLIST_REQUEST,
} from "../constants/playlist";
import { IState } from "../reducers";
import { requestAllPlaylists } from "../services/playlist";
import IFile from "../types/file";
import FileType from "../types/file-type";
import HttpMethods from "../types/http-methods";
import IPlaylist from "../types/playlist";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";
import _ from "lodash";

/*
    ASYNC ACTIONS
*/

export const moveUp = (playlistId: number, sectionId: number) => (dispatch: Dispatch) => {
    dispatch({ type: MOVE_UP_PLAYLIST_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.playlist}/${playlistId}/reorder/up`, {
        body: JSON.stringify({ sectionId }),
        method: HttpMethods.PUT,
    })
        .then((resp) => responseCheck(resp))
        .then(() => dispatch(moveUpSuccess()))
        .catch((error) => dispatch(moveUpFailure(error)));
};

export const moveDown = (playlistId: number, sectionId: number) => (dispatch: Dispatch) => {
    dispatch({ type: MOVE_DOWN_PLAYLIST_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.playlist}/${playlistId}/reorder/down`, {
        body: JSON.stringify({ sectionId }),
        method: HttpMethods.PUT,
    })
        .then((resp) => responseCheck(resp))
        .then(() => dispatch(moveDownSuccess()))
        .catch((error) => dispatch(moveDownFailure(error)));
};

export const fetchPlaylists =
    (params?: IParams, sectionId?: number, selectedPlaylists?: IPlaylist[]) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_PLAYLISTS_REQUEST });
        const sectionPath = sectionId ? `/section/${+sectionId}` : "";
        const endpoint = `${config.api.url}${config.api.paths.playlist}${sectionPath}`;

        const url = !params ? buildUrl(endpoint, requestAllPlaylists()) : buildUrl(endpoint, params);

        return fetch(url)
            .then(responseCheck)
            .then((playlists) => dispatch(fetchPlaylistsSuccess(playlists, selectedPlaylists)))
            .catch((error) => dispatch(fetchPlaylistsFailure(error)));
    };

export const fetchSuggestedPlaylists =
    (tags: string[], selectedPlaylists?: IPlaylist[]) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_SUGGESTION_PLAYLIST_REQUEST });
        const endpoint = `${config.api.url}${config.api.paths.suggestedPlaylists}?${tags.map((t) => `tag=${t}&`).join("")}`;

        return fetch(endpoint)
            .then(responseCheck)
            .then((result) => dispatch(fetchSuggestedPlaylistsSuccess(result.data.playlists, selectedPlaylists)))
            .catch((error) => dispatch(fetchSuggestedPlaylistsFailure(error)));
    };

export const fetchPlaylist =
    (id: number) =>
    (dispatch: Dispatch): Promise<any> => {
        dispatch({ type: FETCH_PLAYLIST_REQUEST });

        return fetch(`${config.api.url}${config.api.paths.playlist}/${id}?include=sections&include=audiences&include=journalists:curator&include=tags`)
            .then(responseCheck)
            .then((playlist) => dispatch(fetchPlaylistSuccess(playlist)))
            .catch((error) => dispatch(fetchPlaylistFailure(error)));
    };

export const createPlaylist =
    (playlist: IPlaylist, history: History) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: CREATE_PLAYLIST_REQUEST });

        return uploadFiles("playlist")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    if (files[0].type === FileType.image) {
                        playlist.playlistImage = files[0].key;
                    }
                });

                return fetch(`${config.api.url}${config.api.paths.playlist}`, {
                    body: JSON.stringify({ model: playlist }),
                    method: HttpMethods.POST,
                });
            })
            .then(responseCheck)
            .then((cat) => dispatch(createPlaylistSuccess(cat)))
            .then(() => dispatch(clearFiles()))
            .then(() => history.push(config.paths.playlist))
            .catch((error) => dispatch(createPlaylistFailure(error)));
    };

export const editPlaylist =
    (playlist: IPlaylist, history?: History) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
        dispatch({ type: UPDATE_PLAYLIST_REQUEST });

        return uploadFiles("playlist")(dispatch, getState)
            .then(() => {
                populateWithFiles(getState, (files: IFile[]) => {
                    if (files[0].type === FileType.image) {
                        playlist.playlistImage = files[0].key;
                    }
                });

                return fetch(`${config.api.url}${config.api.paths.playlist}/${playlist.playlistId}`, {
                    body: JSON.stringify({ model: playlist }),
                    method: HttpMethods.PUT,
                });
            })
            .then(responseCheck)
            .then(() => dispatch(editPlaylistSuccess()))
            .then(() => dispatch(clearFiles()))
            .then(() => {
                if (history) {
                    history.push(config.paths.playlist);
                }
            })
            .catch((error) => dispatch(editPlaylistFailure(error)));
    };

/*
    SYNC ACTIONS
*/

const fetchPlaylistsSuccess = (playlists: IPlaylist[], selectedPlaylists?: IPlaylist[]) => {
    const selectedPlaylistsFiltered = selectedPlaylists
        ? _.filter(
              selectedPlaylists,
              (sp) =>
                  !_.includes(
                      playlists.map((p) => p.playlistId),
                      sp.playlistId,
                  ),
          )
        : [];
    const playlistsConcat = playlists.concat(selectedPlaylistsFiltered);
    return { type: FETCH_PLAYLISTS_SUCCESS, playlists: playlistsConcat };
};

const fetchPlaylistsFailure = (error: Error) => {
    toast("Error fetching playlists");
    return { type: FETCH_PLAYLISTS_FAILURE, error };
};

const fetchPlaylistSuccess = (playlist: IPlaylist) => {
    return { type: FETCH_PLAYLIST_SUCCESS, playlist };
};

const fetchPlaylistFailure = (error: Error) => {
    toast("Error fetching playlist");
    return { type: FETCH_PLAYLIST_FAILURE, error };
};

const createPlaylistSuccess = (playlist: IPlaylist) => {
    toast("Playlist created");
    return { type: CREATE_PLAYLIST_SUCCESS, playlist };
};

const createPlaylistFailure = (error: Error) => {
    toast("Error creating playlist");
    return { type: CREATE_PLAYLIST_FAILURE, error };
};

const editPlaylistSuccess = () => {
    toast("Playlist updated");
    return { type: UPDATE_PLAYLIST_SUCCESS };
};

const editPlaylistFailure = (error: Error) => {
    toast("Error editing playlist");
    return { type: UPDATE_PLAYLIST_FAILURE, error };
};

export const clearPlaylistState = () => {
    return { type: CLEAR_PLAYLIST_STATE };
};

const moveUpSuccess = () => {
    return { type: MOVE_UP_PLAYLIST_SUCCESS };
};

const moveUpFailure = (error: Error) => {
    toast("Error moving playlist");
    return { type: MOVE_UP_PLAYLIST_FAILURE, error };
};

const moveDownSuccess = () => {
    return { type: MOVE_DOWN_PLAYLIST_SUCCESS };
};

const moveDownFailure = (error: Error) => {
    toast("Error moving playlist");
    return { type: MOVE_DOWN_PLAYLIST_FAILURE, error };
};

const fetchSuggestedPlaylistsSuccess = (playlists: IPlaylist[], selectedPlaylists?: IPlaylist[]) => {
    const selectedPlaylistsFiltered = _.filter(
        selectedPlaylists,
        (sp) =>
            !_.includes(
                playlists.map((p) => p.playlistId),
                sp.playlistId,
            ),
    );
    const playlistsConcat = playlists.concat(selectedPlaylistsFiltered);
    return { type: FETCH_SUGGESTION_PLAYLIST_SUCCESS, playlists: playlistsConcat };
};

const fetchSuggestedPlaylistsFailure = (error: Error) => {
    toast("Error fetching suggestions");
    return { type: FETCH_SUGGESTION_PLAYLIST_FAILURE, error };
};
