import * as _ from "lodash";

import ITemplate from "../types/template";
import { IParams } from "../utils/build-url";

export const requestAllTemplates = (): IParams => {
  return {
    orderBy: "ASC",
    sortBy: "templateName"
  };
};

export const createDefaultTemplate = (): ITemplate => {
  return {
    templateId: 0,
    templateName: "",
    templateText: ""
  };
};
