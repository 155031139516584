import * as errors from "../types/errors";

import {
  CLEAR_USER_STATE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from "../constants/user";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";

export interface IState {
  users: IUser[];
  narrators: IUser[];
  isLoading: boolean;
  error?: errors.HttpError;
  user?: IUser;
  offset: number;
}

export interface IAction {
  type: string;
  userRole?: UserRoles;
  users?: IUser[];
  error?: errors.HttpError;
  user?: IUser;
  offset?: number;
}

export const initialState: IState = {
  isLoading: false,
  narrators: [],
  offset: 0,
  users: []
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case CLEAR_USER_STATE:
      return { ...initialState };
    case CREATE_USER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user
      };
    case CREATE_USER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_USERS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_USERS_SUCCESS:
      if (action.userRole === UserRoles.NARRATOR) {
        return {
          ...state,
          isLoading: false,
          narrators: action.users || []
        };
      }

      return {
        ...state,
        isLoading: false,
        offset: action.offset || 0,
        users: action.users || []
      };
    case FETCH_USERS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_USER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user
      };
    case FETCH_USER_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case UPDATE_USER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_USER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
