import * as errors from "../types/errors";

import { FETCH_VOICE_REQUIREMENTS_FAILURE, FETCH_VOICE_REQUIREMENTS_REQUEST, FETCH_VOICE_REQUIREMENTS_SUCCESS } from "src/constants/voicerequirements";
import { IVoiceRequirement } from "src/types/voice-requirements";

export interface IState {
    voiceRequirements: IVoiceRequirement[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    voiceRequirements?: IVoiceRequirement[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    isLoading: false,
    voiceRequirements: [],
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case FETCH_VOICE_REQUIREMENTS_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case FETCH_VOICE_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                voiceRequirements: action.voiceRequirements ? action.voiceRequirements.sort((a, b) => a.name.localeCompare(b.name)) : [],
            };

        case FETCH_VOICE_REQUIREMENTS_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        default:
            return state;
    }
};
