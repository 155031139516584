import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import {
  FETCH_METRIC_FAILURE,
  FETCH_METRIC_REQUEST,
  FETCH_METRIC_SUCCESS
} from "../constants/metric";
import fetch from "../utils/fetch";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const fetchMetric = (allTime?: boolean) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_METRIC_REQUEST });

  const allTimeFilter = allTime ? "?filter=all" : "";

  return fetch(`${config.api.url}${config.api.paths.metric}${allTimeFilter}`)
    .then(responseCheck)
    .then(metric => dispatch(fetchMetricSuccess(metric)))
    .catch(error => dispatch(fetchMetricFailure(error)));
};

/*
    SYNC ACTIONS
*/

const fetchMetricSuccess = (metric: any) => {
  return { type: FETCH_METRIC_SUCCESS, metric };
};

const fetchMetricFailure = (error: Error) => {
  toast("Error fetching metric");
  return { type: FETCH_METRIC_FAILURE, error };
};
