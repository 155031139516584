import ICompany from "../types/company";
import { IParams } from "../utils/build-url";

export const requestAllCompanies = (): IParams => {
  return {
    orderBy: "ASC",
    sortBy: "companyName"
  };
};

export const requestOnlyAdvertiserCompanies = (): IParams => {
  return {
    filter: [{ name: "isAdvertisingEnabled", value: 1 }],
    orderBy: "ASC",
    sortBy: "companyName"
  };
};

export const createDefaultCompany = (): ICompany => {
  return {
    companyDomain: "",
    companyId: 0,
    companyIsPublished: 1,
    companyMaxSubscribers: 1000,
    companyName: "",
    companySubscriptionDurationInMonths: 0
  };
};
