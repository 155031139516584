import IArticle from "../types/article";
import * as errors from "../types/errors";

import {
  CLEAR_ARTICLE_STATE,
  CREATE_ARTICLE_FAILURE,
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  EDIT_ARTICLE_FAILURE,
  EDIT_ARTICLE_REQUEST,
  EDIT_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  MOVE_DOWN_ARTICLE_FAILURE,
  MOVE_DOWN_ARTICLE_REQUEST,
  MOVE_DOWN_ARTICLE_SUCCESS,
  MOVE_UP_ARTICLE_FAILURE,
  MOVE_UP_ARTICLE_REQUEST,
  MOVE_UP_ARTICLE_SUCCESS,
  RECONCILE_ARTICLE,
  TOGGLE_PUBLISH_ARTICLE_FAILURE,
  TOGGLE_PUBLISH_ARTICLE_REQUEST,
  TOGGLE_PUBLISH_ARTICLE_SUCCESS
} from "../constants/article";

export interface IState {
  article?: IArticle;
  articles: IArticle[];
  offset: number;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  article?: IArticle;
  type: string;
  offset?: number;
  articles?: IArticle[];
  error?: errors.HttpError;
}

export const initialState: IState = {
  article: undefined,
  articles: [],
  error: undefined,
  isLoading: false,
  offset: 0
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case CLEAR_ARTICLE_STATE:
      return { ...initialState };
    case FETCH_ARTICLES_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.articles || [],
        isLoading: false,
        offset: action.offset || 0
      };
    case FETCH_ARTICLES_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_ARTICLE_REQUEST:
      return {
        ...state,
        article: undefined,
        error: undefined,
        isLoading: true
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.article,
        isLoading: false,
        offset: action.offset || 0
      };
    case FETCH_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case TOGGLE_PUBLISH_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case TOGGLE_PUBLISH_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TOGGLE_PUBLISH_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case RECONCILE_ARTICLE:
      return { ...state, articles: action.articles || [] };
    case EDIT_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case EDIT_ARTICLE_SUCCESS:
      return { ...state, isLoading: false };
    case EDIT_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_ARTICLE_SUCCESS:
      return { ...state, isLoading: false };
    case CREATE_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case MOVE_UP_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case MOVE_UP_ARTICLE_SUCCESS:
      return { ...state, isLoading: false };
    case MOVE_UP_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case MOVE_DOWN_ARTICLE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case MOVE_DOWN_ARTICLE_SUCCESS:
      return { ...state, isLoading: false };
    case MOVE_DOWN_ARTICLE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
