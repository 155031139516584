import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Loading } from "styleguide";

import ILogin from "../types/login";
import { clearAuth, login } from "../actions/auth";
import LoginForm from "../components/LoginForm";
import { IState } from "../reducers";
import * as errors from "../types/errors";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    loginError?: errors.HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    login: typeof login;
    clearAuth: typeof clearAuth;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public submitForm = (loginParams: ILogin | any) => {
        const { history } = this.props;

        return this.props.login(loginParams, history);
    };

    public componentDidUpdate(prevProps: IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>) {
        if (this.props.location !== prevProps.location) {
            this.props.clearAuth();
        }
    }

    public componentDidMount() {
        this.props.clearAuth();
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Noa CMS | Log In</title>
                </Helmet>
                {isLoading && <Loading />}
                <LoginForm {...this.props} onSubmit={this.submitForm} />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isLoading: state.auth.isLoading,
        loginError: state.auth.error,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearAuth,
            login,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
