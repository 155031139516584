export const FETCH_TEMPLATES_FAILURE = "FETCH_TEMPLATES_FAILURE";
export const FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";

export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";
export const FETCH_TEMPLATE_REQUEST = "FETCH_TEMPLATE_REQUEST";
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";

export const CREATE_TEMPLATE_FAILURE = "CREATE_TEMPLATE_FAILURE";
export const CREATE_TEMPLATE_REQUEST = "CREATE_TEMPLATE_REQUEST";
export const CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS";

export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";
export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";

export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";
export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";

export const CLEAR_TEMPLATE_STATE = "CLEAR_TEMPLATE_STATE";
