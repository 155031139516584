import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Col, Loading, Row } from "styleguide";

import { fetchAudiences } from "../actions/audience";
import { fetchPlaylists } from "../actions/playlist";
import { fetchArticles } from "../actions/article";
import { fetchSections } from "../actions/section";
import { fetchSpecialSections, createEditorPick, removeEditorPick, reOrderEditorPick } from "../actions/specialsection";
import ModalAddPlaylist from "../components/ModalAddPlaylist";
import SpecialSection from "../components/SpecialSectionV2";
import { IState } from "../reducers";
import IAudience from "../types/audience";
import IPlaylist from "../types/playlist";
import { Helmet } from "react-helmet";
import { requestAllPlaylistsWithSections } from "../services/playlist";
import ISpecialSection from "src/types/special-section";
import IArticle from "src/types/article";
import { requestAllArticlesWithSections } from "src/services/article";
import ModalAddArticle from "src/components/ModalAddArticles";
import SectionTypes from "src/types/section-types";
import ISection from "src/types/section";

interface IPropsFromState {
    loading: boolean;
    audiences: IAudience[];
    playlists: IPlaylist[];
    articles: IArticle[];
    specialSections: ISpecialSection[];
    sections: ISection[];
}

interface IPropsFromDispatch {
    fetchAudiences: typeof fetchAudiences;
    fetchSpecialSections: typeof fetchSpecialSections;
    fetchPlaylists: typeof fetchPlaylists;
    createEditorPick: typeof createEditorPick;
    fetchArticles: typeof fetchArticles;
    removeEditorPick: typeof removeEditorPick;
    reOrderEditorPick: typeof reOrderEditorPick;
    fetchSections: typeof fetchSections;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public getPlaylists = () => {
        this.props.fetchPlaylists(requestAllPlaylistsWithSections());
    };

    public getArticles = (sectionId: number) => {
        this.props.fetchArticles(requestAllArticlesWithSections(sectionId), true);
    };

    public getSections = () => {
        this.props.fetchSpecialSections();
    };

    public moveUp = (sectionId: number) => (itemId: number) => {
        this.props
            .reOrderEditorPick(sectionId, itemId, "up")
            // @ts-ignore
            .then(() => this.getSections());
    };

    public moveDown = (sectionId: number) => (itemId: number) => {
        this.props
            .reOrderEditorPick(sectionId, itemId, "down")
            // @ts-ignore
            .then(() => this.getSections());
    };

    public addPlaylistToSection = (sectionId: number) => (playlistId: number) => {
        this.props
            .createEditorPick({
                playlistId,
                sectionId,
            })
            // @ts-ignore
            .then(() => this.getSections());
    };

    public addArticleToSection = (sectionId: number) => (articleId: number) => {
        this.props
            .createEditorPick({
                articleId,
                sectionId,
            })
            // @ts-ignore
            .then(() => this.getSections());
    };

    public removeItemFromSection = (sectionId: number) => (itemId: number) => {
        this.props
            .removeEditorPick(sectionId, itemId)
            // @ts-ignore
            .then(() => this.getSections());
    };

    public filterPlaylistsByAlreadyAdded = (playlistsAlreadyAdded: IPlaylist[]) => {
        const { playlists } = this.props;

        return _.filter(playlists, (playlist) => !_.find(playlistsAlreadyAdded, (p) => p.playlistId === playlist.playlistId));
    };

    public filterArticlesByAlreadyAdded = (articlesAlreadyAdded: IArticle[]) => {
        const { articles } = this.props;

        return _.filter(articles, (article) => !_.find(articlesAlreadyAdded, (a) => a.articleID === article.articleID));
    };

    public componentDidMount() {
        this.props.fetchAudiences({ playlists: true });

        this.getSections();

        this.getPlaylists();

        this.props.fetchSections({
            filter: [{ name: "sectionType", value: SectionTypes.Regular }],
        });
    }

    public render() {
        const { loading, audiences, specialSections } = this.props;

        return (
            <Row>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Editor's Picks</title>
                </Helmet>
                {loading && <Loading />}
                {specialSections.map((s) => {
                    const editorialPlaylists = s.playlists || [];
                    const editorialArticles = s.articles || [];

                    const playlistFormParams = {
                        form: `FormModalAddPlaylistToSpecialSection-${s.sectionId}`,
                        onSubmit: this.addPlaylistToSection(s.sectionId),
                        playlists: this.filterPlaylistsByAlreadyAdded(s.playlists || []),
                    };

                    const articleFormParams = {
                        form: `FormModalAddArticleToSpecialSection-${s.sectionId}`,
                        onSubmit: this.addArticleToSection(s.sectionId),
                        articles: this.filterArticlesByAlreadyAdded(s.articles || []),
                        getArticles: this.getArticles,
                        sections: this.props.sections,
                        isLoading: this.props.loading,
                    };

                    return (
                        <Col key={s.sectionId} s={12}>
                            <SpecialSection
                                name={s.sectionName}
                                articles={editorialArticles}
                                audiences={audiences}
                                playlists={editorialPlaylists}
                                removeItem={this.removeItemFromSection(s.sectionId)}
                                moveDown={this.moveDown(s.sectionId)}
                                moveUp={this.moveUp(s.sectionId)}
                            />
                            {/* @ts-ignore */}
                            <ModalAddPlaylist {...playlistFormParams} />
                            <span style={{ marginRight: "15px" }} />
                            {/* @ts-ignore */}
                            <ModalAddArticle {...articleFormParams} />
                            <div style={{ marginBottom: "50px" }} />
                        </Col>
                    );
                })}
            </Row>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        audiences: state.audience.audiences,
        loading: state.audience.isLoading || state.playlist.isLoading || state.specialSection.isLoading || state.article.isLoading,
        playlists: state.playlist.playlists,
        articles: state.article.articles,
        specialSections: state.specialSection.sections,
        sections: state.section.sections,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchAudiences,
            fetchPlaylists,
            fetchSpecialSections,
            reOrderEditorPick,
            createEditorPick,
            fetchArticles,
            removeEditorPick,
            fetchSections,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
