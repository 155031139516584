import * as errors from "../types/errors";
import ICountry from "../types/country";
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS } from "../constants/country";
import { transformCountriesToGroups } from "../utils/country";
import ICountryGroup from "src/types/country-group";

export interface IState {
    isLoading: boolean;
    error?: errors.HttpError;
    countries: ICountry[];
    countriesGrouped: ICountryGroup[];
}

export interface IAction {
    type: string;
    error?: errors.HttpError;
    countries?: ICountry[];
}

export const initialState: IState = {
    isLoading: false,
    countries: [],
    countriesGrouped: [],
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {
    switch (action.type) {

        case FETCH_COUNTRIES_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                countries: action.countries || [],
                countriesGrouped: transformCountriesToGroups(action.countries),
            };
        case FETCH_COUNTRIES_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};
