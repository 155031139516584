import ICountry from "./country";

export enum CountryGroupTitles {
  COMMON = "Most Active",
  EUROPE = "Europe",
  NORTH_AMERICA = "North America",
  SOUTH_AMERICA = "South America",
  ASIA = "Asia",
  AFRICA = "Africa",
  OCEANIA = "Oceania",
  OTHER = "Other",
}

interface ICountryGroup {
  title: CountryGroupTitles;
  countries: ICountry[];
}

export default ICountryGroup;
