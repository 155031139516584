import { IState } from "../reducers";

const f = (endpoint: string, options: any = {}): Promise<Response> => {
    // tslint:disable-next-line:no-string-literal
    const state: IState = window["store"].getState();
    const contentType = options.formData ? {} : { "Content-Type": "application/json" };

    return fetch(endpoint, {
        ...options,
        headers: {
            ...options.headers,
            ...contentType,
            Authorization: state.auth.user ? state.auth.user.token : null,
        },
    });
};

export default f;
