export const CREATE_ADVERT_FAILURE = "CREATE_ADVERT_FAILURE";
export const CREATE_ADVERT_REQUEST = "CREATE_ADVERT_REQUEST";
export const CREATE_ADVERT_SUCCESS = "CREATE_ADVERT_SUCCESS";

export const FETCH_ADVERT_FAILURE = "FETCH_ADVERT_FAILURE";
export const FETCH_ADVERT_REQUEST = "FETCH_ADVERT_REQUEST";
export const FETCH_ADVERT_SUCCESS = "FETCH_ADVERT_SUCCESS";

export const FETCH_ADVERT_LISTENS_FAILURE = "FETCH_ADVERT_LISTENS_FAILURE";
export const FETCH_ADVERT_LISTENS_REQUEST = "FETCH_ADVERT_LISTENS_REQUEST";
export const FETCH_ADVERT_LISTENS_SUCCESS = "FETCH_ADVERT_LISTENS_SUCCESS";

export const FETCH_ADVERTS_FAILURE = "FETCH_ADVERTS_FAILURE";
export const FETCH_ADVERTS_REQUEST = "FETCH_ADVERTS_REQUEST";
export const FETCH_ADVERTS_SUCCESS = "FETCH_ADVERTS_SUCCESS";

export const UPDATE_ADVERT_FAILURE = "UPDATE_ADVERT_FAILURE";
export const UPDATE_ADVERT_REQUEST = "UPDATE_ADVERT_REQUEST";
export const UPDATE_ADVERT_SUCCESS = "UPDATE_ADVERT_SUCCESS";

export const CLEAR_ADVERT_STATE = "CLEAR_ADVERT_STATE";
