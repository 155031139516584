export const FETCH_SPECIAL_SECTIONS_REQUEST = "FETCH_SPECIAL_SECTIONS_REQUEST";
export const FETCH_SPECIAL_SECTIONS_SUCCESS = "FETCH_SPECIAL_SECTIONS_SUCCESS";
export const FETCH_SPECIAL_SECTIONS_FAILURE = "FETCH_SPECIAL_SECTIONS_FAILURE";
export const CREATE_EDITOR_PICK_REQUEST = "CREATE_EDITOR_PICK_REQUEST";
export const CREATE_EDITOR_PICK_SUCCESS = "CREATE_EDITOR_PICK_SUCCESS";
export const CREATE_EDITOR_PICK_FAILURE = "CREATE_EDITOR_PICK_FAILURE";
export const REMOVE_EDITOR_PICK_REQUEST = "REMOVE_EDITOR_PICK_REQUEST";
export const REMOVE_EDITOR_PICK_SUCCESS = "REMOVE_EDITOR_PICK_SUCCESS";
export const REMOVE_EDITOR_PICK_FAILURE = "REMOVE_EDITOR_PICK_FAILURE";
export const REORDER_EDITOR_PICK_REQUEST = "REORDER_EDITOR_PICK_REQUEST";
export const REORDER_EDITOR_PICK_SUCCESS = "REORDER_EDITOR_PICK_SUCCESS";
export const REORDER_EDITOR_PICK_FAILURE = "REORDER_EDITOR_PICK_FAILURE";
