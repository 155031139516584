import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from 'lodash';

import { Button, Clickable, Icon, Table, Td, Th } from "styleguide";
import { yyyymmdd } from "styleguide/build/utils";
import config from "../config";
import IArticle from "../types/article";
import PlaylistTypes from "../types/playlist-types";
import ArticleType from "../types/article-type";

interface IProps {
  articles?: IArticle[];
  moveUp: (articleId: number) => void;
  moveDown: (articleId: number) => void;
  seeMore: () => void;
  total?: number;
  playlistType?: PlaylistTypes;
}

export class ArticleListForPlaylist extends React.Component<IProps> {
  public goToArticle = (article: IArticle) => () => {
    window.open(`${config.paths.article}/${article.articleID}`, "_blank")
  }

  public render() {
    const { articles, total, playlistType } = this.props;

    if (!articles || !total || total < 1 || _.size(articles) < 1) {
      return null;
    }
    const isStory = playlistType === PlaylistTypes.Story;

    return (
      <Table title="Articles">
        <thead>
          <tr>
            <Th size="small">Id</Th>
            {isStory && <Th size="small">Rank</Th>}
            <Th size="big">Name</Th>
            <Th>Publisher</Th>
            <Th size="small">Comprehension</Th>
            <Th size="small">Quality</Th>
            <Th size="small">Timeliness</Th>
            <Th size="small">Polarisation</Th>
            <Th>View Date</Th>
            <Th align="right">Actions</Th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article, index) => {
            const order = _.get(article, 'playlists[0].articleplaylists.order');

            const specialSections = [ArticleType.STORY_INTRO, ArticleType.STORY_OUTRO]

            const firstArticleType = articles[0].articleType;
            const isFirstArticle = index === 0 || (index === 1 && firstArticleType && specialSections.includes(firstArticleType));

            const lastArticleType = articles[articles.length - 1].articleType;
            const isLastArticle = index === articles.length - 1 || (index === articles.length - 2 && lastArticleType && specialSections.includes(lastArticleType));

            const reorderEnabled = !article.articleType || !specialSections.includes(article.articleType)

            return (
              <tr key={article.articleID}>
                <Td>{article.articleID}</Td>
                {isStory && <Td>{order + 1}</Td>}
                <Td>{article.articleName}</Td>
                <Td>{article.newspaper && article.newspaper.newspaperName}</Td>
                <Td>{article.articleComprehension}</Td>
                <Td>{article.articleQuality}</Td>
                <Td>{article.articleTimeliness}</Td>
                <Td>{article.articlePolarisation}</Td>
                <Td>{article.articleViewDateTime ? yyyymmdd(new Date(article.articleViewDateTime)) : '-'}</Td>
                <Td align="right">
                  {!isFirstArticle && reorderEnabled ? (
                    <Clickable className={css(styles.paddingRight)} onClick={() => isStory ? this.props.moveDown(article.articleID) : this.props.moveUp(article.articleID)}>
                      <Icon icon="arrow_upward" />
                    </Clickable>) :
                    <Icon icon="arrow_upward" className={css(styles.iconDisable, styles.paddingRight)} />}
                  {!isLastArticle && reorderEnabled ? (
                    <Clickable className={css(styles.paddingLeft, styles.paddingRight)} onClick={() => isStory ? this.props.moveUp(article.articleID) : this.props.moveDown(article.articleID)}>
                      <Icon icon="arrow_downward" />
                    </Clickable>) :
                    <Icon icon="arrow_downward" className={css(styles.iconDisable, styles.paddingLeft, styles.paddingRight)} />}
                  {article.articleIsPublished === 1 ? (
                    <Icon icon="cloud_done" className={`${css(styles.paddingLeft, styles.paddingRight)} green-text`} />
                  ) : (
                      <Icon icon="cloud_queue" className={`${css(styles.paddingLeft, styles.paddingRight)} blue-text`} />
                    )}
                  <Clickable className={css(styles.paddingLeft)} onClick={this.goToArticle(article)}>
                    <Icon icon="edit" />
                  </Clickable>
                </Td>
              </tr>
            )
          })}
          {articles.length < total && (
            <tr>
              <td colSpan={10} className="center">
                <Button text="SEE MORE" onClick={this.props.seeMore} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}

const styles = StyleSheet.create({
  iconDisable: {
    color: 'grey'
  },
  order: {
    fontSize: 20
  },
  paddingLeft: {
    paddingLeft: 5
  },
  paddingRight: {
    paddingRight: 5
  },
});

export default ArticleListForPlaylist;
