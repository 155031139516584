import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_USER_STATE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
} from "../constants/user";

import { IState } from "../reducers";
import { requestUser } from "../services/user";
import HttpMethods from "../types/http-methods";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";

/*
    ASYNC ACTIONS
*/

export const fetchUsers = (
  params?: IParams,
  userRole?: UserRoles,
  reset?: boolean
) => (dispatch: Dispatch, getState: () => IState): Promise<any> => {
  dispatch({ type: FETCH_USERS_REQUEST });

  const state = getState();

  const url = buildUrl(`${config.api.url}${config.api.paths.user}`, {
    ...params,
    offset: reset ? 0 : state.user.offset
  });

  return fetch(url)
    .then(responseCheck)
    .then(users =>
      fetchUsersSuccess(
        dispatch,
        users,
        state.user.users,
        reset ? reset : false,
        userRole
      )
    )
    .catch(error => dispatch(fetchUsersFailure(error)));
};

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });

  const url = buildUrl(
    `${config.api.url}${config.api.paths.user}/${id}`,
    requestUser()
  );

  return fetch(url)
    .then(responseCheck)
    .then(u => dispatch(fetchUserSuccess(u)))
    .catch(error => dispatch(fetchUserFailure(error)));
};

export const createUser = (user: IUser, history: History) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: CREATE_USER_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.user}`, {
    body: JSON.stringify({ model: user }),
    method: HttpMethods.POST
  })
    .then(responseCheck)
    .then(pron => dispatch(createUserSuccess(pron)))
    .then(() => history.push(config.paths.user))
    .catch(error => dispatch(createUserFailure(error)));
};

export const editUser = (user: IUser, history: History) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: UPDATE_USER_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.user}/${user.userId}`, {
    body: JSON.stringify({ model: user }),
    method: HttpMethods.PUT
  })
    .then(responseCheck)
    .then(() => dispatch(editUserSuccess()))
    .then(() => history.push(config.paths.user))
    .catch(error => dispatch(editUserFailure(error)));
};

/*
    SYNC ACTIONS
*/

export const clearUserState = () => {
  return { type: CLEAR_USER_STATE };
};

const fetchUsersSuccess = (
  dispatch: Dispatch,
  users: IUser[],
  oldUsers: IUser[],
  reset: boolean,
  userRole?: UserRoles
) => {
  const newUsers = !reset ? [...oldUsers, ...users] : users;

  if (userRole && userRole !== UserRoles.INVALID) {
    return dispatch({
      type: FETCH_USERS_SUCCESS,
      userRole,
      users: newUsers
    });
  }

  return dispatch({
    offset: newUsers.length,
    type: FETCH_USERS_SUCCESS,
    users: newUsers
  });
};

const fetchUsersFailure = (error: Error) => {
  toast("Error fetching users");
  return { type: FETCH_USERS_FAILURE, error };
};

const createUserSuccess = (user: any) => {
  toast("User created");
  return { type: CREATE_USER_SUCCESS, user };
};

const createUserFailure = (error: Error) => {
  toast("Error creating user");
  return { type: CREATE_USER_FAILURE, error };
};

const fetchUserSuccess = (user: IUser) => {
  return { type: FETCH_USER_SUCCESS, user };
};

const fetchUserFailure = (error: Error) => {
  toast("Error fetching user");
  return { type: FETCH_USER_FAILURE, error };
};

const editUserSuccess = () => {
  toast("User updated");
  return { type: UPDATE_USER_SUCCESS };
};

const editUserFailure = (error: Error) => {
  toast("Error updating user");
  return { type: UPDATE_USER_FAILURE, error };
};
