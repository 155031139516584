import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { RouteComponentProps } from "react-router-dom";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, ModalConfirm, Row } from "styleguide";

import { updateFile } from "../actions/file";
import IArticle from "../types/article";
import ArticleValue from "../types/article-value";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import { renderError } from "../utils/input";
import InputFile from "./InputFile";
import InputSwitch from "./InputSwitch";
import { getAudioFileWarnings } from "../services/article";
import IFile from "../types/file";
import { countAllWordsInArticleScript } from "../utils/count-words";

interface IProp {
    article?: IArticle;
    error?: errors.HttpError;
    updateFile: typeof updateFile;
}

interface ILocalState {
    interalAudioDuration?: number;
    externalAudioDuration?: number;
}

class ArticleForm extends React.Component<IProp & InjectedFormProps & RouteComponentProps<{}>, ILocalState> {
    constructor(props: any) {
        super(props);
        this.state = { interalAudioDuration: undefined, externalAudioDuration: undefined };
    }

    public getAudioFileWarnings = () => {
        return getAudioFileWarnings(this.props.article, this.getInternalAudioLength(), this.getExternalAudioLength());
    };

    public updateFile = (id: string, file: IFile, type: FileType, duration?: number): any => {
        if (id === "articleAudioFileName") {
            this.setState({ interalAudioDuration: duration });
        } else {
            this.setState({ externalAudioDuration: duration });
        }

        return this.props.updateFile(id, file, type, duration);
    };

    public renderBadges = () => {
        const { article } = this.props;

        if (!article) {
            return null;
        }

        const published = article.articleIsPublished !== 0;
        const newspaperName = article.newspaper ? article.newspaper.newspaperName : "";
        const articleValue = article.articleValue ? ArticleValue[article.articleValue] : "-";

        return (
            <>
                <span className={`${css(styles.firstBadge)} new orange badge left`} data-badge-caption={`ID: ${article.articleID}`} />
                <span className={`new blue badge left`} data-badge-caption={newspaperName} />
                <span className="new blue badge left lighten-2" data-badge-caption="words">
                    {countAllWordsInArticleScript(article)}
                </span>
                {article.articleValue !== 1 && <span className="blue new badge left" data-badge-caption={articleValue} />}
                <span className={`${published ? "green" : "red"} new badge left modal-trigger`} data-badge-caption={published ? "PUBLISHED" : "UNPUBLISHED"} />
            </>
        );
    };

    public getModalConfirmText = () => {
        const warnings = this.getAudioFileWarnings();

        return (
            <div>
                {warnings.showWarning && <h5 className={css(styles.modalWarningHeading)}>⚠ WARNING - CRITICAL AUDIO FILE ISSUES FOUND</h5>}
                {warnings.internalAudioMissing && <p className={css(styles.modalWarning)}>✖ On-platform audio file is missing.</p>}
                {warnings.externalAudioMissing && <p className={css(styles.modalWarning)}>✖ Off-platform audio file is missing.</p>}
                {/* {warnings.audioLengthMismatch && (
                    <p className={css(styles.modalWarning)}>✖ Incorrect audio length: The off-platform audio file has a shorter duration than the on-platform audio file.</p>
                )} */}
            </div>
        );
    };

    public getInternalAudioLength = (): number | undefined => {
        const length = this.state.interalAudioDuration || this.props.article?.articleAudioLength;
        if (length) {
            return +length;
        }
        return undefined;
    };

    public getExternalAudioLength = () => {
        const length = this.state.externalAudioDuration || this.props.article?.articleExternalAudioLength;
        if (length) {
            return +length;
        }
        return undefined;
    };

    public getPublishIssues = () => {
        const { article } = this.props;
        const warnings = this.getAudioFileWarnings();

        return (
            <div>
                {warnings.showWarning && <h5 className={css(styles.publishWarningHeading)}>⚠ Publish disabled due to the following issues:</h5>}
                {warnings.internalAudioMissing && <p className={css(styles.publishWarning)}>✖ On-platform audio file is missing.</p>}
                {warnings.externalAudioMissing && <p className={css(styles.publishWarning)}>✖ Off-platform audio file is missing.</p>}
                {/* {warnings.audioLengthMismatch && (
                    <p className={css(styles.publishWarning)}>✖ Incorrect audio length: The off-platform audio file has a shorter duration than the on-platform audio file.</p>
                )} */}
                {!article?.articleImageFileName && <p className={css(styles.publishWarning)}>✖ Article image file is missing.</p>}
            </div>
        );
    };

    public render() {
        const { article, handleSubmit, valid } = this.props;

        if (!article) {
            return null;
        }

        const warnings = this.getAudioFileWarnings();
        const shouldDisable = warnings.showWarning || !article.articleImageFileName;

        // @ts-ignore
        const articleAudioType = article ? +article.articleAudioType?.valueOf() : 3;

        const showInternalAudioField = articleAudioType === 0 || articleAudioType === 2;
        const showExternalAudioField = articleAudioType === 1 || articleAudioType === 2;

        return (
            <ModalConfirm title="Save Confirmation" description={this.getModalConfirmText()}>
                {(confirm: any) => (
                    <form onSubmit={confirm(handleSubmit)}>
                        <Row>
                            <Col s={12}>
                                <span className={`${css([styles.fullWidth, styles.title])} card-title`}>{article.articleName}</span>
                            </Col>
                            <Col s={12}>{this.renderBadges()}</Col>

                            <Col s={12}>
                                <h5 className={css(styles.subtitle)}>Published?</h5>
                                <InputSwitch name="articleIsPublished" disabled={shouldDisable} />
                                {shouldDisable && this.getPublishIssues()}
                            </Col>

                            <Col s={12}>
                                <h5 className={css(styles.subtitle)}>Audio</h5>
                            </Col>
                            {showInternalAudioField && (
                                <Col s={12}>
                                    <p className={css(styles.subheading)}>On-Platform Audio</p>
                                    <InputFile
                                        model="articles"
                                        id="articleAudioFileName"
                                        upFile={this.updateFile}
                                        type={FileType.audio}
                                        change={this.props.change}
                                        value={article && article.articleAudioFileName}
                                        audioBitrateCheck
                                    />
                                </Col>
                            )}
                            {showExternalAudioField && (
                                <Col s={12}>
                                    <p className={css(styles.subheading)}>Off-Platform Audio</p>
                                    <InputFile
                                        model="articles"
                                        id="articleExternalAudioFileName"
                                        upFile={this.updateFile}
                                        type={FileType.audio}
                                        change={this.props.change}
                                        value={article && article.articleExternalAudioFileName}
                                        audioBitrateCheck
                                    />
                                </Col>
                            )}

                            <Col s={12}>
                                <Field name="error" type="hidden" component={renderError} />
                            </Col>

                            <Col s={12}>
                                <div className="center">
                                    <Button text="save" icon="save" submit={true} disable={!valid} />
                                </div>
                            </Col>
                        </Row>
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    publishWarningHeading: {
        fontSize: 14,
        marginTop: 30,
        fontWeight: 600,
        color: "red",
    },
    publishWarning: {
        fontSize: 14,
        marginTop: 10,
        fontWeight: 400,
        color: "red",
    },
    modalWarningHeading: {
        fontSize: 20,
        marginBottom: 20,
        fontWeight: 600,
        color: "red",
    },
    modalWarning: {
        color: "red",
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 400,
    },
    firstBadge: {
        marginLeft: 0,
    },
    fullWidth: {
        width: "100%",
    },
    published: {
        paddingTop: 15,
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingTop: 20,
    },
    subheading: {
        fontSize: 13,
        fontWeight: 300,
        paddingTop: 20,
    },
    title: {
        overflowWrap: "break-word",
        margin: 0,
    },
    id: {
        fontSize: 16,
        fontWeight: 300,
        margin: 0,
    },
    viewArticle: {
        lineHeight: "56px",
    },
});

export default reduxForm({
    enableReinitialize: true,
    form: "ArticleForm",
})(ArticleForm);
