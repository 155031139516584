import * as errors from "../types/errors";

import {
    FETCH_ARTICLE_TEMPLATES_FAILURE,
    FETCH_ARTICLE_TEMPLATES_REQUEST,
    FETCH_ARTICLE_TEMPLATES_SUCCESS,
    CREATE_ARTICLE_TEMPLATE_SUCCESS,
    CREATE_ARTICLE_TEMPLATE_FAILURE,
    CREATE_ARTICLE_TEMPLATE_REQUEST,
    DELETE_ARTICLE_TEMPLATE_REQUEST,
    DELETE_ARTICLE_TEMPLATE_SUCCESS,
    DELETE_ARTICLE_TEMPLATE_FAILURE
} from "../constants/articletemplates";
import IArticleTemplate from "../types/article-template";
import _ from "lodash";

export interface IState {
    articleTemplates: IArticleTemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
}

export interface IAction {
    type: string;
    articleTemplates?: IArticleTemplate[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    articleTemplates: [],
    isLoading: false,
    error: undefined,
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {

    switch (action.type) {
        case FETCH_ARTICLE_TEMPLATES_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_ARTICLE_TEMPLATES_SUCCESS:
            const templatesCloned = _.cloneDeep(action.articleTemplates);

            return {
                ...state,
                articleTemplates: templatesCloned || [],
                isLoading: false,
            };
        case FETCH_ARTICLE_TEMPLATES_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case CREATE_ARTICLE_TEMPLATE_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case CREATE_ARTICLE_TEMPLATE_SUCCESS:
            return { ...state, isLoading: false };
        case CREATE_ARTICLE_TEMPLATE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case DELETE_ARTICLE_TEMPLATE_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case DELETE_ARTICLE_TEMPLATE_SUCCESS:
            return { ...state, isLoading: false, error: undefined };
        case DELETE_ARTICLE_TEMPLATE_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};
