import INewspaper from "../types/newspaper";
import { IParams } from "../utils/build-url";

export const requestAllNewspapers = (): IParams => {
  return {
    orderBy: "ASC",
    sortBy: "newspaperName"
  };
};

export const createDefaultNewspaper = (): INewspaper => {
  return {
    geographyID: 102,
    languageID: 38,
    newspaperDailyFreeListens: 2,
    newspaperDisplayOrder: 1,
    newspaperID: 0,
    newspaperInfo: "",
    newspaperIsPublished: 1,
    newspaperName: "",
    newspaperPermitsFreeListens: 1,
    newspaperUseFeaturedArticle: 0
  };
};
