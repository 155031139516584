import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, Modal, Row } from "styleguide";
import IArticle from "src/types/article";
import ISection from "src/types/section";
import ReactSelect from "react-select";
import { ReactSelectStyles } from "src/utils/react-select";

interface IProps {
    articles: IArticle[];
    getArticles: (sectionId: number) => void;
    sections: ISection[];
    isLoading: boolean;
}

interface IState {
    open: boolean;
    selectedSection?: number;
    selectedSubSection?: number;
    availableSubSections: { label: string; value: number }[];
}

export class ModalAddArticle extends React.Component<IProps & InjectedFormProps<{}, IProps>, IState> {
    constructor(props: IProps & InjectedFormProps<{}, IProps>) {
        super(props);

        this.state = { open: false, availableSubSections: [] };
    }

    public close = () => {
        this.props.reset();
        this.setState({ open: false });
    };

    public changeSection = (newValue: number) => {
        const { sections } = this.props;

        this.setState((prev) => ({ ...prev, selectedSection: newValue, selectedSubSection: undefined }));

        const subSections = _.chain(sections)
            .filter((s) => s.parentId === Number(newValue))
            .map((s) => ({ label: s.sectionName, value: s.sectionId }))
            .sortBy("text")
            .value();

        this.setState((prev) => ({ ...prev, availableSubSections: [{ label: "All", value: -1 }, ...subSections] }));
    };

    public changeSubSection = (newValue: number) => {
        this.setState((prev) => ({ ...prev, selectedSubSection: newValue }));

        if (newValue === -1) {
            this.props.getArticles(this.state.selectedSection!);
        } else {
            this.props.getArticles(newValue);
        }
    };

    public onSubmit = (event: any) => {
        this.props.handleSubmit((values) => {
            // @ts-ignore
            if (values.articleID) {
                // @ts-ignore
                this.props.onSubmit(+values.articleID);
            }

            this.close();
        })(event);
    };

    public transformArticles = () => {
        const { articles, isLoading } = this.props;
        const { selectedSection, selectedSubSection } = this.state;

        if (!selectedSection || !selectedSubSection || isLoading) return [];

        return _.chain(articles)
            .map((a) => ({ label: a.articleName, value: a.articleID }))
            .sortBy((a) => a.label)
            .value();
    };

    public transformSections = () => {
        const { sections } = this.props;

        return _.chain(sections)
            .filter((s) => s.parentId === null)
            .map((s) => ({ label: s.sectionName, value: s.sectionId }))
            .sortBy("text")
            .value();
    };

    public render() {
        const { change } = this.props;
        const { open, availableSubSections, selectedSection } = this.state;

        const articlesTransformed = this.transformArticles();
        const sectionsTransformed = this.transformSections();

        return (
            <>
                {open && (
                    <Modal title="Add Article" className={css(styles.modal)} close={this.close}>
                        <Row>
                            <Col s={6}>
                                <label>Categories</label>
                                <ReactSelect
                                    styles={ReactSelectStyles}
                                    name="sections"
                                    placeholder="Select Category"
                                    options={sectionsTransformed}
                                    onChange={(section) => section && this.changeSection(section.value)}
                                />
                            </Col>
                            <Col s={6}>
                                <label>Sub-Categories</label>
                                <ReactSelect
                                    styles={ReactSelectStyles}
                                    name="sections"
                                    placeholder="Select Sub-category"
                                    options={availableSubSections}
                                    key={`add_article_react_select_${selectedSection}`}
                                    onChange={(section) => section && this.changeSubSection(section.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col s={12}>
                                <label>Articles</label>
                                <ReactSelect
                                    styles={ReactSelectStyles}
                                    name="articleID"
                                    placeholder={this.props.isLoading ? "Loading..." : "Select from articles"}
                                    options={articlesTransformed}
                                    onChange={(article) => article && change("articleID", article.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col s={12}>
                                <div className={css(styles.div)}>
                                    <Button text="Add Article" disable={this.props.isLoading} onClick={this.onSubmit} />
                                </div>
                            </Col>
                        </Row>
                    </Modal>
                )}

                <Button text="Add Article" onClick={() => this.setState({ open: true, selectedSection: undefined })} />
            </>
        );
    }
}

const styles = StyleSheet.create({
    div: {
        textAlign: "center",
    },
    empty: {
        paddingTop: 100,
    },
    emptyText: {
        fontSize: "18px",
    },
    modal: {
        overflow: "initial",
    },
});

export default reduxForm<{}, IProps>({
    enableReinitialize: true,
})(ModalAddArticle);
