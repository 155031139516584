import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { fetchCompanies } from "../../../actions/company";
import { fetchCountries } from "../../../actions/country";
import ICompany from "../../../types/company";

import { updateFile } from "../../../actions/file";
import { fetchUsers } from "../../../actions/user";
import { IState } from "../../../reducers";
import {
    createDefaultAdvert,
} from "../../../services/advert";
import IAdvert from "../types/advert";
import * as errors from "../../../types/errors";
import IUser from "../../../types/user";
import { correctValue } from "../../../utils/values";
import AdvertEditor from "../components/AdvertEditor";
import ICountryGroup from "src/types/country-group";
import { clearAdvertState, createAdvert, editAdvert, fetchAdvert } from "../state/actions";

interface IPropsFromState {
    error?: errors.HttpError;
    advert: IAdvert;
    isLoading: boolean;
    narrators: IUser[];
    companies: ICompany[];
    countriesGrouped: ICountryGroup[];
    user?: IUser;
}

interface IPropsFromDispatch {
    clearAdvertState: typeof clearAdvertState;
    createAdvert: typeof createAdvert;
    editAdvert: typeof editAdvert;
    fetchAdvert: typeof fetchAdvert;
    fetchUsers: typeof fetchUsers;
    updateFile: typeof updateFile;
    fetchCompanies: typeof fetchCompanies;
    fetchCountries: typeof fetchCountries;
}

class AdvertEditorContainer extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {

    public render() {
        const { match } = this.props;

        return (
            <AdvertEditor
                {...this.props}
                paramId={match && match.params ? +match.params.id : undefined}
            />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { AdvertForm: advertForm },
        advert: { advert },
    } = state;

    const advertValues = advertForm ? advertForm.values : {};

    const preAdvert = correctValue("advertID", advertValues, advert);
    const validatedAdvert = preAdvert ? preAdvert : createDefaultAdvert();

    return {
        advert: validatedAdvert,
        error: state.advert.error,
        isLoading:
            state.advert.isLoading ||
            state.user.isLoading,
        narrators: state.user.narrators,
        companies: state.company.companies,
        countriesGrouped: state.country.countriesGrouped,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearAdvertState,
            createAdvert,
            editAdvert,
            fetchAdvert,
            fetchUsers,
            updateFile,
            fetchCompanies,
            fetchCountries
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvertEditorContainer));
