import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Clickable, Col, Icon, ModalConfirm, Table, Td, Th } from "styleguide";

import IArticle from "../types/article";
import { yyyymmdd } from "../utils/date";

interface IProps {
    title: string;
    articles?: IArticle[];
    goToAudio: (filename?: string) => void;
    togglePublishArticle: (article: IArticle) => void;
}

export class ArticleListForEditors extends React.Component<IProps> {
    public render() {
        const { articles, goToAudio, togglePublishArticle, title } = this.props;

        return (
            <Col s={12}>
                <Table title={title}>
                    <thead>
                        <tr>
                            <Th>Name</Th>
                            <Th hide="tablet">Narrator</Th>
                            <Th hide="mobile">Created At</Th>
                            <Th align="right">Actions</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles &&
                            articles.map((article) => {
                                const articleAudio = article.articleExternalAudioFileName || article.articleAudioFileName;

                                return (
                                    <tr key={article.articleID}>
                                        <Td>{article.articleName}</Td>
                                        <Td hide="tablet">{article.articleReader && article.articleReader.userFirstName}</Td>
                                        <Td hide="mobile">{article.articleAddedDateTime ? yyyymmdd(new Date(article.articleAddedDateTime)) : "-"}</Td>

                                        <Td align="right">
                                            <Clickable className={`${css(styles.icon)} hide-on-med-and-down`} onClick={() => goToAudio(articleAudio)}>
                                                <Icon icon="audiotrack" className={articleAudio ? "blue-text" : "black-text"} />
                                            </Clickable>
                                            <ModalConfirm title="Confirmation" description={`Are you sure that you want ${article.articleIsPublished === 1 ? "unpublish" : "publish"} this article?`}>
                                                {(confirm: any) => (
                                                    <Clickable className={css(styles.icon)} onClick={confirm(() => togglePublishArticle(article))}>
                                                        {article.articleIsPublished === 1 ? (
                                                            <Icon icon="cloud_done" className="green-text" tooltip="Unpublish" />
                                                        ) : (
                                                            <Icon icon="cloud_off" className="blue-text" tooltip="Publish" />
                                                        )}
                                                    </Clickable>
                                                )}
                                            </ModalConfirm>
                                        </Td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </Col>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        "@media (max-width: 600px)": {
            paddingLeft: 5,
            paddingRight: 5,
        },
        paddingLeft: 10,
        paddingRight: 10,
    },
});

export default ArticleListForEditors;
