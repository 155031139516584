import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { fetchAudiences } from "../actions/audience";
import { fetchCompanies } from "../actions/company";
import { updateFile } from "../actions/file";
import { clearPlaylistState, createPlaylist, editPlaylist, fetchPlaylist } from "../actions/playlist";
import { fetchSections } from "../actions/section";
import PlaylistForm from "../components/PlaylistForm";
import { IState } from "../reducers";
import { createDefaultPlaylist, createDefaultPlaylistNestedProps } from "../services/playlist";
import IAudience from "../types/audience";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import IPlaylist from "../types/playlist";
import ISection from "../types/section";
import SectionTypes from "../types/section-types";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import INewspaper from "../types/newspaper";
import { fetchNewspaper } from "../actions/newspaper";
import { requestAllCompanies } from "../services/company";
interface IPropsFromState {
    error?: errors.HttpError;
    playlist: IPlaylist;
    isLoading: boolean;
    sections: ISection[];
    companies: ICompany[];
    audiences: IAudience[];
    newspaper?: INewspaper;
}

interface IPropsFromDispatch {
    clearPlaylistState: typeof clearPlaylistState;
    createPlaylist: typeof createPlaylist;
    editPlaylist: typeof editPlaylist;
    fetchPlaylist: typeof fetchPlaylist;
    fetchSections: typeof fetchSections;
    updateFile: typeof updateFile;
    fetchCompanies: typeof fetchCompanies;
    fetchAudiences: typeof fetchAudiences;
    fetchNewspaper: typeof fetchNewspaper;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { audiences, companies, playlist, sections, newspaper } = this.props;

        const params = {
            audiences,
            companies,
            newspaper,
            initialValues: {
                ...playlist,
                ...createDefaultPlaylistNestedProps(playlist),
                error: this.props.error,
            },
            onSubmit: this.submitForm,
            playlist,
            sections,
            updateFile: this.props.updateFile,
        };

        return (
            <Col s={12} xl={8} xlOffset={2}>
                <Card title="Playlist">
                    <PlaylistForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (playlist: IPlaylist | any) => {
        const { playlist: oldPlaylist, history, sections } = this.props;

        const playlistTyped = playlist as IPlaylist;

        const sectionIds = playlistTyped.sectionsId || [];
        const subSectionIds = playlistTyped.sectionsIdx || [];
        const subSections = sections ? sections.filter((s) => subSectionIds.includes(s.sectionId.toString())) : [];

        // Only save sub sections that are a child of a selected section
        const subSectionIdsFiltered = subSections.filter((s) => s.parentId && sectionIds.includes(s.parentId.toString())).map((s) => s.sectionId.toString());

        const combinedSectionIds = [...sectionIds, ...subSectionIdsFiltered];

        playlistTyped.sectionsId = combinedSectionIds;
        playlistTyped.tagsId = [
            ...(playlist.tagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: false })),
            ...(playlist.displayTagsId || []).map((t: any) => ({ tagName: t.tagName, tagId: t.tagId, isDisplayTag: true })),
        ];

        if (oldPlaylist && oldPlaylist.playlistId) {
            return this.props.editPlaylist(playlistTyped, history);
        }
        return this.props.createPlaylist(playlistTyped, history);
    };

    public componentWillUnmount() {
        this.props.clearPlaylistState();
    }

    public componentDidMount() {
        const { match } = this.props;

        this.props.fetchSections({
            filter: [
                { name: "sectionIsPublished", value: 1 },
                { name: "sectionType", value: SectionTypes.Regular },
            ],
        });
        this.props.fetchCompanies(requestAllCompanies());
        this.props.fetchAudiences();
        this.props.fetchNewspaper(78);

        if (match.params.id) {
            this.props.fetchPlaylist(+match.params.id);
        }
    }

    public render() {
        const title = `Playlist Editor | ${this.props.playlist && this.props.playlist.playlistName ? this.props.playlist.playlistName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { PlaylistForm: playlistForm },
        playlist: { playlist },
    } = state;

    const playlistValues = playlistForm ? playlistForm.values : {};

    const prePlaylist = correctValue("playlistId", playlistValues, playlist);
    const validatedPlaylist = prePlaylist ? prePlaylist : createDefaultPlaylist();

    return {
        audiences: state.audience.audiences,
        companies: state.company.companies,
        error: state.playlist.error,
        isLoading: state.playlist.isLoading,
        playlist: validatedPlaylist,
        sections: state.section.sections,
        newspaper: state.newspaper.newspaper,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearPlaylistState,
            createPlaylist,
            editPlaylist,
            fetchAudiences,
            fetchCompanies,
            fetchPlaylist,
            fetchSections,
            updateFile,
            fetchNewspaper,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
