import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { IState } from "../../../../reducers";
import { HttpError } from "../../../../types/errors";
import { editSetting, fetchSetting } from "../../state/actions";
import ISetting from "../../types/ISetting";
import SettingsFormWrapper from "../components/SettingsFormWrapper";

interface IPropsFromState {
    setting?: ISetting;
    error?: HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchSetting: typeof fetchSetting;
    editSetting: typeof editSetting;
}

class SettingsFormContainer extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {

    public render() {
        const { match } = this.props;

        return (
            // @ts-ignore
            <SettingsFormWrapper
                {...this.props}
                paramId={match && match.params ? +match.params.id : undefined} />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {

    return {
        setting: state.settings.setting,
        error: state.settings.error,
        isLoading: state.settings.isLoading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            fetchSetting,
            editSetting,
        },
        dispatch
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SettingsFormContainer)
);
