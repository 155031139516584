export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const FETCH_SETTINGS_REQUEST = "FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";

export const FETCH_SETTING_FAILURE = "FETCH_SETTING_FAILURE";
export const FETCH_SETTING_REQUEST = "FETCH_SETTING_REQUEST";
export const FETCH_SETTING_SUCCESS = "FETCH_SETTING_SUCCESS";

export const EDIT_SETTING_REQUEST = "EDIT_SETTING_REQUEST";
export const EDIT_SETTING_SUCCESS = "EDIT_SETTING_SUCCESS";
export const EDIT_SETTING_FAILURE = "EDIT_SETTING_FAILURE";