import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { reducers } from "../reducers";
import { initUserContext, logoutOnUnauthenticated } from "./middlewares";

const persistConfig = {
    blacklist: [
        "advert",
        "form",
        "file",
        "article",
        "search",
        "newspaper",
        "category",
        "user",
        "tag",
        "metric",
        "pronunciation",
        "company",
        "journalist",
        "playlist",
        "section",
        "template",
        "suggestion",
        "audience",
        "specialSection",
        "voiceRequirements",
    ],
    key: "root",
    storage,
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
    const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, logoutOnUnauthenticated, initUserContext)));
    const persistor = persistStore(store);

    return { store, persistor };
};
