enum ArticleValue {
    SINGLE = 1,
    DOUBLE = 2,
    TRIPLE = 3,
    QUADRUPLE = 4,
    QUINTUPLE = 5,
    SEXTUPLE = 6,
    SEPTUPLE = 7,
    OCTUPLE = 8,
    NONUPLE = 9,
}

export default ArticleValue;
