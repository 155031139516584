import * as errors from "../types/errors";

import {
  FETCH_AUDIENCES_FAILURE,
  FETCH_AUDIENCES_REQUEST,
  FETCH_AUDIENCES_SUCCESS,
} from "../constants/audience";
import IAudience from "../types/audience";

export interface IState {
  audiences: IAudience[];
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  audiences?: IAudience[];
  error?: errors.HttpError;
}

export const initialState: IState = {
  audiences: [],
  isLoading: false
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_AUDIENCES_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_AUDIENCES_SUCCESS:
      return {
        ...state,
        audiences: action.audiences || [],
        isLoading: false
      };
    case FETCH_AUDIENCES_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
