import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import AnimateHeight from "react-animate-height";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, ButtonFixed, ButtonFlat, Card, Col, InputField, InputSelect, Row } from "styleguide";
import DatePicker from "react-datepicker";
import ISearch from "../types/search";
import { jQuery } from "../utils/jquery";
import { yyyymmdd } from "src/utils/date";

interface IState {
    open: boolean;
}

interface IValue {
    value: string;
    text: string;
}

export interface IOption {
    value: string;
    text: string;
    values?: IValue[];
    customComponents?: (change: InjectedFormProps["change"], search: ISearch | any) => React.ReactElement[];
    skipValues?: boolean;
    placeholder?: string;
}

interface IProp {
    search: ISearch;
    options: IOption[];
}

export class DynamicSearchForm extends React.Component<InjectedFormProps<{}, IProp> & IProp, IState> {
    constructor(props: InjectedFormProps<{}, IProp> & IProp) {
        super(props);

        this.state = { open: false };
    }

    public open = () => {
        this.setState({ open: !this.state.open });
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    };

    public submit = (e: any) => {
        this.setState({ open: false });
        this.props.handleSubmit(e);
    };

    public changeSearchOption = (name: string, value: any): void => {
        console.log("ran=", name, value);
        this.props.change("text", "");
        return this.props.change(name, value);
    };

    public onChangeSearchOption = () => {
        this.props.change("text", "");
    };

    public clear = () => {
        window.location.reload(false);
    };

    public renderCorrectOption = () => {
        const { change, options, search } = this.props;

        const optionId = search && search.option ? search.option : "0";
        const searchOption = _.find(options, (o) => o.value === optionId);
        const showSelected = searchOption && searchOption.values;
        const values = searchOption && searchOption.values ? searchOption.values : [];

        return (
            <React.Fragment>
                {!searchOption?.skipValues && (
                    <div className="col s12 input-field">
                        <div className={showSelected ? "" : "hide"}>
                            <InputSelect rows={values} name="text" change={change} placeholder={searchOption?.placeholder || "Select One"} />
                        </div>
                        <div className={showSelected ? "hide" : ""}>
                            <label htmlFor="dynamic-search-form-value">Value</label>
                            <Field name="text" type="text" component="input" id="dynamic-search-form-value" />
                        </div>
                    </div>
                )}

                {searchOption?.customComponents &&
                    searchOption.customComponents(change, search).map((c, i) => (
                        <div className="col s12 input-field" key={i}>
                            {c}
                        </div>
                    ))}
            </React.Fragment>
        );
    };

    public render() {
        const { open } = this.state;
        const { change, options } = this.props;

        return (
            <React.Fragment>
                <ButtonFixed icon="clear" onClick={this.open} className={open ? "visible" : "hidden"} bottom={100} />
                <ButtonFixed icon="search" onClick={this.open} className={!open ? "visible" : "hidden"} bottom={100} />
                <AnimateHeight height={open ? "auto" : 0}>
                    <Col s={12}>
                        <div className={css(styles.container)}>
                            <form onSubmit={this.submit}>
                                <Card title="Search">
                                    <Row>
                                        <Col s={12}>
                                            <InputField>
                                                <InputSelect rows={options} name="option" onChange={this.onChangeSearchOption} change={this.changeSearchOption} />
                                            </InputField>
                                        </Col>

                                        {this.renderCorrectOption()}

                                        <Col s={6}>
                                            <DatePicker
                                                onChange={(date) => change("fromDate", yyyymmdd(date!))}
                                                selected={this.props.search?.fromDate ? new Date(this.props.search.fromDate) : null}
                                                dateFormat="dd-MM-yy"
                                                placeholderText="From (Created At)"
                                            />
                                        </Col>

                                        <Col s={6}>
                                            <DatePicker
                                                onChange={(date) => change("toDate", yyyymmdd(date!))}
                                                selected={this.props.search?.toDate ? new Date(this.props.search.toDate) : null}
                                                dateFormat="dd-MM-yy"
                                                placeholderText="To (Created At)"
                                            />
                                        </Col>

                                        <Col s={6}>
                                            <div className="center">
                                                <Button text="Search" icon="search" submit={true} />
                                            </div>
                                        </Col>
                                        <Col s={6}>
                                            <div className="center">
                                                <ButtonFlat text="Clear" onClick={this.clear} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </form>
                        </div>
                    </Col>
                </AnimateHeight>
            </React.Fragment>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        margin: "auto",
        maxWidth: 390,
        width: "100%",
    },
    insideForm: {
        marginBottom: 0,
    },
});

export default reduxForm<{}, IProp>({
    enableReinitialize: true,
    form: "DynamicSearchForm",
})(DynamicSearchForm);
