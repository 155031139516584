import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
  Button,
  Col,
  InputField,
  InputSelect,
  ModalConfirm,
  Row,
} from "styleguide";

import * as errors from "../types/errors";
import ITemplate from "../types/template";
import TemplateTypes from "../types/template-types";
import { renderError, renderInput } from "../utils/input";
import { required } from "../utils/validations";

const templateTypes = [
  { value: TemplateTypes.INTRO, text: TemplateTypes[TemplateTypes.INTRO] },
  { value: TemplateTypes.OUTRO, text: TemplateTypes[TemplateTypes.OUTRO] },
];

interface IProp {
  template?: ITemplate;
  error?: errors.HttpError;
}

export class TemplateForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { change, handleSubmit, template, valid } = this.props;

    if (!template) {
      return null;
    }

    const templateTypeId = template ? template.templateTypeId : "";

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this tag?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12} m={6}>
                    <InputField>
                      <label htmlFor="templateName">Name *</label>
                      <Field
                        name="templateName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12} m={6}>
                    <InputField>
                      <InputSelect
                        placeholder="Select One Type"
                        rows={templateTypes}
                        name="templateTypeId"
                        change={change}
                        value={templateTypeId}
                      />
                      <label>Type</label>
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="textarea1">Text</label>
                      <Field
                        name="templateText"
                        component="textarea"
                        className={`${css(
                          styles.textarea
                        )} materialize-textarea`}
                      />
                    </InputField>
                  </Col>
                </Row>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 20,
    fontWeight: 300,
    paddingTop: 20,
  },
  textarea: {
    height: 200,
    overflowY: "auto",
    resize: "vertical",
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "TemplateForm",
})(TemplateForm);
