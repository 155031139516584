import * as React from "react";

import { css, StyleSheet } from "aphrodite";

import { formError } from "./form-errors";

export const renderError = (field: any) => {
    const errorObj = field.input.value;

    if (!errorObj) {
        return null;
    }

    const errors = formError(errorObj.data);

    return (
        <ul>
            {errors.map(e => (
                <li key={e} className={css(styles.error)}>
                    {e}
                </li>
            ))}
        </ul>
    );
};

export const renderInput = (field: any) => {
    const error = field.meta.touched && field.meta.error;

    return (
        <React.Fragment>
            <input
                {...field.input}
                disabled={field.disabled}
                id={field.input.name}
                type={field.type}
                className={`${css(styles.input)} ${error ? "invalid" : ""}`}
                placeholder={field.placeholder}
            />
            {error && <span className={css(styles.error)}>{field.meta.error}</span>}
        </React.Fragment>
    );
};

export const renderSelect = (field: any) => {
    const error = field.meta.touched && field.meta.error;

    return (
        <React.Fragment>
            <select {...field.input} style={field.style} multiple={field.multiple}>
                {field.children}
            </select>
            {error && <span className={css(styles.error)}>{field.meta.error}</span>}
        </React.Fragment>
    );
};

export const required = (value: any) => (value ? undefined : "Required");

const styles = StyleSheet.create({
    error: {
        color: "#F44336",
        fontSize: 12
    },
    input: {
        marginBottom: 0
    },
    select: {
        marginBottom: 20
    }
});
