import * as errors from "../types/errors";

import { IPodcastPlatform } from "src/types/podcast-platforms";

import { FETCH_PODCAST_PLATFORMS_FAILURE, FETCH_PODCAST_PLATFORMS_REQUEST, FETCH_PODCAST_PLATFORMS_SUCCESS } from "src/constants/podcastplatforms";

export interface IState {
    platforms: IPodcastPlatform[];
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    platforms?: IPodcastPlatform[];
    error?: errors.HttpError;
}

export const initialState: IState = {
    platforms: [],
    isLoading: false,
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case FETCH_PODCAST_PLATFORMS_REQUEST:
            return { ...state, isLoading: true, error: undefined };

        case FETCH_PODCAST_PLATFORMS_SUCCESS:
            return {
                ...state,
                platforms: action.platforms || [],
                isLoading: false,
            };
        case FETCH_PODCAST_PLATFORMS_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        default:
            return state;
    }
};
