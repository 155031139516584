export const FETCH_NEWSPAPER_FAILURE = "FETCH_NEWSPAPER_FAILURE";
export const FETCH_NEWSPAPER_REQUEST = "FETCH_NEWSPAPER_REQUEST";
export const FETCH_NEWSPAPER_SUCCESS = "FETCH_NEWSPAPER_SUCCESS";

export const FETCH_NEWSPAPERS_FAILURE = "FETCH_NEWSPAPERS_FAILURE";
export const FETCH_NEWSPAPERS_REQUEST = "FETCH_NEWSPAPERS_REQUEST";
export const FETCH_NEWSPAPERS_SUCCESS = "FETCH_NEWSPAPERS_SUCCESS";

export const CLEAR_NEWSPAPER_STATE = "CLEAR_NEWSPAPER_STATE";

export const CREATE_NEWSPAPER_FAILURE = "CREATE_NEWSPAPER_FAILURE";
export const CREATE_NEWSPAPER_REQUEST = "CREATE_NEWSPAPER_REQUEST";
export const CREATE_NEWSPAPER_SUCCESS = "CREATE_NEWSPAPER_SUCCESS";

export const UPDATE_NEWSPAPER_FAILURE = "UPDATE_NEWSPAPER_FAILURE";
export const UPDATE_NEWSPAPER_REQUEST = "UPDATE_NEWSPAPER_REQUEST";
export const UPDATE_NEWSPAPER_SUCCESS = "UPDATE_NEWSPAPER_SUCCESS";
