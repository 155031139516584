import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, InputField, ModalConfirm, Row } from "styleguide";

import * as errors from "../types/errors";
import ITag from "../types/tag";
import { renderError, renderInput } from "../utils/input";
import { required } from "../utils/validations";
import { InputSwitch } from "./InputSwitch";

interface IProp {
  tag?: ITag;
  error?: errors.HttpError;
}

export class TagForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { handleSubmit, tag, valid } = this.props;

    if (!tag) {
      return null;
    }

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this tag?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12}>
                    <InputField>
                      <label htmlFor="tagName">Name *</label>
                      <Field
                        name="tagName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Published?</h5>
                      <InputSwitch name="tagIsPublished" />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 20,
    fontWeight: 300,
    paddingTop: 20,
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "TagForm",
})(TagForm);
