import * as errors from "../types/errors";
import ITag from "../types/tag";

import {
  CLEAR_TAG_STATE,
  CREATE_TAG_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  FETCH_SUGGESTED_TAGS_FAILURE,
  FETCH_SUGGESTED_TAGS_REQUEST,
  FETCH_SUGGESTED_TAGS_SUCCESS,
  FETCH_TAG_FAILURE,
  FETCH_TAG_REQUEST,
  FETCH_TAG_SUCCESS,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
} from "../constants/tag";

export interface IState {
  tags: ITag[];
  isLoading: boolean;
  error?: errors.HttpError;
  offset: number;
  tag?: ITag;
}

export interface IAction {
  type: string;
  tags?: ITag[];
  offset?: number;
  error?: errors.HttpError;
  tag?: ITag;
}

export const initialState: IState = {
  isLoading: false,
  offset: 0,
  tags: []
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case CLEAR_TAG_STATE:
      return { ...initialState };
    case FETCH_TAGS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        offset: action.offset || 0,
        tags: action.tags || []
      };
    case FETCH_TAGS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_TAG_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_TAG_SUCCESS:
      return { ...state, isLoading: false, tag: action.tag };
    case FETCH_TAG_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_TAG_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_TAG_SUCCESS:
      return { ...state, isLoading: false, tag: action.tag };
    case CREATE_TAG_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case EDIT_TAG_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case EDIT_TAG_SUCCESS:
      return { ...state, isLoading: false };
    case EDIT_TAG_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_SUGGESTED_TAGS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_SUGGESTED_TAGS_SUCCESS:
      return { ...state, isLoading: false };
    case FETCH_SUGGESTED_TAGS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
