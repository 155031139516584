import * as React from "react";

import { Col, Loading } from "styleguide";

import { updateFile } from "../../../actions/file";
import { fetchUsers } from "../../../actions/user";
import { createDefaultAdvertNestedProps } from "../../../services/advert";
import { requestNarrators } from "../../../services/user";
import IAdvert from "../types/advert";
import * as errors from "../../../types/errors";
import IUser from "../../../types/user";
import UserRoles from "../../../types/user-roles";
import { Helmet } from "react-helmet";
import { History } from "history";
import AdvertForm from "./AdvertForm";
import ICompany from "../../../types/company";
import { fetchCompanies } from "../../../actions/company";
import { requestOnlyAdvertiserCompanies } from "../../../services/company";
import { fetchCountries } from "../../../actions/country";
import { requestAllCountriesOrderedByContinent } from "src/services/country";
import ICountryGroup from "src/types/country-group";
import AdblockWarning from "./AdblockWarning";
import { clearAdvertState, createAdvert, editAdvert, fetchAdvert } from "../state/actions";

interface IProps {
    error?: errors.HttpError;
    advert: IAdvert;
    isLoading: boolean;
    narrators: IUser[];
    companies: ICompany[];
    history: History;
    paramId?: number;
    countriesGrouped: ICountryGroup[];
    user?: IUser;
    clearAdvertState: typeof clearAdvertState;
    createAdvert: typeof createAdvert;
    editAdvert: typeof editAdvert;
    fetchAdvert: typeof fetchAdvert;
    fetchUsers: typeof fetchUsers;
    updateFile: typeof updateFile;
    fetchCompanies: typeof fetchCompanies;
    fetchCountries: typeof fetchCountries;
}

class AdvertEditor extends React.Component<IProps> {
    public renderForm = () => {
        const { advert, narrators, companies, countriesGrouped, user } = this.props;

        const params = {
            advert,
            initialValues: {
                ...advert,
                ...createDefaultAdvertNestedProps(advert),
                error: this.props.error,
            },
            narrators,
            companies,
            countriesGrouped,
            onSubmit: this.submitForm,
            updateFile: this.props.updateFile,
            user,
        };

        return (
            <Col s={12} m={10} mOffset={1} l={8} lOffset={2}>
                <AdblockWarning />
                {/* @ts-ignore */}
                <AdvertForm {...params} />
            </Col>
        );
    };

    public submitForm = (advert: IAdvert) => {
        const { advert: oldAdvert, history } = this.props;

        if (oldAdvert && oldAdvert.advertID) {
            return this.props.editAdvert(advert, history);
        }
        return this.props.createAdvert(advert, history);
    };

    public componentWillUnmount() {
        this.props.clearAdvertState();
    }

    public componentDidMount() {
        const { paramId } = this.props;

        this.props.fetchUsers(requestNarrators(), UserRoles.NARRATOR);
        this.props.fetchCompanies(requestOnlyAdvertiserCompanies());
        this.props.fetchCountries(requestAllCountriesOrderedByContinent());

        if (paramId) {
            this.props.fetchAdvert(paramId);
        }
    }

    public render() {
        const title = `Advert Editor | ${this.props.advert && this.props.advert.advertFriendlyName ? this.props.advert.advertFriendlyName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

export default AdvertEditor;
