import * as React from "react";

import { Field } from "redux-form";
import { required as requiredField } from "../utils/input";
import _ from "lodash";

interface ISection {
    title: string;
    rows: IRow[];
}

interface IRow {
    value: string | number;
    text: string | number;
}

interface IState {
    instance?: any;
}

interface IProp {
    name: string;
    change: (field: string, value: any) => void;
    multiple?: boolean;
    sections?: ISection[];
    placeholder?: string;
    value?: string | number | string[] | number[];
    parse?: (v: any) => any;
    required?: boolean;
    onChange?: (newValue: any, previousValue: any) => void;
    className?: string;
}

export class InputSelectSectioned extends React.Component<IProp, IState> {
    public initSelect = () => {
        const { name } = this.props;
        const elem = document.querySelectorAll(`select#${name}`);

        // @ts-ignore
        if (M) {
            // @ts-ignore
            const instance = M.FormSelect.init(elem, {});
            this.setState({ instance });
        }
    };

    public refreshSelect = () => {
        const { instance } = this.state;

        if (
            Array.isArray(instance) &&
            instance[0] &&
            instance[0]._handleSelectChangeBound
        ) {
            instance[0]._handleSelectChangeBound();
        }
    };

    public parse = (v: any): any => {
        return v !== "" ? v : null;
    };

    public onChange = (event: any, newValue: any, previousValue: any) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(newValue, previousValue);
        }
    }

    public componentDidUpdate(prevProps: IProp) {
        const { sections } = this.props;

        if (!_.isEqual(prevProps.sections, sections)) {
            this.initSelect();
        }

        const sectionRowLength = _.reduce(sections, (len, arr) => {
            len += arr.rows.length;
            return len;
        }, 0);

        const prevSectionRowLength = prevProps.sections ? _.reduce(prevProps.sections, (len, arr) => {
            len += arr.rows.length;
            return len;
        }, 0) : 0;

        if (sectionRowLength !== prevSectionRowLength) {
            this.initSelect();
        }

        this.refreshSelect();
    }

    public componentDidMount() {
        this.initSelect();
    }

    public render() {
        const { multiple, name, parse, placeholder, required, sections, className } = this.props;
        const parseField = parse ? parse : this.parse;
        const requiredProp = required ? { validate: requiredField } : {};

        return (
            // @ts-ignore
            <Field
                name={name}
                component="select"
                id={name}
                multiple={multiple}
                onChange={this.onChange}
                parse={parseField}
                className={className}
                placeholder={placeholder}
                {...requiredProp}
            >
                {placeholder && (
                    <option disabled={true} value="">
                        {placeholder}
                    </option>
                )}
                {Array.isArray(sections) && sections.length > 0 && sections.map(section => (<>
                    <option key={section.title} value={section.title} disabled={true}>
                        {section.title}
                    </option>
                    {Array.isArray(section.rows) && section.rows.length > 0 && section.rows.map(row => (
                        <option key={row.value} value={row.value} disabled={false}>
                            {row.text}
                        </option>
                    ))}
                </>
                ))
                }
            </Field>
        );
    }
}

export default InputSelectSectioned;
