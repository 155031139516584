import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { ButtonFixed, Clickable, Col, Icon, Loading, ModalConfirm, Row, Table, Td, Th } from "styleguide";

import { deleteTemplate, fetchTemplates } from "../actions/template";
import config from "../config";
import { IState } from "../reducers";
import * as errors from "../types/errors";
import ITemplate from "../types/template";
import TemplateTypes from "../types/template-types";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    templates: ITemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
}

interface IPropsFromDispatch {
    fetchTemplates: typeof fetchTemplates;
    deleteTemplate: typeof deleteTemplate;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public deleteTemplate = (template: ITemplate) => () => {
        this.props
            .deleteTemplate(template)
            // @ts-ignore
            .then(() => this.props.fetchTemplates());
    };

    public renderListTemplates = () => {
        const { history, templates } = this.props;

        return (
            <>
                <Col s={12}>
                    <Table title="Templates">
                        <thead>
                            <tr>
                                <Th>Name</Th>
                                <Th>Type</Th>
                                <Th hide="mobile">Text</Th>
                                <Th align="right">Actions</Th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map((template) => (
                                <tr key={template.templateId}>
                                    <Td>{template.templateName}</Td>
                                    <Td>{template.templateTypeId !== undefined && template.templateTypeId !== null ? TemplateTypes[template.templateTypeId] : "-"}</Td>
                                    <Td hide="mobile">{template.templateText}</Td>
                                    <Td align="right">
                                        <Clickable className={css(styles.paddingLeft, styles.paddingRight)} onClick={() => history.push(`${config.paths.template}/${template.templateId}`)}>
                                            <Icon icon="edit" />
                                        </Clickable>
                                        <ModalConfirm title="Confirmation" description="Are you sure that you want save this advert?">
                                            {(confirm: any) => (
                                                <Clickable onClick={confirm(this.deleteTemplate(template))} className={css(styles.paddingLeft, styles.paddingRight)}>
                                                    <Icon icon="cancel" className={css(styles.red)} />
                                                </Clickable>
                                            )}
                                        </ModalConfirm>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ButtonFixed icon="add" onClick={() => history.push(`${config.paths.template}+`)} />
                </Col>
                <Row>
                    <Col s={12} />
                </Row>
            </>
        );
    };

    public componentDidMount() {
        this.props.fetchTemplates();
    }

    public render() {
        const { isLoading } = this.props;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>Templates</title>
                </Helmet>
                {isLoading && <Loading />}
                {this.renderListTemplates()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        error: state.template.error,
        isLoading: state.template.isLoading,
        templates: state.template.templates,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            deleteTemplate,
            fetchTemplates,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));

const styles = StyleSheet.create({
    paddingLeft: {
        paddingLeft: 5,
    },
    paddingRight: {
        paddingRight: 5,
    },
    red: {
        color: "red",
    },
});
