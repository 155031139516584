import * as React from "react";

import { InjectedFormProps } from "redux-form";
import { Loading } from "styleguide";
import { History } from "history";
import ISetting from "../../types/ISetting";
import { editSetting, fetchSetting } from "../../state/actions";
import { HttpError } from "../../../../types/errors";
import SettingsForm from "./SettingsForm";
import { Helmet } from "react-helmet";

interface IProps {
    setting: ISetting;
    error?: HttpError;
    isLoading: boolean;
    paramId?: number;
    history: History;
    fetchSetting: typeof fetchSetting;
    editSetting: typeof editSetting;
}

export class SettingsFormWrapper extends React.Component<IProps & InjectedFormProps> {
    public componentDidMount() {
        if (this.props.paramId) {
            this.props.fetchSetting(this.props.paramId);
        }
    }

    public submitForm = (setting: ISetting) => {
        const { history } = this.props;
        return this.props.editSetting(setting, history);
    };

    public renderForm = () => {
        const { setting } = this.props;

        const params = {
            setting: this.props.setting,
            initialValues: {
                ...setting,
                error: this.props.error,
            },
            onSubmit: this.submitForm,
        };

        return (
            // @ts-ignore
            <SettingsForm {...params} />
        );
    };

    public render() {
        const title = `Settings Editor | ${this.props.setting && this.props.setting.name ? this.props.setting.name : ""}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

export default SettingsFormWrapper;
