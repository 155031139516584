import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { updateFile } from "../actions/file";
import { clearJournalistState, createJournalist, editJournalist, fetchJournalist } from "../actions/journalist";
import { fetchNewspapers } from "../actions/newspaper";
import JournalistForm from "../components/JournalistForm";
import { IState } from "../reducers";
import { createDefaultJournalist, createDefaultJournalistNestedProps } from "../services/journalist";
import * as errors from "../types/errors";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";

interface IPropsFromState {
    error?: errors.HttpError;
    journalist: IJournalist;
    isLoading: boolean;
    newspapers: INewspaper[];
}

interface IPropsFromDispatch {
    clearJournalistState: typeof clearJournalistState;
    createJournalist: typeof createJournalist;
    editJournalist: typeof editJournalist;
    fetchJournalist: typeof fetchJournalist;
    fetchNewspapers: typeof fetchNewspapers;
    updateFile: typeof updateFile;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { journalist, newspapers } = this.props;

        const params = {
            initialValues: {
                ...journalist,
                ...createDefaultJournalistNestedProps(journalist),
                error: this.props.error,
            },
            journalist,
            newspapers,
            onSubmit: this.submitForm,
            updateFile: this.props.updateFile,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Journalist">
                    <JournalistForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (journalist: IJournalist | any) => {
        const { journalist: oldJournalist, history } = this.props;

        if (oldJournalist && oldJournalist.journalistId) {
            return this.props.editJournalist(journalist, history);
        }
        return this.props.createJournalist(journalist, true, history);
    };

    public componentWillUnmount() {
        this.props.clearJournalistState();
    }

    public componentDidMount() {
        const { match } = this.props;

        this.props.fetchNewspapers();

        if (match.params.id) {
            this.props.fetchJournalist(+match.params.id);
        }
    }

    public render() {
        const title = `Journalist Editor | ${this.props.journalist && this.props.journalist.journalistName ? this.props.journalist.journalistName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { JournalistForm: journalistForm },
        journalist: { journalist },
    } = state;

    const journalistValues = journalistForm ? journalistForm.values : {};

    const preJournalist = correctValue("journalistId", journalistValues, journalist);
    const validatedJournalist = preJournalist ? preJournalist : createDefaultJournalist();

    return {
        error: state.journalist.error,
        isLoading: state.journalist.isLoading,
        journalist: validatedJournalist,
        newspapers: state.newspaper.newspapers,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearJournalistState,
            createJournalist,
            editJournalist,
            fetchJournalist,
            fetchNewspapers,
            updateFile,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
