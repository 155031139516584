export const FETCH_SECTIONS_FAILURE = "FETCH_SECTIONS_FAILURE";
export const FETCH_SECTIONS_REQUEST = "FETCH_SECTIONS_REQUEST";
export const FETCH_SECTIONS_SUCCESS = "FETCH_SECTIONS_SUCCESS";

export const FETCH_SECTION_FAILURE = "FETCH_SECTION_FAILURE";
export const FETCH_SECTION_REQUEST = "FETCH_SECTION_REQUEST";
export const FETCH_SECTION_SUCCESS = "FETCH_SECTION_SUCCESS";

export const CREATE_SECTION_FAILURE = "CREATE_SECTION_FAILURE";
export const CREATE_SECTION_REQUEST = "CREATE_SECTION_REQUEST";
export const CREATE_SECTION_SUCCESS = "CREATE_SECTION_SUCCESS";

export const UPDATE_SECTION_FAILURE = "UPDATE_SECTION_FAILURE";
export const UPDATE_SECTION_REQUEST = "UPDATE_SECTION_REQUEST";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";

export const CLEAR_SECTION_STATE = "CLEAR_SECTION_STATE";
