import * as React from "react";

import { css, StyleSheet } from "aphrodite";

export class NotFound extends React.Component {
  public render() {
    return (
      <div className={`${css(styles.empty)} center`}>
        <div className="row">
          <div className="col s12">
            <i className="material-icons large">local_cafe</i>
          </div>
          <div className="col s12">
            <h5 className={css(styles.emptyText)}>Page not found</h5>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  empty: {
    paddingTop: 100
  },
  emptyText: {
    fontSize: "18px"
  }
});

export default NotFound;
