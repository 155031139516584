import * as _ from "lodash";

import IFormError from "../types/form-error";

export const formError = (
  errors: IFormError[] = [{ validatorKey: "unknown" }]
) => {
  const errorsMessage = errors.map(e => {
    const userEmailError = userEmailUnique(e);
    if (!!userEmailError) {
      return userEmailError;
    }

    return "Internal server error";
  });

  return _.uniq(errorsMessage);
};

const userEmailUnique = (error: IFormError) => {
  if (error.path === "userLogin" && error.validatorKey === "not_unique") {
    return "The email address you have entered is already registered";
  }

  return null;
};
