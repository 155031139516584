import * as errors from "../types/errors";

import {
    CLEAR_PLAYLIST_STATE,
    CREATE_PLAYLIST_FAILURE,
    CREATE_PLAYLIST_REQUEST,
    CREATE_PLAYLIST_SUCCESS,
    FETCH_PLAYLIST_FAILURE,
    FETCH_PLAYLIST_REQUEST,
    FETCH_PLAYLIST_SUCCESS,
    FETCH_PLAYLISTS_FAILURE,
    FETCH_PLAYLISTS_REQUEST,
    FETCH_PLAYLISTS_SUCCESS,
    MOVE_DOWN_PLAYLIST_FAILURE,
    MOVE_DOWN_PLAYLIST_REQUEST,
    MOVE_DOWN_PLAYLIST_SUCCESS,
    MOVE_UP_PLAYLIST_FAILURE,
    MOVE_UP_PLAYLIST_REQUEST,
    MOVE_UP_PLAYLIST_SUCCESS,
    UPDATE_PLAYLIST_FAILURE,
    UPDATE_PLAYLIST_REQUEST,
    UPDATE_PLAYLIST_SUCCESS,
    FETCH_SUGGESTION_PLAYLIST_REQUEST,
    FETCH_SUGGESTION_PLAYLIST_SUCCESS,
    FETCH_SUGGESTION_PLAYLIST_FAILURE
} from "../constants/playlist";
import { getSectionsFromPlaylists } from "../services/section";
import IPlaylist from "../types/playlist";
import ISection from "../types/section";

export interface IState {
    playlists: IPlaylist[];
    sections: ISection[];
    playlist?: IPlaylist;
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    playlists?: IPlaylist[];
    playlist?: IPlaylist;
    error?: errors.HttpError;
}

export const initialState: IState = {
    isLoading: false,
    playlists: [],
    sections: []
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {
    switch (action.type) {
        case FETCH_PLAYLISTS_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_PLAYLISTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                playlists: action.playlists || [],
                sections: getSectionsFromPlaylists(action.playlists || []),
            };
        case FETCH_PLAYLISTS_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case FETCH_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                playlist: action.playlist,
            };
        case FETCH_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case CREATE_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case CREATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                playlist: action.playlist,
            };
        case CREATE_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case UPDATE_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case UPDATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UPDATE_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case CLEAR_PLAYLIST_STATE:
            return { ...initialState };
        case MOVE_UP_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case MOVE_UP_PLAYLIST_SUCCESS:
            return { ...state, isLoading: false };
        case MOVE_UP_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case MOVE_DOWN_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case MOVE_DOWN_PLAYLIST_SUCCESS:
            return { ...state, isLoading: false };
        case MOVE_DOWN_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case FETCH_SUGGESTION_PLAYLIST_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_SUGGESTION_PLAYLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                playlists: action.playlists || []
            };
        case FETCH_SUGGESTION_PLAYLIST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};
