import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import { ButtonFlat, Clickable, Col, Icon, ModalConfirm, Table, Td, Th } from "styleguide";

import IArticle from "../types/article";
import { yyyymmdd } from "../utils/date";
import ModalGeneric from "./ModalGeneric";
import { getArticleAudioTypeText } from "../services/article";

interface IProps {
    title: string;
    articles?: IArticle[];
    goToArticleEdit: (articleId: number) => void;
    goToArticleView: (articleId: number) => void;
    goToAudio: (filename?: string) => void;
    togglePublishArticle: (article: IArticle) => void;
}

export class ArticleListForEditors extends React.Component<IProps> {
    public renderListenToAudioModalContents = (article: IArticle) => {
        return (
            <div>
                {article.articleAudioFileName && article.articleAudioType !== 1 && (
                    <>
                        <br />
                        <ButtonFlat text="On-Platform Audio" onClick={() => this.props.goToAudio(article.articleAudioFileName)}></ButtonFlat>
                        <br />
                    </>
                )}
                {article.articleExternalAudioFileName && article.articleAudioType !== 0 && (
                    <>
                        <br />
                        <ButtonFlat text="Off-Platform Audio" onClick={() => this.props.goToAudio(article.articleExternalAudioFileName)}></ButtonFlat>
                    </>
                )}
            </div>
        );
    };

    public renderListenToAudioButton = (article: IArticle) => {
        const hasAudio = article.articleAudioFileName || article.articleExternalAudioFileName;
        return (
            <ModalGeneric title="Choose an audio file to listen to" contents={this.renderListenToAudioModalContents(article)}>
                {(confirm: any) => (
                    <Clickable className={`${css(styles.icon)} hide-on-med-and-down`} onClick={confirm(() => null)}>
                        <Icon icon="audiotrack" tooltip="Listen to audio" className={hasAudio ? "blue-text" : "black-text"} />
                    </Clickable>
                )}
            </ModalGeneric>
        );
    };

    public render() {
        const { articles, goToArticleEdit, goToArticleView, togglePublishArticle, title } = this.props;

        return (
            <Col s={12}>
                <Table title={title}>
                    {Array.isArray(articles) && articles.length > 0 && (
                        <thead>
                            <tr>
                                <Th size="small" hide="tablet">
                                    Id
                                </Th>
                                <Th size="big">Name</Th>
                                <Th hide="mobile">Publisher</Th>
                                <Th size="small" hide="tablet">
                                    Words
                                </Th>
                                <Th size="medium" hide="mobile">
                                    Created At
                                </Th>
                                <Th size="medium" hide="mobile">
                                    Audio Added At
                                </Th>
                                <Th size="medium" hide="mobile">
                                    Audio Platforms
                                </Th>
                                <Th align="right">Actions</Th>
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {Array.isArray(articles) &&
                            articles.length > 0 &&
                            articles.map((article) => (
                                <tr key={article.articleID}>
                                    <Td hide="tablet">{article.articleID}</Td>
                                    <Td>{article.articleName}</Td>
                                    <Td hide="mobile">{article.newspaper && article.newspaper.newspaperName}</Td>
                                    <Td hide="tablet">{article.articleWordCount}</Td>
                                    <Td hide="mobile">{article.articleAddedDateTime ? yyyymmdd(new Date(article.articleAddedDateTime)) : "-"}</Td>
                                    <Td hide="mobile">{article.articleAudioAddedDateTime ? yyyymmdd(new Date(article.articleAudioAddedDateTime)) : "-"}</Td>
                                    <Td hide="mobile">{getArticleAudioTypeText(article)}</Td>

                                    <Td align="right">
                                        <Clickable className={css(styles.icon)} onClick={() => goToArticleEdit(article.articleID)}>
                                            <Icon icon="edit" />
                                        </Clickable>
                                        <Clickable className={css(styles.icon)} onClick={() => goToArticleView(article.articleID)}>
                                            <Icon icon="pageview" />
                                        </Clickable>
                                        {this.renderListenToAudioButton(article)}
                                        <ModalConfirm title="Confirmation" description={`Are you sure that you want ${article.articleIsPublished === 1 ? "unpublish" : "publish"} this article?`}>
                                            {(confirm: any) => (
                                                <Clickable className={css(styles.icon)} onClick={confirm(() => togglePublishArticle(article))}>
                                                    {article.articleIsPublished === 1 ? (
                                                        <Icon icon="cloud_done" className="green-text" tooltip="Unpublish" />
                                                    ) : (
                                                        <Icon icon="cloud_off" className="blue-text" tooltip="Publish" />
                                                    )}
                                                </Clickable>
                                            )}
                                        </ModalConfirm>
                                    </Td>
                                </tr>
                            ))}
                        {Array.isArray(articles) && articles.length === 0 && (
                            <tr>
                                <td className="center" colSpan={10}>
                                    EMPTY
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        );
    }
}

const styles = StyleSheet.create({
    icon: {
        "@media (max-width: 600px)": {
            paddingLeft: 5,
            paddingRight: 5,
        },
        paddingLeft: 10,
        paddingRight: 10,
    },
});

export default ArticleListForEditors;
