export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";
export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";

export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";
export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";

export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";

export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";
export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";

export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
