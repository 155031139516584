import IArticle from "../types/article";

export const countAllWordsInArticleScript = (article?: IArticle) => {
    if (!article) {
        return 0;
    }

    let textToCount = "";

    if (article.articleText) {
        textToCount = textToCount.concat(article.articleText);
    }

    if (!article.intro || article.intro.templateName === "BLANK") {
        textToCount = textToCount.concat(article.manualIntro || "");
    } else {
        textToCount = textToCount.concat(article.intro.templateText);
    }

    if (!article.outro || article.outro.templateName === "BLANK") {
        textToCount = textToCount.concat(article.manualOutro || "");
    } else {
        textToCount = textToCount.concat(article.outro.templateText);
    }

    return countWords(textToCount);
};

export const countWords = (s?: string): number => {
    if (typeof s === "string") {
        const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
        let cleanString = s.replace(regex, " ").trim();
        cleanString = cleanString.replace(/&nbsp;/g, " "); // This isn't a regular dash character
        cleanString = cleanString.replace(/<p>/g, " ");
        cleanString = cleanString.replace(/<\/p>/g, " ");
        cleanString = cleanString.replace(/<br>/g, " ");
        cleanString = cleanString.replace(/—/g, " "); // This isn't a regular dash character
        cleanString = cleanString.replace(/–/g, " "); // This again, isn't a regular dash character but different to above

        const words = cleanString.match(/\S+/g); // s.match(/\S+/g);
        return words ? words.length : 0;
    }

    return 0;
};
