import * as React from "react";

import * as _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Col, Loading, Row } from "styleguide";

import { fetchAudiences } from "../actions/audience";
import { editPlaylist, fetchPlaylists, moveDown, moveUp } from "../actions/playlist";
import { fetchSections } from "../actions/section";
import ModalAddPlaylist from "../components/ModalAddPlaylist";
import SpecialSection from "../components/SpecialSection";
import { IState } from "../reducers";
import IAudience from "../types/audience";
import IPlaylist from "../types/playlist";
import ISection from "../types/section";
import SectionTypes from "../types/section-types";
import { Helmet } from "react-helmet";
import { requestAllPlaylistsWithSections } from "../services/playlist";

interface IPropsFromState {
    loading: boolean;
    audiences: IAudience[];
    playlists: IPlaylist[];
    sections: ISection[];
}

interface IPropsFromDispatch {
    fetchAudiences: typeof fetchAudiences;
    fetchSections: typeof fetchSections;
    fetchPlaylists: typeof fetchPlaylists;
    editPlaylist: typeof editPlaylist;
    moveUp: typeof moveUp;
    moveDown: typeof moveDown;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>> {
    public getPlaylists = () => {
        this.props.fetchPlaylists(requestAllPlaylistsWithSections());
    };

    public getSections = () => {
        this.props.fetchSections({ filter: [{ name: "sectionType!", value: SectionTypes.Regular }], include: ["playlists->audiences"] });
    };

    public moveUp = (sectionId: number) => (playlistId: number) => {
        this.props
            .moveUp(playlistId, sectionId)
            // @ts-ignore
            .then(() => this.getSections());
    };

    public moveDown = (sectionId: number) => (playlistId: number) => {
        this.props
            .moveDown(playlistId, sectionId)
            // @ts-ignore
            .then(() => this.getSections());
    };

    public addPlaylistToSection = (sectionId: number | any) => (playlistId: number) => {
        const { playlists } = this.props;

        const playlist = _.find(playlists, (p) => p.playlistId === playlistId);

        if (playlist) {
            const newSectionsId = _.map([..._.map(playlist.sections, (s) => s.sectionId), sectionId], (s) => `${s}`);

            this.props
                .editPlaylist({
                    playlistId: playlist.playlistId,
                    playlistIsPublished: playlist.playlistIsPublished,
                    playlistName: playlist.playlistName,
                    sectionsId: newSectionsId,
                })
                // @ts-ignore
                .then(() => this.getSections())
                // @ts-ignore
                .then(() => this.getPlaylists());
        }
    };

    public removePlaylistFromSection = (sectionId: number) => (playlistId: number) => {
        const { playlists } = this.props;

        const playlist = _.find(playlists, (p) => p.playlistId === playlistId);

        if (playlist) {
            const newSectionsId = _.chain(playlist.sections || [])
                .filter((s) => s.sectionId !== sectionId)
                .map((s) => `${s.sectionId}`)
                .value();

            this.props
                .editPlaylist({
                    playlistId: playlist.playlistId,
                    playlistIsPublished: playlist.playlistIsPublished,
                    playlistName: playlist.playlistName,
                    sectionsId: newSectionsId,
                })
                // @ts-ignore
                .then(() => this.getSections())
                // @ts-ignore
                .then(() => this.getPlaylists());
        }
    };

    public filterPlaylistsByAlreadyAdded = (playlistsAlreadyAdded: IPlaylist[]) => {
        const { playlists } = this.props;

        return _.filter(playlists, (playlist) => !_.find(playlistsAlreadyAdded, (p) => p.playlistId === playlist.playlistId));
    };

    public componentDidMount() {
        this.props.fetchAudiences({ playlists: true });

        this.getSections();

        this.getPlaylists();
    }

    public render() {
        const { loading, audiences, sections } = this.props;

        return (
            <Row>
                {/*
                // @ts-ignore */}
                <Helmet>
                    <title>Special Sections</title>
                </Helmet>
                {loading && <Loading />}
                {sections.map((s) => {
                    const editorialPlaylists = s.playlists || [];
                    const formParams = {
                        form: `FormModalAddPlaylistToSection-${s.sectionId}`,
                        onSubmit: this.addPlaylistToSection(s.sectionId),
                        playlists: this.filterPlaylistsByAlreadyAdded(s.playlists || []),
                    };

                    return (
                        <Col key={s.sectionId} s={12}>
                            <SpecialSection
                                name={s.sectionName}
                                audiences={audiences}
                                playlists={editorialPlaylists}
                                removePlaylist={this.removePlaylistFromSection(s.sectionId)}
                                moveDown={this.moveDown(s.sectionId)}
                                moveUp={this.moveUp(s.sectionId)}
                            />
                            {/* @ts-ignore */}
                            <ModalAddPlaylist {...formParams} />
                        </Col>
                    );
                })}
            </Row>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        audiences: state.audience.audiences,
        loading: state.audience.isLoading || state.playlist.isLoading || state.section.isLoading,
        playlists: state.playlist.playlists,
        sections: state.section.sections,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            editPlaylist,
            fetchAudiences,
            fetchPlaylists,
            fetchSections,
            moveDown,
            moveUp,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
