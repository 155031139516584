import { History } from "history";
import { Dispatch } from "redux";
import { toast } from "styleguide";

import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_JOURNALIST_STATE,
  CREATE_JOURNALIST_FAILURE,
  CREATE_JOURNALIST_REQUEST,
  CREATE_JOURNALIST_SUCCESS,
  FETCH_JOURNALIST_FAILURE,
  FETCH_JOURNALIST_REQUEST,
  FETCH_JOURNALIST_SUCCESS,
  FETCH_JOURNALISTS_FAILURE,
  FETCH_JOURNALISTS_REQUEST,
  FETCH_JOURNALISTS_SUCCESS,
  UPDATE_JOURNALIST_FAILURE,
  UPDATE_JOURNALIST_REQUEST,
  UPDATE_JOURNALIST_SUCCESS
} from "../constants/journalist";
import { IState } from "../reducers";
import { requestJournalist } from "../services/journalist";
import IFile from "../types/file";
import FileType from "../types/file-type";
import HttpMethods from "../types/http-methods";
import IJournalist from "../types/journalist";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";

/*
    ASYNC ACTIONS
*/

export const fetchJournalists = (params?: IParams, reset?: boolean) => (
  dispatch: Dispatch,
  getState: () => IState
) => {
  dispatch({ type: FETCH_JOURNALISTS_REQUEST });

  const state = getState();

  const url = buildUrl(`${config.api.url}${config.api.paths.journalist}`, {
    ...params,
    offset: reset ? 0 : state.journalist.offset
  });

  return fetch(url)
    .then(responseCheck)
    .then(journalists =>
      fetchJournalistsSuccess(
        dispatch,
        journalists,
        state.journalist.journalists,
        reset ? reset : false
      )
    )
    .catch(error => dispatch(fetchJournalistsFailure(error)));
};

export const fetchJournalist = (id: number) => (dispatch: Dispatch) => {
  dispatch({ type: FETCH_JOURNALIST_REQUEST });

  const url = buildUrl(
    `${config.api.url}${config.api.paths.journalist}/${id}`,
    requestJournalist()
  );

  return fetch(url)
    .then(responseCheck)
    .then(jor => dispatch(fetchJournalistSuccess(jor)))
    .catch(error => dispatch(fetchJournalistFailure(error)));
};

export const createJournalist = (
  journalist: IJournalist,
  uploadFile: boolean,
  history?: History
) => (dispatch: Dispatch, getState: () => IState): Promise<any> => {
  dispatch({ type: CREATE_JOURNALIST_REQUEST });

  if (uploadFile) {
    return uploadFiles("journalist")(dispatch, getState)
      .then(() => {
        populateWithFiles(getState, (files: IFile[]) => {
          if (files[0].type === FileType.image) {
            journalist.journalistImage = files[0].key;
          }
        });

        return fetch(`${config.api.url}${config.api.paths.journalist}`, {
          body: JSON.stringify({ model: journalist }),
          method: HttpMethods.POST
        });
      })
      .then(responseCheck)
      .then(jour => dispatch(createJournalistSuccess(jour)))
      .then(() => dispatch(clearFiles()))
      .then(() => history && history.push(config.paths.journalist))
      .catch(error => dispatch(createJournalistFailure(error)));
  }

  return fetch(`${config.api.url}${config.api.paths.journalist}`, {
    body: JSON.stringify({ model: journalist }),
    method: HttpMethods.POST
  })
    .then(responseCheck)
    .then(jour => dispatch(createJournalistSuccess(jour)))
    .then(() => history && history.push(config.paths.journalist))
    .catch(error => dispatch(createJournalistFailure(error)));
};

export const editJournalist = (journalist: IJournalist, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: UPDATE_JOURNALIST_REQUEST });

  return uploadFiles("journalist")(dispatch, getState)
    .then(() => {
      populateWithFiles(getState, (files: IFile[]) => {
        if (files[0].type === FileType.image) {
          journalist.journalistImage = files[0].key;
        }
      });

      return fetch(
        `${config.api.url}${config.api.paths.journalist}/${
          journalist.journalistId
        }`,
        {
          body: JSON.stringify({ model: journalist }),
          method: HttpMethods.PUT
        }
      );
    })
    .then(responseCheck)
    .then(() => dispatch(editJournalistSuccess()))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.journalist))
    .catch(error => dispatch(editJournalistFailure(error)));
};

/*
    SYNC ACTIONS
*/

const fetchJournalistsSuccess = (
  dispatch: Dispatch,
  journalists: IJournalist[],
  oldJournalists: IJournalist[],
  reset: boolean
) => {
  const newJournalists = !reset
    ? [...oldJournalists, ...journalists]
    : journalists;

  return dispatch({
    journalists: newJournalists,
    offset: newJournalists.length,
    type: FETCH_JOURNALISTS_SUCCESS
  });
};

export const clearJournalistState = () => {
  return { type: CLEAR_JOURNALIST_STATE };
};

const fetchJournalistsFailure = (error: Error) => {
  toast("Error fetching journalists");
  return { type: FETCH_JOURNALISTS_FAILURE, error };
};

const createJournalistSuccess = (journalist: IJournalist) => {
  toast("Journalist created");
  return { type: CREATE_JOURNALIST_SUCCESS, journalist };
};

const createJournalistFailure = (error: Error) => {
  toast("Error creating journalist");
  return { type: CREATE_JOURNALIST_FAILURE, error };
};

const fetchJournalistSuccess = (journalist: IJournalist) => {
  return { type: FETCH_JOURNALIST_SUCCESS, journalist };
};

const fetchJournalistFailure = (error: Error) => {
  toast("Error fetching journalist");
  return { type: FETCH_JOURNALIST_FAILURE, error };
};

const editJournalistSuccess = () => {
  toast("Journalist updated");
  return { type: UPDATE_JOURNALIST_SUCCESS };
};

const editJournalistFailure = (error: Error) => {
  toast("Error updating journalist");
  return { type: UPDATE_JOURNALIST_FAILURE, error };
};
