enum SearchOptions {
    TITLE = "1",
    NARRATOR = "2",
    ID = "3",
    NEWSPAPER = "4",
    CATEGORY = "5",
    JOURNALIST = "6",
    PLAYLIST = "7",
    VOICE_REQUIREMENTS = "8",
    PUBLISHED_UNPUBLISHED = "9",
    SUBCATEGORY = "10",
    WORD_COUNT = "11",
    ARTICLE_UNITS = "12",
    DATE_PUBLISHED = "13",
}

export default SearchOptions;
