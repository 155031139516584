import { Dispatch } from "redux";

import { toast } from "styleguide";
import { History } from "history";
import { buildUrl } from "../../../utils/build-url";
import responseCheck from "../../../utils/response-check";
import HttpMethods from "../../../types/http-methods";
import config from "../../../config";
import { IState } from "../../../reducers";
import fetch from "../../../utils/fetch";
import { EDIT_SETTING_FAILURE, EDIT_SETTING_REQUEST, EDIT_SETTING_SUCCESS, FETCH_SETTINGS_FAILURE, FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_SUCCESS, FETCH_SETTING_FAILURE, FETCH_SETTING_REQUEST, FETCH_SETTING_SUCCESS } from "./constants";
import ISetting from "../types/ISetting";

/*
    ASYNC ACTIONS
*/

export const fetchSettings = () => (
    dispatch: Dispatch,
    getState: () => IState
) => {
    dispatch({ type: FETCH_SETTINGS_REQUEST });

    const url = buildUrl(`${config.api.url}${config.api.paths.setting}`);

    return fetch(url)
        .then(responseCheck)
        .then(settings => dispatch(fetchSettingsSuccess(settings)))
        .catch(error => dispatch(fetchSettingsFailure(error)));
};

export const fetchSetting = (id: number) => (
    dispatch: Dispatch,
    getState: () => IState
) => {
    dispatch({ type: FETCH_SETTING_REQUEST });

    const url = buildUrl(`${config.api.url}${config.api.paths.setting}/${id}`);

    return fetch(url)
        .then(responseCheck)
        .then(settings => dispatch(fetchSettingSuccess(settings)))
        .catch(error => dispatch(fetchSettingFailure(error)));
};

export const editSetting = (setting: ISetting, history: History) => (
    dispatch: Dispatch,
    getState: () => IState
): Promise<any> => {
    dispatch({ type: EDIT_SETTING_REQUEST });

    return fetch(`${config.api.url}${config.api.paths.setting}/${setting.id}`, {
        body: JSON.stringify({ model: setting }),
        method: HttpMethods.PUT
    })
        .then(responseCheck)
        .then(() => dispatch(editSettingSuccess()))
        .then(() => history.push(config.paths.setting))
        .catch(error => dispatch(editSettingFailure(error)));
};

const fetchSettingsSuccess = (settings: ISetting[]) => {
    return {
        settings,
        type: FETCH_SETTINGS_SUCCESS
    };
};

const fetchSettingsFailure = (error: Error) => {
    toast("Error fetching settings");
    return { type: FETCH_SETTINGS_FAILURE, error };
};

const fetchSettingSuccess = (setting: ISetting) => {
    return {
        setting,
        type: FETCH_SETTING_SUCCESS
    };
};

const fetchSettingFailure = (error: Error) => {
    toast("Error fetching setting");
    return { type: FETCH_SETTING_FAILURE, error };
};

const editSettingSuccess = () => {
    toast("Updated setting");
    return { type: EDIT_SETTING_SUCCESS };
};

const editSettingFailure = (error: Error) => {
    toast("Error updating setting");
    return { type: EDIT_SETTING_FAILURE, error };
};
