import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import IPronunciation from "../../types/pronunciation";
import IUser from "../../types/user";
import { daysAgo } from "../../utils/date";
import { Button } from "styleguide/build";
import UserRoles from "../../types/user-roles";
import { canVerifyPronuncation } from "../../utils/pronunciation-utils";

interface IProps {
    user?: IUser;
    pronunciation: IPronunciation;
    verifyPronunciation: any;
}

export class PronunciationVerifTd extends React.Component<IProps> {

    public verifyPronunciation = () => {
        const { pronunciation, user } = this.props;

        if (pronunciation.verifiedAt) {
            return;
        }

        this.props.verifyPronunciation(pronunciation, user, null, true);
    }

    public render = () => {
        const { user, pronunciation } = this.props;

        if (
            user &&
            (user.userRoleIdList === UserRoles.EDITOR ||
                user.userRoleIdList === UserRoles.SUPER_USER)
        ) {

            const canVerif = canVerifyPronuncation(pronunciation, user);

            /* tslint:disable-next-line: jsx-no-lambda*/
            return <td>
                {pronunciation.verifiedAt ?
                    `✓ ${daysAgo(new Date(pronunciation.verifiedAt))} days ago`
                    :
                    <Button onClick={this.verifyPronunciation} className={css(!canVerif && styles.unverifiableButton)} text="Verify" />
                }</td>
        }

        return <td>{pronunciation.verifiedAt ? `✓ ${daysAgo(new Date(pronunciation.verifiedAt))} days ago` : <Button disable={true} text="Unverified" />}</td>
    }
}


const styles = StyleSheet.create({
    unverifiableButton: {
        "@media (max-width: 9000px)": {
            background: 'red',
        },
        background: 'red',
    },
});

export default PronunciationVerifTd;
