import * as _ from "lodash";

export const correctValue = (idNameProp: string, values?: any, state?: any) => {
  if (state && values && state[idNameProp] !== values[idNameProp]) {
    return state;
  } else if (state && values && state[idNameProp] === values[idNameProp]) {
    return values;
  } else if (!state && !_.isEmpty(values)) {
    return values;
  } else {
    return null;
  }
};
