export interface IFilter {
  name: string;
  value: string | number | null;
}

export interface IParams {
  sortBy?: string;
  orderBy?: string;
  limit?: number;
  offset?: number;
  include?: string[];
  filter?: IFilter[];
  innerFilter?: { [key: string]: IFilter[] };
}

export const buildUrl = (url: string, params?: IParams) => {
  if (!params) {
    return url;
  }

  let finalUrl = `${url}?`;

  if (params.sortBy) {
    finalUrl = `${finalUrl}sort_by=${params.sortBy}&`;
  }

  if (params.orderBy) {
    finalUrl = `${finalUrl}order_by=${params.orderBy}&`;
  }

  if (params.limit) {
    finalUrl = `${finalUrl}limit=${params.limit}&`;
  }

  if (params.offset) {
    finalUrl = `${finalUrl}offset=${params.offset}&`;
  }

  if (params.include) {
    params.include.forEach((v: string, i: number) => {
      finalUrl = `${finalUrl}include[${i}]=${v}&`;
    });
  }

  if (params.filter && params.filter.length > 0) {
    const filters = params.filter.map(f => {
      return `${f.name}=${f.value}&`;
    });

    // @ts-ignore
    finalUrl = `${finalUrl}filter=${window.encodeURIComponent(
      filters.join("")
    )}&`;
  }

  if (params.innerFilter && Object.keys(params.innerFilter).length > 0) {
    const fs = params.innerFilter || {};

    const filters = Object.keys(fs)
      .map(key => {
        return `innerFilter[${key}]=${fs[key]
          .map(f =>
            // @ts-ignore
            window.encodeURIComponent(`${f.name}=${f.value}&`)
          )
          .join("")}`;
      })
      .join("");

    finalUrl = `${finalUrl}${filters}`;
  }

  return finalUrl;
};
