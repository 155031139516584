export const FETCH_PRONUNCIATIONS_REQUEST = "FETCH_PRONUNCIATIONS_REQUEST";
export const FETCH_PRONUNCIATIONS_SUCCESS = "FETCH_PRONUNCIATIONS_SUCCESS";
export const FETCH_PRONUNCIATIONS_FAILURE = "FETCH_PRONUNCIATIONS_FAILURE";

export const FETCH_PRONUNCIATION_REQUEST = "FETCH_PRONUNCIATION_REQUEST";
export const FETCH_PRONUNCIATION_SUCCESS = "FETCH_PRONUNCIATION_SUCCESS";
export const FETCH_PRONUNCIATION_FAILURE = "FETCH_PRONUNCIATION_FAILURE";

export const CREATE_PRONUNCIATION_REQUEST = "CREATE_PRONUNCIATION_REQUEST";
export const CREATE_PRONUNCIATION_SUCCESS = "CREATE_PRONUNCIATION_SUCCESS";
export const CREATE_PRONUNCIATION_FAILURE = "CREATE_PRONUNCIATION_FAILURE";

export const EDIT_PRONUNCIATION_REQUEST = "EDIT_PRONUNCIATION_REQUEST";
export const EDIT_PRONUNCIATION_SUCCESS = "EDIT_PRONUNCIATION_SUCCESS";
export const EDIT_PRONUNCIATION_FAILURE = "EDIT_PRONUNCIATION_FAILURE";

export const DELETE_PRONUNCIATION_REQUEST = "DELETE_PRONUNCIATION_REQUEST";
export const DELETE_PRONUNCIATION_SUCCESS = "DELETE_PRONUNCIATION_SUCCESS";
export const DELETE_PRONUNCIATION_FAILURE = "DELETE_PRONUNCIATION_FAILURE";

export const CLEAR_PRONUNCIATION_STATE = "CLEAR_PRONUNCIATION_STATE";
