import * as _ from "lodash";

import IPlaylist from "../types/playlist";
import { IParams } from "../utils/build-url";

export const requestAllPlaylists = (): IParams => {
    return {
        orderBy: "ASC",
        sortBy: "playlistName",
        limit: 987654321,
    };
};

export const requestAllPlaylistsWithSections = (): IParams => {
    return {
        include: ["sections"],
        orderBy: "ASC",
        sortBy: "playlistName",
        limit: 987654321,
    };
};

export const requestAllPlaylistsWithSectionsAndArticles = (sectionId: number): IParams => {
    return {
        include: ["sections", "articles"],
        innerFilter: sectionId === -1 ? {} : { sections: [{ name: "sectionId", value: sectionId }] },
        orderBy: "ASC",
        sortBy: "playlistName",
        limit: 987654321,
    };
};

export const requestTopPlaylistsWithSections = (): IParams => {
    return {
        include: ["sections"],
        orderBy: "ASC",
        sortBy: "playlistName",
        limit: 100,
        offset: 0,
    };
};

export const requestPublishedPlaylists = (): IParams => {
    return {
        filter: [{ name: "playlistIsPublished", value: 1 }],
        include: ["sections"],
        orderBy: "ASC",
        sortBy: "playlistName",
    };
};

export const createDefaultPlaylist = (): IPlaylist => {
    return {
        playlistId: 0,
        playlistIsPublished: 0,
        playlistName: "",
    };
};

export const createDefaultPlaylistNestedProps = (playlist?: IPlaylist) => {
    if (!playlist || !playlist.sections || !playlist.audiences) {
        return null;
    }

    const sectionsId = playlist.sectionsId ? playlist.sectionsId : playlist.sections.filter((s) => s.parentId == null).map((s) => `${s.sectionId}`);

    const sectionsIdx = playlist.sectionsIdx ? playlist.sectionsIdx : playlist.sections.filter((s) => s.parentId != null).map((s) => `${s.sectionId}`);

    const audiencesId = playlist.audiencesId ? playlist.audiencesId : playlist.audiences.map((a) => `${a.audienceId}`);

    const tagsId = playlist.tagsId ? playlist.tagsId : (playlist.tags || []).filter((t) => t.playlisttags.isDisplayTag !== 1).map((t) => ({ tagName: t.tagName, tagId: t.tagID }));

    const displayTagsId = playlist.displayTagsId ? playlist.displayTagsId : (playlist.tags || []).filter((t) => t.playlisttags.isDisplayTag === 1).map((t) => ({ tagName: t.tagName, tagId: t.tagID }));

    return { sectionsId, sectionsIdx, audiencesId, tagsId, displayTagsId };
};
