export const FETCH_TAGS_REQUEST = "FETCH_TAGS_REQUEST";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";

export const FETCH_TAG_REQUEST = "FETCH_TAG_REQUEST";
export const FETCH_TAG_SUCCESS = "FETCH_TAG_SUCCESS";
export const FETCH_TAG_FAILURE = "FETCH_TAG_FAILURE";

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const EDIT_TAG_REQUEST = "EDIT_TAG_REQUEST";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILURE = "EDIT_TAG_FAILURE";

export const CLEAR_TAG_STATE = "CLEAR_TAG_STATE";

export const FETCH_SUGGESTED_TAGS_REQUEST = "FETCH_SUGGESTED_TAGS_REQUEST";
export const FETCH_SUGGESTED_TAGS_SUCCESS = "FETCH_SUGGESTED_TAGS_SUCCESS";
export const FETCH_SUGGESTED_TAGS_FAILURE = "FETCH_SUGGESTED_TAGS_FAILURE";