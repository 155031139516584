import * as errors from "../types/errors";

import {
  CLEAR_TEMPLATE_STATE,
  CREATE_TEMPLATE_FAILURE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_SUCCESS
} from "../constants/template";
import ITemplate from "../types/template";

export interface IState {
  templates: ITemplate[];
  template?: ITemplate;
  isLoading: boolean;
  error?: errors.HttpError;
}

export interface IAction {
  type: string;
  templates?: ITemplate[];
  template?: ITemplate;
  error?: errors.HttpError;
}

export const initialState: IState = {
  isLoading: false,
  templates: []
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_TEMPLATES_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templates: action.templates || []
      };
    case FETCH_TEMPLATES_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_TEMPLATE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        template: action.template
      };
    case FETCH_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_TEMPLATE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        template: action.template
      };
    case CREATE_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case UPDATE_TEMPLATE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case DELETE_TEMPLATE_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_TEMPLATE_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
