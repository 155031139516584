import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router-dom";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/async-creatable";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, ButtonFixed, Col, InputDate, InputField, InputSelect, InputSwitch, ModalConfirm, Row, Tooltipped, Card, toast } from "styleguide";

import { updateFile } from "../actions/file";
import { getWarnWords, missingFieldsToPublish, missingFieldsForSaveWarning } from "../services/article";
import IArticle from "../types/article";
import ArticleType from "../types/article-type";
import ArticleValue from "../types/article-value";
import * as errors from "../types/errors";
import FileType from "../types/file-type";
import IJournalist from "../types/journalist";
import INewspaper from "../types/newspaper";
import IPlaylist from "../types/playlist";
import ISection from "../types/section";
import ITemplate from "../types/template";
import TemplateTypes from "../types/template-types";
import IUser from "../types/user";
import { renderError } from "../utils/input";
import InputFile from "./InputFile";
import InputText from "./InputText";
import RichInputText from "./RichInputText";
import IArticleTemplate from "../types/article-template";
import { deleteArticleTemplate } from "../actions/articletemplates";
import ArticleAudioType from "../types/article-audio-type";

import RichInputTextV2 from "./RichInputTextV2";

import { IVoiceRequirement } from "src/types/voice-requirements";
import { ReactSelectStylesMulti, ReactSelectStyles } from "src/utils/react-select";
import { IPodcastPlatform } from "src/types/podcast-platforms";
import { createTagManually, searchTags, updateTagName } from "src/actions/tag";
import { addOverwriteTag, disableSelectedTags, getTagFromOverwriteTag, sortTags } from "src/utils/tag";

const articleAudioTypes = [
    { value: ArticleAudioType.INTERNAL, text: "On-Platform" },
    { value: ArticleAudioType.EXTERNAL, text: "Off-Platform" },
    { value: ArticleAudioType.BOTH, text: "Both" },
];

const articleTypes = [
    { value: ArticleType.REGULAR, text: "Regular" },
    { value: ArticleType.FLASH, text: "Flash" },
    { value: ArticleType.NUTSHELL, text: "Nutshell" },
    { value: ArticleType.STORY_INTRO, text: "Story Intro" },
    { value: ArticleType.STORY_BRIDGE, text: "Story Bridge" },
    { value: ArticleType.STORY_OUTRO, text: "Story Outro" },
];

const articleValues = [
    { value: ArticleValue.SINGLE, text: "Single" },
    { value: ArticleValue.DOUBLE, text: "Double" },
    { value: ArticleValue.TRIPLE, text: "Triple" },
    { value: ArticleValue.QUADRUPLE, text: "Quadruple" },
    { value: ArticleValue.QUINTUPLE, text: "Quintuple (5)" },
    { value: ArticleValue.SEXTUPLE, text: "Sextuple (6)" },
    { value: ArticleValue.SEPTUPLE, text: "Septuple (7)" },
    { value: ArticleValue.OCTUPLE, text: "Octuple (8)" },
    { value: ArticleValue.NONUPLE, text: "Nonuple (9)" },
];

// update from the backend as well.
const DISPLAY_TAGS_MAX_LENGTH = 60;

const DISPLAY_TAGS_TOTAL_LENGTH = 120;

const MaxLengthInput = (props: any) => <components.Input {...props} maxLength={DISPLAY_TAGS_MAX_LENGTH} />;

interface IProp {
    article?: IArticle & { filterPlaylistsBySectionId: number | string | undefined };
    articleTemplates?: IArticleTemplate[];
    error?: errors.HttpError;
    isLoading: boolean;
    user: IUser;
    newspapers: INewspaper[];
    sections: ISection[];
    sectionsRaw: ISection[];
    playlists: IPlaylist[];
    templates: ITemplate[];
    narrators: IUser[];
    journalists: IJournalist[];
    PronunciationForm?: any;
    updateFile: typeof updateFile;
    createJournalist: () => void;
    fetchPlaylists: (sectionId: number) => void;
    suggestPlaylists: (params: string[]) => void;
    suggestTags: (text: string) => Promise<string[]>;
    createArticleTemplate: any;
    deleteArticleTemplate: typeof deleteArticleTemplate;
    setSelectedPlaylists: any;
    voiceRequirements: IVoiceRequirement[];
    podcastPlatforms: IPodcastPlatform[];
}

interface IState {
    showNarratorTitleField: boolean;
    showPublisherArticleId: boolean;
    selectedArticleTemplate?: IArticleTemplate;
    tagsSearchRequestController?: AbortController;
    tagsSearchRequestSignal?: AbortSignal;
    displayTagsInput: string;
    hiddenTagsInput: string;
}

class ArticleForm extends React.Component<IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>, IState> {
    private searchTagsForReactSelectDebounce: (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => void;

    constructor(props: IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>) {
        super(props);

        this.state = {
            showNarratorTitleField: !!(props && props.article && props.article.altNameForNarration),
            showPublisherArticleId: !!(props && props.article && props.article.publisherArticleId),
            displayTagsInput: "",
            hiddenTagsInput: "",
        };

        this.searchTagsForReactSelectDebounce = _.debounce(this.searchTagsForReactSelect, 400);
    }

    public isShowingNarratorField = (): boolean => {
        const { article } = this.props;
        return !!(this.state.showNarratorTitleField || (article && article.altNameForNarration));
    };

    public toggleNarratorTitleField = () => {
        const { change } = this.props;

        change("altNameForNarration", "");
        this.setState({ showNarratorTitleField: !this.isShowingNarratorField() });
    };

    public isShowingPublisherArticleIdField = (): boolean => {
        const { article } = this.props;
        return !!(this.state.showPublisherArticleId || (article && article.publisherArticleId));
    };

    public togglePublisherArticleIdField = () => {
        const { change } = this.props;

        change("publisherArticleId", "");
        this.setState({
            showPublisherArticleId: !this.isShowingPublisherArticleIdField(),
        });
    };

    public blockSubmit = (): string | null => {
        const { article } = this.props;
        if (!article) {
            return null;
        }

        const fieldsMissing = missingFieldsToPublish(article);

        if (article.articleIsPublished === 1 && fieldsMissing.length > 0) {
            return "Unpublish the article or fill all the required fields to enable saving";
        }

        return null;
    };

    public isPublishDisallow = () => {
        const { article } = this.props;
        if (!article) {
            return false;
        }

        const fieldsMissing = missingFieldsToPublish(article);

        if (article.articleIsPublished === 1 && fieldsMissing.length > 0) {
            return false;
        }

        return fieldsMissing.length > 0;
    };

    public tooltipPublishMessage = () => {
        const { article } = this.props;

        if (!article) {
            return "";
        }

        const missingFields = missingFieldsToPublish(article);

        if (missingFields.length > 0) {
            return `Missing Fields: ${missingFields.join(", ")}`;
        }

        return "You can publish or unpublish";
    };

    public createJournalist = () => {
        this.props.change("journalistName", "");
        this.props.createJournalist();
    };

    public createArticleTemplate = (event: any) => {
        event.preventDefault();

        this.props.handleSubmit((values) => {
            return this.props.createArticleTemplate({ ...values });
        })(event);
    };

    public onSubmit = (event: any, changePage: boolean) => {
        event.preventDefault();

        this.props.handleSubmit((values) => {
            // @ts-ignore
            return this.props.onSubmit({ ...values, changePage });
        })(event);
    };

    public getSuggestions = () => {
        const { article } = this.props;
        if (!article) {
            return;
        }

        const tags = article.tagsId || [];

        this.props.suggestPlaylists(tags.map((tag) => tag.tagName));
    };

    public modalTemplateConfirmDescription = () => {
        return (
            <>
                <p>Are you sure that you want save this article? </p>
                <input type="text">Name</input>
            </>
        );
    };

    public modalConfirmDescription = () => {
        const { article } = this.props;

        if (!article) {
            return null;
        }

        const missingFields = missingFieldsForSaveWarning(article);
        const hasErrors = !!missingFields && missingFields.length > 0;

        const warnWords = getWarnWords(article);
        const hasWarnWords = !!warnWords && warnWords.length > 0;

        return (
            <>
                {hasErrors && (
                    <>
                        <p className={css(styles.danger)}>
                            <b>
                                ⚠ WARNING - CRITICAL ISSUES
                                <br />
                            </b>
                            The following critical issues are present in this article:
                        </p>
                        <ul className={css(styles.danger, styles.warningList)}>
                            {missingFields.map((f) => (
                                <li key={f}>✖ {f} is missing</li>
                            ))}
                        </ul>
                    </>
                )}

                {hasWarnWords && (
                    <>
                        <p className={css(styles.warn)}>
                            <b>
                                ⚠ PLEASE REVIEW - DANGER WORDS
                                <br />
                            </b>
                            In some contexts, the following words will hurt an article's longevity or lead to narration error. Please ensure they are being used appropriately.
                        </p>
                        <p className={css(styles.warn)}>
                            <i>In order of occurance:</i>
                            <br />
                            {warnWords &&
                                warnWords.map((word, i) => (
                                    <span key={word}>
                                        '{word}'{i === warnWords.length - 1 ? "" : ",  "}
                                    </span>
                                ))}
                        </p>
                        <br />
                    </>
                )}

                <p>
                    If you proceed, this article will be saved in a
                    <span className={`blue-text ${css(styles.modalConfirmPublishMessage)}`}>{article.articleIsPublished === 1 ? " PUBLISHED " : " UNPUBLISHED "}</span>
                    state.
                </p>
            </>
        );
    };

    public searchTagsForReactSelect = (searchText: string, callback: (tags: any) => any, displayTags?: boolean) => {
        // aborting already running request
        if (this.state.tagsSearchRequestController) {
            this.state.tagsSearchRequestController.abort();
        }

        // creating new controller
        const newController = new AbortController();
        const newSignal = newController.signal;

        this.setState((prev) => ({ ...prev, tagsSearchRequestController: newController, tagsSearchRequestSignal: newSignal }));

        const options: { signal: AbortSignal; maxLength?: number } = { signal: newSignal };

        if (displayTags) {
            options.maxLength = DISPLAY_TAGS_MAX_LENGTH;
        }

        if (!searchText.trim()) return callback([]);

        searchTags(searchText, options)
            .then((tags) =>
                callback(
                    disableSelectedTags(
                        addOverwriteTag(
                            searchText,
                            sortTags(
                                searchText,
                                tags.map((tag) => ({
                                    label: tag.tagName,
                                    value: tag.tagID,
                                })),
                            ),
                            [...(this.props.article?.displayTagsId || []), ...(this.props.article?.tagsId || [])],
                        ),
                        [...(this.props.article?.displayTagsId || []), ...(this.props.article?.tagsId || [])],
                    ),
                ),
            )
            .catch(() => {
                toast(`Error searching tags by "${searchText}"`);
                callback([]);
            });
    };

    public createTag = (tagName: string, isDisplayTag?: boolean) => {
        const tagsIdName = isDisplayTag ? "displayTagsId" : "tagsId";
        const tags = this.props.article?.[tagsIdName] || [];

        if (!tagName.trim()) return toast("Empty tags cannot be created.");

        createTagManually(tagName.trim())
            .then((tag) => {
                if (isDisplayTag) {
                    this.onChangeDisplayTags({ label: tag.tagName, value: tag.tagID });
                } else {
                    this.props.change(tagsIdName, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
                }
            })
            .catch(() => {
                toast(`Error creating tag: ${tagName}`);
            });
    };

    public isTagInputValid = (input: string) => {
        if (input === "") return true;
        return /^[0-9A-Za-z]+$/.test(input);
    };

    public onDisplayTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ displayTagsInput: input });
        }
    };

    public onHiddenTagsInputChange = (input: string) => {
        if (this.isTagInputValid(input)) {
            this.setState({ hiddenTagsInput: input });
        }
    };

    public onChangeDisplayTags = (newTags: { label: string; value: number } | { label: string; value: number }[]) => {
        const { article, change } = this.props;
        const displayTags = article?.displayTagsId || [];
        const displayTagsLength = displayTags.reduce((prev, curr) => prev + curr.tagName.length, 0) as number;

        if (Array.isArray(newTags)) {
            const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

            // when tag is removed
            if (!newTag) {
                change(
                    "displayTagsId",
                    newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
                );
                return;
            }

            // when tag is selected
            if (displayTagsLength + newTag.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
                // updating the tagname on the backend to correct the tag's cast style
                this.updateTagNameBeforeSelect(newTag.label, newTag.value, "displayTagsId");
                return;
            }

            // when tag is created
        } else if (displayTagsLength + newTags.label.length <= DISPLAY_TAGS_TOTAL_LENGTH) {
            change("displayTagsId", [...displayTags, { tagName: newTags.label, tagId: newTags.value }]);
            return;
        }

        toast("Display tags limit exceeded.");
    };

    public onChangeHiddenTags = (newTags: { label: string; value: number }[]) => {
        const { article, change } = this.props;
        const displayTags = article?.tagsId || [];

        const newTag = newTags.find((nt) => !displayTags.some((t) => t.tagId === nt.value));

        // when tag is removed
        if (!newTag) {
            change(
                "tagsId",
                newTags.map((i) => ({ tagName: i.label, tagId: i.value })),
            );
            return;
        }

        // when tag is selected
        // updating the tagname on the backend to correct the tag's cast style
        this.updateTagNameBeforeSelect(newTag.label, newTag.value, "tagsId");
    };

    public updateTagNameBeforeSelect = (tagName: string, tagId: number, tagsId: "tagsId" | "displayTagsId") => {
        const { article, change } = this.props;
        const tags = article?.[tagsId] || [];

        // to overwrite the tag instead of auto-update
        const tag = getTagFromOverwriteTag(tagName, tagId.toString());

        return updateTagName(tag.tagId, tag.tagName)
            .then((tag) => {
                change(tagsId, [...tags, { tagName: tag.tagName, tagId: tag.tagID }]);
            })
            .catch(() => {
                toast(`unable to select: ${tagName}`);
            });
    };

    public changeNewspaper = (name: string, value: any) => {
        this.props.change("journalistsId", []);
        this.props.change(name, value);
    };

    public changeArticleIntro = (id: number) => {
        const { templates } = this.props;

        const template = templates && templates.find((t) => t.templateId === +id);

        this.props.change("intro", template);
    };

    public changeArticleOutro = (id: number) => {
        const { templates } = this.props;

        const template = templates && templates.find((t) => t.templateId === +id);

        this.props.change("outro", template);
    };

    public changeArticleTemplate = (id: number) => {
        const { articleTemplates } = this.props;

        if (!articleTemplates) {
            return;
        }

        const selectedTemplate = articleTemplates.find((a) => a.templateId === +id);
        this.setState({ selectedArticleTemplate: selectedTemplate });

        if (!selectedTemplate) {
            return;
        }

        this.props.change("newspaperId", selectedTemplate.newspaperId != null ? selectedTemplate.newspaperId : null);
        this.props.change("articleIsNotified", selectedTemplate.articleIsNotified != null ? selectedTemplate.articleIsNotified : false);
        this.props.change("articleName", selectedTemplate.articleName != null ? selectedTemplate.articleName : "");
        this.props.change("articleComments", selectedTemplate.articleComments != null ? selectedTemplate.articleComments : "");
        this.props.change("articleText", selectedTemplate.articleText != null ? selectedTemplate.articleText : "");
        this.props.change("articlePreviewText", selectedTemplate.articlePreviewText != null ? selectedTemplate.articlePreviewText : "");
        this.props.change("altNameForNarration", selectedTemplate.altNameForNarration != null ? selectedTemplate.altNameForNarration : null);
        this.props.change("articleType", selectedTemplate.articleType != null ? selectedTemplate.articleType : null);
        this.props.change("articleValue", selectedTemplate.articleValue != null ? selectedTemplate.articleValue : null);
        this.props.change("articleOriginLink", selectedTemplate.articleOriginLink ? selectedTemplate.articleOriginLink : "");
        this.props.change("articleSection", selectedTemplate.articleSection != null ? selectedTemplate.articleSection : "");
        this.props.change("articleSubName", selectedTemplate.articleSubName != null ? selectedTemplate.articleSubName : "");
        this.props.change("articleIsPublished", selectedTemplate.articleIsPublished != null ? selectedTemplate.articleIsPublished : false);
        this.props.change("introId", selectedTemplate.introId != null ? selectedTemplate.introId : null);
        this.props.change("outroId", selectedTemplate.outroId != null ? selectedTemplate.outroId : null);
        this.props.change("tagsId", selectedTemplate.tagsId != null ? selectedTemplate.tagsId : null);
        this.props.change("displayTagsId", selectedTemplate.displayTagsId != null ? selectedTemplate.displayTagsId : null);
        this.props.change("articleQuality", selectedTemplate.articleQuality != null ? selectedTemplate.articleQuality : null);
        this.props.change("articleTimeliness", selectedTemplate.articleTimeliness != null ? selectedTemplate.articleTimeliness : null);
        this.props.change("articleComprehension", selectedTemplate.articleComprehension != null ? selectedTemplate.articleComprehension : null);
        this.props.change("articlePolarisation", selectedTemplate.articlePolarisation != null ? selectedTemplate.articlePolarisation : null);
        this.props.change("articleAudioRecorderID", selectedTemplate.articleAudioRecorderID != null ? selectedTemplate.articleAudioRecorderID : null);

        const journalistsTransformed = selectedTemplate.journalists ? selectedTemplate.journalists.map((j) => j.journalistId) : [];
        this.props.change("journalistsId", journalistsTransformed);

        const playlistIds = selectedTemplate.playlists ? _.map(selectedTemplate.playlists, (p) => `${p.playlistId}`) : [];
        this.props.change("playlistsId", playlistIds);

        // const tagName = selectedTemplate.tags ? selectedTemplate.tags.map((t) => t.tagName) : [];
        // this.props.change("tagsId", tagName);
    };

    public onChangePlaylist = (playlistsFiltered: IPlaylist[]) => (newPlaylistIds: string[], previousPlaylistIds: string[]) => {
        this.props.setSelectedPlaylists(playlistsFiltered, newPlaylistIds, previousPlaylistIds);

        const playlistIds = _.map(playlistsFiltered, (p) => `${p.playlistId}`);
        const oldPlaylistIds = _.filter(previousPlaylistIds, (p) => !_.includes(playlistIds, p));

        // REDUX-FORM DISPATCH ACTION THAT CLEANS EVERYTHING, THAT'S WHY I DELAYED, TO OVERRIDE REDUX-FORM ACTION
        // setTimeout(() => this.props.change("playlistsId", [...oldPlaylistIds, ...newPlaylistIds]), 50)
        this.props.change("playlistsId", [...oldPlaylistIds, ...newPlaylistIds]);
    };

    public onChangeSection = (newSectionId: string) => {
        // SUGGESTED PLAYLISTS ID
        if (newSectionId === "-1") {
            this.getSuggestions();
            return;
        }

        this.props.fetchPlaylists(+newSectionId);
    };

    public shouldComponentUpdateList = (prevPropList: any[] = [], nextPropList: any[] = []) => {
        return _.size(_.differenceWith(nextPropList, prevPropList, _.isEqual)) > 0;
    };

    public shouldComponentUpdateListXor = (prevPropList: any[] = [], nextPropList: any[] = []) => {
        return _.xor(prevPropList, nextPropList).length !== 0;
    };

    public shouldComponentUpdate(nextProps: IProp & RouteComponentProps<{}> & InjectedFormProps<{}, IProp & RouteComponentProps<{}>>, nextState: any) {
        if (this.state.showNarratorTitleField !== nextState.showNarratorTitleField) {
            return true;
        }

        if (this.state.hiddenTagsInput !== nextState.hiddenTagsInput) {
            return true;
        }

        if (this.state.displayTagsInput !== nextState.displayTagsInput) {
            return true;
        }

        if (this.state.showPublisherArticleId !== nextState.showPublisherArticleId) {
            return true;
        }

        const { article, sections, journalists, playlists, narrators, newspapers, valid, articleTemplates } = this.props;

        if (this.shouldComponentUpdateListXor(articleTemplates, nextProps.articleTemplates)) {
            return true;
        }

        if (this.shouldComponentUpdateList(sections, nextProps.sections)) {
            return true;
        }

        if (!_.isEqual(playlists, nextProps.playlists)) {
            return true;
        }

        if (this.shouldComponentUpdateList(journalists, nextProps.journalists)) {
            return true;
        }

        if (this.shouldComponentUpdateList(narrators, nextProps.narrators)) {
            return true;
        }

        if (this.shouldComponentUpdateList(newspapers, nextProps.newspapers)) {
            return true;
        }

        if (valid !== nextProps.valid) {
            return true;
        }

        if (
            article &&
            nextProps.article &&
            (article.articleAudioRecorderID !== nextProps.article.articleAudioRecorderID ||
                article.articleIsNotified !== nextProps.article.articleIsNotified ||
                article.articleName !== nextProps.article.articleName ||
                article.altNameForNarration !== nextProps.article.altNameForNarration ||
                article.articleType !== nextProps.article.articleType ||
                article.articleValue !== nextProps.article.articleValue ||
                article.articleAudioType !== nextProps.article.articleAudioType ||
                article.articleOriginLink !== nextProps.article.articleOriginLink ||
                article.sectionsId !== nextProps.article.sectionsId ||
                article.sectionsIdx !== nextProps.article.sectionsIdx ||
                article.publisherArticleId !== nextProps.article.publisherArticleId ||
                article.articleSection !== nextProps.article.articleSection ||
                article.articleIsPublished !== nextProps.article.articleIsPublished ||
                article.playlistsId !== nextProps.article.playlistsId ||
                article.journalistsId !== nextProps.article.journalistsId ||
                article.introId !== nextProps.article.introId ||
                article.outroId !== nextProps.article.outroId ||
                article.tagsId !== nextProps.article.tagsId ||
                article.displayTagsId !== nextProps.article.displayTagsId ||
                article.filterPlaylistsBySectionId !== nextProps.article.filterPlaylistsBySectionId ||
                article.newspaperId !== nextProps.article.newspaperId ||
                article.articleQuality !== nextProps.article.articleQuality ||
                article.articleTimeliness !== nextProps.article.articleTimeliness ||
                article.articleComprehension !== nextProps.article.articleComprehension ||
                article.articlePolarisation !== nextProps.article.articlePolarisation ||
                article.voiceRequirementIds !== nextProps.article.voiceRequirementIds ||
                article.articleImageFileName !== nextProps.article.voiceRequirementIds ||
                article.articleAudioFileName !== nextProps.article.articleAudioFileName ||
                article.articleExternalAudioFileName !== nextProps.article.articleExternalAudioFileName ||
                article.podcastPlatformIds !== nextProps.article.podcastPlatformIds)
        ) {
            return true;
        }

        return false;
    }

    public deleteArticleTemplate = (event: any) => {
        event.preventDefault();

        const { selectedArticleTemplate } = this.state;

        if (!selectedArticleTemplate) {
            return;
        }

        this.props.deleteArticleTemplate(selectedArticleTemplate);
        this.setState({ selectedArticleTemplate: undefined });
    };

    public renderArticleTemplates() {
        const { articleTemplates, change } = this.props;
        const { selectedArticleTemplate } = this.state;

        if (!articleTemplates) {
            return null;
        }

        const articleTemplatesTransformed = articleTemplates.map((a) => ({
            text: a.name || "",
            value: a.templateId || 0,
        }));

        return (
            <Card title="Article Template">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <InputSelect placeholder="Select a template" rows={articleTemplatesTransformed} name="articleTemplate" change={change} onChange={this.changeArticleTemplate} />
                                <label>Select a template</label>
                            </InputField>
                        </Col>
                        {selectedArticleTemplate && (
                            <Col s={12}>
                                <Button
                                    text={`Delete template '${selectedArticleTemplate.name}'`}
                                    icon="delete"
                                    // tslint:disable-next-line: jsx-no-lambda
                                    onClick={this.deleteArticleTemplate}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col s={12}>
                            <Button
                                text="Save article as template"
                                icon="save"
                                submit={false}
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={(e: any) => this.createArticleTemplate(e)}
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderTextEditor() {
        const { article, change } = this.props;

        return (
            <Card title="Text Editor">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <RichInputText
                                    // tslint:disable-next-line: jsx-no-lambda
                                    onChange={(text: string) => change("articleText", text)}
                                    initialValue={article ? article.articleText : ""}
                                />
                            </InputField>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderPreviewTextEditor() {
        const { article, change } = this.props;

        return (
            <Card title="Preview Text">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12}>
                            <label className="active">* Preview text can't be more than 1000 characters.</label>
                            <InputField>
                                <RichInputText
                                    limit={1000}
                                    // tslint:disable-next-line: jsx-no-lambda
                                    onChange={(text: string) => change("articlePreviewText", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "")}
                                    initialValue={article ? article.articlePreviewText : ""}
                                    allowBullets
                                />
                            </InputField>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderArticleInfo() {
        const { article, change, journalists, narrators, newspapers } = this.props;

        const journalistsId = article ? article.journalistsId : [];
        const newspaperId = article ? article.newspaperId : "";

        const newspapersTransformed = newspapers.map((n) => ({
            text: n.newspaperName,
            value: n.newspaperID,
        }));

        const journalistsTransformed = journalists.map((j) => ({
            text: j.journalistName,
            value: j.journalistId,
        }));

        const narratorsTransformed = narrators.map((n) => ({
            text: `${n.userFirstName} ${n.userLastName}`,
            value: n.userId,
        }));

        return (
            <Card title="Article Information">
                <div className={css(styles.removeMarginBottom2)}>
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <label htmlFor="articleName">{this.isShowingNarratorField() ? "App Title" : "App & Narration Title"} *</label>
                                <InputText type="text" name="articleName" required={true} change={change} initialValue={article ? article.articleName : ""} />
                            </InputField>
                        </Col>
                    </Row>
                </div>
                <div className={css(styles.removeMarginBottom2)}>
                    {this.isShowingNarratorField() && (
                        <Row>
                            <Col s={12}>
                                <InputField>
                                    <label htmlFor="altNameForNarration">Narration Title</label>
                                    <InputText type="text" name="altNameForNarration" required={false} change={change} initialValue={article ? article.altNameForNarration : ""} />
                                </InputField>
                            </Col>
                        </Row>
                    )}
                </div>
                <div className={css(styles.textButtonWrapper)}>
                    <p className={css(styles.textButton, this.isShowingNarratorField() && styles.textButtonRed)} onClick={this.toggleNarratorTitleField}>
                        {this.isShowingNarratorField() ? "Remove narration title" : "Create a separate narration title"}
                    </p>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect placeholder="Select a Publication" rows={newspapersTransformed} name="newspaperId" change={this.changeNewspaper} value={newspaperId} />
                                <label>Publication *</label>
                            </InputField>
                        </Col>

                        <Col s={12} m={6}>
                            <InputField>
                                <label htmlFor="articleSection">Publication Section</label>
                                <InputText type="text" name="articleSection" change={change} initialValue={article ? article.articleSection : ""} className="red" />
                            </InputField>
                        </Col>
                    </Row>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect rows={narratorsTransformed} name="articleAudioRecorderID" placeholder="Select One Recorder" change={change} />
                                <label>Narrator</label>
                            </InputField>
                        </Col>

                        <Col s={12} m={6}>
                            <InputField>
                                <label htmlFor="articleSubName">Subtitle</label>

                                <InputText type="text" name="articleSubName" change={change} initialValue={article ? article.articleSubName : ""} />
                            </InputField>
                        </Col>
                    </Row>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect placeholder="Select Journalists" rows={journalistsTransformed} name="journalistsId" change={change} multiple={true} value={journalistsId} />
                                <label>Journalists</label>
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <i className={`${css(styles.pointer)} material-icons postfix blue-text`} onClick={this.createJournalist}>
                                    add
                                </i>

                                <InputText
                                    type="text"
                                    name="journalistName"
                                    change={change}
                                    initialValue={article ? article.journalistName : ""}
                                    placeholder="New Journalist"
                                    className={css(styles.inputNewJournalist)}
                                />
                            </InputField>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderTemplates() {
        const { article, change, templates } = this.props;

        const introId = article ? article.introId : "";
        const outroId = article ? article.outroId : "";

        const introsTransformed = templates
            .filter((t) => t.templateTypeId === TemplateTypes.INTRO)
            .map((t) => ({
                text: t.templateName,
                value: t.templateId,
            }));

        const outrosTransformed = templates
            .filter((t) => t.templateTypeId === TemplateTypes.OUTRO)
            .map((t) => ({
                text: t.templateName,
                value: t.templateId,
            }));

        const showManualIntro = !article || article.intro?.templateName === "BLANK";
        const showManualOutro = !article || article.outro?.templateName === "BLANK";

        return (
            <Card title="Script Template">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect placeholder="Select One Intro" rows={introsTransformed} name="introId" onChange={this.changeArticleIntro} change={change} value={introId} />
                                <label>Intro</label>
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect placeholder="Select One Outro" rows={outrosTransformed} name="outroId" onChange={this.changeArticleOutro} change={change} value={outroId} />
                                <label>Outro</label>
                            </InputField>
                        </Col>
                    </Row>
                    {showManualIntro && (
                        <Row>
                            <Col s={12}>
                                <label>Manual Intro</label>
                                <div className="manual-intro-outro">
                                    <InputField>
                                        <RichInputTextV2
                                            // tslint:disable-next-line: jsx-no-lambda
                                            onChange={(text: string) => {
                                                change("manualIntro", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "");
                                            }}
                                            initialValue={article ? article.manualIntro : ""}
                                        />
                                    </InputField>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {showManualOutro && (
                        <Row>
                            <Col s={12}>
                                <label>Manual Outro</label>
                                <div className="manual-intro-outro">
                                    <InputField>
                                        <RichInputTextV2
                                            // tslint:disable-next-line: jsx-no-lambda
                                            onChange={(text: string) => {
                                                change("manualOutro", text !== "<p>&nbsp;</p>" && text !== "<p><br></p>" ? text : "");
                                            }}
                                            initialValue={article ? article.manualOutro : ""}
                                        />
                                    </InputField>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </Card>
        );
    }

    public renderAssets() {
        const { article, change } = this.props;

        // @ts-ignore
        const articleAudioType = article ? +article.articleAudioType?.valueOf() : 3;

        const showInternalAudioField = articleAudioType === 0 || articleAudioType === 2;
        const showExternalAudioField = articleAudioType === 1 || articleAudioType === 2;

        return (
            <Card title="Assets">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputFile
                                    model="articles"
                                    id="articleImageFileName"
                                    upFile={this.props.updateFile}
                                    type={FileType.image}
                                    change={this.props.change}
                                    value={article && article.articleImageFileName}
                                    imageSizeCheck
                                    imageDimensionsCheck
                                />
                                <label className="active">Image</label>
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect rows={articleAudioTypes} name="articleAudioType" placeholder="Select One Value" change={change} required={false} />
                                <label>Audio Platforms *</label>
                            </InputField>
                        </Col>
                        {showInternalAudioField && (
                            <Col s={12} m={6}>
                                <InputField>
                                    <InputFile
                                        model="articles"
                                        id="articleAudioFileName"
                                        upFile={this.props.updateFile}
                                        type={FileType.audio}
                                        change={this.props.change}
                                        value={article && article.articleAudioFileName}
                                        audioBitrateCheck
                                    />
                                    <label className="active">On-Platform Audio</label>
                                </InputField>
                            </Col>
                        )}

                        {showExternalAudioField && (
                            <Col s={12} m={6}>
                                <InputField>
                                    <InputFile
                                        model="articles"
                                        id="articleExternalAudioFileName"
                                        upFile={this.props.updateFile}
                                        type={FileType.audio}
                                        change={this.props.change}
                                        value={article && article.articleExternalAudioFileName}
                                        audioBitrateCheck
                                    />
                                    <label className="active">Off-Platform Audio</label>
                                </InputField>
                            </Col>
                        )}
                    </Row>
                </div>
            </Card>
        );
    }

    public renderDates() {
        const { change } = this.props;

        return (
            <Card title="Article Dates">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <label htmlFor="articleViewDateTime">View Date</label>
                                <InputDate change={change} name="articleViewDateTime" />
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <label htmlFor="articleOriginalPublicationDateTime">Original Publication Date</label>
                                <InputDate change={change} name="articleOriginalPublicationDateTime" />
                            </InputField>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public transformPlaylistsForSelect = (playlists: IPlaylist[]) => {
        return _.map(playlists, (p) => ({
            label: (p.playlistIsPublished ? "" : "[UNPUBLISHED] ") + p.playlistName,
            value: p.playlistId,
        }));
    };

    public transformVoiceRequirementsForSelect = (voiceRequirements: IVoiceRequirement[]) => {
        return _.map(voiceRequirements, (vr) => ({
            label: vr.name,
            value: vr.id,
        }));
    };

    public transformPodcastPlatformsForSelect = (podcastPlatforms: IPodcastPlatform[]) => {
        return _.map(podcastPlatforms, (pp) => ({
            label: pp.name,
            value: pp.id,
        }));
    };

    public renderPodcastPlatforms() {
        const { change, article, podcastPlatforms } = this.props;

        const podcastPlatformIds = article?.podcastPlatformIds || [];
        const podcastPlatformsTransformed = this.transformPodcastPlatformsForSelect(podcastPlatforms);
        const selectedPodcastPlatforms = this.transformPodcastPlatformsForSelect(podcastPlatforms.filter((pp) => podcastPlatformIds.includes(pp.id.toString())));

        return (
            <Card title="Podcast Platforms">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={12}>
                            <ReactSelect
                                isMulti
                                styles={ReactSelectStylesMulti}
                                value={selectedPodcastPlatforms}
                                name="podcastplatforms"
                                placeholder="Select Podcast Platforms"
                                isClearable={false}
                                options={podcastPlatformsTransformed}
                                onChange={(pp) =>
                                    change(
                                        "podcastPlatformIds",
                                        Array.from(pp).map((i) => i.value.toString()),
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderPlaylistSelection() {
        const { article, change, sections, playlists } = this.props;

        const sectionsTransformed = sections.map((c) => ({
            label: c.sectionName,
            value: c.sectionId,
        }));

        const playlistsId = article?.playlistsId || [];
        const playlistsTransformed = this.transformPlaylistsForSelect(playlists);
        const selectedPlaylists = this.transformPlaylistsForSelect(playlists.filter((p) => playlistsId.includes(p.playlistId.toString())));

        return (
            <Card title="Series &amp; Playlists">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <label>Category</label>
                            <ReactSelect
                                styles={ReactSelectStyles}
                                name="playlistSections"
                                placeholder="Select Category"
                                options={sectionsTransformed}
                                onChange={(section) => section && this.onChangeSection(section.value.toString())}
                            />
                        </Col>
                        <Col s={12} m={6}>
                            <label>Series/Playlists</label>
                            <ReactSelect
                                isMulti
                                styles={ReactSelectStylesMulti}
                                value={selectedPlaylists}
                                name="playlists"
                                placeholder="Select Series/Playlists"
                                isClearable={false}
                                options={playlistsTransformed}
                                onChange={(p) =>
                                    change(
                                        "playlistsId",
                                        Array.from(p).map((i) => i.value.toString()),
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public changeCategory = (newIds: string) => {
        // updates the selected sub-categories based on selected parent categories
        const { article, sectionsRaw, change } = this.props;
        const sectionsIdx = article?.sectionsIdx ? article.sectionsIdx : [];
        const sectionsIdxComplete = sectionsRaw.filter((sec) => sectionsIdx.includes(sec.sectionId.toString()));
        const filteredSectionsIdx = sectionsIdxComplete.filter((sec) => sec.parentId && newIds.includes(sec.parentId.toString())).map((sec) => sec.sectionId.toString());

        change("sectionsIdx", filteredSectionsIdx);
    };

    public renderMetadata() {
        const { article, change, sectionsRaw } = this.props;
        const { displayTagsInput, hiddenTagsInput } = this.state;

        const tags = article?.tagsId || [];
        const displayTags = article?.displayTagsId || [];

        const displayTagsTransformed = displayTags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        const tagsTransformed = tags.map((t) => ({
            label: t.tagName,
            value: t.tagId,
        }));

        const sectionsId = article?.sectionsId ? article.sectionsId : [];
        const sectionsIdx = article?.sectionsIdx ? article.sectionsIdx : [];

        const parentSectionsTransformed = sectionsRaw
            .filter((s) => s.parentId == null)
            .map((s) => ({
                text: s.sectionName,
                value: s.sectionId,
            }));

        const subSectionsTransformed = sectionsRaw
            .filter((s) => s.parentId && sectionsId && sectionsId.includes(s.parentId.toString()))
            .map((s) => ({
                text: s.sectionName,
                value: s.sectionId,
            }));

        return (
            <Card title="Metadata">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect
                                    placeholder="Categories"
                                    rows={parentSectionsTransformed}
                                    value={sectionsId}
                                    name="sectionsId"
                                    change={change}
                                    onChange={this.changeCategory}
                                    multiple={true}
                                    required
                                />
                                <label>Categories *</label>
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect placeholder="Sub-categories" rows={subSectionsTransformed} value={sectionsIdx} name="sectionsIdx" change={change} multiple={true} required />
                                <label>Sub-categories *</label>
                            </InputField>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col s={12}>
                            <label className="active">Display tags (Shown on article card)</label>
                            <br />
                            <label className="active">* Max limit for a tag is 60 and the total limit for all the tags collectively is 120. </label>
                            <br />
                            <label className="active">* Only letters and numbers are allowed.</label>
                            <CreatableSelect
                                isMulti
                                value={displayTagsTransformed}
                                loadOptions={(inputValue, callback) => this.searchTagsForReactSelectDebounce(inputValue, callback, true)}
                                styles={ReactSelectStylesMulti}
                                name="displayTagsId"
                                placeholder="Select display tags"
                                isClearable={false}
                                onCreateOption={(t) => this.createTag(t, true)}
                                components={{ Input: MaxLengthInput }}
                                inputValue={displayTagsInput}
                                onInputChange={this.onDisplayTagsInputChange}
                                onChange={(v) => this.onChangeDisplayTags(Array.from(v))}
                                isOptionDisabled={(option: any) => option.disabled}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col s={12}>
                            <label className="active">Hidden tags (Used in search & recommendations)</label>
                            <br />
                            <label className="active">* Max limit for a tag is 60.</label>
                            <br />
                            <label className="active">* Only letters and numbers are allowed.</label>
                            <CreatableSelect
                                isMulti
                                value={tagsTransformed}
                                loadOptions={this.searchTagsForReactSelectDebounce}
                                styles={ReactSelectStylesMulti}
                                name="tagsId"
                                placeholder="Select hidden tags"
                                isClearable={false}
                                onCreateOption={this.createTag}
                                inputValue={hiddenTagsInput}
                                onInputChange={this.onHiddenTagsInputChange}
                                onChange={(v) => this.onChangeHiddenTags(Array.from(v))}
                                isOptionDisabled={(option: any) => option.disabled}
                            />
                        </Col>
                    </Row>
                </div>

                <div className={css(styles.removeMarginBottom2)}>
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <label htmlFor="articleOriginLink">Publisher Article URL *</label>
                                <InputText type="text" name="articleOriginLink" required={true} change={change} initialValue={article ? article.articleOriginLink : ""} />
                            </InputField>
                        </Col>
                        {this.isShowingPublisherArticleIdField() && (
                            <Col s={12}>
                                <InputField>
                                    <label htmlFor="publisherArticleId">Embed Player ID</label>
                                    <InputText type="text" name="publisherArticleId" required={false} change={change} initialValue={article ? article.publisherArticleId : ""} />
                                </InputField>
                            </Col>
                        )}
                    </Row>
                </div>
                <div className={css(styles.textButtonWrapper)}>
                    <p className={css(styles.textButton, this.isShowingPublisherArticleIdField() && styles.textButtonRed)} onClick={this.togglePublisherArticleIdField}>
                        {this.isShowingPublisherArticleIdField() ? "Remove embed article ID" : "Add embed player ID"}
                    </p>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6} xl={3}>
                            <InputField>
                                <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Quality</h5>
                                <p className={`${css(styles.rangeField)} range-field`}>
                                    <Field name="articleQuality" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                                    <div className={css(styles.rangeValue)}>{article && article.articleQuality}</div>
                                </p>
                            </InputField>
                        </Col>

                        <Col s={12} m={6} xl={3}>
                            <InputField>
                                <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Timeliness</h5>
                                <p className={`${css(styles.rangeField)} range-field`}>
                                    <Field name="articleTimeliness" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                                    <div className={css(styles.rangeValue)}>{article && article.articleTimeliness}</div>
                                </p>
                            </InputField>
                        </Col>

                        <Col s={12} m={6} xl={3}>
                            <InputField>
                                <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Comprehension</h5>
                                <p className={`${css(styles.rangeField)} range-field`}>
                                    <Field name="articleComprehension" type="range" component="input" min={1} max={5} className={css(styles.range)} />
                                    <div className={css(styles.rangeValue)}>{article && article.articleComprehension}</div>
                                </p>
                            </InputField>
                        </Col>

                        <Col s={12} m={6} xl={3}>
                            <InputField>
                                <h5 className={css(styles.subtitle, styles.rangeSubtitle)}>Polarisation</h5>
                                <p className={`${css(styles.rangeField)} range-field`}>
                                    <Field name="articlePolarisation" type="range" component="input" min={-5} max={5} className={css(styles.range)} />
                                    <div className={css(styles.rangeValue)}>{article && article.articlePolarisation}</div>
                                </p>
                            </InputField>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderNarratorInfo() {
        const { article, change, voiceRequirements } = this.props;
        const voiceRequirementIds = article?.voiceRequirementIds || [];
        const voiceRequirementsTransformed = this.transformVoiceRequirementsForSelect(voiceRequirements);
        const selectedVoiceRequirements = this.transformVoiceRequirementsForSelect(voiceRequirements.filter((vr) => voiceRequirementIds.includes(vr.id.toString())));

        return (
            <Card title="Narrator Information">
                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12}>
                            <InputField>
                                <InputText
                                    type="textarea"
                                    name="articleComments"
                                    change={change}
                                    initialValue={article ? article.articleComments : ""}
                                    className={`${css(styles.articleComments)} materialize-textarea`}
                                />

                                <label htmlFor="textarea1">Comments</label>
                            </InputField>
                        </Col>
                    </Row>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect rows={articleTypes} name="articleType" placeholder="Select One Value" change={change} required={false} />
                                <label>Article Type *</label>
                            </InputField>
                        </Col>
                        <Col s={12} m={6}>
                            <InputField>
                                <InputSelect rows={articleValues} name="articleValue" change={change} placeholder="Select One Value" required={true} />
                                <label>Article Value *</label>
                            </InputField>
                        </Col>
                    </Row>
                </div>

                <div className={css(styles.removeMarginBottom)}>
                    <Row>
                        <Col s={12}>
                            <label>Voice Requirements</label>
                            <ReactSelect
                                isMulti
                                styles={ReactSelectStylesMulti}
                                value={selectedVoiceRequirements}
                                name="playlists"
                                placeholder="Select Voice Requirements"
                                isClearable={false}
                                options={voiceRequirementsTransformed}
                                onChange={(vr) =>
                                    change(
                                        "voiceRequirementIds",
                                        Array.from(vr).map((i) => i.value.toString()),
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

    public renderControls(confirm: any) {
        const { valid } = this.props;

        const submitBlocked = this.blockSubmit();

        return (
            <Card title="Controls">
                <Row>
                    <Col s={12} m={3}>
                        <InputField>
                            <div className="center">
                                <h5 className={css(styles.subtitle)}>Global</h5>
                                <InputSwitch name="articleIsGlobal" />
                            </div>
                        </InputField>
                    </Col>

                    <Col s={12} m={3}>
                        <InputField>
                            <div className="center">
                                <h5 className={css(styles.subtitle)}>Trending</h5>
                                <InputSwitch name="articleIsFeatured" />
                            </div>
                        </InputField>
                    </Col>

                    <Col s={12} m={3}>
                        <InputField>
                            <div className="center">
                                <Tooltipped tooltip={this.tooltipPublishMessage()}>
                                    <h5 className={css(styles.subtitle)}>Published</h5>

                                    <InputSwitch name="articleIsPublished" disable={this.isPublishDisallow()} />
                                </Tooltipped>
                            </div>
                        </InputField>
                    </Col>
                </Row>

                <Row>
                    <Col s={12}>
                        <Field name="error" type="hidden" component={renderError} />
                    </Col>

                    <Col s={12}>
                        <div className="center">
                            <Button text="Save and Return" icon="save" onClick={confirm((e: any) => this.onSubmit(e, true))} disable={!valid || !!submitBlocked} />
                        </div>
                    </Col>

                    {!!submitBlocked && (
                        <Col s={12}>
                            <div className={`center ${css(styles.submitBlocked)}`}>
                                <p>{submitBlocked}</p>
                            </div>
                        </Col>
                    )}

                    <ButtonFixed icon="save" onClick={confirm((e: any) => this.onSubmit(e, false))} disable={!valid || !!submitBlocked} />
                </Row>
            </Card>
        );
    }

    public render() {
        return (
            <ModalConfirm title="Are you sure you want to save?" description={this.modalConfirmDescription()}>
                {(confirm: any) => (
                    <form>
                        {this.renderArticleTemplates()}
                        {this.renderArticleInfo()}
                        {this.renderDates()}
                        {this.renderTextEditor()}
                        {this.renderPlaylistSelection()}
                        {this.renderMetadata()}
                        {this.renderPreviewTextEditor()}
                        {this.renderTemplates()}
                        {this.renderNarratorInfo()}
                        {this.renderAssets()}
                        {this.renderPodcastPlatforms()}
                        {this.renderControls(confirm)}
                    </form>
                )}
            </ModalConfirm>
        );
    }
}

const styles = StyleSheet.create({
    articleComments: {
        minHeight: 60,
        maxHeight: 500,
        overflowY: "auto",
        resize: "vertical",
    },
    articleText: {
        maxHeight: 600,
        minHeight: 300,
        overflowY: "auto",
        resize: "vertical",
    },
    fixedBtn: {
        bottom: 50,
        right: 50,
    },
    inputNewJournalist: {
        marginBottom: 0,
        marginTop: -10,
    },
    modalConfirmPublishMessage: {
        fontWeight: 900,
    },
    paddingBottom: {
        paddingBottom: 30,
    },
    pointer: {
        cursor: "pointer",
    },
    range: {
        paddingTop: 40,
        paddingBottom: 40,
    },
    rangeField: {
        textAlign: "center",
    },
    rangeValue: {
        color: "#9e9e9e",
        fontSize: 12,
        marginTop: -40,
    },
    removeMarginBottom: {
        marginBottom: -20,
    },
    removeMarginBottom2: {
        marginBottom: -40,
    },
    submitBlocked: {
        color: "red",
        fontSize: 12,
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 300,
        textAlign: "center",
    },
    subtitleLeft: {
        fontSize: 14,
        fontWeight: 300,
    },
    rangeSubtitle: {
        marginBottom: -30,
    },
    tagsLabel: {
        paddingRight: 20,
    },
    textLabel: {
        color: "#9e9e9e",
        fontSize: ".8rem",
    },
    danger: {
        color: "red",
    },
    warn: {
        color: "#FF8C00",
    },
    warningList: {
        marginBottom: 40,
    },
    textButtonWrapper: {
        display: "block",
        position: "relative",
        paddingBottom: 40,
    },
    textButton: {
        color: "#039be5",
        textDecoration: "underline",
        cursor: "pointer",
        display: "block",
        position: "absolute",
        top: -10,
        left: 0,
    },
    textButtonRed: {
        color: "red",
    },
});

export default reduxForm<{}, IProp & RouteComponentProps<{}>>({
    enableReinitialize: true,
    form: "ArticleForm",
})(ArticleForm);
