import * as React from "react";
import { ButtonFlat, Modal } from "styleguide/build";

interface IState {
    callback?: any;
    open: boolean;
}

interface IProps {
    children: any;
    title?: string;
    contents?: any;
    extracontents?: any;
}

class ModalGeneric extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            callback: undefined,
            open: false,
        };
    }

    public show = (callback: (args: any) => null) => (event: any) => {
        event.preventDefault();
        event.persist();

        this.setState({
            callback: () => callback(event),
            open: true,
        });
    };

    public hide = () => this.setState({ open: false, callback: undefined });

    public confirm = () => {
        this.state.callback();
        this.hide();
    };

    public render() {
        return (
            <React.Fragment>
                {this.props.children(this.show)}

                {this.state.open && (
                    <Modal
                        title={this.props.title}
                        actions={
                            <>
                                <ButtonFlat text="Close" onClick={this.hide} />
                            </>
                        }
                    >
                        {this.props.contents}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

export default ModalGeneric;
