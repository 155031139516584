import * as errors from "../types/errors";

import {
  CLEAR_PRONUNCIATION_STATE,
  CREATE_PRONUNCIATION_FAILURE,
  CREATE_PRONUNCIATION_REQUEST,
  CREATE_PRONUNCIATION_SUCCESS,
  DELETE_PRONUNCIATION_FAILURE,
  DELETE_PRONUNCIATION_REQUEST,
  DELETE_PRONUNCIATION_SUCCESS,
  EDIT_PRONUNCIATION_FAILURE,
  EDIT_PRONUNCIATION_REQUEST,
  EDIT_PRONUNCIATION_SUCCESS,
  FETCH_PRONUNCIATION_FAILURE,
  FETCH_PRONUNCIATION_REQUEST,
  FETCH_PRONUNCIATION_SUCCESS,
  FETCH_PRONUNCIATIONS_FAILURE,
  FETCH_PRONUNCIATIONS_REQUEST,
  FETCH_PRONUNCIATIONS_SUCCESS
} from "../constants/pronunciation";
import IPronunciation from "../types/pronunciation";

export interface IState {
  pronunciations: IPronunciation[];
  pronunciation?: IPronunciation;
  isLoading: boolean;
  error?: errors.HttpError;
  offset: number;
}

export interface IAction {
  type: string;
  pronunciations?: IPronunciation[];
  pronunciation?: IPronunciation;
  editedPronunciation?: IPronunciation;
  deletedPronunciation?: IPronunciation;
  error?: errors.HttpError;
  offset?: number;
}

export const initialState: IState = {
  isLoading: false,
  offset: 0,
  pronunciations: [],
};

export const reducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_PRONUNCIATIONS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_PRONUNCIATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        offset: action.offset || 0,
        pronunciations: action.pronunciations || [],
      };
    case FETCH_PRONUNCIATIONS_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_PRONUNCIATION_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case FETCH_PRONUNCIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronunciation: action.pronunciation
      };
    case FETCH_PRONUNCIATION_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CREATE_PRONUNCIATION_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case CREATE_PRONUNCIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronunciation: action.pronunciation
      };
    case CREATE_PRONUNCIATION_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case EDIT_PRONUNCIATION_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case EDIT_PRONUNCIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronunciations: state.pronunciations.map(p => p.pronunciationId === action.editedPronunciation?.pronunciationId ? action.editedPronunciation : p),
      };
    case EDIT_PRONUNCIATION_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case DELETE_PRONUNCIATION_REQUEST:
      return { ...state, isLoading: true };
    case DELETE_PRONUNCIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pronunciations: state.pronunciations.filter(p => p.pronunciationId !== action.deletedPronunciation?.pronunciationId),
      };
    case DELETE_PRONUNCIATION_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case CLEAR_PRONUNCIATION_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
