import * as React from "react";

import { css, StyleSheet } from "aphrodite";
import {
  Button,
  Col,
  InputDate,
  InputField,
  ModalConfirm,
  Row,
} from "styleguide";

import { Field, InjectedFormProps, reduxForm } from "redux-form";
import ICompany from "../types/company";
import * as errors from "../types/errors";
import { renderError, renderInput } from "../utils/input";
import { required } from "../utils/validations";
import InputSwitch from "./InputSwitch";
import InputFile from "./InputFile";
import FileType from "../types/file-type";
import { updateFile } from "../actions/file";

interface IProp {
  company?: ICompany;
  error?: errors.HttpError;
  updateFile: typeof updateFile;
}

export class CompanyForm extends React.Component<
  IProp & InjectedFormProps<{}, IProp>
> {
  public render() {
    const { handleSubmit, change, company, valid } = this.props;

    if (!company) {
      return null;
    }

    return (
      <ModalConfirm
        title="Confirmation"
        description="Are you sure that you want save this company?"
      >
        {(confirm: any) => (
          <form onSubmit={confirm(handleSubmit)}>
            <Row>
              <Col s={12}>
                <Row>
                  <Col s={12} l={6}>
                    <InputField>
                      <label htmlFor="companyName">Name *</label>
                      <Field
                        name="companyName"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12} l={6}>
                    <InputField>
                      <label htmlFor="companyDomain">Domain *</label>
                      <Field
                        name="companyDomain"
                        type="text"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12} l={6}>
                    <InputField>
                      <label htmlFor="companyMaxSubscribers">
                        Max subscribers *
                      </label>
                      <Field
                        name="companyMaxSubscribers"
                        type="number"
                        component={renderInput}
                        validate={required}
                      />
                    </InputField>
                  </Col>
                  <Col s={12} l={6}>
                    <InputField>
                      <label htmlFor="companySubscriptionEndDate">
                        Subscription end date
                      </label>
                      <InputDate
                        name="companySubscriptionEndDate"
                        change={change}
                      />
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={12} l={6}>
                    <InputField>
                      <InputFile
                        model="companies"
                        id="logoFileName"
                        upFile={this.props.updateFile}
                        type={FileType.image}
                        change={this.props.change}
                        value={company && company.logoFileName}
                      />
                      <label className="active">Logo</label>
                    </InputField>
                  </Col>
                </Row>

                <Row>
                  <Col s={6} l={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Published</h5>
                      <InputSwitch name="companyIsPublished" />
                    </div>
                  </Col>
                  <Col s={6} l={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Gifting</h5>
                      <InputSwitch name="isGiftingEnabled" />
                    </div>
                  </Col>
                  <Col s={6} l={4}>
                    <div className="center">
                      <h5 className={css(styles.subtitle)}>Advertiser</h5>
                      <InputSwitch name="isAdvertisingEnabled" />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col s={12}>
                <Field name="error" type="hidden" component={renderError} />
              </Col>

              <Col s={12}>
                <div className="center">
                  <Button
                    text="save"
                    icon="save"
                    submit={true}
                    disable={!valid}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
      </ModalConfirm>
    );
  }
}

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 20,
    fontWeight: 300,
    paddingTop: 20,
  },
});

export default reduxForm<{}, IProp>({
  enableReinitialize: true,
  form: "CompanyForm",
})(CompanyForm);
