import ISearch from "../types/search";
import IUser from "../types/user";
import UserRoles from "../types/user-roles";
import { IFilter, IParams } from "../utils/build-url";
import { filterDate } from "../utils/date";

const LIMIT = 20;

export const requestUsersSearched = (search: ISearch): IParams => {
  let filters: IFilter[] = [];
  const dateFilter = filterDate("userDateAdded", search);

  if (search.text) {
    filters = [...filters, { name: "userLogin~", value: search.text }];
  }

  if (dateFilter) {
    filters = [...filters, dateFilter];
  }

  return {
    filter: filters,
    limit: LIMIT,
    orderBy: "DESC",
    sortBy: "userId"
  };
};

export const requestNarrators = (): IParams => {
  return {
    filter: [{ name: "userRoleIdList", value: UserRoles.NARRATOR }],
    orderBy: "ASC",
    sortBy: "userFirstName"
  };
};

export const requestUser = (): IParams => {
  return {
    // include: ["newspapers"] //API already done that
  };
};

export const createDefaultUser = (): IUser => {
  return {
    token: "",
    userEmail: "",
    userId: 0,
    userRoleIdList: UserRoles.NARRATOR
  };
};

export const createDefaultUserNestedProps = (user?: IUser) => {
  if (!user || !user.newspapers) {
    return null;
  }

  const newspapersId = user.newspapersId
    ? user.newspapersId
    : user.newspapers.map(n => `${n.newspaperID}`);

  return { newspapersId };
};
