import _ from "lodash";
import ICountry from "src/types/country";
import ICountryGroup, { CountryGroupTitles } from "src/types/country-group";

export const transformCountriesToGroups = (countries?: ICountry[]): ICountryGroup[] => {

    if (!countries) {
        return [];
    }

    const commonCountries = _.remove(countries, (c) => ["IE", "US", "UK", "GB", "AU", "NZ", "CA", "IN"].includes(c.alpha2));
    const commonGroup: ICountryGroup = {
        title: CountryGroupTitles.COMMON,
        countries: commonCountries,
    }

    const countriesGrouped =
        _.chain(countries)
            .groupBy('continentCode')
            .map((value, key) => {

                const group: ICountryGroup = {
                    countries: value,
                    title: getCountryGroupTitleByContinent(key)
                }

                return group;
            })
            .value();

    return [
        commonGroup,
        ...countriesGrouped
    ];
}

const getCountryGroupTitleByContinent = (continentCode: string) => {

    switch (continentCode) {
        case 'AF': return CountryGroupTitles.AFRICA;
        case 'AS': return CountryGroupTitles.ASIA;
        case 'EU': return CountryGroupTitles.EUROPE;
        case 'NA': return CountryGroupTitles.NORTH_AMERICA;
        case 'OC': return CountryGroupTitles.OCEANIA;
        default: return CountryGroupTitles.OTHER;
    }
}