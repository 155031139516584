import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import IAdvertListens from "../types/advertlistens";

import { clearSearch, doSearch } from "../../../actions/search";
import { IState } from "../../../reducers";
import IAdvert from "../types/advert";
import * as errors from "../../../types/errors";
import ISearch from "../../../types/search";
import AdvertList from "../components/AdvertList";
import { fetchAdverts, fetchAdvertListens } from "../state/actions";
import { mergeAdvertsWithListens } from "../utils";

interface IPropsFromState {
    adverts: IAdvert[];
    advertListens?: IAdvertListens[];
    error?: errors.HttpError;
    isLoading: boolean;
    offset: number;
    search: ISearch;
}

interface IPropsFromDispatch {
    fetchAdverts: typeof fetchAdverts;
    fetchAdvertListens: typeof fetchAdvertListens;
    doSearch: typeof doSearch;
    clearSearch: typeof clearSearch;
}

class AdvertListContainer extends React.Component<
    IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>
> {

    public render() {

        return (
            <AdvertList {...this.props} />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {

    const advertsWithListens = mergeAdvertsWithListens(state.advert.adverts, state.advert.advertListens)
    return {
        adverts: advertsWithListens,
        advertListens: state.advert.advertListens,
        error: state.advert.error,
        isLoading: state.advert.isLoading,
        offset: state.advert.offset,
        search: state.search,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearSearch,
            doSearch,
            fetchAdverts,
            fetchAdvertListens
        },
        dispatch
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdvertListContainer)
);
