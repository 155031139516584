import _ from "lodash";
import IAdvert from "src/domaindriven/adverts/types/advert";
import IAdvertListens from "../types/advertlistens";

export const mapPriority = (priority: number) => {

    if (priority >= 3) { return "High" }
    if (priority <= 1) { return "Low" }
    return "Medium";
}

export const getListOfCountriesForTable = (advert: IAdvert): string => {

    if (!advert || !advert.countries || advert.countries.length <= 0) {
        return "All";
    }

    if (advert.countries.length > 2) {
        return `${advert.countries[0].name} & ${advert.countries.length - 1} more`;
    }

    return advert.countries.map(c => c.name).join(', ')
}

export const advertIsComplete = (advert: IAdvert) => {
    return advert.advertIsPublished === 1 && advert.advertAudioFileName != null && advert.priority != null && advert.companyId != null;
}

export const isLegacyAd = (advert: IAdvert) => {
    return advert.advertID === 88 || advert.advertID === 89;
}

export const mergeAdvertsWithListens = (adverts: IAdvert[], advertListens?: IAdvertListens[]) => {

    if (!adverts || !_.isArray(adverts) || adverts.length <= 0 || !advertListens || !_.isArray(advertListens) || advertListens.length <= 0) {
        return adverts;
    }

    const advertsWithListens = adverts.map(a => {

        if (a.totalListens) {
            return a;
        }

        const listenFigure = advertListens.find(al => al.advertId && a.advertID && +al.advertId === +a.advertID);

        if (!listenFigure) {
            return a;
        }

        return {
            ...a,
            totalListens: listenFigure["useradverthistories.totalListens"]
        }
    });

    return advertsWithListens;
}