import { HttpError } from "../../../types/errors";
import IAdvert from "../types/advert";
import IAdvertListens from "../types/advertlistens";
import { FETCH_ADVERTS_REQUEST, FETCH_ADVERTS_SUCCESS, FETCH_ADVERTS_FAILURE, FETCH_ADVERT_LISTENS_REQUEST, FETCH_ADVERT_LISTENS_SUCCESS, FETCH_ADVERT_LISTENS_FAILURE, FETCH_ADVERT_REQUEST, FETCH_ADVERT_SUCCESS, FETCH_ADVERT_FAILURE, CREATE_ADVERT_REQUEST, CREATE_ADVERT_SUCCESS, CREATE_ADVERT_FAILURE, UPDATE_ADVERT_REQUEST, UPDATE_ADVERT_SUCCESS, UPDATE_ADVERT_FAILURE, CLEAR_ADVERT_STATE } from "./constants";

export interface IState {
    isLoading: boolean;
    error?: HttpError;
    adverts: IAdvert[];
    advert?: IAdvert;
    advertListens?: IAdvertListens[];
    offset: number;
}

export interface IAction {
    type: string;
    error?: HttpError;
    adverts?: IAdvert[];
    advertListens?: IAdvertListens[];
    advert?: IAdvert;
    offset?: number;
}

export const initialState: IState = {
    adverts: [],
    isLoading: false,
    offset: 0,
    advertListens: [],
};

export const reducer = (
    state: IState = initialState,
    action: IAction
): IState => {
    switch (action.type) {
        case FETCH_ADVERTS_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_ADVERTS_SUCCESS:
            return {
                ...state,
                adverts: action.adverts || [],
                isLoading: false,
                offset: action.offset || 0
            };
        case FETCH_ADVERTS_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case FETCH_ADVERT_LISTENS_REQUEST:
            return { ...state };
        case FETCH_ADVERT_LISTENS_SUCCESS:
            return {
                ...state,
                advertListens: action.advertListens || [],
            };
        case FETCH_ADVERT_LISTENS_FAILURE:
            return { ...state, advertListens: [] };

        case FETCH_ADVERT_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_ADVERT_SUCCESS:
            return {
                ...state,
                advert: action.advert,
                isLoading: false
            };
        case FETCH_ADVERT_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case CREATE_ADVERT_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case CREATE_ADVERT_SUCCESS:
            return {
                ...state,
                advert: action.advert,
                isLoading: false
            };
        case CREATE_ADVERT_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case UPDATE_ADVERT_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case UPDATE_ADVERT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UPDATE_ADVERT_FAILURE:
            return { ...state, isLoading: false, error: action.error };

        case CLEAR_ADVERT_STATE:
            return { ...initialState };

        default:
            return state;
    }
};
