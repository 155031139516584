import * as React from "react";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Card, Col, Loading } from "styleguide";

import { clearPronunciationState, createPronunciation, editPronunciation, fetchPronunciation } from "../actions/pronunciation";
import PronunciationForm from "../components/PronunciationForm";
import { IState } from "../reducers";
import { createDefaultPronunciation } from "../services/pronunciation";
import * as errors from "../types/errors";
import IPronunciation from "../types/pronunciation";
import { correctValue } from "../utils/values";
import { Helmet } from "react-helmet";
import IUser from "../types/user";

interface IPropsFromState {
    error?: errors.HttpError;
    pronunciation: IPronunciation;
    isLoading: boolean;
    user?: IUser;
}

interface IPropsFromDispatch {
    clearPronunciationState: typeof clearPronunciationState;
    createPronunciation: typeof createPronunciation;
    editPronunciation: typeof editPronunciation;
    fetchPronunciation: typeof fetchPronunciation;
}

class MyComponent extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id: string }>> {
    public renderForm = () => {
        const { pronunciation } = this.props;

        const params = {
            initialValues: { ...pronunciation, error: this.props.error },
            onSubmit: this.submitForm,
            pronunciation,
        };

        return (
            <Col s={12} m={8} mOffset={2} l={6} lOffset={3}>
                <Card title="Pronunciation">
                    <PronunciationForm {...params} />
                </Card>
            </Col>
        );
    };

    public submitForm = (pronunciation: IPronunciation | any) => {
        const { pronunciation: oldPronunciation, history, user } = this.props;

        if (oldPronunciation && oldPronunciation.pronunciationId) {
            return this.props.editPronunciation(pronunciation, user, history);
        }
        return this.props.createPronunciation(pronunciation, history);
    };

    public componentWillUnmount() {
        this.props.clearPronunciationState();
    }

    public componentDidMount() {
        const { match } = this.props;

        if (match.params.id) {
            this.props.fetchPronunciation(+match.params.id);
        }
    }

    public render() {
        const title = `Pronunciation Editor | ${this.props.pronunciation && this.props.pronunciation.pronunciationName ? this.props.pronunciation.pronunciationName : "New"}`;

        return (
            <React.Fragment>
                {/* 
                // @ts-ignore */}
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {this.props.isLoading && <Loading />}
                {this.renderForm()}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const {
        // @ts-ignore
        form: { PronunciationForm: pronunciationForm },
        pronunciation: { pronunciation },
    } = state;

    const pronunciationValues = pronunciationForm ? pronunciationForm.values : {};

    const prePronunciation = correctValue("pronunciationId", pronunciationValues, pronunciation);
    const validatedPronunciation = prePronunciation ? prePronunciation : createDefaultPronunciation();

    return {
        error: state.pronunciation.error,
        isLoading: state.pronunciation.isLoading,
        pronunciation: validatedPronunciation,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            clearPronunciationState,
            createPronunciation,
            editPronunciation,
            fetchPronunciation,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyComponent));
