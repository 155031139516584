import * as errors from "../types/errors";

import {
    CLEAR_SECTION_STATE,
    CREATE_SECTION_FAILURE,
    CREATE_SECTION_REQUEST,
    CREATE_SECTION_SUCCESS,
    FETCH_SECTION_FAILURE,
    FETCH_SECTION_REQUEST,
    FETCH_SECTION_SUCCESS,
    FETCH_SECTIONS_FAILURE,
    FETCH_SECTIONS_REQUEST,
    FETCH_SECTIONS_SUCCESS,
    UPDATE_SECTION_FAILURE,
    UPDATE_SECTION_REQUEST,
    UPDATE_SECTION_SUCCESS,
} from "../constants/section";
import ISection from "../types/section";

export interface IState {
    sections: ISection[];
    section?: ISection;
    isLoading: boolean;
    error?: errors.HttpError;
}

export interface IAction {
    type: string;
    sections?: ISection[];
    section?: ISection;
    error?: errors.HttpError;
}

export const initialState: IState = {
    isLoading: false,
    sections: [],
};

export const reducer = (state: IState = initialState, action: IAction): IState => {
    switch (action.type) {
        case FETCH_SECTIONS_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_SECTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                sections: action.sections ? action.sections.sort((a, b) => a.sectionName.localeCompare(b.sectionName)) : [],
            };
        case FETCH_SECTIONS_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case FETCH_SECTION_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case FETCH_SECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                section: action.section,
            };
        case FETCH_SECTION_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case CREATE_SECTION_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case CREATE_SECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                section: action.section,
            };
        case CREATE_SECTION_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case UPDATE_SECTION_REQUEST:
            return { ...state, isLoading: true, error: undefined };
        case UPDATE_SECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_SECTION_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case CLEAR_SECTION_STATE:
            return { ...initialState };
        default:
            return state;
    }
};
