import { History } from "history";
import { Dispatch } from "redux";

import { toast } from "styleguide";
import config from "../config";
import fetch from "../utils/fetch";

import {
  CLEAR_CATEGORY_STATE,
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS
} from "../constants/category";
import { IState } from "../reducers";
import { requestAllCategories } from "../services/category";
import ICategory from "../types/category";
import IFile from "../types/file";
import FileType from "../types/file-type";
import HttpMethods from "../types/http-methods";
import { buildUrl, IParams } from "../utils/build-url";
import responseCheck from "../utils/response-check";
import { clearFiles, populateWithFiles, uploadFiles } from "./file";

/*
    ASYNC ACTIONS
*/

export const fetchCategories = (params?: IParams) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  const endpoint = `${config.api.url}${config.api.paths.category}`;

  const url = !params
    ? buildUrl(endpoint, requestAllCategories())
    : buildUrl(endpoint, params);

  return fetch(url)
    .then(responseCheck)
    .then(categories => dispatch(fetchCategoriesSuccess(categories)))
    .catch(error => dispatch(fetchCategoriesFailure(error)));
};

export const fetchCategory = (id: number) => (
  dispatch: Dispatch
): Promise<any> => {
  dispatch({ type: FETCH_CATEGORY_REQUEST });

  return fetch(`${config.api.url}${config.api.paths.category}/${id}`)
    .then(responseCheck)
    .then(category => dispatch(fetchCategorySuccess(category)))
    .catch(error => dispatch(fetchCategoryFailure(error)));
};

export const createCategory = (category: ICategory, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: CREATE_CATEGORY_REQUEST });

  return uploadFiles("category")(dispatch, getState)
    .then(() => {
      populateWithFiles(getState, (files: IFile[]) => {
        if (files[0].type === FileType.image) {
          category.categoryImage = files[0].key;
        }
      });

      return fetch(`${config.api.url}${config.api.paths.category}`, {
        body: JSON.stringify({ model: category }),
        method: HttpMethods.POST
      });
    })
    .then(responseCheck)
    .then(cat => dispatch(createCategorySuccess(cat)))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.category))
    .catch(error => dispatch(createCategoryFailure(error)));
};

export const editCategory = (category: ICategory, history: History) => (
  dispatch: Dispatch,
  getState: () => IState
): Promise<any> => {
  dispatch({ type: UPDATE_CATEGORY_REQUEST });

  return uploadFiles("category")(dispatch, getState)
    .then(() => {
      populateWithFiles(getState, (files: IFile[]) => {
        if (files[0].type === FileType.image) {
          category.categoryImage = files[0].key;
        }
      });

      return fetch(
        `${config.api.url}${config.api.paths.category}/${category.categoryId}`,
        {
          body: JSON.stringify({ model: category }),
          method: HttpMethods.PUT
        }
      );
    })
    .then(responseCheck)
    .then(() => dispatch(editCategorySuccess()))
    .then(() => dispatch(clearFiles()))
    .then(() => history.push(config.paths.category))
    .catch(error => dispatch(editCategoryFailure(error)));
};

/*
    SYNC ACTIONS
*/

const fetchCategoriesSuccess = (categories: ICategory[]) => {
  return { type: FETCH_CATEGORIES_SUCCESS, categories };
};

const fetchCategoriesFailure = (error: Error) => {
  toast("Error fetching categories");
  return { type: FETCH_CATEGORIES_FAILURE, error };
};

const fetchCategorySuccess = (category: ICategory) => {
  return { type: FETCH_CATEGORY_SUCCESS, category };
};

const fetchCategoryFailure = (error: Error) => {
  toast("Error fetching category");
  return { type: FETCH_CATEGORY_FAILURE, error };
};

const createCategorySuccess = (category: ICategory) => {
  toast("Category created");
  return { type: CREATE_CATEGORY_SUCCESS, category };
};

const createCategoryFailure = (error: Error) => {
  toast("Error creating category");
  return { type: CREATE_CATEGORY_FAILURE, error };
};

const editCategorySuccess = () => {
  toast("Category updated");
  return { type: UPDATE_CATEGORY_SUCCESS };
};

const editCategoryFailure = (error: Error) => {
  toast("Error updating category");
  return { type: UPDATE_CATEGORY_FAILURE, error };
};

export const clearCategoryState = () => {
  return { type: CLEAR_CATEGORY_STATE };
};
